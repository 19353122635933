<template>
  <div class="no-tab-content-inside">
    <h3 class="title-page d-flex">
      <div class="d-flex align-items-center">
        <div class="me-2">Transactions</div>
      </div>
      <div
        class="wrap-datepicker"
        style="top: 6px !important; margin-left: auto; display: flex"
        v-if="rawDateRange"
      >
        <!-- <el-date-picker
          :editable="false"
          v-model="rangeTime"
          type="daterange"
          prefix-icon="el-icon-arrow-down"
          :align="'right'"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          :format="'MMM dd'"
          :clearable="false"
          @change="onUpdateDate()"
        >
        </el-date-picker> -->

        <DatePickerDropdown
          :rawDateRange="rawDateRange"
          :hideDateType="true"
          ref="datepicker"
          @onChangeDate="onUpdateDate"
        />

        <n-button
          style="
            margin: 0px 0px 0px 20px;
            padding: 10px;
            font-size: 10px;
            background-color: #396ad5;
            font-weight: 600;
            color: white;
            white-space: nowrap;
          "
          slot="title"
          @click.native="onReset"
        >
          Reset To Default
        </n-button>
      </div>
    </h3>

    <tabs
      type="primary"
      v-on:change="onTabChange"
      :value="'All Transactions'"
      v-if="tabsCount"
    >
      <tab-pane label="Refunded" :badge="tabsCount.refund || 0"> </tab-pane>
      <tab-pane label="Transfers" :badge="tabsCount.transfer || 0"> </tab-pane>
      <tab-pane label="Orders" :badge="tabsCount.spendCredit || 0"> </tab-pane>
      <tab-pane label="Reloads" :badge="tabsCount.addCredit || 0"> </tab-pane>

      <tab-pane label="All Transactions" :badge="totalTrasaction"> </tab-pane>
    </tabs>

    <div class="tab-content-outside">
      <div class="search-table">
        <el-input
          placeholder="Search by Transaction, User, Vendor, Type or Date"
          v-model="filters[0].value"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>

      <data-tables-server
        v-show="!loading"
        class="event-table"
        :data="orders.result"
        :filters="filters"
        :total="orders.count"
        @query-change="loadData"
        :page-size="params ? params.pageSize : 50"
        :pagination-props="{
          'prev-text': 'Prev',
          'next-text': 'Next',
          pageSizes: [25, 50, 100, 200],
          layout: 'slot, ->, prev, pager, next',
        }"
      >
        <span slot="pagination">
          <span>Showing</span>
          <el-select
            v-if="params"
            v-model="params.pageSize"
            @change="filterData"
          >
            <el-option :label="'25'" :value="25"> </el-option>
            <el-option :label="'50'" :value="50"> </el-option>
            <el-option :label="'100'" :value="100"> </el-option>
            <el-option :label="'200'" :value="200"> </el-option>
          </el-select>
          <span>of {{ orders.count }}</span>
        </span>

        <el-table-column
          :prop="'id'"
          :label="'Order ID'"
          sortable="custom"
          :minWidth="130"
        >
          <template slot-scope="scope">
            <a href="javascript:void(0)" @click="onClickView(scope.row)">{{
              scope.row.id
            }}</a>
          </template>
        </el-table-column>

        <el-table-column
          :cell-style="rowStyle"
          v-for="title in titles"
          :prop="title.prop"
          :label="title.label"
          :key="title.prop"
          :sortable="title.sortable === false ? false : 'custom'"
          :minWidth="130"
          :formatter="title.formatter"
          v-if="
            title.prop !== 'action' &&
            title.prop !== 'timestamp' &&
            title.prop !== 'id'
          "
        >
        </el-table-column>

        <el-table-column
          :cell-style="rowStyle"
          v-for="title in titles"
          :prop="title.prop"
          :label="title.label"
          :key="title.prop"
          :minWidth="150"
          :formatter="title.formatter"
          v-if="title.prop === 'action'"
        >
          <template slot-scope="scope">
            <div
              :class="
                'inline-flex items-center capitalize gap-x-1 whitespace-nowrap rounded px-1.5 py-0.5 text-xs font-semibold ring-1 bg-gray-50 text-gray-700 ring-gray-600/30 ' +
                (scope.row.action === 'addCredit'
                  ? 'bg-blue-50 text-blue-700 ring-blue-600/30'
                  : scope.row.action === 'spendCredit'
                  ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/30'
                  : scope.row.action === 'authorized'
                  ? 'bg-red-50 text-red-800 ring-red-600/30'
                  : scope.row.action === 'transfer'
                  ? 'bg-purple-50 text-purple-800 ring-purple-600/30'
                  : 'bg-green-50 text-green-800 ring-green-600/30')
              "
              disable-transitions
            >
              <span v-if="scope.row.action === 'addCredit'">Reload</span>
              <span v-if="scope.row.action === 'refund'">Refund</span>
              <span v-if="scope.row.action === 'authorized'">Reversed</span>
              <span v-if="scope.row.action === 'spendCredit'">Order</span>
              <span v-if="scope.row.action === 'partialRefund'"
                >Partial Refund</span
              >
              <span v-if="scope.row.action === 'transfer'">Transfer</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :cell-style="rowStyle"
          v-for="title in titles"
          :prop="title.prop"
          :label="title.label"
          :key="title.prop"
          sortable="custom"
          :minWidth="190"
          :formatter="title.formatter"
          v-if="title.prop === 'timestamp'"
        >
        </el-table-column>

        <el-table-column :label="''">
          <template slot-scope="scope">
            <div :class="'action-cl'" disable-transitions>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <img
                    class="dropdown-dot"
                    src="assets/imgs/dot-dropdown.svg"
                    alt=""
                  />
                </span>
                <el-dropdown-menu class="dropdown-table" slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    @click.native="onClickView(scope.row)"
                    ><svg
                      width="16px"
                      height="11px"
                      viewBox="0 0 16 11"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="📦-Orders"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Orders"
                          transform="translate(-1267, -359)"
                          fill="currentColor"
                          fill-rule="nonzero"
                        >
                          <g
                            id="dropdown-copy-2"
                            transform="translate(1255, 345)"
                          >
                            <g id="Group-16" transform="translate(12, 11)">
                              <path
                                d="M8,5.29165197 C7.70559036,5.29641209 7.41335972,5.34464549 7.13222057,5.43489583 C7.26226545,5.67074173 7.33150682,5.93703121 7.33333207,6.20833333 C7.33333207,7.09429012 6.6368848,7.8125 5.77777356,7.8125 C5.51469222,7.81061772 5.25647162,7.73921269 5.02777213,7.60510417 C4.65773102,8.92856003 5.15040745,10.3477198 6.2508765,11.1282695 C7.35134554,11.9088192 8.81268162,11.8756173 9.87874747,11.0458436 C10.9448133,10.2160698 11.3764028,8.77591051 10.9501991,7.47054548 C10.5239955,6.16518045 9.33563116,5.28751025 8,5.29165197 L8,5.29165197 Z M15.9033483,8.08177083 C14.3969566,5.05075521 11.4147287,3 8,3 C4.58527129,3 1.60221007,5.0521875 0.0966516601,8.08205729 C-0.03221722,8.34494005 -0.03221722,8.65534641 0.0966516601,8.91822917 C1.60304341,11.9492448 4.58527129,14 8,14 C11.4147287,14 14.3977899,11.9478125 15.9033483,8.91794271 C16.0322172,8.65505995 16.0322172,8.34465359 15.9033483,8.08177083 Z M8,12.625 C5.25971702,12.625 2.74749003,11.0494792 1.39082078,8.5 C2.74749003,5.95052083 5.25943924,4.375 8,4.375 C10.7405608,4.375 13.25251,5.95052083 14.6091792,8.5 C13.2527878,11.0494792 10.7405608,12.625 8,12.625 Z"
                                id="Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    View</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    @click.native="onClickRefund(scope.row)"
                  >
                    <svg
                      width="14.0000019px"
                      height="14px"
                      viewBox="0 0 14.0000019 14"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="💸-Transactions"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Transactions"
                          transform="translate(-1269, -432)"
                          fill="currentColor"
                          fill-rule="nonzero"
                        >
                          <g id="dropdown" transform="translate(1255, 385)">
                            <g id="refund" transform="translate(14, 44)">
                              <g id="undo-alt" transform="translate(0, 3)">
                                <path
                                  d="M0.578205645,0.578205645 L2.05060484,2.05060484 C3.3172379,0.783745968 5.06695565,0 6.99997177,0 C10.8573387,0 14.0028226,3.14788306 14,7.00522177 C13.9971774,10.8688266 10.864254,14 7,14 C5.1956371,14 3.55075,13.3173024 2.30949194,12.1961452 C2.16517339,12.0657984 2.15839919,11.8416008 2.2958871,11.7040847 L2.85300806,11.1469637 C2.97996774,11.020004 3.18474597,11.0119032 3.3187621,11.1313831 C4.29633468,12.0029113 5.58574597,12.5322581 7,12.5322581 C10.0574476,12.5322581 12.5322581,10.0579274 12.5322581,7 C12.5322581,3.94255242 10.0579274,1.46774194 7,1.46774194 C5.47120565,1.46774194 4.08831048,2.08653629 3.08750806,3.08750806 L4.61531452,4.61534274 C4.82870161,4.82872984 4.67758065,5.19354839 4.37581855,5.19354839 L0.338709677,5.19354839 C0.151657258,5.19354839 0,5.04189113 0,4.85483871 L0,0.817729839 C0,0.515967742 0.364846774,0.364846774 0.578205645,0.578205645 Z"
                                  id="Path"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Refund</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </data-tables-server>

      <spinner v-if="loading" />
    </div>

    <el-dialog
      :visible.sync="modalViewVisible"
      :append-to-body="true"
      :custom-class="'md-dialog hidden-header'"
      :before-close="handleClose"
    >
      <div class="row" v-if="modalViewVisible && !isLoadingTransactionDetail">
        <div class="col-12 col-md-3 left-panel">
          <h3 class="modal-title-n">Transaction</h3>
          <!-- <span class="small text-order-id"># {{ currentView.id }}</span> -->
          <div
            class="infor-modal"
            :class="selectedTabModal === 'transaction' ? 'active' : ''"
            @click="selectedTabModal = 'transaction'"
          >
            <svg
              width="17px"
              height="17px"
              viewBox="0 0 17 17"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="💸-Transactions"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Transaction-Details---Transfer"
                  transform="translate(-304, -205)"
                  fill="currentColor"
                  fill-rule="nonzero"
                >
                  <g id="Group" transform="translate(290, 195)">
                    <g id="info-circle" transform="translate(14, 10)">
                      <path
                        d="M8.5,0 C3.80590927,0 0,3.80728024 0,8.5 C0,13.1954617 3.80590927,17 8.5,17 C13.1940907,17 17,13.1954617 17,8.5 C17,3.80728024 13.1940907,0 8.5,0 Z M8.5,15.3548387 C4.71160484,15.3548387 1.64516129,12.2896633 1.64516129,8.5 C1.64516129,4.71287298 4.71174194,1.64516129 8.5,1.64516129 C12.2869899,1.64516129 15.3548387,4.71170766 15.3548387,8.5 C15.3548387,12.2883266 12.2896633,15.3548387 8.5,15.3548387 Z M8.5,3.77016129 C9.29502419,3.77016129 9.93951613,4.41465323 9.93951613,5.20967742 C9.93951613,6.00470161 9.29502419,6.64919355 8.5,6.64919355 C7.70497581,6.64919355 7.06048387,6.00470161 7.06048387,5.20967742 C7.06048387,4.41465323 7.70497581,3.77016129 8.5,3.77016129 Z M10.4193548,12.4758065 C10.4193548,12.7029415 10.2351996,12.8870968 10.0080645,12.8870968 L6.99193548,12.8870968 C6.7648004,12.8870968 6.58064516,12.7029415 6.58064516,12.4758065 L6.58064516,11.6532258 C6.58064516,11.4260907 6.7648004,11.2419355 6.99193548,11.2419355 L7.40322581,11.2419355 L7.40322581,9.0483871 L6.99193548,9.0483871 C6.7648004,9.0483871 6.58064516,8.86423185 6.58064516,8.63709677 L6.58064516,7.81451613 C6.58064516,7.58738105 6.7648004,7.40322581 6.99193548,7.40322581 L9.18548387,7.40322581 C9.41261895,7.40322581 9.59677419,7.58738105 9.59677419,7.81451613 L9.59677419,11.2419355 L10.0080645,11.2419355 C10.2351996,11.2419355 10.4193548,11.4260907 10.4193548,11.6532258 L10.4193548,12.4758065 Z"
                        id="Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span style="margin-left: 10px"> Transaction Details</span>
          </div>

          <div
            v-if="currentView && currentView.action === 'authorized'"
            class="infor-modal"
            :class="selectedTabModal === 'reverse' ? 'active' : ''"
            @click="selectedTabModal = 'reverse'"
          >
            <svg
              width="16.7802631px"
              height="17.0000021px"
              viewBox="0 0 16.7802631 17.0000021"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="💸-Transactions"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Transaction-Details---Transfer-Reversed"
                  transform="translate(-304, -249)"
                  fill="currentColor"
                  fill-rule="nonzero"
                >
                  <g id="Group-10" transform="translate(304, 239)">
                    <g id="history" transform="translate(0, 10)">
                      <path
                        d="M16.7802485,8.48395968 C16.788871,13.1671855 12.9695605,16.9967097 8.28633468,17 C6.09230645,17.0015423 4.09209879,16.1718327 2.58331452,14.8084395 C2.40861895,14.6505726 2.40166129,14.3785383 2.56813105,14.2120685 L3.14942137,13.6307782 C3.30464919,13.4755504 3.55303427,13.4710948 3.71683065,13.6172399 C4.92849194,14.698248 6.52704032,15.3548387 8.28023387,15.3548387 C12.0692117,15.3548387 15.1350726,12.2883952 15.1350726,8.5 C15.1350726,4.71102218 12.068629,1.64516129 8.28023387,1.64516129 C6.37733065,1.64516129 4.65717742,2.41886694 3.4159375,3.6688125 L5.09849194,5.32812903 C5.36038105,5.58638508 5.17749395,6.03225806 4.80969758,6.03225806 L0.411290323,6.03225806 C0.184155242,6.03225806 5.86197757e-14,5.84810282 5.86197757e-14,5.62096774 L5.86197757e-14,1.28332863 C5.86197757e-14,0.918274194 0.440183468,0.734153226 0.700084677,0.990489919 L2.24574798,2.5148004 C3.78572177,0.96197379 5.92045565,-2.66453526e-14 8.28023387,-2.66453526e-14 C12.9693206,-2.66453526e-14 16.7715625,3.79692944 16.7802485,8.48395968 Z M11.0544556,11.5745665 L11.5382702,10.9093387 C11.671871,10.725629 11.631256,10.4684012 11.4475464,10.3348004 L9.10281452,8.6294879 L9.10281452,3.7016129 C9.10281452,3.47447782 8.91865927,3.29032258 8.69152419,3.29032258 L7.86894355,3.29032258 C7.64180847,3.29032258 7.45765323,3.47447782 7.45765323,3.7016129 L7.45765323,9.46728629 L10.4799173,11.6652903 C10.663627,11.7988569 10.9208548,11.7582419 11.0544556,11.5745665 L11.0544556,11.5745665 Z"
                        id="Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span> Reverse Details</span>
          </div>

          <!-- <div class="wrap-select-bottom">
            <el-select placeholder="Transaction Options" style="width: 190px">
              <el-option label="Transaction Options" value="transction">
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="col-12 col-md-9 right-panel" v-if="currentView">
          <button
            type="button"
            class="close-button-md"
            @click="modalViewVisible = false"
          >
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div
            class="wrap-content-md"
            v-if="selectedTabModal === 'transaction'"
          >
            <div
              v-if="currentView.action === 'authorized'"
              class="overlay-bg-disabled"
            ></div>

            <div class="infor-modal">
              <svg
                width="14.3px"
                height="14.3px"
                viewBox="0 0 14.3 14.3"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="💸-Transactions"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Transaction-Details---Transfer"
                    transform="translate(-564.85, -145.85)"
                    fill="#021B43"
                    fill-rule="nonzero"
                    stroke="#021B43"
                    stroke-width="0.3"
                  >
                    <g id="info-circle" transform="translate(565, 146)">
                      <path
                        d="M7,1 C10.2950278,1 13,3.66875 13,7 C13,10.3136389 10.3163889,13 7,13 C3.68766667,13 1,10.3177222 1,7 C1,3.68880556 3.68338889,1 7,1 M7,0 C3.13427823,0 0,3.13540726 0,7 C0,10.8668508 3.13427823,14 7,14 C10.8657218,14 14,10.8668508 14,7 C14,3.13540726 10.8657218,0 7,0 Z M5.98387097,9.70967742 L6.32258065,9.70967742 L6.32258065,6.32258065 L5.98387097,6.32258065 C5.79681855,6.32258065 5.64516129,6.17092339 5.64516129,5.98387097 L5.64516129,5.75806452 C5.64516129,5.5710121 5.79681855,5.41935484 5.98387097,5.41935484 L7.33870968,5.41935484 C7.5257621,5.41935484 7.67741935,5.5710121 7.67741935,5.75806452 L7.67741935,9.70967742 L8.01612903,9.70967742 C8.20318145,9.70967742 8.35483871,9.86133468 8.35483871,10.0483871 L8.35483871,10.2741935 C8.35483871,10.461246 8.20318145,10.6129032 8.01612903,10.6129032 L5.98387097,10.6129032 C5.79681855,10.6129032 5.64516129,10.461246 5.64516129,10.2741935 L5.64516129,10.0483871 C5.64516129,9.86133468 5.79681855,9.70967742 5.98387097,9.70967742 Z M7,2.93548387 C6.50116532,2.93548387 6.09677419,3.339875 6.09677419,3.83870968 C6.09677419,4.33754435 6.50116532,4.74193548 7,4.74193548 C7.49883468,4.74193548 7.90322581,4.33754435 7.90322581,3.83870968 C7.90322581,3.339875 7.49883468,2.93548387 7,2.93548387 Z"
                        id="Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
              <span style="margin-left: 10px"> Details</span>

              <div
                :class="
                  'inline-flex items-center capitalize gap-x-1 whitespace-nowrap rounded px-1.5 py-0.5 text-xs font-semibold ring-1 bg-gray-50 text-gray-700 ring-gray-600/30 ' +
                  (currentView.action === 'addCredit'
                    ? 'bg-blue-50 text-blue-700 ring-blue-600/30'
                    : currentView.action === 'spendCredit'
                    ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/30'
                    : currentView.action === 'authorized'
                    ? 'bg-red-50 text-red-800 ring-red-600/30'
                    : currentView.action === 'transfer'
                    ? 'bg-purple-50 text-purple-800 ring-purple-600/30'
                    : 'bg-green-50 text-green-800 ring-green-600/30')
                "
                disable-transitions
              >
                <span v-if="currentView.action === 'addCredit'">Reload</span>
                <span v-if="currentView.action === 'refund'">Refund</span>
                <span v-if="currentView.action === 'authorized'">Reversed</span>
                <span v-if="currentView.action === 'spendCredit'">Order</span>
                <span v-if="currentView.action === 'partialRefund'"
                  >Partial Refund</span
                >
                <span v-if="currentView.action === 'transfer'">Transfer</span>
              </div>
            </div>

            <div class="wrap-infor-flex">
              <div class="flex-item">
                <span class="type-m">Amount</span>
                <span
                  class="value-m"
                  v-if="currentView.amount"
                  :style="{
                    color: currentView.amount > 0 ? '#2d61d3' : '#f27474',
                  }"
                  >{{ Math.abs(currentView.amount) }} Tokens (${{
                    Math.abs(currentView.amount) * event.token_value
                  }})</span
                >
                <span class="value-m" v-else>0 Tokens ($0)</span>
              </div>
              <div class="flex-item">
                <span class="type-m">Type</span>
                <span class="value-m" style="text-transform: capitalize">{{
                  currentView.action
                }}</span>
              </div>
              <div class="flex-item">
                <span class="type-m">Transaction #</span>
                <span class="value-m">{{ currentView.id }}</span>
              </div>
              <div class="flex-item">
                <span class="type-m">Transaction Date</span>
                <span class="value-m">{{
                  currentView.timestamp | moment("MMM Do, YYYY hh:mm A")
                }}</span>
              </div>
            </div>

            <div class="infor-modal">
              <svg
                width="14px"
                height="12px"
                viewBox="0 0 14 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="💸-Transactions"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Transaction-Details---Transfer"
                    transform="translate(-565, -287)"
                    fill="#021B43"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M571.415974,299.743245 L568.551419,296.772635 C567.745711,295.951889 568.954273,294.720479 569.760266,295.541226 L570.740871,296.543933 C570.964113,296.772206 571.145164,296.696534 571.145164,296.374816 L571.145164,292.382613 C571.145164,291.905561 571.527831,291.514096 571.999875,291.514096 C572.470959,291.514096 572.854587,291.902944 572.854587,292.382613 L572.854587,296.374816 C572.854587,296.694799 573.035632,296.77225 573.258963,296.543933 L574.23977,295.541226 C575.045478,294.720479 576.254324,295.951889 575.448616,296.772635 L572.61342,299.739144 C572.281941,300.085975 571.747027,300.086554 571.415974,299.743245 Z M567.714286,288.032359 L567.714286,289.772803 C567.714286,290.253647 567.33053,290.643449 566.857143,290.643449 C566.383756,290.643449 566,290.253647 566,289.772803 L566,287.159527 C566,286.517892 566.515004,286 567.142977,286 L576.857023,286 C577.487649,286 578,286.521407 578,287.159527 L578,289.772803 C578,290.253647 577.616244,290.643449 577.142857,290.643449 C576.66947,290.643449 576.285714,290.253647 576.285714,289.772803 L576.285714,288.032359 C576.285714,287.868373 576.158032,287.741293 576.000528,287.741293 L567.999472,287.741293 C567.842104,287.741293 567.714286,287.871608 567.714286,288.032359 Z"
                      id="Shape"
                      transform="translate(572, 293) rotate(270) translate(-572, -293)"
                    ></path>
                  </g>
                </g>
              </svg>
              <span style="margin-left: 10px"> Sender</span>
            </div>

            <div
              class="wrap-infor-flex"
              v-if="transactionFromUser && transactionFromUser.first_name"
            >
              <div class="flex-item">
                <span class="type-m">Name</span>
                <span>
                  <router-link
                    class="value-m"
                    target="_blank"
                    :to="{
                      name: 'Users',
                      params: {
                        id: $route.params.id,
                      },
                      query: {
                        userId: transactionFromUser.id,
                      },
                    }"
                    >{{ transactionFromUser.first_name }}
                    {{ transactionFromUser.last_name }}</router-link
                  >
                </span>
              </div>
              <div class="flex-item">
                <span class="type-m">Phone</span>
                <span class="value-m">{{
                  transactionFromUser.phone_number
                }}</span>
              </div>
              <div class="flex-item">
                <span class="type-m">Email </span>
                <span class="value-m">{{ transactionFromUser.email }}</span>
              </div>
            </div>
            <div class="wrap-infor-flex" v-else>
              <div class="flex-item">
                <span class="type-m">Name</span>
                <span> Support </span>
              </div>
            </div>

            <div class="infor-modal">
              <svg
                width="14px"
                height="12px"
                viewBox="0 0 14 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="💸-Transactions"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Transaction-Details---Transfer"
                    transform="translate(-565, -483)"
                    fill="#021B43"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M572.584026,487.770851 L575.448581,490.741461 C576.254289,491.562207 575.045727,492.793617 574.239734,491.97287 L573.259129,490.970163 C573.035887,490.74189 572.854836,490.817562 572.854836,491.13928 L572.854836,495.131483 C572.854836,495.608535 572.472169,496 572.000125,496 C571.529041,496 571.145413,495.611152 571.145413,495.131483 L571.145413,491.13928 C571.145413,490.819297 570.964368,490.741846 570.741037,490.970163 L569.76023,491.97287 C568.954522,492.793617 567.745676,491.562207 568.551384,490.741461 L571.38658,487.774952 C571.718059,487.428121 572.252973,487.427542 572.584026,487.770851 Z M567.714286,484.032359 L567.714286,485.772803 C567.714286,486.253647 567.33053,486.643449 566.857143,486.643449 C566.383756,486.643449 566,486.253647 566,485.772803 L566,483.159527 C566,482.517892 566.515004,482 567.142977,482 L576.857023,482 C577.487649,482 578,482.521407 578,483.159527 L578,485.772803 C578,486.253647 577.616244,486.643449 577.142857,486.643449 C576.66947,486.643449 576.285714,486.253647 576.285714,485.772803 L576.285714,484.032359 C576.285714,483.868373 576.158032,483.741293 576.000528,483.741293 L567.999472,483.741293 C567.842104,483.741293 567.714286,483.871608 567.714286,484.032359 Z"
                      id="Shape"
                      transform="translate(572, 489) scale(-1, 1) rotate(270) translate(-572, -489)"
                    ></path>
                  </g>
                </g>
              </svg>
              <span
                v-if="transactionToUser && transactionToUser.first_name"
                style="margin-left: 10px"
              >
                Receiver</span
              >
              <span v-else style="margin-left: 10px">Vendor</span>
            </div>

            <div
              class="wrap-infor-flex"
              v-if="transactionToUser && transactionToUser.first_name"
            >
              <div class="flex-item">
                <span class="type-m">Name</span>
                <router-link
                  class="value-m"
                  target="_blank"
                  :to="{
                    name: 'Users',
                    params: {
                      id: $route.params.id,
                    },
                    query: {
                      userId: transactionToUser.id,
                    },
                  }"
                  >{{ transactionToUser.first_name }}
                  {{ transactionToUser.last_name }}</router-link
                >
              </div>
              <div class="flex-item">
                <span class="type-m">Phone</span>
                <span class="value-m">{{
                  transactionToUser.phone_number
                }}</span>
              </div>
              <div class="flex-item">
                <span class="type-m">Email </span>
                <span class="value-m">{{ transactionToUser.email }}</span>
              </div>
            </div>
            <div
              class="wrap-infor-flex"
              v-if="transactionToUser && transactionToUser.name"
            >
              <div class="flex-item">
                <span class="type-m">Name</span>
                <router-link
                  class="value-m"
                  target="_blank"
                  :to="{
                    name: 'VendorDashboard',
                    params: {
                      id: currentEvent.id,
                      vendorId: transactionToUser.id,
                    },
                  }"
                >
                  {{ transactionToUser.name }}</router-link
                >
              </div>

              <div class="flex-item">
                <span class="type-m"> Order Id:</span>
                <router-link
                  class="value-m"
                  target="_blank"
                  :to="{
                    name: 'Orders',
                    params: {
                      id: currentEvent.id,
                      orderId: currentView.order.id,
                    },
                  }"
                >
                  {{ currentView.order.order_display_id }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <spinner v-if="isLoadingTransactionDetail" />
    </el-dialog>

    <el-dialog
      title="Refund Stripe Transactions"
      v-if="currentRow"
      :visible.sync="modalVisible"
      :before-close="handleClose"
    >
      <div
        class="row"
        v-if="
          currentRow.action == 'addCredit' &&
          currentRow.stripe_charge_id != 'CASH'
        "
      >
        <div class="col-6">
          <el-input-number
            v-model="refundAmount"
            :min="1"
            :max="currentRow.amount"
          ></el-input-number>
        </div>
        <div class="col-6" v-if="!currentRow.refunded">
          Refund {{ refundAmount }} to {{ currentRow.user.first_name }}?
        </div>
        <div class="col-6" v-else>Refunded</div>
        <div class="col-12" align="center" v-if="!currentRow.refunded">
          <button class="el-button el-button--primary" @click="refund">
            <span v-if="refundAmount === currentRow.amount">Full Refund</span>
            <span v-else>Partial Refund</span>
          </button>
        </div>
      </div>
      <div v-else>Cannot refund non stripe transactions</div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
import moment from "moment";
import { TabPane, Tabs, Collapse, DatePickerDropdown } from "src/components";
import { formatRevenue } from "@/util/string.js";
import EventMixin from "@/mixins/Event.js";

const userMap = (item) => {
  return {
    ...item,
    name: item.user ? `${item.user.first_name} ${item.user.last_name}` : "NA",
  };
};
import _ from "lodash";
const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    vm.loading = false;

    if (response.data.results) {
      if (vm.params.action === "") {
        vm.totalTrasaction = response.data.count;
      }
      vm.orders.result = response.data.results.map(userMap);
    } else {
      vm.orders.result = response.data.map(userMap);
    }
    vm.orders.count = response.data.count;
    vm.orders.next = response.data.next;
    vm.orders.previous = response.data.previous;
    console.log(vm.orders);
  });
}, 500);

export default {
  components: {
    Spinner,
    TabPane,
    Tabs,
    DatePickerDropdown,
  },
  mixins: [EventMixin],
  props: [
    "user",
    "event",
    "action",
    "fromUser",
    "withUserCol",
    "withOutActions",
    "titleCols",
  ],
  data() {
    let titleCols = [
      {
        prop: "id",
        label: "Transaction ID",
      },
      {
        prop: "from_user",
        label: "From",
        sortable: false,
        formatter: (row, col, cell, index) => {
          if (
            row.action == "addCredit" &&
            row.card &&
            row.stripe_charge_id != "CASH"
          ) {
            return `${row.card.processedCardBrand} *${row.card.lastFourDigits}`;
          } else if (row.stripe_charge_id === "CASH") {
            return "Support (Cash)";
          } else if (row.stripe_charge_id === "CREDIT") {
            return "Support (Credit)";
          } else if (row.action === "spendCredit") {
            if (row.user) {
              return `${row.user.first_name} ${row.user.last_name}`;
            }
            return "N/A";
          } else if (row.action === "transfer") {
            let user;
            if (row.amount < 0) {
              user = row.user;
            } else {
              user = row.from_user;
            }
            if (row.from_user && row.from_user.first_name.length > 0 && row.from_user.last_name.length > 0) {
              return `${row.from_user.first_name} ${row.from_user.last_name}`;
            } else if (user) {
              return user.phone_number;
            }
          }
          return cell;
        },
      },
      {
        prop: "amount",
        label: "Amount",
        sortable: false,
        formatter: (row, col, cell, index) => {
          let value = formatRevenue(row.amount, this.currentEvent, 3);
          value = value.split(" ");
          if (row.amount < 0) {
            if (this.currentPriceType === "token") {
              return (
                <div style="color: #f27474">
                  <span>{value[0].replace("-", "")}</span>{" "}
                </div>
              );
            }
            return (
              <div style="color: #f27474">
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          }

          if (row.amount > 0) {
            if (this.currentPriceType === "token") {
              return (
                <div style="color: #2d61d3">
                  <span>{value[0].replace("-", "")}</span>{" "}
                </div>
              );
            }
            return (
              <div style="color: #2d61d3">
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          }

          if (this.currentPriceType === "token") {
            return (
              <div>
                <span>{value[0].replace("-", "")}</span>
              </div>
            );
          }
          return (
            <div>
              <span>{value[1].replace("-", "")}</span>
            </div>
          );
        },
      },
      {
        prop: "name",
        label: "To",
        sortable: false,

        formatter: (row, col, cell, index) => {
          if (
            row.action == "addCredit" &&
            row.card &&
            row.stripe_charge_id != "CASH"
          ) {
            return `${row.user.first_name} ${row.user.last_name}`;
          } else if (row.stripe_charge_id === "CASH") {
            return "Support Reload/Cash";
          } else if (row.action === "spendCredit" && row.order) {
            if (row.order.vendor_event && row.order.vendor_event.vendor) {
              return `${row.order.vendor_event.vendor.name} ${
                row.order.vendor_event.vendor.name !==
                row.order.vendor_event.booth
                  ? ": " +
                    row.order.vendor_event.booth.replace(
                      row.order.vendor_event.vendor.name + " - ",
                      ""
                    )
                  : ""
              }`;
            }
            return "N/A";
          } else if (row.action === "transfer") {
            let user;
            console.log(row);
            if (row.amount > 0) {
              user = row.user;
            } else {
              user = row.to_user;
            }
            if (!user) {
              return;
            }
            if (user.first_name.length > 0 && user.last_name.length > 0) {
              return `${user.first_name} ${user.last_name}`;
            } else {
              return user.phone_number;
            }
          }
          return cell;
        },
      },
      {
        prop: "action",
        label: "Type",
        formatter: (row, col, cell, index) => {
          const MAP = {
            addCredit: "Reload",
            refund: "Refund",
            authorized: "Reversed",
            spendCredit: "Order",
            partialRefund: "Partial Refund",
            transfer: "Transfer",
          };
          const val = MAP[cell];
          // if (row.refunded) {
          //   return `${val} (Refunded)`;
          // }
          return val;
        },
      },
      {
        prop: "timestamp",
        label: "Date & Time",
        formatter: (row, col, cell, index) => moment(cell).format("llll"),
      },
    ];
    if (this.titleCols) {
      titleCols = this.titleCols;
    }
    return {
      totalTrasaction: 0,
      refundAmount: 0,
      currentView: false,
      currentRow: false,
      modalVisible: false,
      modalViewVisible: false,
      orders: [],
      loading: false,
      titles: titleCols,
      timer: null,
      currentPriceType: "token",
      isTurnOnPageRefresh: false,
      selectedTabModal: "transaction",
      currentPageSize: 25,
      rawDateRange: null,
      rangeTime: null,
      isLoadingTransactionDetail: false,
      filters: [
        {
          value: "",
          label: "search",
        },
        {
          label: "filters",
          value: "",
        },
        {
          label: "debits",
          value: "",
        },
      ],
      actionCol: {
        props: {
          label: "Options",
        },
        buttons: [
          {
            props: {
              type: "primary",
            },
            handler: (row) => {
              if (row.action !== "addCredit") {
                return this.$message(
                  "Can only refund stripe addCredit transactions"
                );
              }
              this.currentRow = row;
              this.refundAmount = this.currentRow.amount;
              this.modalVisible = true;
            },
            label: "Refund",
          },
        ],
      },
      tabs: {
        reversed: "Reversed",
        transfer: "Transfer",
        order: "Order",
        reload: "Reload",
        all: "All Transactions",
      },
      status: "",
      tabsCount: null,
      loadingModelDetail: false,
      params: null,
    };
  },
  beforeMount() {
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/count/?event=${this.event.id}`;
    axios.get(url).then((response) => {
      this.tabsCount = {};
      response.data.forEach((i) => {
        this.tabsCount[i.action] = i.count;
      });
    });
  },
  async mounted() {
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.event.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.loadData(this.params, true);
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.loadData(this.params, true);
      }, 10000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }

    this.loadData({ limit: 50 });
    if (this.$route.params.transactionId) {
      this.getTransactionsDetail(this.$route.params.transactionId);
    }

    if (this.$route.query.id) {
      this.getTransactionsDetail(this.$route.query.id);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  computed: {
    transactionFromUser() {
      if (!this.currentView) {
        return "";
      }
      const row = this.currentView;
      if (
        row.action == "addCredit" &&
        row.card &&
        row.stripe_charge_id != "CASH"
      ) {
        return row.user;
      } else if (row.stripe_charge_id === "CASH") {
        return "Support Reload/Cash";
      } else if (row.action === "spendCredit") {
        return row.user;
      } else if (row.action === "transfer") {
        let user;
        if (row.amount < 0) {
          user = row.user;
        } else {
          user = row.from_user;
        }
        return user;
      }
      return false;
    },
    transactionToUser() {
      const row = this.currentView;
      let user;
      if (row.order) {
        if (
          row.action == "addCredit" &&
          row.card &&
          row.stripe_charge_id != "CASH"
        ) {
          return row.user;
        } else if (row.action === "refund") {
          return row.user;
        } else if (
          row.action === "spendCredit" &&
          row.order &&
          row.order.vendor_event
        ) {
          return row.order.vendor_event.vendor;
        } else if (row.action === "transfer") {
          console.log(row);
          if (row.amount > 0) {
            user = row.user;
          } else {
            user = row.to_user;
          }
          return user;
        }
      } else {
        if (row.amount > 0) {
          user = row.user;
        } else {
          user = row.to_user;
        }
        return user;
      }
    },
  },
  methods: {
    handleClose() {
      if (this.$route.query.id) {
        this.$router.push({
          path: this.$route.path,
          query: { id: undefined },
        });
      }
      this.currentRow = null;
      this.currentView = null;
      this.modalVisible = false;
      this.modalViewVisible = false;
    },
    onReset() {
      this.$refs.datepicker.onReset();
      this.rangeTime = [];

      setTimeout(() => {
        this.loadData(this.params);
      }, 0);
    },
    getTransactionsDetail(id) {
      this.isLoadingTransactionDetail = true;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/${id}/?expand=user,event,from_user,order.vendor_event.vendor,to_user&event=${this.$route.params.id}`;
      this.dialogTableVisible = true;
      this.items = [];
      axios.get(url).then((response) => {
        this.onClickView(response.data);
      });
    },

    onTabChange(tab) {
      switch (tab) {
        case "Refunded":
          this.status = "refund";
          break;
        case "Transfers":
          this.status = "transfer";
          break;
        case "Orders":
          this.status = "spendCredit";
          break;
        case "Reloads":
          this.status = "addCredit";
          break;

        default:
          this.status = "";
          break;
      }
      this.loadData(this.params);
    },
    onClickView(row) {
      this.currentView = row;
      this.selectedTabModal = "transaction";
      this.modalViewVisible = true;
      this.isLoadingTransactionDetail = false;
      if (this.$route.query.id !== this.currentView.id + "") {
        this.$router.push({
          path: this.$route.path,
          query: { id: this.currentView.id },
        });
      }
    },
    onClickRefund(row) {
      if (row.action !== "addCredit") {
        return this.$message("Can only refund stripe addCredit transactions");
      }
      this.currentRow = row;
      this.refundAmount = this.currentRow.amount;
      this.modalVisible = true;
    },
    rowStyle(row) {
      console.log(row);
    },
    refund() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/${this.currentRow.id}/refund/`;
      axios
        .post(url, { amount: this.refundAmount })
        .then((response) => {
          this.modalVisible = false;
          this.$message("Refunded");
          //this.orders.find(item => item.id === this.currentRow.id).action='refunded'
          this.currentRow.refunded = true;
        })
        .catch((error) => {
          this.$message("There was an error refunding this transaction");
        });
    },
    onUpdateDate(daterange) {
      this.rangeTime = daterange;
      this.loadData(this.params);
    },
    filterData(e) {
      this.params.limit = e;
      this.loadData(this.params);
    },
    async loadData(queryInfo, isRefresh = false) {
      if (!isRefresh) {
        this.loading = true;
      }

      // this.modalViewVisible = false;
      // this.currentView = null;
      if (!this.params) {
        this.params = {
          ...queryInfo,
        };
      } else {
        this.params = {
          ...this.params,
          ...queryInfo,
        };
      }

      console.log(queryInfo);
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?expand=user,event,from_user,order.vendor_event.vendor,to_user`;
      console.log(this, this.url);
      if (this.url) {
        url = this.url;
      }

      let params = {
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        search: this.filters[0].value,
        ...queryInfo,
      };

      if (this.params) {
        const passed = this.params;
        params = {
          ...params,
          ...passed,
          offset: (queryInfo.page - 1) * queryInfo.pageSize,
        };
      }

      params["action"] = this.status;

      if (this.user) {
        params["user"] = this.user.id;
      }

      if (this.action) {
        params["action"] = this.action;
      }

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          queryInfo.sort.order == "descending"
            ? "-" + queryInfo.sort.prop
            : "" + queryInfo.sort.prop;
      }

      if (
        queryInfo.type === "sort" &&
        queryInfo.sort &&
        queryInfo.sort.prop === "from_user"
      ) {
        params["ordering"] = "from_user,user,user.first_name";
      }

      if (this.event) {
        params["event"] = this.event.id;
      }

      if (this.rangeTime && this.rangeTime.length > 0) {
        params["timestamp__gte"] = moment(this.rangeTime[0])
          .startOf("day")
          .format();
        params["timestamp__lte"] = moment(this.rangeTime[1])
          .endOf("day")
          .format();
      } else {
        delete params["timestamp__gte"];
        delete params["timestamp__lte"];
      }

      this.filters.forEach((item) => {
        if (item.value) {
          params[item.label] = item.value;
        }
      });
      if(!params.ordering) {
        params.ordering = '-timestamp'
      }
      this.params = params;
      debounced(this, url, params);
    },
  },
};
</script>
<style>
.margin-top {
  margin-top: 1em;
}
</style>
