var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.orders.length > 0)?_c('el-table',{attrs:{"data":_vm.orders}},[_c('el-table-column',{attrs:{"label":"Order","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"target":"_blank","to":{
            name: 'Orders',
            params: {
              id: scope.row.vendor_event.event,
              orderId: scope.row.id,
            },
          }}},[_vm._v(" "+_vm._s(scope.row.id)+" ")])]}}],null,false,3933290420)}),_c('el-table-column',{attrs:{"property":"vendor_event.vendor.name","label":"Vendor","width":"170"}}),_c('el-table-column',{attrs:{"property":"vendor_event.booth","label":"Booth","width":"170"}}),_c('el-table-column',{attrs:{"label":"Order Time","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm._f("moment")(scope.row.created_at,"YYYY-MM-DD LT")))]}}],null,false,2911304138)}),_c('el-table-column',{attrs:{"property":"total","label":"Tokens","width":"95"}}),_c('el-table-column',{attrs:{"property":"status","label":"Status","width":"100"}})],1):_c('h5',{staticClass:"margin-top"},[_vm._v("No Transactions yet")]),(_vm.loading)?_c('spinner'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }