<template>
  <div class="a-account-image-wrapper">
    <img
      src="assets/imgs/header-bg-min.jpg"
      loading="lazy"
      alt=""
      class="a-account-image"
    />
    <div class="a-section-regular">
      <div class="a-container-regular">
        <div class="a-account-form-wrapper">
          <div>
            <div class="a-margin-bottom-48">
              <div class="margin-bottom-16">
                <h2 class="a-h3-heading">Activate Your Account</h2>
              </div>
              <div class="a-account-link-wrapper">
                <div class="paragraph-regular">
                  Please enter your new password
                </div>
              </div>
            </div>
            <div class="a-account-form-block w-form">
              <form
                id="wf-form-Sign-In-Form"
                name="wf-form-Sign-In-Form"
                data-name="Sign In Form"
                class="a-account-form"
                aria-label="Sign In Form"
                @submit.prevent="login"
              >
                <div class="a-margin-bottom-24">
                  <div class="a-account-field-wrapper">
                    <label for="Sign-In-Email-2" class="a-detail-small"
                      >Email</label
                    >
                    <fg-input
                      disabled
                      v-model="model.email"
                      v-validate="'required|email'"
                      name="email"
                      :error="getError('email')"
                      input-classes="a-account-text-field w-input"
                      placeholder="Email"
                    >
                    </fg-input>
                  </div>
                </div>
                <div class="a-margin-bottom-24">
                  <div class="a-account-field-wrapper">
                    <label for="Sign-In-Email-2" class="a-detail-small"
                      >Code</label
                    >
                    <fg-input
                      v-model="model.code"
                      v-validate="'required'"
                      name="code"
                      :error="getError('code')"
                      input-classes="a-account-text-field w-input"
                      placeholder="Code"
                    >
                    </fg-input>
                  </div>
                </div>
                <div class="a-margin-bottom-12">
                  <div class="a-account-field-wrapper">
                    <label for="password-6" class="a-detail-small"
                      >Password</label
                    >
                    <div class="a-account-password">
                      <fg-input
                        v-model="model.password"
                        v-validate="'required|min:5'"
                        type="password"
                        name="password"
                        :error="getError('password')"
                        input-classes="a-account-text-field w-input"
                        placeholder="Password"
                      ></fg-input>
                    </div>
                  </div>
                </div>
                <input
                  :disabled="isLogging"
                  :style="{
                    opacity: isLogging ? 0.5 : 1,
                  }"
                  type="submit"
                  :value="isLogging ? 'Please wait...' : 'Submit'"
                  class="button-primary w-button"
                />
              </form>

              <!-- <div class="alert alert-warning margin-top-16" v-if="errorMsg">
                {{ errorMsg }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { humanize } from "src/util/string";

export default {
  name: "login",
  data() {
    return {
      errorMsg: "",
      model: {
        email: this.$route.params.email,
        code: this.$route.params.code,
        password: "",
      },
      isLogging: false,
    };
  },
  mounted() {
    sessionStorage.removeItem("isRefresh");
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    login() {
      let isValidForm = this.$validator.validateAll();
      if (!this.model.email || !this.model.password || !this.model.code) {
        return;
      }
      if (isValidForm) {
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/verify-code/email/`;
        const data = {
          email: this.model.email,
          password: this.model.password,
          code: Number(this.model.code),
        };
        const self = this;
        this.isLogging = true;
        axios
          .post(url, data)
          .then(async (response) => {
            const urlUser = `${process.env.VUE_APP_BASE_HOST}/en/api/user/?search=${this.model.email}`;
            const responseUser = await axios.get(urlUser, {
              headers: {
                Authorization: "Token " + response.data.token,
              },
            });
            self.$message("Your account has been activated.");
            if (
              responseUser.data &&
              responseUser.data.results &&
              responseUser.data.results[0] &&
              responseUser.data.results[0].type === "user"
            ) {
              window.location.href = "https://eventpay.com/download";
              return;
            } else {
              self.$router.push("/login");
            }
          })
          .catch((r) => {
            this.isLogging = false;

            if (
              r.response.data.errors &&
              r.response.data.errors.non_field_errors
            ) {
              self.errorMsg = r.response.data.errors.non_field_errors[0];
            } else {
              self.errorMsg = humanize(r.response.data).join(" ");
            }
            this.$message.error(self.errorMsg);
          });
      }
    },
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
