<template>
  <div class="event-page">
    <div class="event-calendar !hidden md:!block">
      <n-button
        type="primary small-button calendar-today"
        @click.native="moveToday"
        >Today</n-button
      >
      <v-calendar
        :attributes="attributes"
        :title-position="'left'"
        is-double-paned
        ref="calendar"
        v-model="calendarValue"
      >
      </v-calendar>
      <div class="wrap-event-this">
        <div class="row">
          <div class="col-12">
            <h5 class="title-event mt-5">
              Next Up<!-- Happening This Month -->
            </h5>
          </div>
          <div class="col-12" v-for="(event, x) in upcomingEvents" :key="x">
            <event :event="event" :show-links="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="event-main">
      <div class="d-flex justify-content-between mb-4 mobile-create-event">
        <input
          type="text"
          class="search-box"
          placeholder="Search"
          v-model="search"
          v-on:input="onSearch"
        />
        <button
          v-if="user.type == 'org-administrator'"
          class="create-event"
          @click="showCreate = !showCreate"
        >
          Create Event
        </button>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <h3 class="title-page mb-0">
              Events
              <!-- <button class="dropdown-btn pull-right">
                <img
                  class="dropdown-dot"
                  src="assets/imgs/dot-dropdown.svg"
                  alt=""
                />
              </button> -->
            </h3>
            <h5 class="subtitle">Select an event you want to manage</h5>

            <spinner v-if="!upcomingEvents" />
            <div class="row">
              <div class="col-12">
                <h5 class="title-event mt-5">
                  <span class="circle purple"></span> Upcoming
                </h5>
              </div>
              <div
                class="col-12 col-xl-6"
                v-for="(event, x) in upcomingEvents"
                :key="x"
              >
                <event :event="event" :show-links="true" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <spinner v-if="!pastEvents" />
          <div class="row">
            <div class="col-12">
              <h5 class="title-event mt-5">
                <span class="circle dark-peach"></span> Past Events
              </h5>
            </div>
            <div
              class="col-12 col-xl-6"
              v-for="(event, x) in pastEvents"
              :key="x"
            >
              <event :event="event" :show-links="true" />
            </div>
          </div>
        </div>
        <div
          v-if="user.type == 'org-administrator'"
          class="col-md-12 add-vendor"
        >
          <el-dialog
            :visible.sync="showCreate"
            custom-class="event-dialog"
            append-to-body
          >
            <template #title>
              <div></div>
            </template>
            <create-event-form
              @onClose="onCloseCreateEvent"
            ></create-event-form>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PreviewUpload from "./Components/PreviewUpload";
import { humanize } from "src/util/string";
import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";
import CreateVendor from "./Events/CreateVendor.vue";
import CreateEventForm from "./Events/CreateEventForm.vue";
import Event from "src/components/Event.vue";
import moment from "moment/moment";
import Spinner from "src/components/Spinner";

import _ from "lodash";
const debounced = _.debounce((vm, search) => {
  vm.loading = true;
  vm.$store.dispatch("getEvents", search);
  vm.loading = false;
}, 300);

export default {
  components: {
    PreviewUpload,
    CreateEventForm,
    CreateVendor,
    Spinner,
    Event,
  },
  computed: {
    upcomingEvents() {
      return this.$store.state.events;
    },
    pastEvents() {
      return this.$store.state.pastEvents;
    },
  },
  methods: {
    onSearch: _.debounce(function (e) {
      this.loading = true;
      this.$store.dispatch("getEvents", this.search);
      this.loading = false;
    }, 500),
    moveToday() {
      this.$refs.calendar.move(new Date());
    },
    goToEvent: function (eventId) {
      this.$router.push(`/pages/events/${eventId}/dashboard`);
    },
    onCloseCreateEvent() {
      this.showCreate = false;
    },
  },
  data() {
    return {
      loading: true,
      search: "",
      upcomingEventsLoading: true,
      pastEventsLoading: true,
      showCreate: false,
      calendarValue: new Date(),
      attributes: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
        {
          dot: "blue",
          dates: [
            new Date(2020, 10, 1), // Jan 1st
            new Date(2020, 10, 10), // Jan 10th
            new Date(2020, 10, 22), // Jan 22nd
          ],
        },
        {
          dot: "red",
          dates: [
            new Date(2020, 10, 4), // Jan 4th
            new Date(2020, 10, 10), // Jan 10th
            new Date(2020, 10, 15), // Jan 15th
          ],
        },
        {
          dot: "yellow",
          dates: [
            new Date(2020, 10, 12), // Jan 12th
            new Date(2020, 10, 26), // Jan 26th
            new Date(2020, 10, 15), // Jan 15th
          ],
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getEvents");
    this.loading = false;
  },
  mixins: [GetError, User],
};
</script>

<style lang="scss">
.add-vendor {
  margin-top: 20px;
}

.event-main {
  margin-right: 385px;
  position: relative;
}

.search-box {
  width: 240px;
}

.wrap-event-this {
  padding: 25px;
  padding-top: 0;
}
</style>
