<template>
   <span :class="value < 1 ? 'negative': 'positive'">{{percentage.toFixed(2)}}% <i :class="value < 1 ? 'el-icon-bottom-right' : 'el-icon-top-right'" /></span>
</template>
<script>
export default {
  props: ['value'],
  computed: {
    percentage () {
      return this.value * 100
    }
  }
}
</script>
<style scoped>
.negative {
  color: red;
}
.positive {
  color: green;
}
</style>