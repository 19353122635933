<template>
  <!-- <li>
    class="user user__menu"
    <div class="photo">
      <img :src="image" alt="avatar" />
    </div>
    <div class="info">

    <a
      data-toggle="collapse"
      :aria-expanded="!isClosed"
      @click.stop="toggleMenu"
      href="#"
      class="nav-link sidebar-menu-item pb-0"
    >
      <img :src="'assets/imgs/accounts-icon.svg'" />
      <p>
        Accounts
        <b class="caret"></b>
      </p>
    </a>

    <collapse-transition>
      <div v-show="!isClosed">
        <ul class="nav user-menu__nav">
          <slot>
            <li>
              <a href="#vue">
                <span class="sidebar-mini-icon">MP</span>
                <span class="sidebar-normal">My Profile</span>
              </a>
            </li>
          </slot>
        </ul>
      </div>
    </collapse-transition>

    </div>
  </li> -->
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "user-menu",
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: "James Amos",
    },
    image: {
      type: String,
      default: "img/e.jpg",
    },
  },
  computed: {
    userName: function () {
      const user = JSON.parse(localStorage.getItem("user"));
      return user.firstName && user.lastName
        ? [user.firstName, user.lastName].join(" ")
        : user.email;
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
