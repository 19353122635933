<template>
  <div>
    <h3 class="title-page">
      Vendors
      <n-button type="primary azul" @click.native="onShowNewVendor()"
        >New Vendor</n-button
      >
      <!-- <n-button type="primary azul">Bulk Import</n-button> -->
    </h3>

    <tabs type="primary" v-model="tabs.all" @change="changeTab">
      <!-- <tab-pane label="Invited" badge="6">
         </tab-pane> -->
      <tab-pane label="All Vendors" :badge="totalVendors">
        <div class="search-table">
          <el-input
            placeholder="Search by Vendor, Owner, Location, Phone or Email"
            v-model="filters[0].value"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <spinner v-if="loading" />
        <data-tables-server
          class="event-table"
          v-show="!isInit"
          :data="items"
          :total="count"
          :filters="filters"
          @query-change="loadData"
          :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            v-for="title in titles"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            :sortable="title.isSortable"
            :minWidth="title.minWidth"
            v-if="title.prop === 'name'"
            ><template slot-scope="scope">
              <div>
                <router-link
                  :to="{
                    name: 'VendorDashboard',
                    params: { id: $route.params.id, vendorId: scope.row.id },
                  }"
                  >{{ scope.row.name }}</router-link
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="title in titles"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            :sortable="title.isSortable"
            :formatter="title.formatter"
            :minWidth="title.minWidth"
            v-if="title.label !== 'Is Approved' && title.label !== 'Vendor'"
          >
          </el-table-column>
          <el-table-column
            v-for="(title, index) in titles"
            :label="title.label"
            :key="index"
            :prop="title.prop"
            sortable="custom"
            :width="title.width"
            :minWidth="title.minWidth"
            :formatter="title.formatter"
            v-if="title.label === 'Is Approved'"
          >
            <template slot-scope="scope">
              <div
                :class="scope.row.is_approved ? 'tag azul' : 'tag purple'"
                disable-transitions
              >
                {{ scope.row.is_approved }}
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="''">
            <template slot-scope="scope">
              <div :class="'action-cl'" disable-transitions>
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <img
                      class="dropdown-dot"
                      src="assets/imgs/dot-dropdown.svg"
                      alt=""
                    />
                  </span>
                  <el-dropdown-menu>
                    <!-- <el-dropdown-item
                      slot="dropdown"
                      v-for="(vendor_event, i) in scope.row.vendorevent_set"
                      :key="i"
                      command="a"
                      @click.native="handlerView(vendor_event)"
                      ><i class="el-icon-view"></i> View
                      {{ vendor_event }}</el-dropdown-item
                    > -->
                    <!-- <el-dropdown-item
                      command="c"
                      @click.native="handlerFinances(scope.row)"
                      ><ViewIcon /> Financials
                      </el-dropdown-item
                    > -->
                    <el-dropdown-item
                      command="c"
                      @click.native="handlerView(scope.row)"
                      ><ViewIcon /> View
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </data-tables-server>
      </tab-pane>
    </tabs>

    <div
      v-if="user && user.type == 'org-administrator'"
      class="col-md-12 add-vendor"
    >
      <el-dialog
        :visible.sync="isShowVendorForm"
        custom-class="event-dialog"
        append-to-body
      >
        <template #title>
          <div></div>
        </template>
        <vendor-form
          v-if="isShowVendorForm"
          :onClose="onCloseVendor"
          :rawVendorEdit="selectedVendor"
          :isEdit="selectedVendor !== null"
          @onCreateNew="onCreateNew"
        ></vendor-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import CreateVendor from "./Events/CreateVendor.vue";
import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";
import axios from "axios";
import _ from "lodash";
import VendorForm from "src/pages/Dashboard/VendorEventDetail/VendorForm";
import Spinner from "src/components/Spinner";
import throttle from "src/util/throttle";
import { formatRevenue } from "@/util/string.js";
import ViewIcon from "src/components/Icons/View.vue";
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";

const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    vm.vendors = response.data.results;
  });
}, 500);
export default {
  components: {
    CreateVendor,
    VendorForm,
    TabPane,
    Tabs,
    Spinner,
    ViewIcon,
  },
  data() {
    return {
      isInit: true,
      count: 0,
      isShowVendorForm: false,
      totalVendors: 0,
      vendors: [],
      loading: false,
      titles: [
        {
          prop: "name",
          label: "Vendor",
          isSortable: true,
          minWidth: 160,
        },
        {
          prop: "locations",
          label: "Locations",
          width: 120,
          minWidth: 140,
        },

        {
          prop: "email",
          label: "Owner",
          isSortable: true,
          minWidth: 200,
        },
        {
          prop: "phone",
          label: "Phone",
          isSortable: true,
          minWidth: 200,
        },
      ],
      filters: [
        {
          value: "",
        },
      ],
      tabs: {
        invited: "Invited",
        all: "All Vendors",
      },
      params: null,
      timer: null,
      currentPriceType: "token",
      isTurnOnPageRefresh: false,
      selectedVendor: null,
      items: [],
    };
  },
  mixins: [GetError, User],
  mounted() {
    this.loadData();
  },
  methods: {
    onCreateNew(vt) {
      this.selectedVendor = vt;
    },
    onShowNewVendor() {
      this.isShowVendorForm = true;
      this.selectedVendor = null;
    },
    changeTab(tab) {
      console.log(tab);
      this.type = tab.toLowerCase();
      this.items = [];
      this.loadData({ type: tab, pageSize: 50 });
    },
    onCloseVendor() {
      this.isShowVendorForm = false;
      this.selectedVendor = null;
    },
    handlerView(vendor_event) {
      // this.$router.push({
      //   name: "EventVendorDetail",
      //   params: { id: this.$route.params.id, vendorId: vendor_event.id },
      // });
      this.selectedVendor = vendor_event;
      this.isShowVendorForm = true;
    },

    handlerFinances(row) {
      this.$router.push({
        name: "Org Vendor Financials",
        params: { id: row.event, vendorId: row.vendor.id },
      });
    },

    async loadData(queryInfo, isRefresh = false) {
      if (!queryInfo) {
        queryInfo = {};
      }
      if (!isRefresh) {
        // this.loading = true;
      }

      if (!this.params) {
        this.params = {
          ...queryInfo,
        };
      }

      console.log(queryInfo);
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/`;
      const params = {
        event: this.$route.params.id,
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        expand: "vendorevent_set",
        search: this.filters[0].value,
        aggregates: "1",
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          (queryInfo.sort.order == "descending" ? "-" : "") +
          queryInfo.sort.prop;
      }

      this.filters.forEach((element) => {
        if (element.value.length > 3) {
          params[element.prop] = element.value;
        }
      });

      throttle.debounced(this, url, params, isRefresh);
    },
  },
};
</script>
