<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-event row">
        <div class="col-md-6">
          <h3>Add Vendor</h3>
          <div class="alert alert-success" v-if="successMsg">
            {{ successMsg }}
          </div>
          <div class="alert alert-warning" v-if="serverErrors.length > 0">
            <ul>
              <li v-bind:key="error" v-for="error in serverErrors">
                {{ error }}
              </li>
            </ul>
          </div>
          <div>
            <label>Email</label>
            <fg-input
              v-model="rawVendor.email"
              v-validate="'required|email'"
              placeholder="admin@vendor.com"
              name="email"
              :error="getError('email')"
            ></fg-input>
          </div>
          <div>
            <label>Name</label>
            <fg-input
              v-model="rawVendor.name"
              v-validate="'required'"
              placeholder="Venor X"
              name="name"
              :error="getError('name')"
            ></fg-input>
          </div>
          <div>
            <label>Phone</label>
            <fg-input
              placeholder="Phone..."
              name="phone"
              v-model="rawVendor.phone"
              :error="getError('phone')"
              v-validate="{
                        required: true,
                        regex:/^\+?[0-9]{3}-?[0-9]{7,11}$/,
                      }"
            ></fg-input>
          </div>
          <div>
            <label>Website</label>

            <fg-input
              placeholder="URL"
              v-validate="'url'"
              name="website"
              :error="getError('website')"
              v-model="rawVendor.website"
            ></fg-input>
          </div>
          <div class="logo">
            <label>Logo</label>
            <preview-upload v-model="rawVendor.logo"></preview-upload>
          </div>
          <div class="logo">
            <label>Logo</label>
            <preview-upload v-model="rawVendor.background"></preview-upload>
          </div>
          <div>
            <label>Description</label>
            <fg-input v-model="rawVendor.description"></fg-input>
          </div>
          <n-button type="info" @click.native="create"> Create </n-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PreviewUpload from "../Components/PreviewUpload";
import { humanize } from "src/util/string";
import GetError from "src/mixins/GetError.js";
// TODO: Add category?
export default {
  components: {
    PreviewUpload,
  },
  computed: {
    vendor: function () {
      const e = {
        name: this.rawVendor.name,
        description: this.rawVendor.description,
        website: this.rawVendor.website,
        logo: this.rawVendor.logo,
        background: this.rawVendor.background,
        email: this.rawVendor.email,
        phone: this.rawVendor.phone,
      };
      const form = new FormData();

      Object.keys(e).map((k) => {
        if (!!e[k]) {
          form.append(k, e[k]);
        }
      });

      return form;
    },
  },
  methods: {
    create: function () {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/`;
      this.successMsg = "";
      this.serverErrors = [];
      const payload = this.vendor
      if (payload.phone[0] !== "+") {
        payload.phone = "+" + payload.phone;
      }
      axios
        .post(url, payload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.successMsg = "Successfully created the vendor";
          this.rawVendor = {};
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    },
  },
  data() {
    return {
      rawVendor: {},
      serverErrors: [],
      successMsg: "",
    };
  },
  mounted() {},
  mixins: [GetError],
};
</script>
<style lang="scss">
.event-branding,
.logo {
  .image-preview {
    margin-top: 10px;
    height: 162px;
    width: 285px;
    border: solid 1px;
  }
}
</style>
