<template>
  <div>
    <el-table :data="orders" v-if="orders.length > 0">
      <el-table-column label="Order" width="90">
        <template slot-scope="scope">
          <router-link
            target="_blank"
            :to="{
              name: 'Orders',
              params: {
                id: scope.row.vendor_event.event,
                orderId: scope.row.id,
              },
            }"
          >
            {{ scope.row.id }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        property="vendor_event.vendor.name"
        label="Vendor"
        width="170"
      ></el-table-column>
      <el-table-column
        property="vendor_event.booth"
        label="Booth"
        width="170"
      ></el-table-column>
      <el-table-column label="Order Time" width="200">
        <template slot-scope="scope">{{
          scope.row.created_at | moment("YYYY-MM-DD LT")
        }}</template>
      </el-table-column>

      <el-table-column
        property="total"
        label="Tokens"
        width="95"
      ></el-table-column>
      <el-table-column
        property="status"
        label="Status"
        width="100"
      ></el-table-column>
    </el-table>
    <h5 class="margin-top" v-else>No Transactions yet</h5>
    <spinner v-if="loading" />
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
export default {
  components: {
    Spinner,
  },
  props: ["user", "event"],
  data() {
    return {
      orders: [],
      loading: false,
      isTurnOnPageRefresh: false,
      currentPriceType: "token",
    };
  },
  methods: {
    init() {
      const url = `${
        process.env.VUE_APP_BASE_HOST
      }/en/api/order/?expand=vendor_event.vendor&user=${this.user.id}&event=${
        this.event ? this.event.id : ""
      }`;
      axios.get(url).then((response) => {
        this.loading = false;
        this.orders = response.data.results;
      });
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  async mounted() {
    this.orders = [];
    this.loading = true;

    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.init();
        }, 5000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.init();
      }, 5000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
  },
};
</script>
