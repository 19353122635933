<template>
  <div>
    <div>
      <h3 class="title-page d-flex align-items-center">
        Users
        <n-button
          type="primary azul  ms-2"
          @click.native="isDisplayed = !isDisplayed"
          >Add user</n-button
        >

        <template v-if="userDetail && userDetail.type === 'cash'">
          <n-button
            type="primary azul ms-2"
            @click.native="isShowReloadForm = true"
            >Reload Tokens</n-button
          >
          <n-button
            @click.native="isShowTransferToken = true"
            type="primary azul  ms-2"
            >Transfer Tokens</n-button
          >
        </template>
      </h3>

      <tabs type="primary" v-model="type" @change="changeTab">
        <tab-pane
          :key="key"
          :label="tab"
          :badge="counts[key]"
          v-for="(tab, key) in tabs"
        >
          <div class="search-table">
            <el-input
              placeholder="Search by Name, Phone, Email or Type"
              v-model="filters[0].value"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <data-tables-server
            v-if="isReady"
            :class="'event-table ' + (loading ? 'hidden' : '')"
            :data="users"
            :filters="filters"
            @query-change="loadData"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="paramsGrid ? paramsGrid.pageSize : 50"
            :pagination-props="{
              'prev-text': 'Prev',
              'next-text': 'Next',
              pageSizes: [25, 50, 100, 200],
              layout: 'slot, ->, prev, pager, next',
            }"
          >
            <span slot="pagination">
              <span>Showing</span>
              <el-select
                v-if="paramsGrid"
                v-model="paramsGrid.pageSize"
                @change="filterData"
              >
                <el-option :label="'25'" :value="25"> </el-option>
                <el-option :label="'50'" :value="50"> </el-option>
                <el-option :label="'100'" :value="100"> </el-option>
                <el-option :label="'200'" :value="200"> </el-option>
              </el-select>
              <span>of {{ total }}</span>
            </span>

            <template v-if="userDetail.type === 'cash'">
              <el-table-column
                :label="'Full Name'"
                :prop="'first_name'"
                sortable="custom"
                :minWidth="300"
                :width="300"
              >
                <template slot-scope="scope">
                  <div class="d-flex">
                    <a
                      style="
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-right: 5px;
                      "
                      href="javascript:void(0)"
                      @click="onClickUserView(scope.row)"
                    >
                      <template
                        v-if="scope.row.first_name || scope.row.last_name"
                      >
                        {{ scope.row.first_name }} {{ scope.row.last_name }}
                      </template>
                      <template v-else> N/A </template>
                    </a>
                    <div
                      :class="'action-cl'"
                      disable-transitions
                      style="justify-content: flex-end; margin-left: auto"
                    >
                      <n-button
                        size="sm"
                        type="btn btn-primary azul  ms-0"
                        @click.native="
                          selectedUser = scope.row;
                          isShowReloadForm = true;
                        "
                        >Reload</n-button
                      >
                      <n-button
                        @click.native="
                          selectedUser = scope.row;
                          isShowTransferToken = true;
                        "
                        size="sm"
                        type="btn btn-primary azul  ms-2"
                        >Transfer</n-button
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
            </template>

            <template v-if="userDetail.type !== 'cash'">
              <el-table-column
                :label="'Full Name'"
                :prop="'first_name'"
                sortable="custom"
                :minWidth="300"
                :width="300"
              >
                <template slot-scope="scope">
                  <div class="d-flex">
                    <a
                      style="
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-right: 5px;
                      "
                      href="javascript:void(0)"
                      @click="onClickUserView(scope.row)"
                    >
                      <template
                        v-if="scope.row.first_name || scope.row.last_name"
                      >
                        {{ scope.row.first_name }} {{ scope.row.last_name }}
                      </template>
                      <template v-else> N/A </template>
                    </a>
                  </div>
                </template>
              </el-table-column>
            </template>

            <el-table-column
              v-for="(title, index) in titles"
              :label="title.label"
              :key="index"
              :prop="title.prop"
              sortable="custom"
              :formatter="title.formatter"
              :minWidth="title.width"
              v-if="title.prop !== 'type'"
            >
            </el-table-column>

            <el-table-column
              v-for="(title, index) in titles"
              :label="title.label"
              :key="index"
              :prop="title.prop"
              sortable="custom"
              :minWidth="title.width"
              :formatter="title.formatter"
              v-if="title.prop === 'type'"
            >
              <template slot-scope="scope">
                <div
                  :class="scope.row.type === 'user' ? 'tag azul' : 'tag purple'"
                  disable-transitions
                >
                  {{ scope.row.type }}
                </div>
              </template>
            </el-table-column>

            <template v-if="userDetail.type !== 'cash'">
              <el-table-column :label="''" :width="50">
                <template slot-scope="scope">
                  <div :class="'action-cl'" disable-transitions>
                    <el-dropdown
                      type="dropdown-modal"
                      v-if="userDetail.type !== 'cash'"
                    >
                      <span class="el-dropdown-link">
                        <img
                          class="dropdown-dot"
                          src="assets/imgs/dot-dropdown.svg"
                          alt=""
                        />
                      </span>

                      <el-dropdown-menu
                        class="dropdown-table"
                        slot="dropdown"
                        v-if="userDetail.type !== 'cash'"
                      >
                        <el-dropdown-item
                          command="a"
                          @click.native="onClickUserView(scope.row)"
                        >
                          <svg
                            width="16px"
                            height="11px"
                            viewBox="0 0 16 11"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                          >
                            <g
                              id="📦-Orders"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g
                                id="Orders"
                                transform="translate(-1267, -359)"
                                fill="currentColor"
                                fill-rule="nonzero"
                              >
                                <g
                                  id="dropdown-copy-2"
                                  transform="translate(1255, 345)"
                                >
                                  <g
                                    id="Group-16"
                                    transform="translate(12, 11)"
                                  >
                                    <path
                                      d="M8,5.29165197 C7.70559036,5.29641209 7.41335972,5.34464549 7.13222057,5.43489583 C7.26226545,5.67074173 7.33150682,5.93703121 7.33333207,6.20833333 C7.33333207,7.09429012 6.6368848,7.8125 5.77777356,7.8125 C5.51469222,7.81061772 5.25647162,7.73921269 5.02777213,7.60510417 C4.65773102,8.92856003 5.15040745,10.3477198 6.2508765,11.1282695 C7.35134554,11.9088192 8.81268162,11.8756173 9.87874747,11.0458436 C10.9448133,10.2160698 11.3764028,8.77591051 10.9501991,7.47054548 C10.5239955,6.16518045 9.33563116,5.28751025 8,5.29165197 L8,5.29165197 Z M15.9033483,8.08177083 C14.3969566,5.05075521 11.4147287,3 8,3 C4.58527129,3 1.60221007,5.0521875 0.0966516601,8.08205729 C-0.03221722,8.34494005 -0.03221722,8.65534641 0.0966516601,8.91822917 C1.60304341,11.9492448 4.58527129,14 8,14 C11.4147287,14 14.3977899,11.9478125 15.9033483,8.91794271 C16.0322172,8.65505995 16.0322172,8.34465359 15.9033483,8.08177083 Z M8,12.625 C5.25971702,12.625 2.74749003,11.0494792 1.39082078,8.5 C2.74749003,5.95052083 5.25943924,4.375 8,4.375 C10.7405608,4.375 13.25251,5.95052083 14.6091792,8.5 C13.2527878,11.0494792 10.7405608,12.625 8,12.625 Z"
                                      id="Shape"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <span style="font-size: 12px; padding-left: 5px"
                            >View</span
                          >
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
            </template>
          </data-tables-server>
          <spinner v-if="loading" />
        </tab-pane>
      </tabs>

      <el-dialog
        :visible.sync="isDisplayed"
        custom-class="event-dialog"
        append-to-body
        :before-close="beforeClose"
      >
        <template #title>
          <div></div>
        </template>
        <new-user v-if="isDisplayed" :onClose="onCloseUserModal"></new-user>
      </el-dialog>

      <el-dialog
        :title="`${currentRow.first_name} ${currentRow.last_name} - ${currentRow.email}`"
        v-if="modalVisible && currentRow"
        :before-close="beforeClose"
        :visible.sync="modalVisible"
        :custom-class="'md-dialog hidden-header event-dialog'"
      >
        <spinner v-if="isLoadingUserDetail" />
        <div class="row" v-if="!isLoadingUserDetail && currentRow">
          <div class="col-12">
            <div class="form-event">
              <div class="left-navigation">
                <h4>
                  User
                  <div
                    class="small text-order-id"
                    style="font-size: 11px; line-height: 15px"
                  >
                    {{ currentRow.first_name }} {{ currentRow.last_name }}
                    <br />
                    <span class="small">
                      {{ currentRow.email }}
                      <br />
                      {{ currentRow.phone_number }}
                    </span>
                  </div>
                </h4>
                <ul>
                  <li
                    @click="selectedTabModal = 'detail'"
                    :class="selectedTabModal === 'detail' ? 'active' : ''"
                  >
                    <InforCircleIcon />User Details
                  </li>
                  <li
                    @click="selectedTabModal = 'orders'"
                    :class="selectedTabModal === 'orders' ? 'active' : ''"
                    v-if="userDetail.type !== 'cash'"
                  >
                    <OrderIcon />Orders
                  </li>
                  <li
                    @click="selectedTabModal = 'transactions'"
                    :class="selectedTabModal === 'transactions' ? 'active' : ''"
                  >
                    <TransactionIcon />Transactions
                  </li>
                  <li
                    @click="selectedTabModal = 'payment'"
                    :class="selectedTabModal === 'payment' ? 'active' : ''"
                    v-if="userDetail.type !== 'cash'"
                  >
                    <WalletIcon />Payment Method
                  </li>

                  <li
                    @click="
                      selectedUser = currentRow;
                      isShowReloadForm = true;
                    "
                    v-if="userDetail.type === 'cash'"
                  >
                    <MoneyIcon />Reload
                  </li>
                  <li
                    @click="
                      selectedUser = currentRow;
                      isShowTransferToken = true;
                    "
                    v-if="userDetail.type === 'cash'"
                  >
                    <CardIcon />Transfer
                  </li>
                  <li
                    :class="selectedTabModal === 'qrCode' ? 'active' : ''"
                    @click="qrClicked(currentRow)"
                  >
                    <FingerIcon />QR Code
                  </li>
                </ul>
              </div>
              <div
                class="col-12 col-lg-9 right-panel"
                style="position: relative; min-height: 600px"
              >
                <button
                  type="button"
                  class="close-button-md"
                  @click="onCloseUserDetail()"
                >
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </button>
                <user-modal
                  v-if="selectedTabModal === 'detail'"
                  @qrClicked="qrClicked"
                  :current-user="currentRow"
                  :event="event"
                />
                <user-orders-modal
                  v-if="selectedTabModal === 'orders'"
                  @qrClicked="qrClicked"
                  :orders="userOrders"
                  :currentUser="currentRow"
                  :event="event"
                />
                <user-transaction-modal
                  v-if="selectedTabModal === 'transactions'"
                  @qrClicked="qrClicked"
                  @onClickUser="onClickUserView"
                  :transactions="userTransactions"
                  :currentUser="currentRow"
                  :event="event"
                />
                <user-payment-modal
                  v-if="selectedTabModal === 'payment'"
                  @qrClicked="qrClicked"
                  :current-user="currentRow"
                  :event="event"
                />

                <div
                  class="wrap-content-md"
                  v-if="selectedTabModal === 'qrCode'"
                >
                  <div class="infor-modal">
                    <div style="margin-right: 7px">
                      <FingerIcon />
                    </div>
                    <span> Wallet ID</span>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="wrap-user-qrcode-list">
                        <div v-for="code in listQRCodes">
                          <el-dropdown type="dropdown-modal">
                            <span class="el-dropdown-link">
                              <img
                                class="dropdown-dot"
                                src="assets/imgs/dot-dropdown.svg"
                                alt=""
                              />
                            </span>

                            <el-dropdown-menu
                              class="dropdown-table"
                              slot="dropdown"
                              v-if="userDetail.type !== 'cash'"
                            >
                              <el-dropdown-item
                                command="a"
                                @click.native="onRemoveCode(code)"
                              >
                                <span style="font-size: 12px; padding-left: 5px"
                                  >Remove</span
                                >
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <qrcode
                            :value="code.barcode"
                            :options="{ width: 95, height: 95 }"
                          ></qrcode>
                          <div class="wrap-infor-code">
                            <div>
                              <label for="">
                                <b>Wallet ID</b>
                              </label>
                              <div>
                                <span>*********</span>
                              </div>
                            </div>
                            <div>
                              <label for="">
                                <b>Event</b>
                              </label>
                              <div>
                                <span>{{ code.event.name }}</span>
                              </div>
                            </div>
                            <div>
                              <label for="">
                                <b>Generated By</b>
                              </label>
                              <div>
                                <span>N/A</span>
                              </div>
                            </div>
                            <div>
                              <label for="">
                                <b>Date Added</b>
                              </label>
                              <div>
                                <span>{{
                                  moment(code.created).format(
                                    "MMM DD, YYYY hh:mm a"
                                  )
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 mt-3 text-center">
                      <n-button
                        type="default"
                        :style="{
                          background: '#F6F6F6',
                          opacity: 1,
                        }"
                        @click.native="
                          isShowQRCodeFram = true;
                          qrCodeStep = 1;
                        "
                      >
                        Add Wallet ID
                      </n-button>
                    </div>
                  </div>

                  <div class="qr-code-frame" v-if="isShowQRCodeFram">
                    <template v-if="qrCodeStep === 1">
                      <h3>New Wallet ID</h3>
                      <h5>Scan Wallet ID</h5>

                      <qrcode-stream
                        @detect="onDetect"
                      >
                        <!-- <div class="qr-code-sec"></div> -->
                      </qrcode-stream>

                      <h5 class="mt-3">Enter Manually</h5>
                      <h6
                        @click="
                          isShowQRCodeFram = false;
                          qrCodeStep = 1;
                        "
                      >
                        Cancel
                      </h6>
                    </template>
                    <template v-if="qrCodeStep === 2">
                      <h3>New Wallet ID</h3>
                      <h5>Enter Wallet ID</h5>
                      <div class="mb-3 wrap-input-with-btn">
                        <input
                          type="text"
                          v-model="qrCodeText"
                          :disabled="isUploadingQR"
                        />
                        <button
                          type="button"
                          @click="onSubmitCode()"
                          :disabled="isUploadingQR"
                        >
                          Submit
                        </button>
                      </div>
                      <h5>Scan Wallet ID</h5>
                      <h6
                        @click="
                          isShowQRCodeFram = false;
                          qrCodeStep = 1;
                        "
                      >
                        Cancel
                      </h6>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="isShowReloadForm"
        custom-class="event-dialog"
        append-to-body
        :before-close="beforeClose"
      >
        <template #title>
          <div></div>
        </template>
        <reload-form
          v-if="isShowReloadForm"
          :onCloseReload="onCloseReloadForm"
          :defaultReloadUser="selectedUser"
        ></reload-form>
      </el-dialog>

      <el-dialog
        :visible.sync="isShowTransferToken"
        custom-class="event-dialog"
        append-to-body
        :before-close="beforeClose"
      >
        <template #title>
          <div></div>
        </template>
        <transfer-token
          v-if="isShowTransferToken"
          @onClose="onCloseTransferForm"
          :user="$store.state.user"
          :event="event"
          :defaultTransferFromUser="selectedUser"
        ></transfer-token>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import ReloadForm from "./ReloadComp/ReloadForm";
import TransferToken from "src/components/TransferToken.vue";

import UserModal from "src/components/Modals/User.vue";
import UserOrdersModal from "src/components/Modals/UserOrders.vue";
import UserTransactionModal from "src/components/Modals/UserTransaction.vue";
import UserPaymentModal from "src/components/Modals/UserPayment.vue";
import UserWalletIDModal from "src/components/Modals/UserWalletID.vue";

import ViewIcon from "src/components/Icons/View.vue";

import NewUser from "src/pages/Dashboard/NewUser.vue";
import _ from "lodash";
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import moment from "moment/moment";
import Spinner from "src/components/Spinner";
import { formatRevenueValue, formatRevenueToken } from "@/util/string.js";
import UserGroupIcon from "src/components/Icons/UserGroup";
import WalletIcon from "src/components/Icons/Wallet";
import MoneyIcon from "src/components/Icons/Money";
import InforCircleIcon from "src/components/Icons/InforCircle";
import CardIcon from "src/components/Icons/Card";
import FingerIcon from "src/components/Icons/Finger";
import OrderIcon from "src/components/Icons/Order";
import TransactionIcon from "src/components/Icons/Transaction";
import { QrcodeDropZone, QrcodeStream } from "vue-qrcode-reader";

const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    vm.loading = false;

    if (response.data.results) {
      vm.users = response.data.results;
      vm.total = response.data.count;
    } else {
      vm.users = response.data;
      vm.total = vm.users.length;
    }
  });
}, 500);
export default {
  props: ["params", "url", "event", "currentUser"],
  components: {
    UserModal,
    NewUser,
    TabPane,
    Tabs,
    UserOrdersModal,
    UserPaymentModal,
    UserTransactionModal,
    ViewIcon,
    UserWalletIDModal,
    Spinner,
    ReloadForm,
    TransferToken,
    UserGroupIcon,
    TransactionIcon,
    OrderIcon,
    WalletIcon,
    MoneyIcon,
    CardIcon,
    FingerIcon,
    InforCircleIcon,
    QrcodeDropZone,
    QrcodeStream,
  },
  computed: {
    userDetail() {
      return this.$store.state.user;
    },
    titles() {
      const user = this.$store.state.user;
      const self = this;
      return user.type === "cash"
        ? [
            {
              prop: "phone_number",
              label: "Phone",
              width: 140,
            },
            {
              prop: "email",
              label: "Email",
              width: 150,
            },
            {
              prop: "balance",
              label: "Token Balance",
              width: 125,
              formatter: (item) => {
                if (this.currentPriceType === "token") {
                  return formatRevenueToken(item.balance, self.event, 3);
                }
                return formatRevenueValue(item.balance, self.event, 3);
              },
            },
            {
              prop: "type",
              label: "Type",
              width: 160,
            },
            {
              prop: "date_joined",
              label: "Date Created",
              width: 190,
              formatter: (row) => {
                return moment(row).format("llll");
              },
            },
          ]
        : [
            // {
            //   prop: "first_name",
            //   label: "Full Name",
            //   formatter: (row) => {
            //     return row.first_name + " " + row.last_name;
            //   },
            //   width: 180,
            // },
            {
              prop: "phone_number",
              label: "Phone",
              width: 140,
            },
            {
              prop: "email",
              label: "Email",
              width: 150,
            },
            {
              prop: "balance",
              label: "Token Balance",
              width: 150,
              formatter: (item) => {
                if (this.currentPriceType === "token") {
                  return formatRevenueToken(item.balance, self.event, 3);
                }
                return formatRevenueValue(item.balance, self.event, 3);
              },
            },
            {
              prop: "type",
              label: "Type",
              width: 160,
            },
            {
              prop: "date_joined",
              label: "Date Created",
              width: 190,
              formatter: (row) => {
                return moment(row.date_joined).format("llll");
              },
            },
          ];
    },
  },
  data() {
    const self = this;
    return {
      listQRCodes: [],
      isUploadingQR: false,
      qrCodeStep: 1,
      isShowQRCodeFram: false,
      isReady: false,
      isShowReloadForm: false,
      isShowTransferToken: false,
      selectedTabModal: "detail",
      isDisplayed: false,
      qrCode: false,
      counts: { all: 0, attendees: 0, vendors: 0 },
      userOrders: [],
      userTransactions: [],
      currentRow: false,
      currentPage: 1,
      pageSize: 50,
      total: 0,
      users: [],
      modalVisible: false,
      filters: [
        {
          value: "",
        },
      ],
      type: "All Users",
      tabs: {
        vendors: "Vendors",
        attendees: "Attendees",
        all: "All Users",
      },
      selectedRow: [],
      dialogTableVisible: false,
      loading: false,
      paramsGrid: null,
      timer: null,
      currentPriceType: "token",
      isTurnOnPageRefresh: false,
      selectedUser: null,
      isLoadingUserDetail: false,
      constraintOptions: [
        { label: "rear camera", constraints: { facingMode: "environment" } },
        { label: "front camera", constraints: { facingMode: "user" } },
      ],
    };
  },
  async mounted() {
    this.paramsGrid = {};
    if (this.$route.query.page) {
      this.paramsGrid.page = Number(this.$route.query.page);
      this.currentPage = Number(this.$route.query.page);
    }
    if (this.$route.query.pageSize) {
      this.paramsGrid.pageSize = Number(this.$route.query.pageSize);
    }

    this.loadData(this.paramsGrid);
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.loadData(this.paramsGrid, true);
          // if (this.currentRow && this.modalVisible) {
          //   this.onClickTransationView(this.currentRow, true);
          // }
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.loadData(this.paramsGrid, true);
        // if (this.currentRow && this.modalVisible) {
        //   this.onClickTransationView(this.currentRow, true);
        // }
      }, 10000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }

    if (this.$route.query.userId) {
      this.getUserDetail(this.$route.query.userId);
    }
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/user-counts/`
    );
    this.counts = response.data;
    this.isReady = true;
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    moment,
    async onCameraReady() {
      // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
      // camera access permission. `QrcodeStream` internally takes care of
      // requesting the permissions. The `camera-on` event should guarantee that this
      // has happened.
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(({ kind }) => kind === "videoinput");

      this.constraintOptions = [
        ...this.constraintOptions,
        ...videoDevices.map(({ deviceId, label }) => ({
          label: `${label} (ID: ${deviceId})`,
          constraints: { deviceId },
        })),
      ];
    },
    fetchListUserCode() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentRow.id}/event/${this.$route.params.id}/qr-code/`;

      axios
        .get(url)
        .then((response) => {
          this.listQRCodes = response.data;
        })
        .catch((er) => {});
    },
    onRemoveCode(code) {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentRow.id}/event/${this.$route.params.id}/qr-code/${code.barcode}/delete/`;

      axios
        .delete(url)
        .then((response) => {
          this.listQRCodes = this.listQRCodes.filter((e) => e.id !== code.id);
          this.fetchListUserCode();
        })
        .catch((er) => {});
    },
    onSubmitCode() {
      if (!this.qrCodeText || this.qrCodeText === "") {
        return;
      }

      this.isUploadingQR = true;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentRow.id}/event/${this.$route.params.id}/qr-code/add/`;

      axios
        .post(url, {
          code: this.qrCodeText,
        })
        .then((response) => {
          this.qrCodeText = "";
          this.qrCodeStep = 1;
          this.isShowQRCodeFram = false;
          this.fetchListUserCode();
        })
        .catch((er) => {
          this.isUploadingQR = false;
        });
    },
    onDetect(detectedCodes) {
      this.isUploadingQR = false;
      detectedCodes.then((rs) => {
        if (rs.content) {
          this.qrCodeText = rs.content;
          this.qrCodeStep = 2;
        } else {
          this.$message.error("Please upload valid qr code!");
        }
      });
    },
    beforeClose() {
      this.isDisplayed = false;
      this.currentRow = null;
      this.modalVisible = false;
      this.isShowReloadForm = false;
      this.isShowTransferToken = false;
      this.selectedUser = null;
      if (this.$route.query.userId) {
        this.$router.push({
          path: this.$route.path,
          query: { userId: undefined },
        });
      }
    },
    onCloseUserDetail() {
      this.isDisplayed = false;
      this.currentRow = null;
      this.selectedUser = null;
      this.modalVisible = false;
      if (this.$route.query.userId) {
        this.$router.push({
          path: this.$route.path,
          query: { userId: undefined },
        });
      }
    },
    onCloseReloadForm() {
      this.isShowReloadForm = false;
    },
    onCloseTransferForm() {
      this.isShowTransferToken = false;
    },
    filterData(e) {
      this.paramsGrid.pageSize = e;
      this.loadData(this.paramsGrid);
    },
    onCloseUserModal() {
      this.isDisplayed = false;
    },
    onClickUserView(row) {
      if (Number(this.$route.query.userId) !== row.id) {
        this.$router.push({
          path: this.$route.path,
          query: { userId: row.id },
        });
        this.getUserDetail(row.id);
      }
    },
    async getUserDetail(id) {
      this.isLoadingUserDetail = true;
      this.modalVisible = true;

      this.$nextTick(async () => {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_HOST}/en/api/user/${id}/`
        );
        this.currentRow = response.data;
        this.fetchListUserCode();
        this.isLoadingUserDetail = false;
        this.onClickTransationView(this.currentRow);
      });
    },
    onClickTransationView(row, isRefresh = false) {
      // this.currentRow = row;
      if (!this.isRefresh) {
        this.isDisplayed = false;
        this.modalVisible = true;
      }

      if (this.userDetail.type !== "cash") {
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${row.id}/orders/${this.$route.params.id}/?expand=vendor_event.vendor`;
        this.dialogTableVisible = true;
        axios.get(url).then((response) => {
          this.userOrders = response.data;
        });
      }
      const transactionUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${row.id}/transactions/${this.$route.params.id}/?expand=order.vendor_event.vendor,to_user,from_user&ordering=-timestamp`;
      this.dialogTableVisible = true;
      axios.get(transactionUrl).then((response) => {
        this.userTransactions = response.data;
      });
    },
    changeTab(tab) {
      console.log(tab);
      this.type = tab.toLowerCase();
      this.users = [];
      this.paramsGrid = null;
      this.loadData({ type: tab, pageSize: 50 });
    },
    qrClicked(item) {
      this.selectedTabModal = "qrCode";
      this.qrCode = item;
    },
    async loadData(queryInfo, isRefresh = false) {
      if (!isRefresh) {
        this.loading = true;
        if (!this.$route.query.userId) {
          this.isDisplayed = false;
          this.currentRow = null;
          this.modalVisible = false;
        }
      }
      if (!this.paramsGrid) {
        this.paramsGrid = {
          ...queryInfo,
        };
      }

      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/`;
      if (this.url) {
        url = this.url;
      }

      let params = {
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        search: this.filters[0].value,
        expand: "balance",
        event: this.$route.params.id,
        all_users: 1,
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          (queryInfo.sort.order == "descending" ? "-" : "") +
          queryInfo.sort.prop;
      }

      if (this.type && this.type !== "all users" && this.type !== "All Users") {
        params["type"] = this.type;
      }

      // if (this.currentRow) {
      //   this.onClickTransationView(this.currentRow);
      // }

      // if (
      //   Number(this.$route.query.page) !== Number(queryInfo.page) ||
      //   Number(this.$route.query.pageSize) !== Number(queryInfo.pageSize)
      // ) {
      //   this.$router.replace({
      //     ...this.$router.currentRoute,
      //     query: {
      //       pageSize: Number(queryInfo.pageSize),
      //       page: Number(queryInfo.page),
      //     },
      //   });
      // }

      debounced(this, url, params);
    },
    canAddUser() {
      const user = this.$store.state.user;
      return user.is_staff || user.type === "org-administrator";
    },
  },
};
</script>

<style lang="scss">
.add-user-link {
  float: right;
}
</style>
<style lang="scss" scoped>
.btn.btn-primary.azul {
  margin-left: 30px;
}
.title-page {
  margin-bottom: 30px;
}

.qr-code-frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 102%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000e6;
  flex-direction: column;
  color: #fff;
  z-index: 9999;
  h3 {
    font-size: 16px;
    &:first-child {
      margin-bottom: 42px;
    }
  }
  h5 {
    font-size: 12px;
    margin-bottom: 15px;
  }

  h6 {
    margin-top: 70px;
    font-size: 10px;
    cursor: pointer;
  }
  .qrcode-stream-wrapper {
    width: 200px!important;
    height: 200px!important;
    border-radius: 10px;
    background-color: #d9d9d9;
  }
}

.wrap-input-with-btn {
  width: 350px;
  height: 36px;
  left: 668px;
  top: 298px;
  background: #f6f6f6;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  display: flex;
  color: black;
  input {
    width: 100%;
    height: 100%;
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    &:focus-visible {
      outline: none;
    }
  }
  button {
    white-space: nowrap;
    border: none;
    background: transparent;
    font-size: 12px;
    background: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.wrap-user-qrcode-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  & > div {
    display: flex;
    padding: 20px;
    border: 1px solid #eaeaec;
    border-radius: 20px;
    position: relative;
    .el-dropdown {
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }

  .wrap-infor-code {
    margin-left: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: #021b43;
    & > div {
      width: 50%;
      flex: 1 1 auto;
    }
  }
}
</style>
