<template>
  <div class="panel-header panel-header-lg">
    <canvas id="chart" style="width: 0; height: 0"></canvas>
    <LineChartGenerator
      v-if="!isEmptyData && chartData"
      :data="chartData"
      :options="options"
      :height="600"
      :width="600"
    />
    <div
      v-if="!isEmptyData && !chartData"
      style="height: 300px"
      class="d-flex justify-content-center align-items-center"
    >
      <Spinner />
    </div>

    <div
      class="w-100 d-flex h-100 align-items-center justify-content-center"
      v-if="isEmptyData"
    >
      No Data Available Yet
    </div>
  </div>
</template>

<script>
// import HeaderChart from "./HeaderChart";
import moment from "moment";
import Spinner from "src/components/Spinner";

import { Line as LineChartGenerator } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import "chartjs-adapter-moment";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

ChartJS.defaults.font.weight = 600;

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "8px";
    tooltipEl.style.color = "#021B43";
    tooltipEl.style.padding = "15px";
    tooltipEl.style.boxShadow = "0 0 12px 2px rgba(231,234,243,0.30)";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.minWidth = "180px";
    tooltipEl.classList.add("arrow-panel");

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, vm) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tooltip._tooltipItems.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;
      tr.style.borderWidth = 0;
      tr.style.fontFamily = '"Inter", sans-serif;';
      tr.style.fontSize = "12px";
      tr.style.marginTop = "8px";

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(
        "$" + body.formattedValue + " Rev via Orders"
      );

      // td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);

      // const tr2 = document.createElement("tr");
      // tr2.style.backgroundColor = "inherit";
      // tr2.style.borderWidth = 0;
      // tr2.style.borderWidth = 0;
      // tr2.style.fontFamily = '"Inter", sans-serif;';
      // tr2.style.fontSize = "12px";
      // tr2.style.marginTop = "8px";

      // const td2 = document.createElement("td");
      // td2.style.borderWidth = 0;
      // const text2 = document.createTextNode(
      //   body.formattedValue + " Tokens via Cash"
      // );

      // td2.appendChild(text2);
      // tr2.appendChild(td2);
      // tableBody.appendChild(tr2);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left =
    tooltip.x <= 60
      ? 100 + "px"
      : positionX +
        (tooltip.x + tooltip.width + 30 < chart.canvas.offsetWidth ? 0 : -90) +
        tooltip.caretX +
        "px";
  tooltipEl.style.top = -positionY + tooltip.y - 20 + "px";
  //  -
  // (tooltip.y + tooltip.height + 120 < chart.canvas.offsetHeight ? 0 : -90) +
  // tooltip.caretY +

  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";

  tooltipEl.classList.remove("right");
  if (tooltip.x + tooltip.width + 100 >= chart.canvas.offsetWidth) {
    tooltipEl.classList.add("right");
  }
  tooltipEl.classList.remove("left");
  if (tooltip.x <= 60) {
    tooltipEl.classList.add("left");
  }

  if (tooltip.y + tooltip.height + 30 >= chart.canvas.offsetHeight) {
    tooltipEl.classList.add("bottom");
  }
};

export default {
  props: ["groupType"],
  name: "overview-header",
  components: {
    LineChartGenerator,
    Spinner,
  },
  data() {
    return {
      chartData: null,
      chartLabels: [],
      options: {},
      isEmptyData: false,
      responseChart: [],
    };
  },
  mounted() {
    const self = this;
    window.eventBus.$on("renderChartData", (response) => {});
    window.eventBus.$on("receivedCounts", (response) => {
      if(!response || !response.data) {
        return;
      }
      this.loading = false;
      this.chartData = null;
      this.responseChart = response.data.time_data;
      if (self.groupType !== "time" && self.groupType !== "hour") {
        this.responseChart = [
          ...this.responseChart
            .reduce((map, item) => {
              const { date, count, amount } = item;
              let key = date.split(" ")[0];
              const prev = map.get(key);

              if (prev) {
                prev.count += count;
                prev.amount += amount;
                prev.date = key;
              } else {
                map.set(key, Object.assign({}, { ...item, date: key }));
              }

              return map;
            }, new Map())
            .values(),
        ];
      } else {
        this.responseChart = [...this.responseChart.map((e) => {
        e.date = moment(e.date).format('YYYY-MM-DD HH:mm')
        return e
      })];
      }

      if (!response) {
        this.isEmptyData = true;
        return;
      }
      console.log(this, this.datasets);
      let chartColor = "#2d61d3"; //"rgba(45, 97, 211, 0.25)";

      this.$nextTick(() => {
        const ctx = document.getElementById("chart").getContext("2d");
        const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, "#2d61d3");
        gradientStroke.addColorStop(1, chartColor);

        const gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
        const whitefill = ctx.createLinearGradient(0, 200, 0, 50);
        whitefill.addColorStop(0, "rgba(255, 255, 255, 0.5)");
        gradientFill.addColorStop(1, "rgba(89, 154, 235, 0.8)");
        gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");

        this.options = {
          interaction: {
            intersect: false,
            mode: "index",
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 40,
              bottom: 0,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: undefined,
              gridLines: {
                zeroLineColor: "transparent",
                display: false,
                color: "#fff",
              },
              grid: {
                display: false,
              },
              distribution: "series",
              ticks: {
                padding: 10,
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: 10,
                color: "#6B7280",
                font: {
                  weight: 400,
                  size: 14,
                  family: '"Inter", sans-serif;',
                },
                callback: function (label, index, labels) {
                  const lb = this.getLabelForValue(label);
                  if (self.groupType === "hour") {
                    return lb;
                  }
                  return moment(lb).format("ddd DD");
                },
              },
            },
            y: {
              ticks: {
                fontColor: "#8e8e93",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 10,
                color: "#6B7280",
                font: {
                  weight: 400,
                  size: 14,
                  family: '"Inter", sans-serif;',
                },
              },
              gridLines: {
                drawTicks: true,
                drawBorder: false,
                display: true,
                borderDash: [4, 2],
                color: "#bdbdbd",
                zeroLineColor: "transparent",
              },
            },
          },
          plugins: {
            decimation: {
              algorithm: "lttb",
              enabled: true,
              samples: 10,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: (event) => externalTooltipHandler(event, this),
            },
          },
        };
        this.chartData = {
          labels: this.responseChart.map((item) => item.date),
          datasets: [
            {
              label: "Orders",
              borderColor: chartColor,
              // fillColor: gradientFill,
              pointBorderColor: "#2d61d3",
              pointBackgroundColor: "#2d61d3",
              pointHoverBackgroundColor: "#2d61d3",
              borderColor: "#2d61d3",
              pointBorderWidth: 2,
              pointHoverRadius: 7,
              pointHoverBorderWidth: 3,
              pointRadius: 3,
              fill: false,
              borderWidth: 2,
              lineTension: 0,
              data: this.responseChart.map((item) => item.amount) || [],
              type: "line",
            },
            // {
            //   yAxisID: 'y1',
            //   type: "line",
            //   label: "Cash",
            //   // backgroundColor: gradientFill,
            //   pointBorderColor: "#2d61d3",
            //   pointBackgroundColor: "#2d61d3",
            //   pointHoverBackgroundColor: "#2d61d3",
            //   borderColor: "#2d61d3",
            //   pointBorderWidth: 2,
            //   pointHoverRadius: 7,
            //   pointHoverBorderWidth: 3,
            //   pointRadius: 3,
            //   // fill: true,
            //   borderWidth: 2,
            //   lineTension: 0.5,
            //   data: this.responseChart.map((item) => item.amount) || [],
            // },
          ],
        };
      });
    });
  },
};
</script>

<style scoped></style>
