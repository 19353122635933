import axios from "axios";
export default {
  methods: {
    getSummaryData(url) {
      this.summary = {}
      axios
        .get(url)
        .then((response) => {
          this.rawResponse = response;
          window.eventBus.$emit("receivedCounts", response);

          this.summary = {
            ...response.data,
          };
          this.top5Items = response.data.top_5_item;
          this.top5Vendors = response.data.top_5_vendor;
          this.loading = false;
        })
        .catch((error) => {
          window.eventBus.$emit("receivedCounts", null);

          this.top5Items = [];
          this.top5Vendors = [];
          this.loading = false;
        });
    },
  },
  watch: {
    rangeTime(newVal, oldVal) {
      this.getSummaryData(this.countUrl);
    },
  },
};
