<template>
  <user-list
    :event="currentEvent"
    :url="this.url"
  />
</template>
<script>
import UserList from "./UserList.vue";
import Event from 'src/mixins/Event.js';
export default {
  components: {
    UserList
  },
  data() {
    return {
      url: `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/users/`
    }
  },
  mixins: [Event]
}
</script>
