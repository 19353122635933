<template>
  <navbar
    :show-navbar="showNavbar"
    v-click-outside="onClickOutSide"
    :style="
      $route.name === 'EventReloadList' && $store.state.user.type === 'cash'
        ? ''
        : $route.meta.hasRightPanel
        ? 'width : calc(100% - 385px)'
        : ''
    "
    v-if="currentEvent"
  >
    <div class="navbar-wrapper">
      <a class="navbar-brand m-0" v-if="!$route.meta.noHeader">
        <span
          >{{ currentEvent.name }} ({{ currentEvent.address }})
          <span>{{ $store.state.user?.user_type?.vendor?.name }}</span></span
        >
        <span class="small" v-if="currentEvent">{{
          format(currentEvent.start_date_time, "llll")
        }}</span>
        <!-- <span class="small" v-if="currentEvent">{{format(currentEvent.start_date_time, 'llll')}}</span> -->
      </a>
    </div>
    <!-- <button
      @click="toggleNavbar"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button> -->
    <template slot="navbar-menu">
      <div class="wrap-right-nav">
        <!-- <div class="nav-item">
          <img src="assets/imgs/weather.png" alt="">
          <span>26 &deg;</span>
        </div>
        <div class="nav-item">
          <i class="now-ui-icons ui-1_bell-53"></i>
        </div> -->
        <template v-if="$route.meta.additionControl && currentEvent">
          <div class="nav-item" v-if="currentEvent">
            <div class="wrap-page-refresh-control">
              <div class="refresh-label">Auto Refresh</div>
              <el-switch
                v-model="isTurnOnPageRefresh"
                :active-text="isTurnOnPageRefresh ? 'On' : 'Off'"
              >
              </el-switch>
            </div>
          </div>
          <div
            class="nav-item m-0"
            v-if="currentEvent && !$route.meta.isHideTokenSwitch"
          >
            <div class="wrap-switcher-currency">
              <div class="switches-container">
                <input
                  type="radio"
                  id="switchToken"
                  name="switchPlan"
                  value="token"
                  v-model="currentPriceType"
                />
                <input
                  type="radio"
                  id="switchDollar"
                  name="switchPlan"
                  value="dollar"
                  v-model="currentPriceType"
                />
                <label for="switchToken"><PoundIcon /></label>
                <label for="switchDollar"><DollarIcon /></label>
                <div class="switch-wrapper">
                  <div class="switch">
                    <div><PoundIcon /></div>
                    <div><DollarIcon /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <div>
      <el-dialog
        :visible.sync="showUserProfile"
        custom-class="event-dialog"
        append-to-body
      >
        <template #title>
          <div></div>
        </template>
        <user-profile-form
          :onClose="onCloseUserProfile"
          :isEdit="true"
        ></user-profile-form>
      </el-dialog>
    </div>
  </navbar>
</template>
<script>
import { RouteBreadCrumb, Navbar, NavbarToggleButton } from "src/components";
import { CollapseTransition } from "vue2-transitions";
import Event from "src/mixins/Event";
import UserProfileForm from "../UserProfileForm";
import DollarIcon from "src/components/Icons/Dollar";
import PoundIcon from "src/components/Icons/Pound";
import moment from "moment";

export default {
  components: {
    RouteBreadCrumb,
    Navbar,
    NavbarToggleButton,
    CollapseTransition,
    UserProfileForm,
    DollarIcon,
    PoundIcon,
  },
  mixins: [Event],
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  watch: {
    isTurnOnPageRefresh(newVal, oldVal) {
      if (this.$route.meta.additionControl && this.currentEvent) {
        sessionStorage.setItem("isRefresh", newVal.toString());
        window.eventBus.$emit("onChangeRefreshPage", newVal);
      }
    },
    currentPriceType(newVal, oldVal) {
      if (this.$route.meta.additionControl && this.currentEvent) {
        sessionStorage.setItem("priceType", newVal.toString());
        window.eventBus.$emit("onChangePriceType", newVal);
      }
    },
  },
  data() {
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    let currentPriceType = "token";
    if (
      sessionStorage.getItem("priceType") === "token" ||
      sessionStorage.getItem("priceType") === "dollar"
    ) {
      currentPriceType = sessionStorage.getItem("priceType");
    }
    return {
      activeNotifications: false,
      showNavbar: false,
      showUserProfile: false,
      isTurnOnPageRefresh,
      currentPriceType,
    };
  },
  methods: {
    format(val, type) {
      return moment(val).format(type);
    },
    onClickOutSide(event) {
      if (this.showNavbar) {
        this.showNavbar = false;
      }
    },
    onCloseUserProfile() {
      this.showUserProfile = false;
    },
    onSelectItemDropdown(evt) {
      switch (evt) {
        case "logout":
          this.logout();
          break;
        case "profile":
          this.showUserProfile = true;
        default:
          break;
      }
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      sessionStorage.removeItem("isRefresh");

      this.$router.push({ name: "Login" });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style></style>
