<template>
  <spinner v-if="loading" />
  <div v-else>
    <slot></slot>
  </div>
</template>
<script>
import Spinner from "src/components/Spinner";

export default {
  components: {
    Spinner,
  },
  props: ["loading"],
};
</script>
