import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Dashboard/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Page Headers
import CalendarHeader from "src/pages/Dashboard/Calendar/CalendarHeader";
const DashboardHeader = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/DashboardHeader.vue"
  );
import DefaultHeader from "src/pages/Dashboard/DefaultHeader";
import SweetAlertHeader from "src/pages/Dashboard/Components/Headers/SweetAlertHeader";
import VectorMapsHeader from "src/pages/Dashboard/Maps/VectorMapsHeader";
import Events from "src/pages/Dashboard/Events.vue";
import Accounts from "src/pages/Dashboard/Accounts.vue";
import EventDetail from "src/pages/Dashboard/EventDetail.vue";
import EventUserList from "src/pages/Dashboard/EventUserList.vue";
import EventTransactionList from "src/pages/Dashboard/EventTransactionList.vue";
import EventReloadList from "src/pages/Dashboard/EventReloadList.vue";
import EventOrderList from "src/pages/Dashboard/EventOrderList.vue";
import Organizations from "src/pages/Dashboard/Organizations.vue";
import OrganizationAdmins from "src/pages/Dashboard/OrganizationAdmins.vue";
import EventVendorDetail from "src/pages/Dashboard/EventVendorDetail.vue";
import VendorDetail from "src/pages/Dashboard/VendorDetail.vue";
import Edit from "src/pages/Dashboard/EventDetail/Edit.vue";
import VendorList from "src/pages/Dashboard/VendorList.vue";
import UserList from "src/pages/Dashboard/UserList.vue";
import NewUser from "src/pages/Dashboard/NewUser.vue";
import EventVendorList from "src/pages/Dashboard/EventVendorList.vue";
import EventReportingList from "src/pages/Dashboard/EventReportingList.vue";
import EventLocations from "src/pages/Dashboard/EventLocation.vue";
import EventDemographics from "src/pages/Dashboard/EventDemographics.vue";
import EventMenu from "src/pages/Dashboard/EventMenu.vue";

import EventFinancials from "src/pages/Dashboard/EventFinancials.vue";
import EventVendorFinancials from "src/pages/Dashboard/EventVendorFinancials.vue";
import Menu from "src/pages/Dashboard/Menu.vue";
import CashUserIndex from "src/pages/Dashboard/CashUser/Index.vue";
import CashUserEvent from "src/pages/Dashboard/CashUser/Detail.vue";
import Activation from "src/pages/Dashboard/Pages/Activation"
// Calendar
const Calendar = () =>
  import(
    /* webpackChunkName: "calendar" */ "src/pages/Dashboard/Calendar/CalendarRoute.vue"
  );
// Charts
const Charts = () =>
  import(/* webpackChunkName: "charts" */ "src/pages/Dashboard/Charts.vue");

// Components pages
const Buttons = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Buttons.vue"
  );
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/GridSystem.vue"
  );

const Panels = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Panels.vue"
  );
const SweetAlert = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/SweetAlert.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Notifications.vue"
  );
const Icons = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Icons.vue"
  );
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Typography.vue"
  );

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
  );
import Widgets from "src/pages/Dashboard/Widgets.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// Maps pages
const GoogleMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/GoogleMaps.vue"
  );
const FullScreenMap = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/FullScreenMap.vue"
  );
const VectorMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/VectorMaps.vue"
  );

// Pages
const User = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/UserProfile.vue"
  );
const Pricing = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/Pricing.vue"
  );
const TimeLine = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/TimeLinePage.vue"
  );
const Login = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Login.vue");
const Register = () =>
  import(
    /* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Register.vue"
  );
const Lock = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Lock.vue");

// TableList pages
const RegularTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/RegularTables.vue"
  );
const ExtendedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/ExtendedTables.vue"
  );
const PaginatedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/PaginatedTables.vue"
  );

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons, header: DefaultHeader },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem, header: DefaultHeader },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels, header: DefaultHeader },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert, header: SweetAlertHeader },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications, header: DefaultHeader },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons, header: DefaultHeader },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography, header: DefaultHeader },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms, header: DefaultHeader },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms, header: DefaultHeader },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard, header: DefaultHeader },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables, header: DefaultHeader },
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables, header: DefaultHeader },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps, header: DefaultHeader },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps, header: VectorMapsHeader },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User, header: DefaultHeader },
      meta: {
        requiresAuth: true,
        isAdminOnly: true,
      },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine, header: DefaultHeader },
      meta: {
        requiresAuth: true,
        isAdminOnly: true,
      },
    },
    {
      path: "events",
      name: "Events",
      components: {
        default: Events,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        noHeader: true,
      },
    },
    {
      path: "accounts",
      name: "Accounts",
      components: {
        default: Accounts,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        noHeader: true,
        isAdminOnly: true,
      },
    },
    {
      path: "events/:id/all-vendors",
      name: "AllVendors",
      components: {
        default: VendorList,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/all-users",
      name: "AllUsers",
      components: {
        default: UserList,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "users/new",
      name: "NewUser",
      components: {
        default: NewUser,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/dashboard",
      name: "Dashboard",
      components: {
        default: EventDetail,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        isBlockCash: true,
      },
    },
    {
      path: "events/:id/users",
      name: "Users",
      components: {
        default: EventUserList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        is_admin: true,
        cashUserAllowed: true,
      },
    },
    // {
    //   path: "events/:id/users/:userId",
    //   name: "EventUsersModal",
    //   components: {
    //     default: EventUserList,
    //     header: DefaultHeader,
    //   },
    //   meta: {
    //     requiresAuth: true,
    //     is_admin: true,
    //     additionControl: true,
    //     cashUserAllowed: true,
    //   },
    // },

    {
      path: "events/:id/transactions/:transactionId?",
      name: "Transactions",
      components: {
        default: EventTransactionList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/financials",
      name: "Financials",
      components: {
        default: EventFinancials,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/vendor-financials",
      name: "Vendor Financials",
      components: {
        default: EventVendorFinancials,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        isBlockCash: true,
        additionControl: true,
        isHideTokenSwitch: true
      },
    },
    {
      path: "events/:id/vendor/:vendorId/financials",
      name: "Org Vendor Financials",
      components: {
        default: EventVendorFinancials,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/orders/:orderId?",
      name: "Orders",
      components: {
        default: EventOrderList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        isBlockCash: true,
      },
    },
    {
      path: "events/:id/edit",
      name: "EventEdit",
      components: {
        default: Edit,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/vendors",
      name: "Vendors",
      components: {
        default: EventVendorList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        is_admin: true,
      },
    },
    {
      path: "events/:id/organizations",
      name: "Organizations",
      components: {
        default: Organizations,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "organizations/:id",
      name: "OrganizationEvents",
      components: {
        default: Events,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "organizations/:id/admins",
      name: "OrganizationAdmins",
      components: {
        default: OrganizationAdmins,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "/pages/events/:id/vendors/:vendorId/:eventVendorId",
      name: "EventVendorDetail",
      components: {
        default: EventVendorDetail,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
        additionControl: true,
        isVendorUserAllow: true
      },
    },
    {
      path: "/pages/events/:id/vendors/:vendorId",
      name: "VendorDashboard",
      components: {
        default: VendorDetail,
        header: DashboardHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
        additionControl: true,
        isVendorUserAllow: true
      },
    },
    {
      path: "/pages/events/:id/reloads",
      name: "EventReloadList",
      components: {
        default: EventReloadList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        hasRightPanel: true,
        additionControl: true,
        is_admin: true,
        // cashUserAllowed: true
      },
    },
    {
      path: "/pages/events/:id/locations",
      name: "EventLocations",
      components: {
        default: EventLocations,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "/pages/events/:id/demographics",
      name: "EventDemographics",
      components: {
        default: EventDemographics,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "/pages/events/:id/menu",
      name: "EventMenu",
      components: {
        default: EventMenu,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        isBlockCash: true,
      },
    },
    {
      path: "/pages/events/:id/reporting",
      name: "EventReportingList",
      components: {
        default: EventReportingList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        additionControl: true,
        is_admin: true,
      },
    },
    {
      path: "/pages/vendor/:id/",
      name: "VendorDetail",
      components: {
        default: VendorDetail,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "/menu/:id",
      name: "Menu",
      components: {
        default: Menu,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        is_admin: true,
      },
    },
    {
      path: "/mini",
      name: "Support Dashboard",
      components: {
        default: CashUserIndex,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        cashUserAllowed: true,
        is_admin: true,
      },
    },
    {
      path: "/mini/:id",
      name: "Event Dashboard",
      components: {
        default: EventUserList,
        header: DefaultHeader,
      },
      meta: {
        requiresAuth: true,
        cashUserAllowed: true,
      },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard, header: DashboardHeader },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar, header: CalendarHeader },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts, header: DefaultHeader },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets, header: DefaultHeader },
      },
    ],
  },
  {
    path: "/account/activation/:email/:code",
    name: "activation",
    component: Activation,
  },
  { path: "*", component: NotFound },
];

export default routes;
