export default {
  computed: {
    user: function() {
      return this.$store.state.user;
    },
    isOrgAdmin() {
      return false; //this.user.type == 'org-administrator' || this.user.is_superuser
    },
    isSuperUSer() {
      return false; //this.user.is_superuser
    }
  },  
};
