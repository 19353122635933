<template>
  <div id="financials">
    <div
      class="event-main no-tab-content-inside"
      :style="{
        marginRight: $store.state.user.type === 'cash' ? 0 : undefined,
      }"
    >
      <h3 class="title-page d-flex align-items-center">
        <div class="d-flex align-items-center">
          <div class="me-2">Reload</div>
        </div>
        <div class="d-flex">
          <n-button type="primary azul" @click.native="isShowReloadForm = true"
            >Reload Tokens</n-button
          >
          <n-button
            @click.native="isShowTransferToken = true"
            type="primary azul"
            >Transfer Tokens</n-button
          >
        </div>
        <div
          class="wrap-datepicker"
          style="top: 6px !important; margin-left: auto; display: flex"
          v-if="rawDateRange"
        >
          <DatePickerDropdown
            :rawDateRange="rawDateRange"
            ref="datepicker"
            @onChangeDateType="onChangeDateType"
            @onChangeDate="onChangeDate"
          />

          <n-button
            style="
              margin: 0px 0px 0px 20px;
              padding: 10px;
              font-size: 10px;
              background-color: #396ad5;
              font-weight: 600;
              color: white;
              white-space: nowrap;
            "
            slot="title"
            @click.native="onReset"
          >
            Reset To Default
          </n-button>
          <!-- <el-date-picker :editable="false" 
            v-model="rangeTime"
            type="daterange"
            prefix-icon="el-icon-arrow-down"
            :align="'right'"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :format="'MMM dd'"
            :clearable="false"
            @change="loadData({})"
          >
          </el-date-picker> -->
        </div>
      </h3>

      <div class="row mt-3 mb-3" v-if="$store.state.user.type !== 'cash'">
        <div class="col-12 col-md-12">
          <div class="card card-stats card-raised bg-transparent shadow-none">
            <div class="card-body pl-0 pr-0">
              <div class="row">
                <div class="col-xs-12 col-xl-4 mb-3">
                  <animated-card
                    label="Reloaded by Me"
                    :value="data.my_reloads.sum"
                    image="assets/imgs/token-yellow.svg"
                  />
                </div>
                <!-- <div class="col-md-3">

                  <animated-card label="# My Reloads" :value="data.my_reloads.count" image="assets/imgs/token-yellow.svg" />
                </div> -->
                <div class="col-xs-12 col-xl-4 mb-3">
                  <animated-card
                    label="Total Reloaded"
                    :value="
                      loading && data.totals
                        ? 0
                        : currentPriceType === 'token'
                        ? data.totals.total
                        : data.totals.total * currentEvent.token_value
                    "
                    :isCurrent="currentPriceType !== 'token'"
                    image="assets/imgs/token.svg"
                  />
                </div>
                <div class="col-xs-12 col-xl-4 mb-3">
                  <animated-card
                    label="Total Transferred"
                    :isCurrent="currentPriceType !== 'token'"
                    :value="
                      loading && data.totals
                        ? 0
                        : currentPriceType === 'token'
                        ? data.totals.count
                        : data.totals.count * currentEvent.token_value
                    "
                    image="assets/imgs/transfer.svg"
                  />
                </div>
                <!-- <div class="col-xs-12 col-xl-4 mb-3">
                  <animated-card
                    label="Avg. Cash Reload"
                    :value="
                      loading
                        ? 0
                        : (data.totals.total / data.totals.count)
                    "
                    image="assets/imgs/token.svg"
                    
                  />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas id="chart" style="width: 0; height: 0"></canvas>
      <div class="row mt-3 mb-3" v-if="$store.state.user.type !== 'cash'">
        <div class="col-12">
          <h3 class="title-page medium-size">Token Reloads</h3>
          <div class="panel-header panel-header-lg">
            <LineChartGenerator
              v-if="chartData"
              :data="chartData"
              :options="options"
              :height="600"
              :width="'100%'"
            />
            <div
              v-if="!chartData"
              style="height: 300px"
              class="d-flex justify-content-center align-items-center"
            >
              <Spinner />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <tabs type="primary" v-on:change="onTabChange" v-model="tab">
            <tab-pane label="By Date"> </tab-pane>
            <tab-pane label="By Account"> </tab-pane>
          </tabs>

          <div class="tab-content-outside">
            <div class="search-table">
              <el-input
                placeholder="Search by Account"
                v-model="filters[0].value"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>

            <data-tables-server
              v-show="!loading && tabData"
              class="event-table"
              :data="tabData"
              :filters="filters"
              :total="tabData.length"
              @query-change="loadData"
              :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
            >
              <el-table-column
                :prop="'from_user_id'"
                :label="'Reload User ID'"
                :minWidth="150"
              >
              </el-table-column>

              <el-table-column
                :prop="'from_user__email'"
                :label="'Reload User Email'"
                :minWidth="150"
              >
                <template slot-scope="scope">
                  <a
                    href="javascript:void(0)"
                    @click="handleViewUserFromGrid(scope.row)"
                    >{{ scope.row.from_user__email }}</a
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-for="title in titles"
                :prop="title.prop"
                :label="title.label"
                :key="title.prop"
                :formatter="title.formatter"
                :minWidth="title.width"
              >
              </el-table-column>
              <!-- <el-table-column :label="''">
                <template slot-scope="scope">
                  <div :class="'action-cl'" disable-transitions>
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <img
                          class="dropdown-dot"
                          src="assets/imgs/dot-dropdown.svg"
                          alt=""
                        />
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          command="a"
                          @click.native="handleView(scope.row)"
                          ><i class="el-icon-view"></i> View</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column> -->
            </data-tables-server>

            <spinner v-if="loading" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="event-calendar !hidden md:!block"
      v-if="$store.state.user.type !== 'cash'"
    >
      <div class="wrap-right-sec">
        <h3 class="title-page mt-5">Reload History</h3>
        <div class="search-table border-t-b">
          <el-input
            v-model="reloadHistorySearch"
            placeholder="Search by User, Account, Date or Transaction ID"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div
          class="wrap-list-reload"
          style="max-height: calc(100vh - 180px); overflow: auto"
        >
          <spinner v-if="loadingHistory" />

          <template v-if="reloadHistory">
            <div
              class="reload-item"
              v-for="(item, key) in reloadHistory"
              :key="key"
            >
              <h5>
                <a
                  v-if="item.to_user"
                  href="javascript:void(0)"
                  @click="handleViewUser(item.to_user)"
                  >{{
                    `${item.to_user?.first_name} ${item.to_user?.last_name}`
                  }}</a
                >
                <span v-else>N/A</span>
              </h5>
              <p>
                {{ item.amount }} token(s) added by
                {{
                  item.from_user
                    ? `${item.from_user?.first_name} ${item.from_user?.last_name}`
                    : "N/A"
                }}
                in exchange for
                {{ item.event.currency }}
                {{ item.amount * item.event.token_value }} cash on
                {{ moment(item.timestamp).format("MMM DD, YYYY") }} at
                {{ moment(item.timestamp).format("hh:mm A") }}
              </p>
              <div class="wrap-tag">
                <div
                  :class="`tag ${
                    item.action === 'spendCredit'
                      ? 'azul'
                      : item.action === 'addCredit'
                      ? 'green'
                      : item.action === 'refund'
                      ? 'purple'
                      : 'squash'
                  }`"
                >
                  {{ mappingStatus(item.action) }}
                </div>
                <div class="timg-ago">
                  {{ moment(item.timestamp).fromNow(true) }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <card>
      <spinner v-if="!event || !data" />
      <el-dialog title="User" :visible.sync="currentRow" v-if="currentRow">
        <transactions
          :event="currentEvent"
          :from-user="currentRow.from_user_id"
          :with-user-col="true"
        />
      </el-dialog>
    </card>

    <el-dialog
      :visible.sync="isShowReloadForm"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <reload-form :onCloseReload="onCloseReloadForm"></reload-form>
    </el-dialog>

    <el-dialog
      :visible.sync="isShowTransferToken"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <transfer-token
        @onClose="onCloseTransferForm"
        :user="$store.state.user"
        :event="event"
      ></transfer-token>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import EventMixin from "src/mixins/Event";
import Spinner from "src/components/Spinner";
import Transactions from "src/components/Transactions";
import ReloadForm from "./ReloadComp/ReloadForm";
import TransferToken from "src/components/TransferToken.vue";
import { mappingTrasactionStatus } from "src/util/string.js";
import {
  TabPane,
  Tabs,
  AnimatedNumber,
  DatePickerDropdown,
} from "src/components";
import AnimatedCard from "src/components/Cards/AnimatedCard";
import _ from "lodash";
import moment from "moment";
import { Line as LineChartGenerator } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import "chartjs-adapter-moment";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

ChartJS.defaults.font.weight = 600;

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "8px";
    tooltipEl.style.color = "#021B43";
    tooltipEl.style.padding = "15px";
    tooltipEl.style.boxShadow = "0 0 12px 2px rgba(231,234,243,0.30)";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.minWidth = "180px";
    tooltipEl.classList.add("arrow-panel");

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tooltip._tooltipItems.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;
      tr.style.borderWidth = 0;
      tr.style.fontFamily = '"Inter", sans-serif';
      tr.style.fontSize = "12px";
      tr.style.marginTop = "8px";

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(
        body.formattedValue + " Tokens via " + body.dataset.label
      );

      // td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left =
    tooltip.x <= 100
      ? 100 + "px"
      : positionX +
        (tooltip.x + tooltip.width + 30 < chart.canvas.offsetWidth ? 0 : -90) +
        tooltip.caretX +
        "px";
  tooltipEl.style.top = -positionY + tooltip.y - 60 + "px";
  //  -
  // (tooltip.y + tooltip.height + 120 < chart.canvas.offsetHeight ? 0 : -90) +
  // tooltip.caretY +

  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";

  tooltipEl.classList.remove("right");
  if (tooltip.x + tooltip.width + 100 >= chart.canvas.offsetWidth) {
    tooltipEl.classList.add("right");
  }
  tooltipEl.classList.remove("left");
  if (tooltip.x <= 100) {
    tooltipEl.classList.add("left");
  }

  if (tooltip.y + tooltip.height + 30 >= chart.canvas.offsetHeight) {
    tooltipEl.classList.add("bottom");
  }
};

const debounced = _.debounce((vm, url, params) => {
  vm.loading = true;
  axios.get(url, { params }).then((response) => {
    vm.total = response.data.reloads.length;
    vm.data = response.data;
    vm.totals = response.data.totals;
    vm.chart_data = response.data.time_data;
    let chartColor = "#582EC8"; //"rgba(45, 97, 211, 0.25)";
    if (!document.getElementById("chart")) {
      return;
    }

    const ctx = document.getElementById("chart").getContext("2d");
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#582EC8");
    gradientStroke.addColorStop(1, chartColor);

    const gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    const whitefill = ctx.createLinearGradient(0, 200, 0, 50);
    whitefill.addColorStop(0, "rgba(255, 255, 255, 0.5)");
    gradientFill.addColorStop(1, "rgba(88, 46, 200, 0.8)");
    gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");

    let responseChart = response.data.time_data;
  
    if (vm.groupType === "date") {
      responseChart = [
        ...responseChart
          .reduce((map, item) => {
            const { date, count, amount } = item;
            let key = moment(date).format("DD MMM YYYY");
            const prev = map.get(key);

            if (prev) {
              prev.count += count;
              prev.amount += amount;
              prev.date = key;
            } else {
              map.set(
                key,
                Object.assign(
                  {},
                  {
                    count,
                    amount,
                    date: key,
                  }
                )
              );
            }

            return map;
          }, new Map())
          .values(),
      ];
    } else {
      responseChart = [...responseChart.map((e) => {
        e.date = moment(e.date).format('YYYY-MM-DD HH:mm')
        return e
      })];
    }
    console.log(responseChart);
    vm.chartData = {
      labels: responseChart.map((item) => item.date),
      datasets: [
        {
          type: "line",
          label: "Cash",
          backgroundColor: gradientFill,
          pointBorderColor: chartColor,
          pointBackgroundColor: "#582EC8",
          pointHoverBackgroundColor: "#582EC8",
          borderColor: "#582EC8",
          pointBorderWidth: 2,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 3,
          pointRadius: 4,
          lineTension: 0,
          // fill: true,
          borderWidth: 2,
          // yAxisID: "y1",
          data: responseChart.map((item) => item.amount),
        },
      ],
    };

    vm.options = {
      interaction: {
        intersect: false,
        mode: "index",
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 40,
          bottom: 0,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: undefined,
          gridLines: {
            zeroLineColor: "transparent",
            display: false,
            color: "#fff",
          },
          grid: {
            display: false,
          },
          distribution: "series",
          ticks: {
            padding: 10,
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: 10,
            color: "#6B7280",
            font: {
              weight: 400,
              size: 14,
              family: '"Inter", sans-serif;',
            },
            callback: function (label, index, labels) {
              const lb = this.getLabelForValue(label);
              if (vm.groupType === "hour") {
                return lb;
              }
              return moment(lb).format("ddd DD");
            },
          },
        },
        y: {
          ticks: {
            fontColor: "#8e8e93",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 10,
            color: "#6B7280",
            font: {
              weight: 400,
              size: 14,
              family: '"Inter", sans-serif;',
            },
          },
          gridLines: {
            drawTicks: true,
            drawBorder: false,
            display: true,
            borderDash: [4, 2],
            color: "#bdbdbd",
            zeroLineColor: "transparent",
          },
        },
      },
      plugins: {
        decimation: {
          algorithm: "lttb",
          enabled: true,
          samples: 10,
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: "nearest",
          external: (event) => externalTooltipHandler(event, this),
        },
      },
    };
    vm.loading = false;
  });
}, 500);

const debouncedHistory = _.debounce((vm, url, params) => {
  vm.loadingHistory = true;
  axios.get(url, { params }).then((response) => {
    vm.reloadHistory = response.data.results;
    vm.loadingHistory = false;
  });
}, 500);

export default {
  components: {
    Spinner,
    Transactions,
    TabPane,
    Tabs,
    AnimatedNumber,
    AnimatedCard,
    ReloadForm,
    LineChartGenerator,
    TransferToken,
    DatePickerDropdown,
  },
  computed: {
    event() {
      console.log(this.$store.state.allEvents);
      return this.$store.getters.allEvents
        ? this.$store.getters.allEvents.find(
            (item) => item.id == parseInt(this.$route.params.id)
          )
        : null;
    },
    fees() {
      console.log(this.data.total_card_amount, this.event.fee);
      return (this.data.total_card_amount * this.event.fee).toFixed(2);
    },
    tax() {
      return (this.data.total_card_amount * this.event.tax).toFixed(2);
    },
    tabData() {
      return this.data[this.tabs[this.tab]];
    },
  },
  data() {
    const self = this;
    return {
      rawDateRange: null,
      rangeTime: null,
      loadingHistory: false,
      reloadHistorySearch: "",
      groupType: "hour",
      chart_data: null,
      options: null,
      chartData: null,
      isShowReloadForm: false,
      data: {
        reloads: [],
        time_data: [],
        totals: {},
        my_reloads: {},
      },
      date: {},
      tabs: {
        "By Account": "reloads",
        "By Date": "time_data",
      },
      tab: "By Account",
      vendorEvent: "",
      vendorEvents: [],
      aggregationBy: "day",
      currentRow: false,
      loading: true,
      total: 0,
      reloadHistory: null,
      titles: [
        {
          prop: "total",
          label: "Total Reload",
          width: 200,
          formatter: (item) => {
            if (this.currentPriceType === "token") {
              return item.total;
            }
            return "$" + item.total * self.currentEvent.token_value;
          },
        },

        {
          prop: "count",
          label: "# Reloads",
          width: 200,
          formatter: (item) => {
            if (this.currentPriceType === "token") {
              return item.count;
            }
            return "$" + item.count * self.currentEvent.token_value;
          },
        },
        // {
        //   prop: "status",
        //   label: "Revenue",
        //   formatter: (item) => {
        //     return "$" + item.total * self.currentEvent.token_value;
        //   },
        // },
      ],
      filters: [
        {
          prop: "from_user__email",
          value: "",
        },
      ],
      queryInfo: {},
      timer: null,
      currentPriceType: "token",
      isShowTransferToken: false,
    };
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  async mounted() {
    this.loading = true;
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }

    this.getHistory();
    this.fetchVendorEvents();
    // this.filterData();
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.fetchVendorEvents();
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.fetchVendorEvents();
      }, 10000);
    } else {
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
  },

  watch: {
    reloadHistorySearch(newvl, oldvl) {
      this.reloadHistory = [];
      this.getHistory();
    },
  },
  methods: {
    moment,
    handleViewUserFromGrid(row) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/users/?userId=${row.from_user_id}`,
      });
    },
    handleViewUser(row) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/users/?userId=${row.id}`,
      });
    },
    onReset() {
      this.$refs.datepicker.onReset();
      this.rangeTime = [];
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    mappingStatus(st) {
      return mappingTrasactionStatus(st);
    },
    onChangeDateType(type) {
      this.groupType = type;
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    onChangeDate(daterange) {
      this.rangeTime = daterange;
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    onCloseTransferForm() {
      this.isShowTransferToken = false;
    },
    onCloseReloadForm() {
      this.isShowReloadForm = false;
    },
    onTabChange(tab) {
      console.log("setting", tab);
      this.tab = tab;
    },
    fetchVendorEvents() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?event=${this.$route.params.id}&expand=vendor`;

      if (this.$store.state.user.type == "vendor") {
        url += `&vendor=${this.$store.state.user.user_type.vendor.id}`;
      }
      axios.get(url).then((response) => {
        this.vendorEvents = response.data.results;
        this.loading = false;
      });
    },

    async loadData(queryInfo) {
      console.log(queryInfo);
      this.chartData = null;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/reloads`;
      const params = {
        vendor_event__event: this.$route.params.id,
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        expand: "vendor_event,vendor_event.vendor,user",
        search: this.filters[0].value,
        status__in: this.status,
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          queryInfo.sort.order == "descending" ? "-" : "" + queryInfo.sort.prop;
      }

      if (this.rangeTime && this.rangeTime[0]) {
        params["start"] = moment(this.rangeTime[0]).startOf("day").format();
      }
      if (this.rangeTime && this.rangeTime[1]) {
        params["end"] = moment(this.rangeTime[1]).endOf("day").format();
      }

      if (this.aggregationBy) {
        params["aggregation_by"] = this.aggregationBy;
      }

      this.queryInfo = queryInfo;
      debounced(this, url, params);
    },

    async getHistory() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}&expand=user,event,from_user,order.vendor_event.vendor,to_user&action=addCredit`;
      const params = {
        search: this.reloadHistorySearch,
        limit: 10,
        ordering: "-timestamp",
      };
      if (this.rangeTime && this.rangeTime.length > 0) {
        params["timestamp__gte"] = moment(this.rangeTime[0])
          .startOf("day")
          .format();
        params["timestamp__lte"] = moment(this.rangeTime[1])
          .endOf("day")
          .format();
      } else {
        delete params["timestamp__gte"];
        delete params["timestamp__lte"];
      }

      debouncedHistory(this, url, params);
    },

    async filterData() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/reloads`;
      const params = { vendor_event_id: this.vendorEvent };

      if (this.rangeTime.length > 0) {
        params["start"] = moment(this.rangeTime[0]).startOf("day").format();
        params["end"] = moment(this.rangeTime[1]).endOf("day").format();
      }

      if (this.aggregationBy) {
        params["aggregation_by"] = this.aggregationBy;
      }

      const response = await axios.get(url, { params });
      let counter = 0;
      Object.keys(response.data.top_date).forEach((item) => {
        response.data.top_date[item].show = counter++ == 0;
      });
      this.data = response.data;

      this.loading = false;
      window.eventBus.$emit("receivedCounts", response);
    },
  },
  mixins: [EventMixin],
};
</script>
<style lang="scss">
tr {
  &.date-header {
    background-color: #cdcdcd;
    cursor: pointer;
  }
}
#financials {
  .el-input {
    input {
      margin: 0;
      font-size: 12px;
    }
  }
}

.wrap-datepicker {
  // position: absolute;
  // right: 0;
  // top: 10px;
  .el-date-editor {
    background: #ffffff;
    border: 1px solid rgba(238, 238, 239, 0.7);
    box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    color: #021b43;
    font-size: 12px;
    font-weight: 500;
    input {
      font-family: "Inter", sans-serif;
      color: #021b43;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .el-icon-arrow-down {
    position: absolute;
    right: 5px;
    color: #021b43;
    font-weight: bold;
  }
}
</style>
