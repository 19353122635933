var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{staticClass:"card-chart event-card",attrs:{"no-footer-line":""},on:{"click":function($event){return _vm.$emit('clicked', _vm.event)}}},[_c('div',{on:{"click":function($event){return _vm.$emit('clicked', _vm.event)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"img-sec"},[(_vm.showLinks)?_c('router-link',{attrs:{"to":{
            name: _vm.$store.state.user.type === 'cash' ? 'Users' : 'Dashboard',
            params: { id: _vm.event.id },
          }}},[_c('img',{attrs:{"src":_vm.event.image ? _vm.event.image : 'img/placeholder.jpg'}})]):_c('img',{attrs:{"src":_vm.event.image ? _vm.event.image : 'img/placeholder.jpg'}})],1),_c('div',{staticClass:"des-sec"},[_c('p',{staticClass:"card-category"},[(_vm.showLinks)?_c('router-link',{attrs:{"to":{
              name: _vm.$store.state.user.type === 'cash' ? 'Users' : 'Dashboard',
              params: { id: _vm.event.id },
            }}},[_vm._v(_vm._s(_vm.event.name)+" ")]):_c('span',[_vm._v(_vm._s(_vm.event.name))])],1),(_vm.showLinks && _vm.$store.state.user.type !== 'cash')?_c('drop-down',{attrs:{"hide-arrow":true,"position":"right"}},[_c('n-button',{staticClass:"dropdown-toggle no-caret",attrs:{"slot":"title","round":"","simple":"","icon":""},slot:"title"},[_c('img',{staticClass:"btn-arrow-right m-auto",attrs:{"src":'img/icons/arrow-right.svg'}})]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ path: `/pages/events/${_vm.event.id}/dashboard` }}},[_vm._v("Event Dashboard")]),(_vm.$store.state.user.type === 'org-administrator')?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ path: `/pages/events/${_vm.event.id}/vendors` }}},[_vm._v("Vendors")]):_vm._e(),(_vm.$store.state.user.type !== 'cash')?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ path: `/pages/events/${_vm.event.id}/orders` }}},[_vm._v("Orders")]):_vm._e(),(_vm.$store.state.user.type === 'org-administrator')?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ path: `/pages/events/${_vm.event.id}/users` }}},[_vm._v("Users")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"stats small-stats pb-2",attrs:{"slot":"footer"},slot:"footer"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.event.start_date_time,"dddd, MMMM Do YYYY"))+" - "+_vm._s(_vm._f("moment")(_vm.event.end_date_time,"dddd, MMMM Do YYYY"))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }