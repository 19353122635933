<template>
  <div>
    <!-- <div
      class="wrap-switcher-currency"
      style="position: absolute; right: 70px; top: 20px"
    >
      <div class="switches-container">
        <input
          type="radio"
          id="switchTokenMD"
          name="switchPlanMD"
          value="token"
          v-model="currentPriceType"
        />
        <input
          type="radio"
          id="switchDollarMD"
          name="switchPlanMD"
          value="dollar"
          v-model="currentPriceType"
        />
        <label for="switchTokenMD"><PoundIcon /></label>
        <label for="switchDollarMD"><DollarIcon /></label>
        <div class="switch-wrapper">
          <div class="switch">
            <div><PoundIcon /></div>
            <div><DollarIcon /></div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="wrap-content-md">
      <div class="infor-modal">
        <img src="assets/imgs/orders-icon-navy.svg" alt="" />
        <span> Orders</span>
      </div>
      <div class="row mt-2 mb-2">
        <div class="col-4">
          <animated-card
            class="card-user-stats"
            label="Orders"
            :value="numOrders"
          />
        </div>
        <div class="col-4">
          <animated-card
            class="card-user-stats"
            label="Total Spend"
            :value="spendCredit"
            :value2="spendCredit * event.token_value"
          />
        </div>
        <div class="col-4">
          <animated-card
            class="card-user-stats"
            label="Avg. Order Value"
            :value="spendCredit / numOrders"
            :value2="(spendCredit / numOrders) * event.token_value"
          />
        </div>
      </div>

      <div class="wrap-shadow-box">
        <div class="search-table">
          <el-input
            placeholder="Search by Order, Vendor, Customer or Date"
            v-model="filters[0].value"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <!-- :action-col="withOutActions == true ? {} : actionCol" -->
        <data-tables
          v-if="orders && orders.orders"
          class="event-table"
          :data="orders.orders"
          :filters="filters"
          :total="orders.orders.length"
          :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            sortable="custom"
            :formatter="title.formatter"
            v-if="title.prop === 'order_display_id'"
          >
            <template slot-scope="scope">
              <div>
                <router-link
                  target="_blank"
                  :to="{
                    name: 'Orders',
                    params: { id: event.id, orderId: scope.row.id },
                  }"
                  v-if="scope.row"
                >
                  {{ scope.row.order_display_id }}
                </router-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            sortable="custom"
            :formatter="title.formatter"
            v-if="title.prop !== 'status' && title.prop !== 'order_display_id'"
          >
          </el-table-column>
          <el-table-column
            :cell-style="rowStyle"
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            sortable="custom"
            :formatter="title.formatter"
            v-if="title.prop === 'status'"
          >
            <template slot-scope="scope">
              <div
                :class="
                  'inline-flex items-center capitalize gap-x-1 whitespace-nowrap rounded px-1.5 py-0.5 text-xs font-semibold ring-1 bg-gray-50 text-gray-700 ring-gray-600/30 ' +
                  (scope.row.status === 'refunded'
                    ? 'bg-blue-50 text-blue-700 ring-blue-600/30'
                    : scope.row.status === 'accepted'
                    ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/30'
                    : scope.row.status === 'delivered'
                    ? 'bg-green-50 text-green-800 ring-green-600/30'
                    : scope.row.status === 'completed'
                    ? 'bg-red-50 text-red-800 ring-red-600/30'
                    : '')
                "
                disable-transitions
              >
                {{ scope.row.status }}
              </div>
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import AddToken from "src/components/AddToken.vue";
import TransferToken from "src/components/TransferToken.vue";
import Transactions from "src/components/Transactions.vue";
import Orders from "src/components/Orders.vue";
import Reloads from "src/components/Reloads.vue";
import GetBalance from "src/mixins/GetBalance.js";
import { formatRevenueToken, formatRevenueValue } from "@/util/string.js";
import AnimatedCard from "../Cards/AnimatedCard.vue";
import { Row } from "element-ui";
import EventMixin from "@/mixins/Event.js";

import DollarIcon from "src/components/Icons/Dollar";
import PoundIcon from "src/components/Icons/Pound";
export default {
  props: ["currentUser", "event", "orders"],
  components: { AnimatedCard, DollarIcon, PoundIcon },
  computed: {
    numOrders() {
      if (this.orders && this.orders.orders) {
        return this.orders.orders.length;
      }
      return 0;
    },
    spendCredit() {
      if (this.orders && this.orders.orders) {
        return this.orders.orders.reduce((a, b) => a + b.total, 0);
      }
      return 0;
    },
  },
  data() {
    return {
      currentPriceType: "token",
      selectedTabModal: "detail",
      eventBalance: 0,
      component: false,
      modalVisible: true,
      localEvent: "",
      filters: [
        {
          prop: "order_display_id",
          value: "",
        },
        {
          prop: "vendor_event.vendor.name",
          value: "",
        },
        {
          prop: "total",
          value: "",
        },
        {
          prop: "status",
          value: "",
        },
      ],
      titleCols: [
        {
          prop: "order_display_id",
          label: "ID",
        },
        {
          prop: "vendor_event.vendor.name",
          label: "Name",
        },
        {
          prop: "total",
          label: "Tokens",
          formatter: (row, col, cell, index) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(cell, this.currentEvent, 3);
            }
            return formatRevenueValue(cell, this.currentEvent, 3);
          },

          // formatter: (row, col, cell, index) => {
          //   return formatRevenue(cell, this.event);
          // },
        },
        {
          prop: "status",
          label: "Status",
        },
        {
          prop: "created_at",
          label: "Date & Time",
          formatter: (row, col, cell, index) => moment(cell).format("llll"),
        },
      ],
    };
  },
  methods: {
    qrClicked() {
      this.$emit("qrClicked", this.currentUser);
    },
    rowStyle(row) {
      console.log(row);
    },
  },
  mounted() {
    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
    if (this.eventId) {
      this.getBalance(this.currentUser.id, this.eventId);
    }
  },
  watch: {
    currentUser(newVal, oldVal) {
      console.log(oldVal, newVal);
      this.eventBalance = 0;
      const eventId = this.eventId;
      if (eventId) {
        this.getBalance(newVal.id, eventId);
      }
    },
  },
  mixins: [GetBalance, EventMixin],
};
</script>
