/**
 * Simple throttle function that executes a passed function only once in the specified timeout
 * @param handlerFunc
 * @param [timeout] the throttle interval
 */
import _ from "lodash";
import axios from "axios";
const throttle = (handlerFunc, timeout = 66) => {
  let resizeTimeout;
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      handlerFunc();
      // The actualResizeHandler will execute at a rate of 15fps
    }, timeout);
  }
};
const debounced = _.debounce((vm, url, params, isRefresh = false) => {
  if (!isRefresh) {
    vm.loading = true;
    vm.count = 0;
    vm.items = [];
  }
  axios.get(url, { params }).then((response) => {
    vm.count = response.data.count;
    vm.items = response.data.results;
    vm.loading = false;
    vm.isInit = false;
  });
}, 500);

export default { throttle, debounced };
