<template>
  <div>
    <h1 class="title-page d-flex">
      {{ $route.name }}
      <div
        class="wrap-datepicker"
        style="width: 300px; display: flex; margin-left: auto"
        v-if="rawDateRange"
      >
        <DatePickerDropdown
          :rawDateRange="rawDateRange"
          v-if="rawDateRange"
          ref="datepicker"
          @onChangeDateType="onChangeDateType"
          @onChangeDate="onChangeDate"
        />
        <!-- <el-date-picker :editable="false" 
          v-model="rangeTime"
          type="daterange"
          :format="'MMM dd'"
          :clearable="false"
        >
        </el-date-picker> -->

        <n-button
          style="margin: 0px 0px 0px 20px;padding: 10px;font-size: 10px;background-color: #396ad5;font-weight: 600;color: white;white-space: nowrap;"
          slot="title"
          @click.native="onReset"
        >
          Reset To Default
        </n-button>
      </div>
    </h1>

    <!-- <div class="wrap-page-refresh-control">
      <div class="refresh-label">Auto Refresh</div>
      <el-switch
        v-model="isTurnOnPageRefresh"
        :active-text="isTurnOnPageRefresh ? 'On' : 'Off'"
      >
      </el-switch>
    </div> -->

    <order-summary
      :summary="summary"
      :hideTokens="user.type == 'vendor'"
      :currentPriceType="currentPriceType"
    />

    <div class="row">
      <div class="col-12" style="min-height: 422px">
        <h3 class="title-page medium-size">Revenue</h3>
        <DashboardHeader :groupType="groupType" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <top-5-items
          :items="top5Items"
          :event="event"
          :currentPriceType="currentPriceType"
        />
      </div>
      <div class="col-12 col-lg-6">
        <top-5-vendors
          :items="top5Vendors"
          :currentPriceType="currentPriceType"
        />
      </div>
    </div>

    <!-- <div>
    <order-summary :summary="summary" />
    <div class='row'>
      <div class="col-md-6">
        <info :summary="summary" :event="event"></info>
        <vendor></vendor>
      </div>
      <div class="col-md-6">

        <detail :edit-link="true"></detail>
        <top-5-vendors :items="top5Vendors" />
      </div>
    </div>
    <top-5-items :items="top5Items" />
 </div> -->
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Detail from "./EventDetail/Detail.vue";
import Vendor from "./EventDetail/Vendor.vue";
import Info from "./EventDetail/Info.vue";
import HeaderChart from "./Dashboard/HeaderChart";
import Top5Items from "./EventDetail/Top5Items.vue";
import Top5Vendors from "./EventDetail/Top5Vendors.vue";
import OrderSummary from "./EventDetail/Summary.vue";
import GetSummaryData from "@/mixins/GetSummaryData.js";
import DashboardHeader from "../Dashboard/Dashboard/DashboardHeader";
import { Card, AnimatedNumber, DatePickerDropdown } from "src/components";
import EventMixin from "@/mixins/Event.js";

export default {
  components: {
    Detail,
    Vendor,
    Info,
    HeaderChart,
    Card,
    AnimatedNumber,
    Top5Items,
    Top5Vendors,
    OrderSummary,
    DashboardHeader,
    DatePickerDropdown,
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    canSeeUsers() {
      return this.user.user_type == "org-administrator";
    },
    countUrl() {
      let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__event=${this.$route.params.id}`;

      if ("vendor" in this.user.user_type) {
        countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__vendor=${this.user.user_type.vendor.id}&vendor_event__event=${this.$route.params.id}`;
      }

      if (this.rangeTime && this.rangeTime.length > 0) {
        countUrl += `&created_at__gte=${moment(this.rangeTime[0])
          .startOf("day")
          .format()}${
          this.rangeTime.length > 1
            ? "&created_at__lte=" +
              moment(this.rangeTime[1]).endOf("day").format()
            : ""
        }`;
      }
      return countUrl;
    },
  },
  data() {
    return {
      orders: 0,
      revenue: 0,
      customers: 0,
      avgOrderRevenue: 0,
      userOrders: [],
      summary: false,
      rawResponse: null,
      isShowChart: true,
      chartData: [],
      chartLabels: [],
      event: null,
      top5Items: null,
      top5Vendors: null,
      currentPriceType: "token",
      isTurnOnPageRefresh: false,
      timer: null,
      rawDateRange: null,
      rangeTime: null,
      groupType: "hour",
    };
  },
  methods: {
    onChangeDateType(type) {
      this.groupType = type;
    },
    onChangeDate(daterange) {
      this.rangeTime = daterange;
    },
    onReset() {
      this.$refs.datepicker.onReset();
      this.isShowChart = false;
      this.rangeTime = this.rawDateRange;
      setTimeout(() => {
        this.isShowChart = true;
      }, 100);
    },
    handleSelectionChange(val) {
      this.selectedRow = val;
    },
    init() {
      const user = this.$store.state.user;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}`;
      axios.get(url).then((response) => {
        this.event = response.data;
        // if (!this.rangeTime) {
        //   this.rangeTime = [
        //     moment(this.event.start_date_time).subtract(30, "days"),
        //     moment(this.event.end_date_time).add(2, "days"),
        //   ];
        // }
        // if (!this.rawDateRange) {
        //   this.rawDateRange = [
        //     moment(this.event.start_date_time).subtract(30, "days"),
        //     moment(this.event.end_date_time).add(2, "days"),
        //   ];
        // }
      });
      //this.getSummaryData(this.countUrl);
    },
  },
  async mounted() {
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.init();
        }, 5000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.init();
      }, 5000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }

    this.init();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  watch: {
    groupType() {
      setTimeout(() => {
        window.eventBus.$emit("receivedCounts", this.rawResponse);
      }, 100);
    },
    "$route.params.id"(newVal, oldVal) {
      this.init();
    },
  },
  mixins: [GetSummaryData, EventMixin],
};
</script>
<style lang="scss"></style>
