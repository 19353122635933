<template>
  <Transactions action='addCredit' :user="user" :event="event" :withOutActions="true" :titleCols="titleCols" />
</template>
<script>
import Transactions from './Transactions.vue'
export default {
  props: ['user', 'event'],
  components: {
    Transactions
  },
  data() {
    return {
      titleCols: [{
        prop: "timestamp",
        label: "Time",
        }, {
          prop: "event.name",
          label: "Event",
        }, {
          prop: "amount",
          label: "Tokens",
        }, {
          prop: "stripe_charge_id",
          label: "Conf. #",
        }, {
          prop: "from_user",
          label: "From",
        }
      ]
    }
  }
}
</script>
