<template>
  <card>
    <h3>Items</h3>
    <div class="alert alert-success" v-if="successMsg">{{ successMsg }}</div>
    <modal :show.sync="modal" headerclasses="justify-content-center">
      <h4 slot="header" class="title title-up">Add Menu</h4>
      <div class="alert alert-warning" v-if="serverErrors.length > 0">
        <ul>
          <li v-bind:key="error" v-for="error in serverErrors">{{ error }}</li>
        </ul>
      </div>
      <div>
        <label>Name</label>
        <fg-input v-model="rawItem.name"></fg-input>
      </div>
      <div>
        <label>Description</label>
        <fg-input v-model="rawItem.description"></fg-input>
      </div>
      <div>
        <label>Price</label>
        <fg-input v-model="rawItem.price"></fg-input>
      </div>

      <div class="picture">
        <label>Image</label>
        <preview-upload v-model="rawItem.picture"></preview-upload>
      </div>
      <template slot="footer">
        <n-button type="primary" @click.native="addItem">Add</n-button>
        <n-button type="danger" @click.native="modal = false">Close</n-button>
      </template>
    </modal>
    <spinner v-if="loading"/>
    <div class="row" v-else>
      <div class="col-md-12">
        <card>
          <div>
            <label>Name</label>
            <fg-input v-model="menu.name" disabled></fg-input>
          </div>
          <div>
            <label>Description</label>
            <fg-input v-model="menu.description" disabled></fg-input>
          </div>
          <div>
            <label>Image</label>
            <div class="menu-picture">
              <img :src="menu.picture">
            </div>
          </div>
          <div>
            <div class="frm-add-item-to-menu">
              <div>
                <el-autocomplete
                  class="inline-input"
                  v-model="rawItem.name"
                  :fetch-suggestions="autocompleteItems"
                  placeholder="Please Input"
                  @select="handleSelect"
                  :trigger-on-focus="false"
                ></el-autocomplete>
                <div class="checkboxes">
                  <span>
                    <n-checkbox v-model="rawItemMenu.isAvailable">Available</n-checkbox>
                  </span>
                  <span>
                    <n-checkbox v-model="rawItemMenu.isFeatured">Featured</n-checkbox>
                  </span>
                </div>
              </div>
              <n-button type="primary" @click.native="addItemToMenu">Add item</n-button>
            </div>
            <table class="table table-hover table-border">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Available</th>
                  <th>Discount</th>
                  <th>Picture</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in menu.items" :key="item.id">
                  <td v-if="item.content_object">{{ item.content_object.name }}</td>
                  <td v-if="item.content_object">{{ item.content_object.description }}</td>
                  <td>{{ item.price }}</td>
                  <td>
                    <div class="check-mark">
                      <i v-if="!!item.is_available" class="el-icon-success"/>
                      <i v-else class="el-icon-error"/>
                    </div>
                  </td>
                  <td>
                    <div class="check-mark">
                      <i v-if="!!item.is_featured" class="el-icon-success"/>
                      <i v-else class="el-icon-error"/>
                    </div>
                  </td>

                  <td>
                    <img v-if="item.content_object" :src="item.content_object.picture">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </card>
</template>
<script>
import axios from "axios";
import { Autocomplete } from "element-ui";
import { Checkbox, Modal } from "src/components";
import PreviewUpload from "./Components/PreviewUpload";
import Spinner from "src/components/Spinner";
import User from "src/mixins/User";
import { humanize } from "src/util/string";

export default {
  components: {
    [Autocomplete.name]: Autocomplete,
    [Checkbox.name]: Checkbox,
    Modal,
    PreviewUpload,
    Spinner
  },
  computed: {
    item: function() {
      const e = {
        ...this.rawItem
      };

      const form = new FormData();

      Object.keys(e).map(k => {
        if (!!e[k]) {
          form.append(k, e[k]);
        }
      });

      return form;
    }
  },
  methods: {
    itemMenu: function() {
      return {
        menu: this.$route.params.id,
        content_type: 28, // item
        is_featured: this.rawItemMenu.isFeatured,
        is_available: this.rawItemMenu.isAvailable,
        object_id: this.rawItemMenu.id,
        price: this.rawItemMenu.price
      };
    },
    addItem: function() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/item/`;
      this.successMsg = "";
      this.serverErrors = [];

      axios
        .post(url, this.item)
        .then(response => {
          this.successMsg = "New item is added";
          this.rawItem = {};
          this.modal = false;
          this.fetchMenu();
        })
        .catch(e => {
          if (e.response.status !== 403) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    },
    addItemToMenu: function() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/menu-item/`;
      this.successMsg = "";
      this.serverErrors = [];
      console.log(this.rawItemMenu, this.itemMenu());
      if (!this.rawItemMenu.id) {
        this.modal = true;
      } else {
        axios
          .post(url, { ...this.itemMenu() })
          .then(response => {
            this.successMsg = "Item is added to the menu";
            this.fetchMenu();
          })
          .catch(e => {
            if (e.response.status !== 403) {
              this.serverErrors = humanize(e.response.data);
            } else {
              this.serverErrors = [e.response.data.detail];
            }
          });
      }
    },
    fetchMenu: async function() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/menu/${
        this.$route.params.id
      }?expand=items`;
      this.loading = true;
      try {
        const response = await axios.get(url);

        this.loading = false;
        console.log(response.data);

        this.menu = response.data;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    handleSelect(itemMenu) {
      this.rawItemMenu.id = itemMenu.id;
      this.rawItemMenu.price = itemMenu.price;
    },
    autocompleteItems(queryString, cb) {
      if (queryString) {
        const url = `${
          process.env.VUE_APP_BASE_HOST
        }/en/api/item/?name__icontains=${queryString}`;

        axios.get(url).then(response => {
          cb(
            response.data["results"].map(x => ({
              value: x.name,
              id: x.id,
              price: x.price
            }))
          );
        });
      }
    }
  },
  data: () => ({
    serverErrors: [],
    successMsg: "",
    rawItem: { name: null },
    menu: {
      name: null,
      description: null,
      picture: null
    },
    rawItemMenu: {
      name: ""
    },
    modal: false,
    loading: false
  }),
  mounted() {
    this.fetchMenu();
  },
  mixins: [User]
};
</script>
<style lang="scss">
.menu-picture {
  height: 300px;
  width: 300px;
  border: solid 1px #ccc;
}
.frm-add-item-to-menu {
  display: flex;
  justify-content: flex-end;
}
.checkboxes {
  display: flex;
}
.check-mark {
  text-align: center;
}
</style>
