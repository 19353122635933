<template>
  <div class="row">
    <div class="col-lg-4" v-for="e in events" :key="e.id">
      <event :event="e" @clicked="setCurrentEvent(e)" />
    </div>

  </div>
</template>
<script>
import axios from "axios";
import Event from 'src/components/Event.vue';

export default {
  components: {
    Event,
  },
  data() {
    return {
      
      events: [],
      
    };
  },
  methods: {
    fetchEvents() {
      const url = `${
        process.env.VUE_APP_BASE_HOST
      }/en/api/event/`;
      axios.get(url).then(response => {
        this.loading = false;
        this.events = response.data.results
      });
    },
    setCurrentEvent (event) {
      this.$router.push({name: 'Users', params: {id: event.id}});
    },
  },
  mounted() {
    this.fetchEvents();
  }
};
</script>
<style lang="scss">
.event-select {
  margin-left: 15px;
}
.user-search {
  margin-top: 15px;
}
</style>
