<template>
  <div class="statistics">
    <div class="info">
      <div v-if="image" class="icon icon-primary" :class="background">
        <img :src="image" alt="" />
      </div>
      <div class="statistics-infor">
        <h3 class="info-title" :style="styleCard">
          <small>
            {{ isCurrent ? "$" : ""
            }}<animated-number :value="value"></animated-number
          ></small>
        </h3>
        <h6 class="stats-title">{{ label }}</h6>
        <small v-if="value2" :style="styleCard"
          >$<animated-number :value="value2"></animated-number
        ></small>
      </div>
    </div>
  </div>
</template>
<script>
import { AnimatedNumber } from "src/components";

export default {
  components: {
    AnimatedNumber,
  },
  props: [
    "image",
    "value",
    "value2",
    "label",
    "background",
    "isCurrent",
    "styleCard",
  ],
};
</script>
