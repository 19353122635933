import axios from 'axios';

export default {
  methods: {
    getBalance(userId, eventId) {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${userId}/event/${eventId}/get-balance/`;
      axios.get(url).then(response => {
        this.eventBalance = response.data.balance
      })
    },
  }
}