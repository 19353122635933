<template>
  <div id="financials" v-if="currentEvent">
    <div class="event-main no-tab-content-inside">
      <h3 class="title-page d-flex align-items-center">
        <div class="d-flex align-items-center">
          <div class="me-2">Locations</div>
        </div>
        <div class="d-flex">
          <n-button
            type="primary azul"
            @click.native="isShowAddNewLocation = true"
            >New Location</n-button
          >
        </div>
      </h3>

      <div class="row mt-3 mb-3">
        <div class="col-12 col-md-12">
          <div class="card card-stats card-raised bg-transparent shadow-none">
            <div class="card-body pl-0 pr-0">
              <div class="row">
                <div class="col-md-4">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-primary purple-bg filter-gray">
                        <img src="assets/imgs/vendors-icon-purple.svg" alt="" />
                      </div>
                      <div class="statistics-infor">
                        <h3 class="info-title">
                          <animated-number :value="20"></animated-number>
                        </h3>
                        <h6 class="stats-title">Vendors</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-success blue-bg filter-gray">
                        <img src="assets/imgs/booth-icon-blue.svg" alt="" />
                      </div>
                      <div class="statistics-infor">
                        <h3 class="info-title">
                          <small>#</small>
                          <animated-number :value="24"></animated-number>
                        </h3>
                        <h6 class="stats-title">Locations</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-info red-bg filter-gray">
                        <img src="assets/imgs/booth-icon-red.svg" alt="" />
                      </div>
                      <div class="statistics-infor">
                        <h3 class="info-title">
                          <animated-number :value="1"></animated-number>
                        </h3>
                        <h6 class="stats-title">Inactive Locations</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="textbox-coming-soon">
            <span class="text-coming-soon">Coming Soon</span>
          </div>
          <div id="card-event" class="card-event">
            <h3 class="title-page medium-size d-flex">
              Venue Locations Map

              <!-- <button class="more-button ml-auto" type="button">
                <img
                  class="dropdown-dot"
                  src="assets/imgs/dot-dropdown.svg"
                  alt=""
                />
              </button> -->
            </h3>

            <div class="map-content">
              <!-- <div class="empty-sec" v-if="!currentEvent.map">
                Please upload your event map
                <div>
                  <input
                    class="input-file__input hidden"
                    ref="file2"
                    type="file"
                    v-on:change="onUploadMap"
                  />
                  <span
                    v-if="!isUpdatingEvent"
                    class="text-coming-soon"
                    @click="$refs.file2.click()"
                    >Upload</span
                  >

                  <spinner v-if="isUpdatingEvent" />
                </div>
              </div>
              <v-stage
                :config="configKonva"
                v-if="configKonva && currentEvent.map"
                ref="stage"
                @mouseDown="handleMouseDown"
              >
                <v-layer ref="layer">
                  <v-circle
                    v-for="(l, index) in circles"
                    :key="index"
                    :config="l"
                    @mouseOver="handleMouseEnter"
                    @mouseOut="handleMouseLeave"
                  ></v-circle>
                  <v-image
                    ref="image"
                    :config="{
                      image: image,
                      width: configKonva.width,
                      height: configKonva.height,
                      opacity: 0.5,
                      listening: false,
                      filters: filters,
                      name: 'bg',
                    }"
                  />
                </v-layer>
              </v-stage> -->

              <!-- <div id="zoom-button" class="btn-group-vertical">
                <n-button type="primary default m-0" disabled
                  ><i class="el-icon-plus"></i
                ></n-button>
                <n-button type="primary default m-0" disabled
                  ><i class="el-icon-minus"></i
                ></n-button>
              </div> -->

              <!-- <div id="edit-button">
                <n-button type="primary default m-0"
                  ><i class="el-icon-edit-outline"></i>
                  Edit Mode
                </n-button>
              </div> -->

              <!-- <img src="assets/Desert-Trip-Map-1.png" alt="" /> -->
              <!-- <img
                style="filter: grayscale(100%)"
                src="src/assets/Desert-Trip-Map-1.png"
                alt=""
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event-calendar !hidden md:!block">
      <div class="wrap-right-sec">
        <h3 class="title-page mt-5">Vendor Locations</h3>
        <div class="search-table border-t-b">
          <el-input placeholder="Search by Location, Vendor or Status">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class="wrap-list-event-location">
          <div class="event-loction-item">
            <img src="assets/food.jpg" alt="" />
            <div class="location-detail">
              <p class="location-type">Location</p>
              <p class="mb">Grand Electric Tacos</p>
              <p class="location-type">Vendor</p>
              <p>Grand Electric Tacos</p>
            </div>

            <div class="wrap-tag">
              <div class="tag green">Online</div>
            </div>
          </div>

          <div class="event-loction-item">
            <img src="assets/food.jpg" alt="" />
            <div class="location-detail">
              <p class="location-type">Location</p>
              <p class="mb">Grand Electric Tacos</p>
              <p class="location-type">Vendor</p>
              <p>Grand Electric Tacos</p>
            </div>

            <div class="wrap-tag">
              <div class="tag dark-peach">Offline</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="isShowAddNewLocation"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <create-location
        v-if="isShowAddNewLocation"
        @onClose="onCloseCreateLocation"
        :currentEvent="currentEvent"
        :rawLocationEdit="currentLocation"
        @onComplete="onCompleteAdd"
      ></create-location>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import EventMixin from "src/mixins/Event";
import Spinner from "src/components/Spinner";
import { TabPane, Tabs, AnimatedNumber } from "src/components";
import Background from "src/assets/Desert-Trip-Map-1.png";
import _ from "lodash";
import Konva from "konva";
import CreateLocation from "src/pages/Dashboard/Location/CreateLocationMap.vue";

export default {
  components: {
    Spinner,
    TabPane,
    Tabs,
    AnimatedNumber,
    CreateLocation,
  },
  computed: {},
  data() {
    const self = this;
    return {
      data: {},
      loading: false,
      configKonva: null,
      image: null,
      filters: [Konva.Filters.Grayscale],
      circles: [],
      isDragging: false,
      isOnCircle: false,
      isShowAddNewLocation: false,
      currentLocation: null,
      isUpdatingEvent: false,
    };
  },
  created() {},
  watch: {
    currentEvent(newValue, oldValue) {
      this.loadMap();
    },
  },
  async mounted() {
    this.loadMap();
  },
  methods: {
    async imageUrlToBase64(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
      });
    },
    async loadMap() {
      if (!this.currentEvent) {
        return;
      }

      if (!document.getElementById("card-event")) {
        return;
      }
      const self = this;

      this.configKonva = {
        width: document.getElementById("card-event").clientWidth - 40,
        height: document.getElementById("card-event").clientHeight - 40 - 20.5,
      };
      const base64 = await this.imageUrlToBase64(this.currentEvent.map);
      const image = new Image();
      var rand = "?" + Math.random();
      image.src = base64 + rand;
      image.crossOrigin = "anonymous";
      image.onload = function () {
        var with_cors = new Image();
        with_cors.crossOrigin = "anonymous";
        with_cors.src = image.src;
        with_cors.onload = function () {
          console.log("loaded");
          self.image = image;

          self.$nextTick(() => {
            if (self.$refs.image) {
              const node = self.$refs.image.getNode();
              node.cache();
              node.getLayer().batchDraw();
            }
          });
        };
        with_cors.onerror = function () {
          console.error("failed");
        };
      };
    },
    onUploadMap(uploadEvent) {
      const paths = uploadEvent.target.files;

      if (paths && paths[0]) {
        const path = paths[0];
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/`;
        const form = new FormData();
        form.append("map", path);
        this.isUpdatingEvent = true;
        axios
          .patch(url, form)
          .then((response) => {
            this.$message("Successfully update the event map");
            this.$store.dispatch("getEvents");

            setTimeout(() => {
              this.loadMap();
              this.isUpdatingEvent = false;
            }, 1000);
          })
          .catch((e) => {
            this.$message(humanize(e.response.data).join(" "));
            this.isUpdatingEvent = false;
          });
      }
    },
    onCompleteAdd() {},
    onCloseCreateLocation() {
      this.isShowAddNewLocation = false;
    },
    handleDragStart() {
      this.isDragging = true;
    },
    handleDragEnd() {
      this.isDragging = false;
    },
    handleMouseLeave(event) {
      setTimeout(() => {
        this.isOnCircle = false;
      }, 300);
    },
    handleMouseEnter(event) {
      this.isOnCircle = true;
    },
    handleMouseDown(event) {
      if (this.isOnCircle) {
        return;
      }
      const pos = this.$refs.stage.getNode().getPointerPosition();
      this.circles.push({
        x: pos.x,
        y: pos.y,
        radius: 8,
        fill: "#2D61D3BF",
        stroke: "#2D61D3",
        strokeWidth: 2,
        draggable: true,
        name: "circle",
        listening: true,
      });
    },
  },
  mixins: [EventMixin],
};
</script>
<style lang="scss">
#card-event {
  height: calc(100vh - 80px);
  min-height: 887px;
  padding: 20px !important;
}
.map-content {
  position: relative;
  padding: 5px;
  #zoom-button {
    position: absolute;
    left: 0;
    z-index: 9;
  }

  #edit-button {
    position: absolute;
    right: 0;
    z-index: 9;
    button {
      color: #2d61d3;
      border-radius: 8px;
      box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
      border: solid 1px rgba(238, 238, 239, 0.7);
      background: #fff;
    }
  }
}

.empty-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  flex-direction: column;
  gap: 30px;
  background: #f5f5f5;
  border-radius: 6px;
}
</style>
