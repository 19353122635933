var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrap-content-md"},[_vm._m(0),_c('div',{staticClass:"row mt-2 mb-2"},[_c('div',{staticClass:"col-4"},[_c('animated-card',{staticClass:"card-user-stats",attrs:{"label":"Orders","value":_vm.numOrders}})],1),_c('div',{staticClass:"col-4"},[_c('animated-card',{staticClass:"card-user-stats",attrs:{"label":"Total Spend","value":_vm.spendCredit,"value2":_vm.spendCredit * _vm.event.token_value}})],1),_c('div',{staticClass:"col-4"},[_c('animated-card',{staticClass:"card-user-stats",attrs:{"label":"Avg. Order Value","value":_vm.spendCredit / _vm.numOrders,"value2":(_vm.spendCredit / _vm.numOrders) * _vm.event.token_value}})],1)]),_c('div',{staticClass:"wrap-shadow-box"},[_c('div',{staticClass:"search-table"},[_c('el-input',{attrs:{"placeholder":"Search by Order, Vendor, Customer or Date"},model:{value:(_vm.filters[0].value),callback:function ($$v) {_vm.$set(_vm.filters[0], "value", $$v)},expression:"filters[0].value"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1),(_vm.orders && _vm.orders.orders)?_c('data-tables',{staticClass:"event-table",attrs:{"data":_vm.orders.orders,"filters":_vm.filters,"total":_vm.orders.orders.length,"pagination-props":{ pageSizes: [50, 100, 500, 1000] }}},[_vm._l((_vm.titleCols),function(title){return (title.prop === 'order_display_id')?_c('el-table-column',{key:title.prop,attrs:{"prop":title.prop,"label":title.label,"sortable":"custom","formatter":title.formatter},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[(scope.row)?_c('router-link',{attrs:{"target":"_blank","to":{
                  name: 'Orders',
                  params: { id: _vm.event.id, orderId: scope.row.id },
                }}},[_vm._v(" "+_vm._s(scope.row.order_display_id)+" ")]):_vm._e()],1)]}}],null,true)}):_vm._e()}),_vm._l((_vm.titleCols),function(title){return (title.prop !== 'status' && title.prop !== 'order_display_id')?_c('el-table-column',{key:title.prop,attrs:{"prop":title.prop,"label":title.label,"sortable":"custom","formatter":title.formatter}}):_vm._e()}),_vm._l((_vm.titleCols),function(title){return (title.prop === 'status')?_c('el-table-column',{key:title.prop,attrs:{"cell-style":_vm.rowStyle,"prop":title.prop,"label":title.label,"sortable":"custom","formatter":title.formatter},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:'inline-flex items-center capitalize gap-x-1 whitespace-nowrap rounded px-1.5 py-0.5 text-xs font-semibold ring-1 bg-gray-50 text-gray-700 ring-gray-600/30 ' +
                (scope.row.status === 'refunded'
                  ? 'bg-blue-50 text-blue-700 ring-blue-600/30'
                  : scope.row.status === 'accepted'
                  ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/30'
                  : scope.row.status === 'delivered'
                  ? 'bg-green-50 text-green-800 ring-green-600/30'
                  : scope.row.status === 'completed'
                  ? 'bg-red-50 text-red-800 ring-red-600/30'
                  : ''),attrs:{"disable-transitions":""}},[_vm._v(" "+_vm._s(scope.row.status)+" ")])]}}],null,true)}):_vm._e()})],2):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infor-modal"},[_c('img',{attrs:{"src":"assets/imgs/orders-icon-navy.svg","alt":""}}),_c('span',[_vm._v(" Orders")])])
}]

export { render, staticRenderFns }