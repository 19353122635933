var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentEvent)?_c('div',[_c('div',{staticClass:"title-links mb-1 text-xs font-medium leading-6 text-gray-500"},[_vm._v(" Event Navigation ")]),_c('div',{attrs:{"link":{
      name: 'Event',
      icon: 'now-ui-icons design_image',
    }}},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'assets/imgs/dashboard-icon.svg',
        iconActive: 'assets/imgs/dashboard-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/dashboard`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Reload',
        icon: 'assets/imgs/reload-icon.svg',
        iconActive: 'assets/imgs/reload-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/reloads`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Event Info',
        icon: 'assets/imgs/events-icon.svg',
        iconActive: 'assets/imgs/events-icon-active.svg',
        path: `/pages/events/${_vm.currentEvent ? _vm.currentEvent.id : 'id'}/edit`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Orders',
        icon: 'assets/imgs/orders-icon.svg',
        iconActive: 'assets/imgs/orders-icon-active.svg',
        path: `/pages/events/${_vm.currentEvent ? _vm.currentEvent.id : 'id'}/orders`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Transactions',
        icon: 'assets/imgs/transactions.svg',
        iconActive: 'assets/imgs/transactions-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/transactions`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Users',
        icon: 'assets/imgs/users-icon.svg',
        iconActive: 'assets/imgs/users-icon-active.svg',
        path: `/pages/events/${_vm.currentEvent ? _vm.currentEvent.id : 'id'}/users`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Vendors',
        icon: 'assets/imgs/vendors-icon.svg',
        iconActive: 'assets/imgs/vendors-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/vendors`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Locations',
        icon: 'assets/imgs/booth-icon.svg',
        iconActive: 'assets/imgs/booth-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/locations`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Menu',
        icon: 'assets/imgs/menu-icon.svg',
        iconActive: 'assets/imgs/menu-icon-active.svg',
        path: `/pages/events/${_vm.currentEvent ? _vm.currentEvent.id : 'id'}/menu`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Reporting',
        icon: 'assets/imgs/reporting-icon.svg',
        iconActive: 'assets/imgs/reporting-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/reporting`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Financials',
        icon: 'assets/imgs/sales-icon.svg',
        iconActive: 'assets/imgs/sales-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/financials`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Demographics',
        icon: 'assets/imgs/demographics-icon.svg',
        iconActive: 'assets/imgs/demographics-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/demographics`,
      }}})],1),(_vm.user.is_superuser)?_c('sidebar-item',{attrs:{"link":{
      name: 'Superuser Menu',
      icon: 'assets/imgs/users-card.svg',
      iconActive: 'assets/imgs/users-card.svg',
    }}},[_c('div',{staticClass:"flex flex-column"},[_c('sidebar-item',{attrs:{"link":{
          name: 'All Vendors',
          // icon: 'el-icon-coffee',
          path: `/pages/events/${
            _vm.currentEvent ? _vm.currentEvent.id : 'id'
          }/all-vendors`,
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'All Users',
          //icon: 'now-ui-icons users_single-02',
          path: `/pages/events/${
            _vm.currentEvent ? _vm.currentEvent.id : 'id'
          }/all-users`,
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'All Organizations',
          //icon: 'now-ui-icons design_image',
          path: `/pages/events/${
            _vm.currentEvent ? _vm.currentEvent.id : 'id'
          }/organizations`,
        }}})],1)]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }