<template>
  <!-- <div>
          <div class="row col-md-12 mb-4">
            <div class="col-6 user-card">
              <div class="row">
                <div class="col-7">
                  <p class="user-card-text user-card-name">
                    {{ currentUser.first_name }}
                    {{ currentUser.last_name }} (Event {{ event.id }}}
                  </p>
                  <p class="user-card-text">{{ currentUser.email }}</p>
                  <p class="user-card-text">{{ currentUser.phone_number }}</p>
                </div>
                <div class="col-5">
                  <button
                    class="el-button el-button--primary"
                    @click="component = 'transactions'"
                  >
                    Transactions
                  </button>
                  <button
                    class="el-button el-button--primary"
                    @click="component = 'reloads'"
                  >
                    Reloads
                  </button>
                  <button
                    class="el-button el-button--primary"
                    @click="qrClicked"
                  >
                    Show Wallet QR
                  </button>
                </div>
              </div>
            </div>

            <div class="col-6 user-card">
              <div class="mt-2 row" v-if="eventId">
                <div class="col-7 text-center">
                  <h5 class="user-card-text user-card-name">Balance</h5>
                  <h3>{{ eventBalance }}</h3>
                </div>
                <div class="col-5 text-center">
                  <button
                    class="el-button el-button--primary"
                    @click="component = 'transferToken'"
                  >
                    Transfer
                  </button>
                  <button
                    class="el-button el-button--primary"
                    @click="component = 'Orders'"
                  >
                    Orders
                  </button>
                  <button
                    class="el-button el-button--primary"
                    @click="component = 'addToken'"
                  >
                    Add Balance
                  </button>
                </div>
              </div>
              <div v-else>Select an event below to see Balance options</div>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-md-12">
              <el-select
                v-if="!event"
                v-model="localEvent"
                placeholder="Select Event to view Wallet"
              >
                <el-option
                  v-for="item in events"
                  :key="item.value"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-md-12 text-center pt-1">
              <component
                @complete="component = ''"
                @reload="getBalance(currentUser.id, event.id)"
                :is="component"
                :event="eventObj"
                :user="currentUser"
                :max="eventBalance"
                :withOutActions="true"
                :titleCols="titleCols"
              />
            </div>
          </div>
        </div> -->
  <div class="wrap-content-md">
    <div class="infor-modal">
      <i class="now-ui-icons travel_info"></i>
      <span> User Details</span>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-6">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">First Name</label>
          <input
            type="text"
            class="event-form"
            v-model="currentUser.first_name"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Last Name</label>
          <input
            type="text"
            class="event-form"
            v-model="currentUser.last_name"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-3">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Email</label>
          <input
            type="text"
            class="event-form"
            v-model="currentUser.email"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-3">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Phone</label>
          <input
            type="text"
            class="event-form"
            v-model="currentUser.phone_number"
          />
        </div>
        <!-- <div class="form-group form-event-wrap">
          <label for="" class="event-label">User Type</label>
          <select disabled class="event-form">
            <option value="1">Attendee</option>
          </select>
        </div> -->
      </div>
      <div class="col-12 col-lg-6 mt-3">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">DOB</label>
          <el-date-picker
            :editable="false"
            type="date"
            v-model="currentUser.dob"
          />
        </div>
      </div>

      <div class="col-12 col-lg-6 mt-3">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">User Status</label>
          <el-select v-model="currentUser.status">
            <el-option :label="'Approved'" :value="'approved'"> </el-option>
            <el-option :label="'Restrict Add Credit'" :value="'hold-1'">
            </el-option>
            <el-option :label="'Restrict Spend Credit'" :value="'hold-2'">
            </el-option>
            <el-option :label="'Suspend Account'" :value="'restricted'">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <!-- <div class="col-12 col-lg-6 ">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Gender</label>
          <el-select v-model="currentUser.status">
            <el-option :label="'Male'" :value="'female'"> </el-option>
            <el-option :label="'Female'" :value="'hold-1'"> </el-option>
            <el-option :label="'Other'" :value="'other'"> </el-option>
          </el-select>
        </div>
      </div> -->
    </div>

    <!-- <div class="row mt-3">
      
    </div> -->
    <div class="row mt-3">
      <div class="col-lg-12">
        <button type="button" class="btn btn-primary m-0" @click="save">
          Save
        </button>
      </div>
    </div>

    <!-- <div class="infor-modal mt-3">
      <i class="el-icon-lock"></i>
      <span> User Password </span>
    </div> -->

    <!-- <div class="row mt-2">
 <div class="col-12 col-md-6">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Password</label>
          <input
            type="text"
            class="event-form"
            v-model="passwords.password1"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Confirm</label>
          <input
            type="text"
            class="event-form"
            v-model="passwords.password2"
          />
        </div>
      </div>
      <button
        type="button"
        :disabled="passwords.password1 != passwords.password2 || passwords.password1.length < 8"
        class="btn btn-warning grey"
        @click="resetPassword">Reset Password</button>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import AddToken from "src/components/AddToken.vue";
import TransferToken from "src/components/TransferToken.vue";
import Transactions from "src/components/Transactions.vue";
import Orders from "src/components/Orders.vue";
import Reloads from "src/components/Reloads.vue";
import GetBalance from "src/mixins/GetBalance.js";
import { humanize } from "src/util/string";

export default {
  props: ["currentUser", "event"],
  components: {
    AddToken,
    Transactions,
    Orders,
    Reloads,
    TransferToken,
  },
  computed: {
    events() {
      return this.$store.getters.allEvents;
    },
    eventId() {
      return this.eventObj ? this.eventObj.id : false;
    },
    eventObj() {
      if (this.localEvent) {
        return this.localEvent;
      }

      if (typeof this.event == "object") {
        return this.event;
      }
      if (this.events && this.events.length) {
        return this.events.find((item) => item.id == this.event);
      }
    },
  },
  data() {
    return {
      eventBalance: 0,
      component: false,
      modalVisible: true,
      localEvent: "",
      passwords: {
        password1: "",
        password2: "",
      },
      titleCols: [
        {
          prop: "timestamp",
          label: "Time",
        },
        {
          prop: "event.name",
          label: "Event",
        },
        {
          prop: "amount",
          label: "Amount",
        },
        {
          prop: "stripe_charge_id",
          label: "Conf. #",
        },
        {
          prop: "from_user.phone_number",
          label: "From",
        },
      ],
    };
  },
  methods: {
    qrClicked() {
      this.$emit("qrClicked", this.currentUser);
    },
    async save() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/`;
      try {
        const response = await axios.patch(url, {
          first_name: this.currentUser.first_name,
          last_name: this.currentUser.last_name,
          phone_number: this.currentUser.phone_number,
          email: this.currentUser.email,
        });
        if (response.status == 200) {
          this.$message({
            message: "Successfully saved user",
          });
        }
      } catch (e) {
        this.$message(humanize(e.response.data).join(" "));
      }
    },
    async resetPassword() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/set-password/`;
      const response = await axios.post(url, this.passwords);
      if (response.status == 202) {
        this.$message({
          message: "Successfully saved password",
        });
      }
    },
  },
  mounted() {
    if (this.eventId) {
      this.getBalance(this.currentUser.id, this.eventId);
    }
  },
  watch: {
    currentUser(newVal, oldVal) {
      console.log(oldVal, newVal);
      this.eventBalance = 0;
      const eventId = this.eventId;
      if (eventId) {
        this.getBalance(newVal.id, eventId);
      }
    },
  },
  mixins: [GetBalance],
};
</script>
<style scoped lang="scss">
.el-button {
  width: 100%;
  display: block;
  margin-left: 0;
  margin-top: 5px;
}
</style>
