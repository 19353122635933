<template>
  <div>
    <div
      class="wrap-switcher-currency"
      style="position: absolute; right: 70px; top: 20px"
    >
      <!-- <div class="switches-container">
        <input
          type="radio"
          id="switchTokenMD"
          name="switchPlanMD"
          value="token"
          v-model="currentPriceType"
        />
        <input
          type="radio"
          id="switchDollarMD"
          name="switchPlanMD"
          value="dollar"
          v-model="currentPriceType"
        />
        <label for="switchTokenMD"><PoundIcon /></label>
        <label for="switchDollarMD"><DollarIcon /></label>
        <div class="switch-wrapper">
          <div class="switch">
            <div><PoundIcon /></div>
            <div><DollarIcon /></div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="wrap-content-md" v-if="event">
      <div class="infor-modal">
        <img src="assets/imgs/transactions-navy.svg" alt="" />
        <span> Transactions</span>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-12 col-md-2 mb-2">
          <animated-card
            class="card-user-stats"
            label="Purchased"
            :value="addCredit"
            :value2="addCredit * event.token_value"
          />
        </div>
        <div class="col-12 col-md-2 mb-2">
          <animated-card
            class="card-user-stats"
            label="Spent"
            :value="Math.abs(spendCredit)"
            :value2="Math.abs(spendCredit) * event.token_value"
            :style-card="
              spendCredit < 0
                ? {
                    color: '#f27474',
                  }
                : {
                    color: '#2d61d3',
                  }
            "
          />
        </div>
        <div class="col-12 col-md-2 mb-2">
          <animated-card
            class="card-user-stats"
            label="Received"
            :value="received"
            :value2="received * event.token_value"
          />
        </div>
        <div class="col-12 col-md-2 mb-2">
          <animated-card
            class="card-user-stats"
            label="Sent"
            :value="Math.abs(sent)"
            :value2="Math.abs(sent) * event.token_value"
            :style-card="
              sent < 0
                ? {
                    color: '#f27474',
                  }
                : {
                    color: '#2d61d3',
                  }
            "
          />
        </div>
        <div class="col-12 col-md-2 mb-2">
          <animated-card
            class="card-user-stats"
            label="Refunded"
            :value="refunded"
            :value2="refunded * event.token_value"
          />
        </div>
        <div class="col-12 col-md-2 mb-2">
          <animated-card
            class="card-user-stats"
            label="Balance"
            :value="eventBalance"
            :value2="eventBalance * event.token_value"
          />
        </div>
      </div>

      <div class="wrap-shadow-box">
        <div class="search-table">
          <el-input
            placeholder="Search by Transaction, User, Vendor, Type or Date"
            v-model="filters[0].value"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <!-- :action-col="withOutActions == true ? {} : actionCol" -->
        <data-tables-server
          class="event-table"
          :data="items"
          :filters="filters"
          :total="items.length"
          :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            sortable
            :formatter="title.formatter"
            v-if="title.prop === 'id'"
          >
            <template slot-scope="scope">
              <div>
                <router-link
                  target="_blank"
                  :to="{
                    name: 'Orders',
                    params: { id: event.id, orderId: scope.row.order.id },
                  }"
                  v-if="scope.row.order"
                >
                  {{ scope.row.order.order_display_id }}
                </router-link>
                <template v-else>
                  <span v-if="currentUser.type !== 'cash'">{{
                    scope.row.id
                  }}</span>
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'Transactions',
                      params: { id: event.id, transactionId: scope.row.id },
                    }"
                    v-else
                  >
                    {{ scope.row.id }}</router-link
                  >
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            sortable
            :formatter="title.formatter"
            v-if="title.prop === 'to_from'"
          >
            <template slot-scope="scope">
              <div>
                <a
                  href="javascript:void(0)"
                  @click="onClickUser(scope.row.targtUser)"
                >
                  {{
                    scope.row.to_from && scope.row.to_from !== ""
                      ? scope?.row?.to_from
                      : "N/A"
                  }}
                </a>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            sortable
            :key="title.prop"
            :formatter="title.formatter"
            v-if="
              title.prop !== 'action' &&
              title.prop !== 'id' &&
              title.prop !== 'to_from' &&
              title.prop !== 'timestamp'
            "
          >
          </el-table-column>
          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            sortable
            :key="title.prop"
            :formatter="title.formatter"
            v-if="title.prop === 'timestamp'"
          >
          </el-table-column>
          <el-table-column
            v-for="title in titleCols"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            sortable
            :formatter="title.formatter"
            v-if="title.prop === 'action' && title.prop !== 'id'"
          >
            <template slot-scope="scope">
              <div
                :class="
                  'inline-flex items-center capitalize gap-x-1 whitespace-nowrap rounded px-1.5 py-0.5 text-xs font-semibold ring-1 bg-gray-50 text-gray-700 ring-gray-600/30 ' +
                  (scope.row.action === 'addCredit'
                    ? 'bg-blue-50 text-blue-700 ring-blue-600/30'
                    : scope.row.action === 'refundCredit'
                    ? 'bg-blue-50 text-blue-700 ring-blue-600/30'
                    : scope.row.action === 'spendCredit'
                    ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/30'
                    : scope.row.action === 'transfer'
                    ? 'bg-red-50 text-red-800 ring-red-600/30'
                    : 'bg-green-50 text-green-800 ring-green-600/30')
                "
                disable-transitions
              >
                {{ mappingTrasactionStatusFunc(scope.row.action) }}
              </div>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import AddToken from "src/components/AddToken.vue";
import TransferToken from "src/components/TransferToken.vue";
import Transactions from "src/components/Transactions.vue";
import Orders from "src/components/Orders.vue";
import Reloads from "src/components/Reloads.vue";
import GetBalance from "src/mixins/GetBalance.js";
import AnimatedCard from "../Cards/AnimatedCard.vue";
import {
  formatRevenueToken,
  formatRevenue,
  formatRevenueValue,
  mappingTrasactionStatus,
} from "@/util/string.js";
import { Row } from "element-ui";
import DollarIcon from "src/components/Icons/Dollar";
import PoundIcon from "src/components/Icons/Pound";
import EventMixin from "@/mixins/Event.js";

export default {
  props: ["currentUser", "event", "transactions"],
  components: { AnimatedCard, DollarIcon, PoundIcon },
  computed: {
    items() {
      if (!this.transactions.items) {
        return [];
      }
      return this.transactions.items
        .sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
        .map((i) => {
          let to_from = "N/A";
          let targtUser = null;
          if (i.from_user) {
            to_from = `${i.from_user.first_name} ${i.from_user.last_name}`;
            targtUser = i.from_user;
          } else if (i.to_user) {
            to_from = `${i.to_user.first_name} ${i.to_user.last_name}`;
            targtUser = i.to_user;
          }

          const map = {
            addCredit: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            refund: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            partialRefund: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            transfer: to_from.trim(),
            spendCredit: `${
              i.order && i.order.vendor_event
                ? i.order.vendor_event.vendor.name
                : ""
            }`,
          };
          //console.log(i.action, map)
          return {
            ...i,
            targtUser: targtUser,
            to_from: map[i.action],
          };
        });
    },
    addCredit() {
      return this.items
        .filter((i) => i.action == "addCredit")
        .reduce((a, b) => a + b.amount, 0);
    },
    spendCredit() {
      return this.items
        .filter((i) => i.action == "spendCredit")
        .reduce((a, b) => a + b.amount, 0);
    },
    received() {
      return this.items
        .filter((i) => i.action == "transfer" && i.amount > 0)
        .reduce((a, b) => a + b.amount, 0);
    },
    sent() {
      return this.items
        .filter((i) => i.action == "transfer" && i.amount < 0)
        .reduce((a, b) => a + b.amount, 0);
    },
    refunded() {
      return this.items
        .filter((i) => i.action == "refund")
        .reduce((a, b) => a + b.amount, 0);
    },
  },
  data() {
    return {
      currentPriceType: "token",
      selectedTabModal: "detail",
      eventBalance: 0,
      component: false,
      modalVisible: true,
      localEvent: "",
      timer: null,
      eventId: this.$route.params.id,
      filters: [
        {
          prop: "id",
          value: "",
        },
      ],
      titleCols: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "to_from",
          label: "From/To",
        },
        {
          prop: "amount",
          label: "Amount",
          formatter: (row, col, cell, index) => Math.abs(cell),
        },

        {
          prop: "action",
          label: "Action",
        },
        {
          prop: "timestamp",
          label: "Date & Time",
          formatter: (row, col, cell, index) => moment(cell).format("llll"),
        },
      ],
    };
  },
  methods: {
    formatRevenueToken,
    formatRevenueValue,
    mappingTrasactionStatusFunc(type) {
      return mappingTrasactionStatus(type);
    },
    qrClicked() {
      this.$emit("qrClicked", this.currentUser);
    },
    onClickUser(row) {
      this.$emit("onClickUser", row);
    },
  },
  mounted() {
    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
    if (this.eventId && this.currentUser.id) {
      this.getBalance(this.currentUser.id, this.eventId);
    }
    const self = this;

    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          if (self.eventId && self.currentUser.id) {
            this.getBalance(self.currentUser.id, self.eventId);
          }
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        if (self.eventId && self.currentUser.id) {
          this.getBalance(self.currentUser.id, self.eventId);
        }
      }, 10000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  watch: {
    currentUser(newVal, oldVal) {
      console.log(oldVal, newVal);
      this.eventBalance = 0;
      const eventId = this.eventId;
      if (eventId) {
        this.getBalance(newVal.id, eventId);
      }
    },
  },
  mixins: [GetBalance, EventMixin],
};
</script>
