<template>
  <div>
    <div
      v-for="(event, x) in events"
      v-if="event.id == $route.params.id"
      :key="x"
      :link="{
        name: event.name,
        icon: 'now-ui-icons design_image',
      }"
    >
      <sidebar-item
        :link="{
          name: 'Dashboard',
          icon: 'assets/imgs/dashboard-icon.svg',
          iconActive: 'assets/imgs/dashboard-icon-active.svg',
          path: `/pages/events/${event.id}/dashboard`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Sales',
          icon: 'assets/imgs/sales-icon.svg',
          iconActive: 'assets/imgs/sales-icon-active.svg',
          path: `/pages/events/${event.id}/vendor-financials`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Orders',
          icon: 'assets/imgs/orders-icon.svg',
          iconActive: 'assets/imgs/orders-icon-active.svg',
          path: `/pages/events/${event.id}/orders`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Menu',
          icon: 'assets/imgs/menu-icon.svg',
          iconActive: 'assets/imgs/menu-icon-active.svg',
          path: `/pages/events/${currentEvent ? currentEvent.id : 'id'}/menu`,
        }"
      ></sidebar-item>

      <sidebar-item
        :link="{
          name: 'Locations',
          icon: 'assets/imgs/booth-icon.svg',
          iconActive: 'assets/imgs/booth-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/vendors/${user.user_type.vendor.id}`,
        }"
      ></sidebar-item>
    </div>
  </div>
</template>
<script>
import UserMenu from "src/pages/Dashboard/Layout/Extra/UserMenu";
import User from "src/mixins/User";
import Event from "src/mixins/Event";
export default {
  components: {
    UserMenu,
  },
  mixins: [User, Event],
};
</script>
