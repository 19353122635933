var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.events),function(event,x){return (event.id == _vm.$route.params.id)?_c('div',{key:x,attrs:{"link":{
      name: 'Event',
      icon: 'now-ui-icons design_image',
    }}},[_c('sidebar-item',{attrs:{"link":{
        name: 'Users',
        icon: 'assets/imgs/users-icon.svg',
        iconActive: 'assets/imgs/users-icon-active.svg',
        path: `/pages/events/${event.id}/users`,
      }}})],1):_vm._e()}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }