<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card bg-transparent shadow-none card-new">
        <div class="card-header">
          <h4 slot="header" class="card-title">{{ theTitle }}</h4>
        </div>
        <div class="card-body" v-if="event">
          <div v-if="items && items.length === 0">
            <div
              class="w-100 d-flex h-100 align-items-center justify-content-center p-5"
            >
              No Data Available Yet
            </div>
          </div>
          <div class="table-responsive" v-if="items && items.length > 0">
            <n-table class="table-shopping" :data="items">
              <template slot="columns">
                <th>Item</th>
                <th v-if="$store.state.user.type == 'org-administrator'">
                  Vendor
                </th>
                <th class="text-right">Price</th>

                <th class="text-right" style="white-space: nowrap"># Sold</th>
                <th class="text-right">Total</th>
              </template>
              <template slot-scope="{ row }">
                <td class="td-name">
                  {{ row.items__vendor_event_item__name }}
                  <br />
                  <small>{{ row.subTitle }}</small>
                </td>
                <td v-if="$store.state.user.type == 'org-administrator'">
                  <!-- <router-link
                    :to="{
                      path: `/pages/events/${row.vendor_event__event_id}/vendors/${row.vendor_event_id}`,
                    }"
                  > -->
                  <!-- {{ row }} -->
                  {{ row.vendor_event__vendor__name }}
                  <!-- </router-link> -->
                </td>

                <td class="td-number">
                  <span v-if="currentPriceType === 'token'">
                    {{ row.items__vendor_event_item__price }}</span
                  >
                  <span v-if="currentPriceType === 'dollar'"
                    >${{
                      row.items__vendor_event_item__price * event.token_value
                    }}</span
                  >
                </td>

                <td class="td-number">
                  {{ row.count }}
                </td>
                <td class="td-number">
                  <span v-if="currentPriceType === 'token'">
                    {{ row.count * row.items__vendor_event_item__price }}</span
                  >
                  <span v-if="currentPriceType === 'dollar'"
                    >${{
                      row.count *
                      row.items__vendor_event_item__price *
                      event.token_value
                    }}</span
                  >
                </td>
              </template>
              <template slot="summary-row"> </template>
            </n-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Table as NTable } from "src/components";
export default {
  props: ["items", "event", "title", "currentPriceType"],
  components: {
    Card,
    NTable,
  },
  computed: {
    theTitle() {
      return this.title ? this.title : "Top Items";
    },
  },
};
</script>
