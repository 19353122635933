<template>
  <div class="wrap-content-md">
    <div class="infor-modal">
      <i class="el-icon-wallet"></i>
      <span> Payment Types</span>
    </div>
    <div class="wrap-shadow-box p-3 position-relative" v-for="(card, i) in cards" :key="i">
      <div class="row" >
        <div class="col-12 col-md-4 flex-column">
          <span class="title-navy mb-2"> Cardholder Name <span v-if="card.id == currentUser.default_card" class="default-card"></span>
          <span v-if="card.id == currentUser.auto_topup_card" class="default-card top-up-card"></span>
           </span>
          <div><span class="value-navy"> {{card.name}} </span></div>
        </div>
        <div class="col-12 col-md-2 flex-column">
          <span class="title-navy mb-2"> Card Type </span>
           <div><span class="value-navy"> {{card.brand}} </span></div>
        </div>
        <div class="col-12 col-md-2 flex-column">
          <span class="title-navy mb-2"> Expiry Date </span>
           <div><span class="value-navy"> {{card.exp_month}}/{{card.exp_year}} </span></div>
        </div>
        <div class="col-12 col-md-2 flex-column">
          <span class="title-navy mb-2"> Last 4 Digits </span>
          <div> <span class="value-navy"> *{{card.last4}} </span></div>
        </div>

        <div class="col-12 col-md-2 flex-column">
          <el-dropdown>
            <span class="el-dropdown-link">
              <img
                class="dropdown-dot"
                src="assets/imgs/dot-dropdown.svg"
                alt=""
              />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="a"
                @click.native="deleteCard(card)"
                ><i class="el-icon-view"></i> Delete</el-dropdown-item>
              <el-dropdown-item
                command="a"
                @click.native="makeDefault(card)"
                ><i class="el-icon-view"></i> Make Default</el-dropdown-item>
                <el-dropdown-item
                command="a"
                @click.native="makeAutoTopup(card)"
                ><i class="el-icon-view"></i> Make Auto Topup</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 text-center">
        <button type="button" class="btn btn-primary grey" @click="addCard = !addCard">Add Payment Type</button>
      </div>
      <div class="row mt-3" v-if="addCard">

      <div class="col-12 col-md-6">

        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Name</label>
          <input
            type="text"
            class="event-form"
            v-model="newCard.name"
          />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Number</label>
          <input
            type="text"
            class="event-form"
            v-model="newCard.number"
          />
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">CVC</label>
          <input
            type="text"
            class="event-form"
            v-model="newCard.cvc"
          />
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Exp. Month</label>
          <input
            type="text"
            class="event-form"
            v-model="newCard.exp_month"
          />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Exp. Year</label>
          <input
            type="text"
            class="event-form"
            v-model="newCard.exp_year"
          />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group form-event-wrap">
          <label for="" class="event-label">Zip Code</label>
          <input
            type="text"
            class="event-form"
            v-model="newCard.address_zip"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <button type="button" class="btn btn-primary grey" @click="addNewCard">Save</button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AddToken from "src/components/AddToken.vue";
import TransferToken from "src/components/TransferToken.vue";
import Transactions from "src/components/Transactions.vue";
import Orders from "src/components/Orders.vue";
import Reloads from "src/components/Reloads.vue";
import GetBalance from "src/mixins/GetBalance.js";

const newCard = () => {
  return {
    name: '',
    exp_month: '',
    exp_year: '',
    cvc: '',
    address_zip: ''
  }
}

export default {
  props: ["currentUser", "event"],
  components: {},
  computed: {},
  data() {
    return {
      addCard: false,
      newCard: newCard(),
      cards: [],
    };
  },
  methods: {
    async makeDefault(i) {
      const response = await axios.patch(`${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/`, {
        default_card: i.id
      })
      if (response.status == 200) {
        this.$notify('Card is now default')
        this.fetchData()
        this.currentUser.default_card = i.id
      }
    },
    async makeAutoTopup(i) {
      const response = await axios.patch(`${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/`, {
        auto_topup_card: i.id
      })
      if (response.status == 200) {
        this.$notify('Card is now used for auto topup')
        this.fetchData()
        this.currentUser.auto_topup_card = i.id
      }
    },
    async deleteCard(i) {
      if(confirm("Are you sure you want to do this?")) {
        const response = await axios.delete(`${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/stripe-cards/${i.id}/`)
        if (response.status === 204) {
          this.$notify('Card deleted')
          this.fetchData()
        } else {
          this.$notify('error')
        }
      }
    },
    async addNewCard() {
      const response = await axios.post(`${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/stripe-cards/create/`, this.newCard)
      if (response.status == 201) {
        this.newCard = newCard()
        this.fetchData()
        this.$notify('Card added')
        this.addCard = false
      } else {
        this.$notify('error')
      }
    },
    async fetchData() {
      this.cards = []
      const response = await axios.get(`${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.currentUser.id}/stripe-cards/`)
      this.cards = response.data.data
    },
  },
  async mounted() {
    this.fetchData()
  },
  watch: {
    currentUser(newVal, oldVal) {
      if(newVal.id !== oldVal.id) {
        this.fetchData()
      }
    },
  },
  mixins: [GetBalance],
};
</script>

<style lang="scss" scoped>
.more-button{
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
