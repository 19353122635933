import fgInput from './components/Inputs/formGroupInput.vue';
import DropDown from './components/Dropdown.vue';
import Card from './components/Cards/Card.vue';
import Button from './components/Button.vue';
import {
  Input,
  InputNumber,
  Tooltip,
  Popover
} from 'element-ui';
import VCalendar from "v-calendar";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('fg-input', fgInput);
    Vue.component('drop-down', DropDown);
    Vue.component('card', Card);
    Vue.component('n-button', Button);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.use(Tooltip);
    Vue.use(Popover);
    // Use v-calendar & v-date-picker components
    Vue.use(VCalendar, {
      componentPrefix: 'v',
    });
  }
};

export default GlobalComponents;
