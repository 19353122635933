<template>
  <transactions v-if="eventObj" :event="eventObj"/>
  <spinner v-else />
</template>
<script>
import Transactions from "@/components/Transactions.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  components: {
    Transactions,
    Spinner
  },
  computed: {
    eventObj () {
      if (this.$store.getters.allEvents.length) {
        return this.$store.getters.allEvents.find(item => item.id === parseInt(this.$route.params.id))
      }
    }
  }
}
</script>