<template>
  <div class='margin-top pt-2'>
      <div class='row' v-if="!confirmScreen">
        <h5 class='text-center col-12'>Reload Tokens </h5>
        <div class="col-md-12 pt-2 pb-2">
          <button :class="{active: amount == 5}" class='el-button' @click="amount = 5">
            5 Tokens
          </button>
          <button :class="{active: amount == 10}" class='el-button' @click="amount = 10">
            10 Tokens
          </button>
          <button :class="{active: amount == 15}" class='el-button' @click="amount = 15">
            15 Tokens
          </button>
          <button :class="{active: amount == 20}" class='el-button' @click="amount = 20">
            20 Tokens
          </button>
        
          <div class='row'>
            <div class='col-6 offset-3 pt-4 pb-2'>
              Custom Amount: <input type='number' v-model="amount" placeholder="Token Amount">
            </div>
          </div>
          <div class='row'>
            <div class='col-3 margin-top'>
              <h6>Transaction</h6>
              Support Reload
            </div>
            <div class='col-2 margin-top'>
              <h6>From</h6>
              {{event.name}}
            </div>
            <div class='col-2 margin-top'>
              <h6>To</h6>
              {{user.email}}
            </div>
            <div class='col-2  margin-top'>
              <h6>Tokens</h6>
              {{amount}} 
            </div>
            <div class='col-3 margin-top'>
              <h6>Total Amount ({{eventObj.currency}})</h6>
              ${{amount * eventObj.token_value}}.00 
            </div>
          </div>
        </div>
        <div class='col-md-12 pt-3'>
          <button class='primary-no-bg btn-primary' @click="confirmScreen = true">Confirm Transaction</button>
        </div>

      </div>
      <div class='row ' v-else>
          <div v-if="error.length" class='alert-danger'>{{error}}</div>
          <div v-if="msg.length" class='alert-successr'>{{msg}}</div>
          <h5 class='text-center col-12 margin-top'>
            Verify Transaction
          </h5>
          <div class='box col-md-12 pb-3'>
            <strong>{{user.first_name}} {{user.last_name}}</strong>
            <div>{{user.email}}</div>
            <div>{{user.phone_number}}</div>
          </div>
          <div class='col-3 offset-3'>
            {{amount}} Tokens
        </div>
        <div class='col-3 '>
        ${{amount * eventObj.token_value}}.00 {{eventObj.currency}}
        </div>
        <!-- <div class='col-md-12 margin-top'>
          Your Pin: <input type='number' v-model='pin'>
        </div> -->
        <div class='col-12 pt-4'>
          <button @click="confirmScreen = false" class='primary'>Back</button>
          <button :disabled="processing" @click="addToken()" class='primary'>Process</button>
        </div>
      </div>
  </div>
</template>
<script>
import { humanize } from "src/util/string";
import axios from 'axios';

  export default {
    props: ['user', 'event'],
    data () {
      return {
        amount: '',
        confirmScreen: false,
        pin: '',
        error: '',
        msg: '',
        processing: false
      }
    },
    computed: {
      eventObj () {
        if(typeof this.event == 'number' || typeof this.event == 'string') {
          let event = this.$store.state.events.find(item => item.id == parseInt(this.event))
          if (event) {
            return event
          }
          return this.$store.state.pastEvents.find(item => item.id == parseInt(this.event))
        } else {
          return this.event
        }
      }
    },
    methods: {
      addToken() {
        this.processing = true;
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/`;
        const transaction = {
          amount: this.amount,
          event: this.event.id,
          user: this.user.id,
          order: null
        };

        this.msg = "";
        axios
          .post(url, transaction)
          .then(response => {
            this.confirmation = false;
            this.$message("Successfully added to your account");
            this.eventDetailLoading = false;
            this.eventDetail = response.data;
            this.processing = false;
            this.$emit('complete')
            this.$emit('reload');
          })
          .catch(e => {
            this.processing = false;
            if (e.response.status !== 403 || e.response.status != 401) {
              this.error = humanize(e.response.data);
            } else {
              this.error = [e.response.data.detail];
            }
          });
      }
    }
  }
</script>
<style scoped lang='scss'>
.active {
  border: 1px solid blue;
}

.margin-top {
  margin-top: 1em;
  input {
    width: 50%;
  }
}
</style>
