<template>
  <div :class="'row ' + (isEdit ? 'editing' : '')">
    <div class="col-md-12">
      <div class="form-event">
        <div class="left-navigation">
          <h4
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ isEdit ? rawLocation.booth : "New Location" }}
          </h4>
          <ul>
            <li
              @click="onChangeStep(0)"
              :class="formIndex === 0 ? 'active' : ''"
            >
              <InforCircleIcon />Location Details
            </li>
            <!-- <li
              @click="onChangeStep(1)"
              :class="formIndex === 1 ? 'active' : ''"
            >
              <MapIcon />Event Map
            </li>
            <li
              @click="onChangeStep(2)"
              :class="formIndex === 2 ? 'active' : ''"
            >
              <MenuIcon />Menu
            </li> -->
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="handleClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <form class="event-form-2" v-if="formIndex === 0">
            <div class="row">
              <div class="col-md-12">
                <h3><InforCircleIcon />Location Details</h3>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group form-event-wrap">
                      <label for="" class="event-label">Location Name</label>
                      <fg-input
                        placeholder="Location Name"
                        name="locationName"
                        v-model="rawLocation.booth"
                        :error="getError('booth')"
                        v-validate="'required'"
                      ></fg-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group form-event-wrap">
                      <label for="" class="event-label">Description</label>
                      <fg-input
                        placeholder="Description"
                        name="Description"
                        v-model="rawLocation.description"
                        :error="getError('booth')"
                        v-validate="'required'"
                      ></fg-input>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="!$route.params.vendorId">
                  <div class="col-12 col-md-12">
                    <div class="form-group form-event-wrap">
                      <label for="" class="event-label">Vendor</label>

                      <el-select
                        v-model="rawLocation.vendor_event"
                        v-if="vendorEvents"
                      >
                        <el-option
                          v-for="vendor in vendorEvents"
                          :label="vendor.name"
                          :value="vendor.id"
                          :key="vendor.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12 mt-4">
                <h3><BrandingIcon />Location Branding</h3>
              </div>
              <div class="col-xs-12 col-lg-12 col-xl-4">
                <div class="wrap-empty-image">
                  <img v-if="previewLogo" :src="previewLogo" alt="" />
                  <img
                    class="preview-bg"
                    v-if="previewBg"
                    :src="previewBg"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-xs-12 col-lg-12 col-xl-4">
                <div class="wrap-event-input">
                  <label>
                    <strong class="me-2">Upload Logo </strong>
                    <InformationIcon />
                  </label>
                  <div class="input-file">
                    <input
                      class="input-file__input"
                      ref="file2"
                      type="file"
                      v-on:change="onUploadLogo"
                    />
                    <div
                      class="input-file__button"
                      @click="$refs.file2.click()"
                    >
                      Upload
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-xs-12 col-lg-12 col-xl-4"
                style="display: flex; flex-direction: column"
              >
                <div class="wrap-event-input">
                  <label>
                    <strong class="me-2">Upload Background </strong>
                    <InformationIcon />
                  </label>
                  <div class="input-file">
                    <input
                      class="input-file__input"
                      ref="file1"
                      type="file"
                      v-on:change="onUploadBackground"
                    />
                    <div
                      class="input-file__button"
                      @click="$refs.file1.click()"
                    >
                      Upload
                    </div>
                  </div>
                </div>

                <div class="form-navigation text-right mt-auto">
                  <div>
                    <n-button
                      type="primary"
                      :disabled="
                        !rawLocation.booth ||
                        rawLocation.booth === '' ||
                        loading
                      "
                      @click.native="save"
                    >
                      {{ rawLocation.id ? "Save" : "Save" }}
                    </n-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { humanize } from "src/util/string";
import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";

import InforCircleIcon from "src/components/Icons/InforCircle";
import InformationIcon from "src/components/Icons/Information";
import BrandingIcon from "src/components/Icons/Branding";
import MapIcon from "src/components/Icons/Map";
import MenuIcon from "src/components/Icons/Menu";

import moment from "moment/moment";

import _ from "lodash";

export default {
  components: {
    InforCircleIcon,
    InformationIcon,
    BrandingIcon,
    MapIcon,
    MenuIcon,
  },
  props: ["rawLocationEdit", "currentEvent"],
  computed: {},
  data() {
    const self = this;
    let rwEvent = {};
    let isEdit = false;
    if (this.rawLocationEdit) {
      rwEvent = this.rawLocationEdit;
      if (!rwEvent.vendor_event && rwEvent.vendor) {
        rwEvent.vendor_event = rwEvent.vendor;
      }
      isEdit = true;
    }

    const rs = JSON.parse(JSON.stringify(rwEvent));

    return {
      formIndex: 0,
      rawLocation: rs,
      successMsg: "",
      loading: false,
      vendorEvents: null,
      previewLogo: rs.logo,
      previewBg: rs.background,
      isEdit,
    };
  },
  methods: {
    onChangeStep(step) {
      this.formIndex = step;
    },
    onUploadBackground(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.rawLocation.background = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewBg = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    onUploadLogo(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.rawLocation.logo = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewLogo = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    fetchVendorEvents() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/?event=${this.$route.params.id}`;

      axios.get(url).then((response) => {
        this.vendorEvents = response.data.results;
        if (!this.rawLocation.vendor_event && this.$route.params.vendorId) {
          this.rawLocation.vendor_event = this.$route.params.vendorId;
        }
      });
    },
    handleClose() {
      this.rawLocation = {};
      this.$emit("onClose");
    },
    async save() {
      if (!this.rawLocation.vendor_event) {
        this.$message.warning("Please select a vendor");
        return;
      }
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/${
        this.rawLocation && this.rawLocation.id ? this.rawLocation.id + "/" : ""
      }`;
      this.successMsg = "";
      this.loading = true;

      const payload = this.rawLocation;
      payload.event = this.$route.params.id;
      payload.vendor = this.rawLocation.vendor_event;
      let func = axios.post;
      if (this.rawLocation.id) {
        func = axios.put;
      }

      const form = new FormData();

      Object.keys(payload).map((k) => {
        if (!!payload[k] && k !== "id") {
          if (k === "logo" || k === "background") {
            if (typeof payload[k] !== "string") {
              form.append(k, payload[k]);
            }
          } else {
            form.append(k, payload[k]);
          }
        }
      });

      func(url, form, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (!payload.id) {
            this.formIndex = 0;
            this.handleClose();
          }
          this.rawLocation = response.data;
          this.$message("Successfully update the event location");
          this.$emit("onComplete", this.rawLocation.id ? true : false);
          this.loading = false;
        })
        .catch((e) => {
          this.$message(e.response.data.detail);
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.fetchVendorEvents();
  },
  mounted() {},
  mixins: [GetError, User],
};
</script>
<style lang="scss">
.form-event {
  display: flex;
  background: white;
  margin: 0;
}

.right-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .location,
  .returns {
    margin-top: 60px;
  }
}

.form-navigation {
  text-align: right;
  button {
    margin: 0;
    margin-left: 15px;
  }
}

.event-branding,
.logo {
  .image-preview {
    margin-top: 10px;
    height: 162px;
    width: 285px;
    border: solid 1px;
  }
}

.token-value-summary {
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  padding: 5px 18px;
  margin-bottom: 10px;
}

.add-vendor {
  margin-top: 20px;
}

.hint-label {
  font-family: Inter, sans-serif;
  font-size: 11px;
  color: #021b43;
  letter-spacing: 0.39px;
  line-height: 14px;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

.hint-small {
  font-family: Inter, sans-serif;
  font-size: 12px;
  color: #021b43;
}

.editing {
  .left-navigation {
    ul li {
      cursor: pointer;
    }
  }
}
</style>
