

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.directive('click-outside', {
  bind () {
      this.event = event => this.vm.$emit(this.expression, event)
      this.el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.event)
  },   
  unbind() {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },

  stopProp(event) { event.stopPropagation() }
})

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    events: [],
    pastEvents: [],
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  },
  mutations: {
    setEvents(state, events) {
      state.events = Object.values(events);
    },
    setPastEvents(state, events) {
      state.pastEvents = Object.values(events);
    },
    setUser(state, user) {
      state.user = user;
    }
    
  },
  getters: {
    allEvents: state => {
      return state.events.concat(state.pastEvents);
    }
  },
  actions: {
    getEvents (context, search = '') {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/`;

      const data = { expand: "organization", name__icontains: search };
      // if (this.state.user.type == "vendor") {
      //   data["vendor_event__vendor"] = 33;
      // }
      axios.get(url, { params: data }).then(response => {
        context.commit('setEvents', response.data.results);
        this.upcomingEventsLoading = false;
      });

      const pastUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/event/?past=1`;
      axios.get(pastUrl, { params: data }).then(response => {
        context.commit('setPastEvents', response.data.results);
      });
    },
    async fetchVendor(context, vendorId) {
      const url = `${
        process.env.VUE_APP_BASE_HOST
      }/en/api/vendor/${vendorId}/?expand=administrators.user,vendor_event,vendor_event.vendor,user`;
      const response = await axios.get(url)
      return response.data
    },
    async fetchVendorEvent(context, vendorEventId) {
      const url = `${
        process.env.VUE_APP_BASE_HOST
      }/en/api/vendor-event/${vendorEventId}/?expand=vendor,vendor_event,vendor_event.vendor,user`;
      const response = await axios.get(url)
      return response.data
    }
  }
})

export default store;
