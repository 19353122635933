<!-- <template>
  <card>
    <h3>{{rawEvent.name}}
        <router-link v-if="this.user.type == 'org-administrator'" :to="{name: 'EventEdit', params: {id: $route.params.id}}"><i class='el-icon-edit pull-right'/></router-link>
        </h3>
      <spinner
        v-if="loading"
      />

    <div>

      <div class='row'>
        <div class='col-6'><i class='el-icon-time' /> Start: {{rawEvent.start_date_time | moment("YYYY-MM-DD HH:mm")}} </div>
        <div class='col-6'><i class='el-icon-time' /> End: {{rawEvent.end_date_time | moment("YYYY-MM-DD HH:mm")}}</div>

        <div class='col-6'><i class='el-icon-message' /> <a :href='`mailto:${rawEvent.email}`'>{{rawEvent.email}}</a></div>
        <div class='col-6'><i class='el-icon-phone' /> <a :href='`tel:${rawEvent.phone}`'>{{rawEvent.phone}}</a></div>
        <div class='col-6'><i class='el-icon-price-tag' /> Currency: {{rawEvent.currency}}</div>
        <div class='col-6'><i class='el-icon-money' /> Token {{rawEvent.token_name}}</div>
        <div class='col-6'><i class='el-icon-money' /> Conversion rate: {{rawEvent.token_value}} {{rawEvent.currency}} for 1 {{rawEvent.token_symbol}}</div>
        
      </div>

    </div>

  </card>
</template> -->
<script>
import axios from "axios";
import User from "src/mixins/User";
import DragToUpload from "src/pages/Dashboard/Components/DragToUpload.vue";
import { humanize } from "src/util/string";
import Spinner from 'src/components/Spinner';

export default {
  components: {
    DragToUpload,
    Spinner
  },
  computed: {

  },
  methods: {
    changed: function(key) {
      if (!this.dirties.includes(key)) {
        this.dirties.push(key);
      }
    },
    eventAsFormData: function() {
      const self = this;
      const e = {
        ...this.rawEvent,
        start_date_time: this.start_end_date_time[0],
        end_date_time: this.start_end_date_time[1],
      };
      console.log(e);
      const form = new FormData();
      const ignores = ["id", "vendor_events"].filter(
        x => !this.dirties.includes(x)
      );

      Object.keys(e)
        .filter(x => !ignores.includes(x))
        .map(k => {
          if (!!e[k] || k === 'logo' || k === 'image') {
            form.append(k, e[k] || '');
          }
        });

      return form;
    },
    fetchEvent: function() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${
        this.$route.params.id
      }/`;
      axios.get(url).then(response => {
        this.rawEvent = response.data;
        this.start_end_date_time = [
          this.rawEvent.start_date_time,
          this.rawEvent.end_date_time
        ]
        this.publish_start_end_date_time =  [
          this.rawEvent.publish_start_date_time,
          this.rawEvent.publish_end_date_time
        ]
        this.loading = false;
      });
    },
    updateEventDetail: function() {
      const self = this;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${
        this.$route.params.id
      }/`;
      self.successMsg = "";

      axios
        .patch(url, this.eventAsFormData())
        .then(response => {
          self.successMsg = "Successfully update the event detail";
        })
        .catch(e => {
          if (e.response.status !== 403 || e.response.status != 401) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    }
  },
  data() {
    return {
      rawEvent: {},
      dirties: [],
      successMsg: "",
      serverErrors: [],
      loading: true,
      start_end_date_time: [],
      disabled: true,
    };
  },
  mounted() {
    this.fetchEvent();
  },
  mixins: [User]
};
</script>
<style lang="scss" scoped>
i {
  margin-right: 6px;
}
</style>
