<template>
  <div class="wrap-date-picker" @click="isOpen = true">
    <div class="date-picker-inputs">
      {{ displayValue }}

      <i class="el-input__icon el-range__icon el-icon-arrow-down"></i>
    </div>
    <div
      class="wrap-picker-dropdown"
      v-if="isOpen"
      v-click-outside="onClickOutSide"
    >
      <div class="wrap-row-date">
        <el-date-picker
          :editable="true"
          v-model="fromDate"
          type="date"
          :format="'MM/dd/yyyy'"
          :clearable="false"
          :picker-options="pickerOptionsStart"
        >
        </el-date-picker>

        <div>-</div>
        <el-date-picker
          :editable="true"
          v-model="toDate"
          type="date"
          :format="'MM/dd/yyyy'"
          :clearable="false"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div class="wrap-type-date wrap-custom-date-style mb-2">
        <label for="">Quick Filter</label>
        <el-select
          v-model="selectedQuickRange"
          placeholder="Range..."
          style="min-width: 180px"
        >
          <el-option
            v-for="(item, indx) in shortcuts"
            :key="indx"
            :label="item.text"
            :value="item.text"
            @click.native="onChangeDate(item)"
          >
          </el-option>
        </el-select>
      </div>
      <div class="wrap-type-date wrap-custom-date-style" v-if="!hideDateType">
        <label for="">View By</label>
        <el-select
          v-model="selectedType"
          placeholder="Select"
          style="min-width: 130px"
        >
          <el-option :label="'Date'" :value="'date'"> </el-option>
          <el-option :label="'Hourly'" :value="'hour'"> </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["rawDateRange", "rawValue", "hideDateType"],
  components: {},
  computed: {
    displayValue() {
      let label = "";
      if (this.fromDate) {
        label = moment(this.fromDate).startOf("day").format("MMM DD") + " - ";
      } else {
        label = "";
      }

      if (this.toDate) {
        label = label + moment(this.toDate).endOf("day").format("MMM DD");
      } else {
        label = "";
      }
      if (label === "") {
        label = this.labelDate || "Select a range...";
      }
      return label;
    },
  },
  watch: {
    rawValue() {
      this.fromDate = this.rawDateRange[0];
      this.toDate = this.rawDateRange[1];
    },
    fromDate(newVal, oldVal) {
      if (!this.fromDate && !this.toDate) {
        this.$emit("onChangeDate", []);
        return;
      }
      this.$emit("onChangeDate", [this.fromDate, this.toDate]);
    },
    toDate(newVal, oldVal) {
      if (!this.fromDate && !this.toDate) {
        this.$emit("onChangeDate", []);
        return;
      }
      this.$emit("onChangeDate", [this.fromDate, this.toDate]);
    },
    selectedType(newVal, oldVal) {
      this.$emit("onChangeDateType", this.selectedType);
    },
  },
  data() {
    let fromDate = "";
    let toDate = "";

    if (this.rawDateRange) {
      fromDate = moment(this.rawDateRange[0]).startOf("day");
      toDate = moment(this.rawDateRange[1]).startOf("day");
    }
    const self = this;
    return {
      selectedQuickRange: null,
      isOpen: false,
      fromDate: fromDate,
      toDate: toDate,
      labelDate: "",
      selectedType: "date",
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() > new Date(self.toDate).getTime();
        },
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(self.fromDate).getTime();
        },
      },
      shortcuts: [
        {
          text: "Last 7 Days",
          value: [moment().subtract(7, "days").toDate(), moment().toDate()],
        },
        {
          text: "Last 30 Days",
          value: [moment().subtract(30, "days").toDate(), moment().toDate()],
        },
        {
          text: "Last 90 Days",
          value: [moment().subtract(90, "days").toDate(), moment().toDate()],
        },
        {
          text: "Year to date",
          value: [moment().startOf("year").toDate(), moment().toDate()],
        },
      ],
    };
  },
  methods: {
    moment,
    onChangeDate(item) {
      this.fromDate = item.value[0];
      this.toDate = item.value[1];
    },
    onReset() {
      this.fromDate = this.rawDateRange[0];
      this.toDate = this.rawDateRange[1];
    },
    onClickOutSide(event) {
      if (event.target.closest(".wrap-date-picker")) {
        return;
      }
      this.isOpen = false;
    },
  },
  mounted() {},
  beforeUnmount() {},
};
</script>
<style lang="scss">
.wrap-date-picker {
  position: relative;
  min-width: 140px;
  padding-right: 30px;
  background: #ffffff;
  border: 1px solid rgba(238, 238, 239, 0.7);
  -webkit-box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
  box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
  border-radius: 8px;
  z-index: 999;
  .date-picker-inputs {
    font-family: "Inter", sans-serif;
    color: #021b43;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding: 10px 15px;
    & > i {
      position: absolute;
      top: 0;
    }
  }

  .wrap-picker-dropdown {
    position: absolute;
    min-width: 100%;
    width: 340px;
    right: 0;
    padding: 15px;
    background: #fff;
    border-radius: 6px;
    top: 40px;
    box-shadow: 0px 0px 10px #dddddd8c;
    .wrap-row-date {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }
    .wrap-type-date {
      color: #021b43;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      & > label {
        margin-right: 15px;
        width: 100%;
      }
    }
  }
}
</style>
