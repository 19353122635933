<!-- <template>
  <card>
    <h3>Order summary</h3>
    <spinner v-if="loading"></spinner>
    <div v-else>
      <table style="width:100%">
        <tr>
          <th>Completed</th>
          <th>Open</th>
          <th>Rejected</th>
          <th>Refunded</th>
          <th>Total Tokens</th>
          <th>Total {{summary['currency']}}</th>
        </tr>
        <tr>
          <td>{{ summary["completed"] }}</td>
          <td>{{ summary["open"] }}</td>
          <td>{{ summary["rejected"] }}</td>
          <td>{{ summary["refunded"] }}</td>
          <td>{{ summary["amount"] * event.token_value }}</td>
          <td>{{ formatPrice(summary["amount"])}} {{summary['currency']}}</td>
        </tr>
      </table>
    </div>
  </card>
</template> -->
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
import User from "src/mixins/User.js";
import { humanize } from "src/util/string";
export default {
  components: {
    Spinner
  },
  props: ['summary', 'event'],
  data() {
    return {
      counts: {},
      loading: false
    };
  },
  mounted() {

  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
}
};
</script>
<style lang="scss"></style>
