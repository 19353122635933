import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import DashboardPlugin from "./dashboard-plugin";
import { DataTables, DataTablesServer } from "vue-data-tables";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// Plugins
import App from "./App.vue";
import * as VueGoogleMaps from "vue2-google-maps";

import './input.css'
// router setup
import routes from "./routes/routes";

import locale from "element-ui/lib/locale/lang/en";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import mitt from "mitt";
import VueKonva from "vue-konva";

Vue.use(VueKonva);

Vue.component(VueQrcode.name, VueQrcode);

Vue.use(ElementUI, { locale });

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(DataTables);
Vue.use(DataTablesServer);
Vue.use(require("vue-moment"));

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: "places",
  },
});

import store from "./store/index";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Token ${localStorage.getItem("token")}`;

      if (user.type == "cash") {
        if (to.matched.some((record) => record.meta.cashUserAllowed)) {
          next();
        } else if (
          to.matched.some(
            (record) => record.meta.is_admin || record.meta.isAdminOnly
          )
        ) {
          next({ path: "/dashboard" });
        } else {
          next();
        }
      } else if (
        user.type === "vendor" &&
        to.matched.some(
          (record) => record.meta.is_admin || record.meta.isAdminOnly
        )
      ) {
        console.log(to.params.vendorId);
        console.log(user?.user_type?.vendor?.id?.toString());
        console.log(to.meta.isVendorUserAllow);
        if (
          to.params?.vendorId?.toString() ===
            user?.user_type?.vendor?.id?.toString() &&
          to.meta.isVendorUserAllow
        ) {
          next();
        } else {
          next({ path: "/dashboard" });
        }
      } else if (to.matched.some((record) => record.meta.is_superadmin)) {
        if (user.is_superadmin) {
          next();
        } else {
          next({ name: "userboard" });
        }
      } else if (to.matched.some((record) => record.meta.is_org_admin)) {
        if (user.type === "org-administrator") {
          next();
        } else {
          next({ name: "userboard" });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

/* eslint-disable no-new */
window.eventBus = new Vue();
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
});
