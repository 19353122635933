<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': error },
      { 'input-group-focus': focused },
      { 'has-label': label || $slots.label },
      { 'has-success': !error && touched && value },
    ]"
  >
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <slot name="addonLeft">
      <div
        v-if="addonLeftIcon"
        :style="addonLeftIconStyle"
        class="input-group-addon input-group-prepend"
      >
        <i :class="addonLeftIcon"></i>
      </div>
    </slot>

    <slot name="addonLeftComp"> </slot>

    <slot>
      <input
        v-if="!isAutoComplete && !textArea && !isDatePicker"
        :style="inputStyle"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :class="[
          {
            valid: value && !error,
            'add-on-right': addonRightIcon,
            'add-on-left': addonLeftIcon,
          },
          inputClasses,
        ]"
        :autocomplete="isUseSuggest ? '' : 'new-password'"
        aria-describedby="addon-right addon-left"
      />

      <el-date-picker
        :editable="false"
        v-if="isDatePicker"
        :style="inputStyle"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        type="date"
        placeholder="Pick a day"
      >
      </el-date-picker>

      <textarea
        v-if="!isAutoComplete && textArea"
        :style="inputStyle"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :class="[
          {
            valid: value && !error,
            'add-on-right': addonRightIcon,
            'add-on-left': addonLeftIcon,
          },
          inputClasses,
        ]"
        aria-describedby="addon-right addon-left"
      ></textarea>

      <el-autocomplete
        v-if="isAutoComplete"
        :class="[{ valid: value && !error }, inputClasses]"
        class="form-control"
        :trigger-on-focus="true"
        v-bind="$attrs"
        v-on="listeners"
        :value="value"
      >
        <template slot-scope="{ item }">
          <div
            class="d-flex w-100 align-items-center justify-content-between"
            style="padding: 10px"
          >
            <div
              class="value"
              style="
                font-family: 'Inter';
                font-size: 14px;
                color: #021b43;
                letter-spacing: 0.43px;
                text-align: left;
                line-height: 14px;
                min-width: calc(100% / 3);
              "
            >
              {{ item.first_name }} {{ item.last_name }}
            </div>
            <div
              class="phone"
              style="
                font-family: 'Inter';
                font-size: 12px;
                color: #8e8e93;
                letter-spacing: 0.43px;
                text-align: left;
                line-height: 14px;
                min-width: calc(100% / 3);
              "
            >
              {{ item.phone_number }}
            </div>
            <div
              class="email"
              style="
                font-family: 'Inter';
                font-size: 12px;
                color: #8e8e93;
                letter-spacing: 0.43px;
                text-align: left;
                line-height: 14px;
                min-width: calc(100% / 3);
              "
            >
              {{ item.email }}
            </div>
          </div>
        </template>
      </el-autocomplete>
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-addon input-group-append">
        <i :class="addonRightIcon"></i>
      </span>
    </slot>

    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-if="error"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "fg-input",
  props: {
    required: Boolean,
    label: String,
    error: String,
    labelClasses: String,
    inputClasses: String,
    value: [String, Number],
    addonRightIcon: String,
    addonLeftIcon: String,
    addonLeftIconStyle: String,
    inputStyle: String,
    isAutoComplete: Boolean,
    textArea: Boolean,
    isDatePicker: Boolean,
    isUseSuggest: Boolean
  },
  data() {
    return {
      touched: false,
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt;
      if (evt.target) {
        value = evt.target.value;
      }
      if (!this.touched && value) {
        this.touched = true;
      }
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
  },
};
</script>
<style></style>
