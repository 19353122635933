<template>
  <div
    class="el-dialog event-dialog"
    style="width: 100% !important; max-width: 100% !important"
  >
    <CreateEventForm
      :isEdit="true"
      :isPage="true"
      :rawEventEdit="rawEvent"
      v-if="rawEvent"
      :origEvent="origEvent"
    />
    <spinner v-if="!rawEvent"></spinner>
  </div>
  <!-- <card>
    <h3>{{rawEvent.name}} </h3>
      <div>
      <div class="alert alert-success" v-if="successMsg">
        {{ successMsg }}
      </div>
      <div class="alert alert-warning" v-if="serverErrors.length > 0">
        <ul>
          <li v-bind:key="error" v-for="error in serverErrors">
            {{ error }}
          </li>
        </ul>
      </div>
      <div>
        <label>Name</label>
        <fg-input @input="changed('name')" v-model="rawEvent.name" :disabled="disabled"></fg-input>
      </div>
      <div>
        <label>Description</label>
        <fg-input v-model="rawEvent.description" :disabled="disabled"></fg-input>
      </div>
      <div>
        <label>
          Address
          <gmap-autocomplete
            placeholder="search for your event location"
            @place_changed="setPlace">
          </gmap-autocomplete>
          {{rawEvent.address}}
          {{rawEvent.point}}
        </label>
        <Gmap-Map
          v-if="rawEvent.point"
          style="width: 600px; height: 300px;"
          :zoom="15"
          :center="rawEvent.point">
          <Gmap-Marker
            label=""
            :position="rawEvent.point"
            ></Gmap-Marker>
        </Gmap-Map>
      </div>
      <div>
        <label>Event Start Date and Time</label>
        <el-date-picker :editable="false" 
          v-model="start_end_date_time"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Event Start date and time"
          end-placeholder="Event End date and time">
        </el-date-picker>
        <label>Publish Start Date and Time</label>
        <el-date-picker :editable="false" 
          v-model="publish_start_end_date_time"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Publish Start date"
          end-placeholder="Publish End date">
        </el-date-picker>

      </div>

      <div>
        <label>Email</label>
        <fg-input v-model="rawEvent.email" @input="changed('email')" :disabled="disabled"></fg-input>
      </div>

      <div>
        <label>Currency</label>
        <el-select v-model="rawEvent.currency" placeholder="Select">
          <el-option
            v-for="item in ['CAD', 'USD']"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div>
        <label>Token name</label>
        <fg-input v-model="rawEvent.token_name" :disabled="disabled"></fg-input>
      </div>
      <div>
        <label>Token value (Exchange Rate)</label>
        <fg-input class="disabled" v-model="rawEvent.token_value" :disabled="disabled"></fg-input>
        1 {{rawEvent.token_symbol ? rawEvent.token_symbol : rawEvent.token_name}} = ${{rawEvent.token_value}}{{rawEvent.currency}}
      </div>
      <div>
        <label>Token symbol</label>
        <fg-input v-model="rawEvent.token_symbol" :disabled="disabled"></fg-input>
      </div>
      <div>
        <label>Enable Order Ahea symbol</label>
        <n-checkbox v-model="rawEvent.order_ahead" :disabled="disabled"></n-checkbox>
        <br />
      </div>
      <div>
        <label>Is Event Hidden</label>
        <n-checkbox v-model="rawEvent.is_hidden" :disabled="disabled"></n-checkbox>
        <br />
      </div>
      <div>
        <label>Min transactions</label>
        <fg-input
          v-model="rawEvent.min_transactions"
          :disabled="disabled"
        ></fg-input>
      </div>


      <div>
        <label>Logo (drag a file to upload)</label>
        <drag-to-upload
          :src="rawEvent.logo"
          v-model="rawEvent.logo"
          @input="changed('logo')"
        ></drag-to-upload>
      </div>
      <div>
        <label>Image</label>
        <drag-to-upload
          :src="rawEvent.image"
          v-model="rawEvent.image"
          @input="changed('image')"
        ></drag-to-upload>
      </div>
      <div>
        <h4>Event reload amounts</h4>
        <div v-for="(amount, i) in rawEvent.topup_amounts_array">
          <input v-model="rawEvent.topup_amounts_array[i]" />
          <button @click="deleteAmount(i)">X</button></div>
        <div><input @change="addAmount(newAmount)" v-model="newAmount" /></div>
      </div>
      <div v-show="user.type == 'org-administrator'">
        <n-button @click.native="updateEventDetail">Save</n-button>
      </div>
    </div>
    <div>
      <h4>Send Push</h4>
      <div>
        <label>Title</label>
        <fg-input v-model="push.title" ></fg-input>
      </div>
      <div>
      <label>Message</label>
        <fg-input v-model="push.message" ></fg-input>
        <n-button @click.native="sendPush">Save</n-button>
      </div>
    </div>
  </card> -->
</template>
<script>
import axios from "axios";
import { Checkbox } from "src/components";
import User from "src/mixins/User";
import DragToUpload from "src/pages/Dashboard/Components/DragToUpload.vue";
import { humanize } from "src/util/string";
import Spinner from "src/components/Spinner";
import CreateEventForm from "../Events/CreateEventForm.vue";
export default {
  props: ["editLink"],
  components: {
    DragToUpload,
    Spinner,
    CreateEventForm,
    [Checkbox.name]: Checkbox,
  },
  computed: {},
  methods: {
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      this.place = place;

      this.rawEvent.address = place.formatted_address;
      const point = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      if (this.place) {
        this.center = point;
        this.rawEvent.point = point;
      }
    },
    changed: function (key) {
      if (!this.dirties.includes(key)) {
        this.dirties.push(key);
      }
    },
    eventAsFormData: function () {
      const self = this;
      const e = {
        ...this.rawEvent,
        start_date_time: this.start_end_date_time[0],
        end_date_time: this.start_end_date_time[1],
      };
      console.log(e);
      const form = new FormData();
      const ignores = ["id", "vendor_events"].filter(
        (x) => !this.dirties.includes(x)
      );

      Object.keys(e)
        .filter((x) => !ignores.includes(x))
        .map((k) => {
          if (k === "logo" || k === "image") {
            console.log(e[k], this.origEvent[k]);
            if (e[k] != this.origEvent[k]) {
              form.append(k, e[k] || "");
            }
          } else if (k == "point") {
            form.append(k, `POINT(${e[k].lng} ${e[k].lat})`);
          } else {
            form.append(k, e[k] || "");
          }
        });

      return form;
    },
    fetchEvent: function () {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/`;
      axios.get(url).then((response) => {
        this.rawEvent = response.data;
        this.origEvent = {
          ...response.data,
        };
        this.start_end_date_time = [
          this.rawEvent.start_date_time,
          this.rawEvent.end_date_time,
        ];
        this.publish_start_end_date_time = [
          this.rawEvent.publish_start_date_time,
          this.rawEvent.publish_end_date_time,
        ];
        this.loading = false;
        if (this.rawEvent.point) {
          this.rawEvent.point = {
            lat: this.rawEvent.point.coordinates[1],
            lng: this.rawEvent.point.coordinates[0],
          };
        }
      });
    },
    updateEventDetail: function () {
      const self = this;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/`;
      self.successMsg = "";
      this.serverErrors = "";
      axios
        .patch(url, this.eventAsFormData())
        .then((response) => {
          self.$message("Successfully update the event detail");
        })
        .catch((e) => {
          if (e.response.status !== 403 || e.response.status != 401) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
          self.$message(humanize(e.response.data).join(" "));
        });
    },
    sendPush() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/send-push/`;
      axios.post(url, this.push).then((response) => {
        this.$message("Push sent successfully");
        this.push.title = "";
        this.push.message = "";
      });
    },
    addAmount(amount) {
      this.rawEvent.topup_amounts_array.push(parseInt(amount));
      this.rawEvent.topup_amounts_array.sort((a, b) => {
        if (a < b) {
          return -1;
        } else if (b == a) {
          return 0;
        }
        return 1;
      });
      this.newAmount = "";
      this.rawEvent.topup_amounts = this.rawEvent.topup_amounts_array.join(",");
    },
    deleteAmount(index) {
      this.rawEvent.topup_amounts_array.splice(index, 1);
      this.rawEvent.topup_amounts = this.rawEvent.topup_amounts_array.join(",");
    },
  },
  data() {
    return {
      rawEvent: null,
      dirties: [],
      successMsg: "",
      serverErrors: [],
      loading: true,
      start_end_date_time: [],
      disabled: false,
      origEvent: {},
      markers: [],
      place: null,
      center: false,
      push: {
        title: "",
        message: "",
      },
      newAmount: "",
    };
  },
  mounted() {
    this.fetchEvent();
  },
  mixins: [User],
};
</script>
<style lang="scss" scoped>
i {
  margin-right: 6px;
}
</style>
