export const humanize = (errors, isHideKey = false) =>
  Object.keys(errors).map(k => `${!isHideKey ? `${k}: ` : ''}${errors[k][0]}`);

export const numberWithCommas = (x, type) => {
    var formatter = new Intl.NumberFormat('en-US', {
    style: type,
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(x);
}

export const booleanFormatter = (item, col) => {            
  console.log(item)
  return item[col] ? "True" : 'False'
}

export const formatNumber = (item, digits) => {
  digits = digits || 6
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: digits }).format(item)
}

export const numberFormatter = (row, col ,cell, index) => {
  return formatNumber(cell, 6)
}

export const formatRevenue = (value, event, digits) => {
  digits = digits || 6
  return `${formatNumber(value, digits)} (\$${formatNumber(value * event.token_value, digits)})`
}

export const formatRevenueValue = (value, event, digits) => {
  digits = digits || 6
  return `$${formatNumber(value * event.token_value, digits)}`
}

export const formatRevenueToken = (value, event, digits) => {
  digits = digits || 6
  return `${formatNumber(value, digits)}`
}

export const mappingTrasactionStatus = (type) => {
  const mapping = {
    addCredit: 'Add Credit',
    transfer: 'Transfer',
    spendCredit: 'Spend Credit',
    refund: 'Refund',
  }
  return mapping[type]
}