<template>
  <div v-if="currentEvent">
    <div class="title-links mb-1 text-xs font-medium leading-6 text-gray-500">
      Event Navigation
    </div>
    <div
      :link="{
        name: 'Event',
        icon: 'now-ui-icons design_image',
      }"
    >
      <sidebar-item
        :link="{
          name: 'Dashboard',
          icon: 'assets/imgs/dashboard-icon.svg',
          iconActive: 'assets/imgs/dashboard-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/dashboard`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Reload',
          icon: 'assets/imgs/reload-icon.svg',
          iconActive: 'assets/imgs/reload-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/reloads`,
        }"
      >
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Event Info',
          icon: 'assets/imgs/events-icon.svg',
          iconActive: 'assets/imgs/events-icon-active.svg',
          path: `/pages/events/${currentEvent ? currentEvent.id : 'id'}/edit`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Orders',
          icon: 'assets/imgs/orders-icon.svg',
          iconActive: 'assets/imgs/orders-icon-active.svg',
          path: `/pages/events/${currentEvent ? currentEvent.id : 'id'}/orders`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Transactions',
          icon: 'assets/imgs/transactions.svg',
          iconActive: 'assets/imgs/transactions-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/transactions`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Users',
          icon: 'assets/imgs/users-icon.svg',
          iconActive: 'assets/imgs/users-icon-active.svg',
          path: `/pages/events/${currentEvent ? currentEvent.id : 'id'}/users`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Vendors',
          icon: 'assets/imgs/vendors-icon.svg',
          iconActive: 'assets/imgs/vendors-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/vendors`,
        }"
      ></sidebar-item>

      <!-- <sidebar-item
        :link="{
          name: 'Sales',
          icon: 'now-ui-icons business_money-coins',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/financials`,
        }"
      ></sidebar-item> -->

      <sidebar-item
        :link="{
          name: 'Locations',
          icon: 'assets/imgs/booth-icon.svg',
          iconActive: 'assets/imgs/booth-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/locations`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Menu',
          icon: 'assets/imgs/menu-icon.svg',
          iconActive: 'assets/imgs/menu-icon-active.svg',
          path: `/pages/events/${currentEvent ? currentEvent.id : 'id'}/menu`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Reporting',
          icon: 'assets/imgs/reporting-icon.svg',
          iconActive: 'assets/imgs/reporting-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/reporting`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Financials',
          icon: 'assets/imgs/sales-icon.svg',
          iconActive: 'assets/imgs/sales-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/financials`,
        }"
      ></sidebar-item>
      <sidebar-item
        :link="{
          name: 'Demographics',
          icon: 'assets/imgs/demographics-icon.svg',
          iconActive: 'assets/imgs/demographics-icon-active.svg',
          path: `/pages/events/${
            currentEvent ? currentEvent.id : 'id'
          }/demographics`,
        }"
      ></sidebar-item>
    </div>
    <sidebar-item
      v-if="user.is_superuser"
      :link="{
        name: 'Superuser Menu',
        icon: 'assets/imgs/users-card.svg',
        iconActive: 'assets/imgs/users-card.svg',
      }"
    >
      <div class="flex flex-column">
        <sidebar-item
          :link="{
            name: 'All Vendors',
            // icon: 'el-icon-coffee',
            path: `/pages/events/${
              currentEvent ? currentEvent.id : 'id'
            }/all-vendors`,
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Users',
            //icon: 'now-ui-icons users_single-02',
            path: `/pages/events/${
              currentEvent ? currentEvent.id : 'id'
            }/all-users`,
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Organizations',
            //icon: 'now-ui-icons design_image',
            path: `/pages/events/${
              currentEvent ? currentEvent.id : 'id'
            }/organizations`,
          }"
        >
        </sidebar-item>
      </div>
    </sidebar-item>
  </div>
</template>
<script>
import UserMenu from "src/pages/Dashboard/Layout/Extra/UserMenu";
import User from "src/mixins/User";
import Event from "src/mixins/Event";
export default {
  components: {
    UserMenu,
  },
  mixins: [User, Event],
};
</script>
<style>
.full-width {
  width: 100%;
}
</style>
