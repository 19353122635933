<template>
  <div id="financials">
    <div class="no-tab-content-inside">
      <div class="col-12 create-event-table" style="position: relative">
        <h3 class="title-page d-flex w-100">
          Financials
          <div
            class="wrap-datepicker action-create-event"
            style="width: auto; margin-left: auto; display: flex"
            v-if="rawDateRange"
          >
            <!-- <el-date-picker
              :editable="false"
              style="width: 190px !important"
              v-model="rangeTime"
              type="daterange"
              prefix-icon="el-icon-arrow-down"
              :align="'right'"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :format="'MMM dd'"
              :clearable="false"
              @change="init()"
            >
            </el-date-picker> -->
            <DatePickerDropdown
              :rawDateRange="rawDateRange"
              :hideDateType="true"
              ref="datepicker"
              @onChangeDate="onUpdateDate"
            />
            <n-button
              style="
                margin: 0px 0px 0px 20px;
                padding: 10px;
                font-size: 10px;
                background-color: #396ad5;
                font-weight: 600;
                color: white;
                white-space: nowrap;
              "
              slot="title"
              @click.native="onReset"
            >
              Reset To Default
            </n-button>
          </div>
        </h3>
        <canvas id="chart" style="width: 0; height: 0"></canvas>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-12 col-md-12">
          <div class="card card-stats card-raised bg-transparent shadow-none">
            <div class="card-body pl-0 pr-0">
              <div class="row">
                <div class="col-xs-12 col-lg-6 col-xl-3">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-primary">
                        <img src="assets/imgs/yellow-transfer.svg" alt="" />
                      </div>
                      <div
                        class="statistics-infor"
                        v-if="data.by_users || data.by_users === 0"
                      >
                        <h3 class="info-title">
                          <animated-number
                            :value="data.by_users.length"
                          ></animated-number>
                        </h3>
                        <h6 class="stats-title">Customers</h6>
                      </div>
                      <spinner v-if="!data.by_users && data.by_users !== 0" />
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-lg-6 col-xl-3">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-primary purple-bg">
                        <img src="assets/imgs/token-purple.svg" alt="" />
                      </div>
                      <div
                        class="statistics-infor"
                        v-if="
                          data.total_card_amount || data.total_card_amount === 0
                        "
                      >
                        <h3 class="info-title">
                          <animated-number
                            v-if="currentPriceType === 'token'"
                            :value="
                              (data.num_transactions === 0
                                ? 0
                                : data.total_tokens_created /
                                  data.num_transactions
                              ).toFixed(2)
                            "
                          ></animated-number>
                          <small v-else>
                            $<animated-number
                              :value="
                                (data.num_transactions === 0
                                  ? 0
                                  : data.total_tokens_created /
                                    data.num_transactions) * event.token_value
                              "
                            ></animated-number
                          ></small>
                        </h3>
                        <h6 class="stats-title">Avg. Reload Amount</h6>
                      </div>
                      <spinner
                        v-if="
                          !data.total_card_amount &&
                          data.total_card_amount !== 0
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-lg-6 col-xl-3">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-primary blue-bg">
                        <img src="assets/imgs/token-blue.svg" alt="" />
                      </div>
                      <div
                        class="statistics-infor"
                        v-if="
                          data.total_tokens_created ||
                          data.total_tokens_created === 0
                        "
                      >
                        <h3 class="info-title">
                          <animated-number
                            v-if="currentPriceType === 'token'"
                            :value="data.total_tokens_created"
                          ></animated-number>
                          <small v-else>
                            $<animated-number
                              :value="
                                data.total_tokens_created * event.token_value
                              "
                            ></animated-number
                          ></small>
                        </h3>
                        <h6 class="stats-title">Tokens Issued</h6>
                      </div>
                      <spinner
                        v-if="
                          !data.total_tokens_created &&
                          data.total_tokens_created !== 0
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-lg-6 col-xl-3">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-primary green-bg">
                        <img src="assets/imgs/green-money.svg" alt="" />
                      </div>
                      <div
                        class="statistics-infor"
                        v-if="
                          data.total_tokens_created ||
                          data.total_tokens_created === 0
                        "
                      >
                        <h3 class="info-title">
                          <animated-number
                            v-if="currentPriceType === 'token'"
                            :value="
                              data.total_tokens_created -
                              data.event_open_token_balance
                            "
                          ></animated-number
                          ><small v-else>
                            $<animated-number
                              :value="
                                (data.total_tokens_created -
                                  data.event_open_token_balance) *
                                event.token_value
                              "
                            ></animated-number
                          ></small>
                        </h3>
                        <h6 class="stats-title">Tokens Spent</h6>
                      </div>
                      <spinner v-if="!data.total_tokens_created" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-12">
          <tabs
            type="primary"
            v-on:change="onTabChartChange"
            :value="'Tokens Purchased'"
            :hidden-dot="true"
            tabNavWrapperClasses="right-align-tabs"
          >
            <tab-pane label="Tokens Purchased"> </tab-pane>
            <tab-pane label="Tokens Spent"> </tab-pane>
          </tabs>
          <div
            class="tab-content-outside"
            style="background: transparent"
            v-if="currentChartTab === 'Tokens Purchased'"
          >
            <h3 class="title-page medium-size">Tokens Purchased</h3>
            <div class="panel-header panel-header-lg pb-0">
              <LineChartGenerator
                v-if="chartData2 && options"
                :data="chartData2"
                :options="options"
                :height="600"
                :width="'100%'"
              />
              <div
                v-if="!chartData2"
                style="height: 300px"
                class="d-flex justify-content-center align-items-center"
              >
                <Spinner />
              </div>
            </div>
          </div>
          <div
            class="tab-content-outside"
            style="background: transparent"
            v-if="currentChartTab === 'Tokens Spent'"
          >
            <h3 class="title-page medium-size">Tokens Spent</h3>
            <div class="panel-header panel-header-lg">
              <LineChartGenerator
                v-if="chartData && options"
                :data="chartData"
                :options="options"
                :height="600"
                :width="'100%'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <tabs
            type="primary"
            v-on:change="onTabChange"
            :value="'User'"
            :hidden-dot="true"
          >
            <tab-pane label="Date"> </tab-pane>
            <tab-pane label="Support"> </tab-pane>
            <tab-pane label="User"> </tab-pane>
          </tabs>

          <div class="tab-content-outside" v-if="currentTab === 'Support'">
            <div class="search-table">
              <el-input
                placeholder="Search by Account"
                v-model="filters[1].value"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>

            <el-table
              max-height="250"
              v-show="!loading && data"
              class="event-table"
              :data="data.by_system"
              :filters="filters"
              :total="data.by_system ? data.by_system.length : 0"
              :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
            >
              <el-table-column
                :prop="'from_user_id'"
                :label="'User'"
                :minWidth="150"
              >
                <template slot-scope="scope">
                  <a href="javascript:void(0)" @click="handleView(scope.row)">
                    {{
                      (
                        scope.row.user__first_name +
                        " " +
                        scope.row.user__last_name
                      ).trim() === ""
                        ? "N/A"
                        : scope.row.user__first_name +
                          " " +
                          scope.row.user__last_name
                    }}
                  </a>
                </template>
              </el-table-column>

              <el-table-column
                v-for="title in titlesSupport"
                :prop="title.prop"
                :label="title.label"
                :key="title.prop"
                :minWidth="150"
                :formatter="title.formatter"
              >
              </el-table-column>
              <el-table-column :label="''">
                <template slot-scope="scope">
                  <div :class="'action-cl'" disable-transitions>
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <img
                          class="dropdown-dot"
                          src="assets/imgs/dot-dropdown.svg"
                          alt=""
                        />
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          command="a"
                          @click.native="handleView(scope.row)"
                          ><i class="el-icon-view"></i> View</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <spinner v-if="loading" />
          </div>
          <div class="tab-content-outside" v-if="currentTab !== 'Support'">
            <div class="search-table">
              <el-input
                placeholder="Search by Account"
                v-model="filters[2].value"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>

            <el-table
              max-height="250"
              v-show="!loading && data"
              class="event-table"
              :data="currentTabData"
              :filters="filters"
              :total="currentTabData ? currentTabData.length : 0"
              :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
            >
              <el-table-column
                :prop="'from_user_id'"
                :label="currentTab === 'Date' ? 'Date' : 'User'"
                :minWidth="150"
              >
                <template slot-scope="scope">
                  <a
                    v-if="currentTab !== 'Date'"
                    href="javascript:void(0)"
                    @click="handleViewUser(scope.row)"
                  >
                    {{
                      (
                        scope.row.user__first_name +
                        " " +
                        scope.row.user__last_name
                      ).trim() === ""
                        ? "N/A"
                        : scope.row.user__first_name +
                          " " +
                          scope.row.user__last_name
                    }}
                  </a>
                  <span v-else>
                    {{ scope.row.timestamp__date }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                v-for="title in titles"
                :prop="title.prop"
                :label="
                  title.prop === 'from_user_id'
                    ? currentTab !== 'Date'
                      ? 'User'
                      : 'Date'
                    : title.label
                "
                :key="title.prop"
                :minWidth="150"
                :formatter="title.formatter"
              >
              </el-table-column>
              <el-table-column :label="''" v-if="currentTab !== 'Date'">
                <template slot-scope="scope">
                  <div :class="'action-cl'" disable-transitions>
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <img
                          class="dropdown-dot"
                          src="assets/imgs/dot-dropdown.svg"
                          alt=""
                        />
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          command="a"
                          @click.native="handleViewUser(scope.row)"
                          ><i class="el-icon-view"></i> View</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <spinner v-if="loading" />
          </div>
        </div>
      </div>

      <div ref="exportContent" style="min-height: 600px">
        <h3
          class="title-page mt-4 text-center"
          style="display: flex; align-items: center"
        >
          Event Financial Report
          <n-button
            type="primary azul"
            id="export-btn"
            data-html2canvas-ignore="true"
            style="margin-left: auto"
            @click.native="exportPDF"
            >Export as PDF</n-button
          >
        </h3>

        <div class="row mt-3">
          <div class="col-12 col-md-6 m-auto">
            <doughnut
              v-if="chartDataFinancial"
              :data="chartDataFinancial"
              :options="optionsFinancial"
              :style="{
                height: '255px',
              }"
            />
          </div>
          <div class="col-12 col-md-6 m-auto">
            <div
              class="card card-stats card-raised bg-white rounded-lg shadow-lg mb-0"
            >
              <div
                class="card-body p-0 table-card"
                v-if="data && data.total_tokens_created"
              >
                <h4 class="title-page title-small mt-0">Income</h4>
                <div class="wrap-row">
                  <div class="lb">Tokens Sold</div>
                  <span class="lb-value">
                    <animated-number
                      :value="data.total_tokens_created"
                    ></animated-number
                  ></span>
                </div>

                <!-- <div class="wrap-row">
                <div class="lb">Token Value</div>
                <span class="lb-value">
                  <span class="lb-value">
                    $<animated-number
                      :value="event.token_value"
                    ></animated-number></span
                ></span>
              </div> -->

                <div class="wrap-row">
                  <div class="lb">Token Revenue</div>
                  <span class="lb-value">
                    <span class="lb-value">
                      $<animated-number
                        :value="data.total_tokens_created * event.token_value"
                      ></animated-number></span
                  ></span>
                </div>

                <h4 class="title-page title-small mt-0">Expenses</h4>
                <div class="wrap-row">
                  <div class="lb">Refunds</div>
                  <span class="lb-value">
                    <animated-number
                      :value="
                        data.total_tokens_created -
                        data.event_open_token_balance
                      "
                    ></animated-number
                  ></span>
                </div>

                <div class="wrap-row">
                  <div class="lb">Card Processing Fees</div>
                  <span class="lb-value">
                    <span class="lb-value">
                      $0
                      <!-- <animated-number :value="0"></animated-number> -->
                    </span></span
                  >
                </div>

                <div class="wrap-row">
                  <div class="lb">Taxes (13%)</div>
                  <span class="lb-value">
                    $0<!-- <animated-number
                    :value="
                      (data.total_tokens_refunded -
                        data.event_open_token_balance) *
                      event.token_value
                    "
                  ></animated-number
                  > --></span
                  >
                </div>

                <div class="wrap-row">
                  <div class="lb">EventPay Fees (6%)</div>
                  <span class="lb-value">
                    <span class="lb-value">
                      $0
                      <!-- <animated-number :value="0"></animated-number> -->
                    </span></span
                  >
                </div>

                <!-- <div class="wrap-row">
                <div class="lb">Processing Fee</div>
                <span class="lb-value"> -$ </span>
              </div> -->
                <!-- <div class="wrap-row">
                <div class="lb">Taxes (13%)</div>
                <span class="lb-value"> 18,420 </span>
              </div> -->
                <!-- <div class="wrap-row">
                <div class="lb">EventPay Fee</div>
                <span class="lb-value"> -$ </span>
              </div> -->

                <h4 class="title-page title-small">Profit</h4>
                <div class="wrap-row">
                  <div class="lb">Event Profit</div>
                  <span class="lb-value">
                    $<animated-number
                      :value="
                        (data.total_tokens_created -
                          data.event_open_token_balance) *
                        event.token_value
                      "
                    ></animated-number>
                  </span>
                </div>
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
import {
  TabPane,
  Tabs,
  AnimatedNumber,
  DatePickerDropdown,
} from "src/components";
import _ from "lodash";
import "chart.js/auto";
import { Doughnut } from "vue-chartjs";
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  formatRevenue,
  formatRevenueToken,
  formatRevenueValue,
} from "@/util/string.js";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import "chartjs-adapter-moment";
import EventMixin from "@/mixins/Event.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

ChartJS.defaults.font.weight = 600;

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "8px";
    tooltipEl.style.color = "#021B43";
    tooltipEl.style.padding = "15px";
    tooltipEl.style.boxShadow = "0 0 12px 2px rgba(231,234,243,0.30)";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.minWidth = "180px";
    tooltipEl.classList.add("arrow-panel");

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tooltip._tooltipItems.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;
      tr.style.borderWidth = 0;
      tr.style.fontFamily = '"Inter", sans-serif;';
      tr.style.fontSize = "12px";
      tr.style.marginTop = "8px";

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(
        body.formattedValue + " Tokens via " + body.dataset.label
      );

      // td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left =
    tooltip.x <= 100
      ? 142 + "px"
      : positionX +
        (tooltip.x + tooltip.width + 30 < chart.canvas.offsetWidth ? 0 : -90) +
        tooltip.caretX +
        "px";

  tooltipEl.style.top = -positionY + tooltip.y - 42 + "px";
  //  -
  // (tooltip.y + tooltip.height + 120 < chart.canvas.offsetHeight ? 0 : -90) +
  // tooltip.caretY +

  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";

  tooltipEl.classList.remove("right");
  if (tooltip.x + tooltip.width + 100 >= chart.canvas.offsetWidth) {
    tooltipEl.classList.add("right");
  }
  tooltipEl.classList.remove("left");
  if (tooltip.x <= 100) {
    tooltipEl.classList.add("left");
  }

  if (tooltip.y + tooltip.height + 30 >= chart.canvas.offsetHeight) {
    tooltipEl.classList.add("bottom");
  }
};

export default {
  components: {
    Spinner,
    TabPane,
    Tabs,
    Doughnut,
    AnimatedNumber,
    LineChartGenerator,
    DatePickerDropdown,
  },
  mixins: [EventMixin],
  computed: {
    event() {
      console.log(this.$store.state.allEvents);
      return this.$store.getters.allEvents
        ? this.$store.getters.allEvents.find(
            (item) => item.id == parseInt(this.$route.params.id)
          )
        : null;
    },
    fees() {
      console.log(this.data.total_card_amount, this.event.fee);
      return (this.data.total_card_amount * this.event.fee).toFixed(2);
    },
    tax() {
      return (this.data.total_card_amount * this.event.tax).toFixed(2);
    },
    currentTabData() {
      return this.data[this.tabs[this.currentTab]];
    },
  },
  data() {
    return {
      chartDataFinancial: null,
      optionsFinancial: {
        cutout: 70,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return "$" + context.formattedValue;
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              padding: 30,
            },
          },
        },
      },
      chartData: null,
      chartData2: null,
      options: null,
      data: {},
      loading: false,
      currentChartTab: "Tokens Purchased",
      tabsChart: {
        "Tokens Purchased": "tokens_purchased",
        "Tokens Spent": "tokens_spent",
      },
      currentTab: "User",
      tabs: {
        Date: "by_date",
        User: "by_users",
        Support: "by_support",
      },
      titles: [
        // {
        //   prop: "from_user_id",
        //   label: this.currentTab === "Date" ? "Date" : "User",
        //   formatter: (item) => {
        //     if (item.user__first_name) {
        //       return item.user__first_name + " " + item.user__last_name;
        //     } else {
        //       return item.timestamp__date;
        //     }
        //   },
        // },
        {
          prop: "tokens_purchased",
          label: "Tokens Purchased",
          formatter: (row, col, cell, index) => {
            const balance = row.tokens_purchased;
            const revenue = balance * this.event.token_value;
            let value = [(balance || 0) + "", (revenue || 0) + ""];
            if (balance < 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #f27474">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #f27474">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (balance > 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #2d61d3">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #2d61d3">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (this.currentPriceType === "token") {
              return (
                <div>
                  <span>{value[0].replace("-", "")}</span>
                </div>
              );
            }
            return (
              <div>
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          },
        },
        {
          prop: "tokens_spent",
          label: "Tokens Spent",
          formatter: (row, col, cell, index) => {
            const balance = row.tokens_spent;
            const revenue = balance * this.event.token_value;
            let value = [(balance || 0) + "", (revenue || 0) + ""];
            if (balance < 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #f27474">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #f27474">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (balance > 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #2d61d3">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #2d61d3">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (this.currentPriceType === "token") {
              return (
                <div>
                  <span>{value[0].replace("-", "")}</span>
                </div>
              );
            }
            return (
              <div>
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          },
        },
        {
          prop: "avg_tokens_spent",
          label: "Token Balance",
          formatter: (row, col, cell, index) => {
            const balance = row.tokens_purchased + row.tokens_spent;
            const revenue = balance * this.event.token_value;
            let value = [(balance || 0) + "", (revenue || 0) + ""];
            if (balance < 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #f27474">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #f27474">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (balance > 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #2d61d3">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #2d61d3">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (this.currentPriceType === "token") {
              return (
                <div>
                  <span>{value[0].replace("-", "")}</span>
                </div>
              );
            }
            return (
              <div>
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          },
        },
      ],
      titlesSupport: [
        {
          prop: "from_user_id",
          label: "User",
          formatter: (item) => {
            return item.from_user__first_name + " " + item.from_user__last_name;
          },
        },
        {
          prop: "tokens_purchased",
          label: "Tokens Purchased",
          formatter: (row, col, cell, index) => {
            const balance = row.tokens_purchased;
            const revenue = balance * this.event.token_value;
            let value = [(balance || 0) + "", (revenue || 0) + ""];
            if (balance < 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #f27474">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #f27474">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (balance > 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #2d61d3">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #2d61d3">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (this.currentPriceType === "token") {
              return (
                <div>
                  <span>{value[0].replace("-", "")}</span>
                </div>
              );
            }
            return (
              <div>
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          },
        },
        {
          prop: "tokens_spent",
          label: "Tokens Spent",
          formatter: (row, col, cell, index) => {
            const balance = row.tokens_spent;
            const revenue = balance * this.event.token_value;
            let value = [(balance || 0) + "", (revenue || 0) + ""];
            if (balance < 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #f27474">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #f27474">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (balance > 0) {
              if (this.currentPriceType === "token") {
                return (
                  <div style="color: #2d61d3">
                    <span>{value[0].replace("-", "")}</span>{" "}
                  </div>
                );
              }
              return (
                <div style="color: #2d61d3">
                  <span>{value[1].replace("-", "")}</span>
                </div>
              );
            }

            if (this.currentPriceType === "token") {
              return (
                <div>
                  <span>{value[0].replace("-", "")}</span>
                </div>
              );
            }
            return (
              <div>
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          },
        },
        {
          prop: "from_user__email",
          label: "Email",
        },
      ],
      filters: [
        {
          prop: "user__first_name",
          value: "",
        },
        {
          prop: "from_user__first_name",
          value: "",
        },
        {
          prop: "timestamp__date",
          value: "",
        },
      ],
      queryInfo: {},
      rangeTime: null,
      rawDateRange: null,
      timer: null,
      currentPriceType: "token",
    };
  },
  methods: {
    onReset() {
      this.options = null;
      this.rangeTime = [];
      this.$refs.datepicker.onReset();
      setTimeout(() => {
        this.init();
      }, 100);
    },
    onUpdateDate(daterange) {
      this.rangeTime = daterange;
      this.init();
    },
    exportPDF() {
      domtoimage
        .toPng(this.$refs.exportContent, {
          filter: (node) => node.id !== "export-btn",
        })
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF({
            orientation: "portrait",
            // // unit: "pt",
            format: [500, 600],
          });
          doc.addImage(img, "JPEG", 20, 20);
          const date = new Date();
          const filename =
            "Financial_Report" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
          doc.save(filename);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
    handleViewUser(row) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/users/?userId=${row.user__id}`,
      });
    },
    handleView(row) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/users/?userId=${row.from_user__id}`,
      });
    },
    onTabChartChange(tab) {
      this.currentChartTab = tab;
    },
    onTabChange(tab) {
      this.currentTab = tab;
    },
    async init() {
      this.options = null;
      const params = {
        expand: "user",
      };
      if (this.rangeTime && this.rangeTime.length > 0) {
        params["created_at__gte"] = moment(this.rangeTime[0])
          .startOf("day")
          .format();
        params["created_at__lte"] = moment(this.rangeTime[1])
          .endOf("day")
          .format();
      }
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/financials`,
        { params }
      );
      this.data = response.data;
      this.chartDataFinancial = {
        labels: ["Expenses", "Profit"],
        datasets: [
          {
            backgroundColor: ["#2D61D3", "#59AF63"],
            data: [
              this.data.total_tokens_created * this.event.token_value -
                (this.data.total_tokens_created -
                  this.data.event_open_token_balance) *
                  this.event.token_value,
              (this.data.total_tokens_created -
                this.data.event_open_token_balance) *
                this.event.token_value,
            ],
            borderWidth: 0,
          },
        ],
      };
      let chartColor = "#2d61d3"; //"rgba(45, 97, 211, 0.25)";

      const ctx = document.getElementById("chart").getContext("2d");
      const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#2d61d3");
      gradientStroke.addColorStop(1, chartColor);

      const gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      const whitefill = ctx.createLinearGradient(0, 200, 0, 50);
      whitefill.addColorStop(0, "rgba(255, 255, 255, 0.5)");
      gradientFill.addColorStop(1, "rgba(89, 154, 235, 0.8)");
      gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");

      this.chartData = {
        labels: response.data.by_date.map((item) => item.timestamp__date),
        datasets: [
          {
            label: "Tokens Spent",
            // backgroundColor: gradientFill,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2d61d3",
            pointHoverBackgroundColor: "#2d61d3",
            borderColor: "#2d61d3",
            pointHoverBorderColor: chartColor,
            pointBorderWidth: 2,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 3,
            pointRadius: 4,
            // fill: true,
            borderWidth: 2,
            lineTension: 0,
            data: response.data.by_date.map((item) => {
              if (!item.tokens_spent) return 0;
              return Math.abs(item.tokens_spent);
            }),
            type: "line",
          },
        ],
      };

      this.chartData2 = {
        labels: response.data.by_date.map((item) => item.timestamp__date),
        datasets: [
          {
            type: "line",
            label: "Tokens Purchased",
            // backgroundColor: gradientFill,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2d61d3",
            pointHoverBackgroundColor: "#2d61d3",
            borderColor: "#2d61d3",
            pointBorderWidth: 2,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 3,
            pointRadius: 4,
            lineTension: 0,
            // fill: true,
            borderWidth: 2,
            data: response.data.by_date.map((item) => {
              if (!item.tokens_purchased) return 0;
              return item.tokens_purchased;
            }),
          },
        ],
      };
      this.options = {
        interaction: {
          intersect: false,
          mode: "index",
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 40,
            bottom: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: undefined,
            gridLines: {
              zeroLineColor: "transparent",
              display: false,
              color: "#fff",
            },
            grid: {
              display: false,
            },
            distribution: "series",
            ticks: {
              padding: 10,
              maxRotation: 0,
              autoSkip: true,
              maxTicksLimit: 10,
              color: "#6B7280",
              font: {
                weight: 400,
                size: 14,
                family: '"Inter", sans-serif;',
              },
              callback: function (label, index, labels) {
                const lb = this.getLabelForValue(label);
                if (self.groupType === "hour") {
                  return lb;
                }
                return moment(lb).format("ddd DD");
              },
            },
          },
          y: {
            ticks: {
              fontColor: "#8e8e93",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 10,
              color: "#6B7280",
              font: {
                weight: 400,
                size: 14,
                family: '"Inter", sans-serif;',
              },
            },
            gridLines: {
              drawTicks: true,
              drawBorder: false,
              display: true,
              borderDash: [4, 2],
              color: "#bdbdbd",
              zeroLineColor: "transparent",
            },
          },
        },
        plugins: {
          decimation: {
            algorithm: "lttb",
            enabled: true,
            samples: 10,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: (event) => externalTooltipHandler(event, this),
          },
        },
      };

      this.loading = false;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  async mounted() {
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }

    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.init();
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.init();
      }, 10000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
    this.loading = true;
    this.init();
  },
};
</script>
