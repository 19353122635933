<template>
  <Spinner v-if="!event || !data" />
  <div id="financials" v-else>
    <div class="no-tab-content-inside">
      <h3 class="title-page">
        <div class="d-flex align-items-center">
          <div class="me-2">Sales</div>
        </div>
        <div
          class="wrap-datepicker"
          style="top: 6px !important; display: flex; width: 530px; gap: 10px"
        >
          <el-date-picker
            :editable="false"
            v-model="date"
            type="daterange"
            prefix-icon="el-icon-arrow-down"
            :align="'right'"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :format="'MMM dd'"
            :clearable="false"
            @change="filterData"
            style="width: 100%"
          >
          </el-date-picker>
          <div class="form-group form-event-wrap w-100 md-sl">
            <el-select
              style="width: 100%"
              v-model="vendorEvent"
              placeholder="All Booths"
              @change="filterData"
            >
              <el-option label="All Booths" value=""> </el-option>
              <el-option
                v-for="item in vendorEvents"
                :key="item.id"
                :label="item.booth"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form-group form-event-wrap w-100 md-sl">
            <el-select
              style="width: 100%"
              v-model="aggregationBy"
              placeholder="Aggregate By"
              @change="filterData"
            >
              <el-option label="Day" value="day"> </el-option>
              <el-option label="Hour" value="hour"> </el-option>
              <el-option label="Month" value="month"> </el-option>
            </el-select>
          </div>
        </div>
      </h3>

      <tabs type="primary" v-model="tab" v-if="data">
        <tab-pane
          label="Sales By Date"
          :badge="data.listDate ? data.listDate.length : 0"
        >
        </tab-pane>
        <tab-pane
          label="Sales By items"
          :badge="data.top_items ? data.top_items.length : 0"
        >
        </tab-pane>
      </tabs>
      <div class="tab-content-outside">
        <template v-if="tab === 'Sales By Date'">
          <el-table
            v-if="!loading && data"
            class="event-table"
            :data="data.listDate"
            :total="data.listDate.length"
            :pagination-props="{
              layout: 'slot',
            }"
            show-summary
            :summary-method="getSummaries"
            @sort-change="onSortChange"
          >
            <span slot="pagination"> </span>

            <el-table-column type="expand">
              <template slot-scope="props" v-if="props.row.hasChildren">
                <table class="el-table__body" style="width: 100%">
                  <tbody>
                    <tr v-for="(item, y) in props.row.items" :key="y">
                      <td class="el-table__cell" style="width: 46px">
                        <div class="cell"></div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          {{ item.items__vendor_event_item__name }}
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          {{ item.count }}
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          {{ item.count * item.items__price_at_ordertime }}
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          {{ (item.count * item.items__price_at_ordertime ) * event.token_value }}
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          {{ item.num_orders }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </el-table-column>

            <el-table-column
              v-for="title in salesByDateColumns"
              :prop="title.prop"
              :label="title.label"
              :key="title.prop"
              sortable
              :minWidth="title.prop === 'total' ? 100 : 130"
              :formatter="title.formatter"
            >
            </el-table-column>
          </el-table>
        </template>
        <template v-if="tab === 'Sales By items'">
          <data-tables-server
            v-if="!loading && data && data.top_items"
            class="event-table"
            :data="data.top_items"
            :total="data.top_items.length"
            @sort-change="onSortChange"
            :pagination-props="{
              layout: 'slot',
            }"
          >
            <span slot="pagination"> </span>

            <el-table-column
              v-for="title in salesByItemsColumns"
              :prop="title.prop"
              :label="title.label"
              :key="title.prop"
              :minWidth="title.prop === 'total' ? 100 : 130"
              :formatter="title.formatter"
            >
            </el-table-column>
          </data-tables-server>
        </template>
      </div>
    </div>

    <!-- <card>
      <h3>Sales By Date</h3>
      <table class="table table-hover">
        <tr>
          <th>Item</th>
          <th>Items Sold</th>
          <th>Tokens</th>
          <th>Revenue</th>
          <th>{{ event.currency_symbol }} Revenue</th>
          <th>Orders</th>
        </tr>
        <tbody
          v-for="(date_container, date) in data.top_date"
          :key="x"
          @click="date_container.show = !date_container.show"
        >
          <tr class="date-header">
            <th>{{ date }}</th>
            <th>
              {{ date_container.totals.count }}
              <percentage
                v-if="date_container.totals.percentages"
                :value="date_container.totals.percentages.items_ordered"
              />
            </th>
            <th></th>
            <th>
              {{ date_container.totals.amount }}
              <percentage
                v-if="date_container.totals.percentages"
                :value="date_container.totals.percentages.amount"
              />
            </th>
            <th>
              {{ date_container.totals.cash_amount }}
              <percentage
                v-if="date_container.totals.percentages"
                :value="date_container.totals.percentages.cash_amount"
              />
            </th>
            <th>
              {{ date_container.totals.num_orders }}
              <percentage
                v-if="date_container.totals.percentages"
                :value="date_container.totals.percentages.num_orders"
              />
            </th>
          </tr>
          <tr
            v-for="(item, y) in date_container.items"
            :key="y"
            v-if="date_container.show"
          >
            <td>{{ item.items__vendor_event_item__name }}</td>
            <td>{{ item.count }}</td>
            <td>{{ item.items__price_at_ordertime }}</td>
            <td>{{ item.items__price_at_ordertime * item.count }}</td>
            <td>
              {{
                item.items__price_at_ordertime * item.count * event.token_value
              }}
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
        <tr>
          <th>Total</th>
          <th>{{ data.top_items_total.count }}</th>
          <th>&nbsp;</th>
          <th>{{ data.top_items_total.amount }}</th>
          <th>{{ data.top_items_total.cash_amount }}</th>
          <th>{{ data.top_items_total.orders }}</th>
        </tr>
      </table>
    </card> -->
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
import Percentage from "src/components/Percentage";
import { TabPane, Tabs } from "src/components";
import { props } from "@chenfengyuan/vue-qrcode";
export default {
  components: {
    Spinner,
    TabPane,
    Tabs,
    Percentage,
  },
  computed: {
    event() {
      console.log(this.$store.state.allEvents);
      return this.$store.getters.allEvents
        ? this.$store.getters.allEvents.find(
            (item) => item.id == parseInt(this.$route.params.id)
          )
        : null;
    },
    fees() {
      console.log(this.data.total_card_amount, this.event.fee);
      return (this.data.total_card_amount * this.event.fee).toFixed(2);
    },
    tax() {
      return (this.data.total_card_amount * this.event.tax).toFixed(2);
    },
  },
  data() {
    return {
      currentPriceType: "token",
      timer: null,
      tab: "Sales By Date",
      data: {},
      date: {},
      vendorEvent: "",
      vendorEvents: [],
      aggregationBy: "day",
      currentSort: {},
      salesByDateColumns: [
        {
          prop: "date",
          label: "Item",
        },
        {
          prop: "count",
          label: "Items Sold",
          formatter: (item) => {
            return item.count;
          },
        },
        {
          prop: "amount",
          label: "Tokens",
          formatter: (item) => {
            return item.amount;
          },
        },
        {
          prop: "cash_amount",
          label: "$ Revenue",
          formatter: (item) => {
            return "$" + item.cash_amount;
          },
        },
        {
          prop: "num_orders",
          label: "Orders",
          formatter: (item) => {
            return item.num_orders;
          },
        },
      ],
      salesByItemsColumns: [
        {
          prop: "items__vendor_event_item__name",
          label: "Item",
          formatter: (item) => {
            return item.items__vendor_event_item__name;
          },
        },
        {
          prop: "count",
          label: "Items Sold",
        },
        {
          prop: "items__price_at_ordertime",
          label: "Tokens",
        },
        {
          prop: "total",
          label: "Token Revenue",
          formatter: (item) => {
            return "$" + item.items__price_at_ordertime * item.count;
          },
        },
      ],
    };
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  async mounted() {
    this.fetchVendorEvents();
    this.filterData();

    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.fetchVendorEvents();
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.fetchVendorEvents();
      }, 10000);
    } else {
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
  },
  methods: {
    sorted(data) {
      return data.sort((a, b) => {
        if (this.currentSort.prop && this.currentSort.order) {
          let sortfield = "";
          if (this.currentSort.prop === "date") {
            sortfield = "items__vendor_event_item__name";
          }
          if (this.currentSort.prop === "amount") {
            sortfield = "items__price_at_ordertime";
          }
          if (this.currentSort.prop === "count") {
            sortfield = "count";
          }

          let dataA = a[sortfield];
          let dataB = b[sortfield];

          if (this.currentSort.prop === "cash_amount") {
            dataA = a.items__price_at_ordertime * a.count;
            dataB = b.items__price_at_ordertime * b.count;
          }

          if (this.currentSort.prop === "num_orders") {
            dataA =
              a.items__price_at_ordertime * a.count * this.event.token_value;
            dataB =
              b.items__price_at_ordertime * b.count * this.event.token_value;
          }
          if (!dataA) {
            dataA = "";
          }
          if (!dataB) {
            dataB = "";
          }

          dataA = dataA + "";
          dataB = dataB + "";

          if (this.currentSort.order === "ascending") {
            return dataA.localeCompare(dataB);
          }
          return dataB.localeCompare(dataA);
        }
        return 0;
      });
    },
    onSortChange({ column, prop, order }) {
      this.currentSort = {
        prop,
        order,
      };
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        }
        if (index === 1) {
          sums[index] = " Total";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    fetchVendorEvents() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?event=${this.$route.params.id}&expand=vendor`;

      if (this.$store.state.user.type == "vendor") {
        url += `&vendor=${this.$store.state.user.user_type.vendor.id}`;
      } else if (
        this.$route.params.vendorId &&
        this.$store.state.user.type == "org-administrator"
      ) {
        url += `&vendor=${this.$route.params.vendorId}`;
      }

      axios.get(url).then((response) => {
        this.vendorEvents = response.data.results;
        this.loading = false;
      });
    },
    async filterData() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/vendor-financials/`;
      const params = { vendor_event_id: this.vendorEvent };
      if (this.date) {
        params["start"] = this.date[0];
        params["end"] = this.date[1];
      }

      if (
        this.$route.params.vendorId &&
        this.$store.state.user.type == "org-administrator"
      ) {
        params["vendor_id"] = this.$route.params.vendorId;
      }

      if (this.aggregationBy) {
        params["aggregation_by"] = this.aggregationBy;
      }

      const response = await axios.get(url, { params });
      let counter = 0;
      Object.keys(response.data.top_date).forEach((item) => {
        response.data.top_date[item].show = counter++ == 0;
      });
      this.data = response.data;
      this.data.listDate = [];
      Object.keys(this.data.top_date).forEach((item) => {
        this.data.listDate.push({
          date: item,
          amount: this.data.top_date[item].totals.amount,
          cash_amount: this.data.top_date[item].totals.cash_amount,
          count: this.data.top_date[item].totals.count,
          num_orders: this.data.top_date[item].totals.num_orders,
          items: this.data.top_date[item].items,
          orders: this.data.top_date[item].orders,
          hasChildren: true,
        });
      });
      window.eventBus.$emit("receivedCounts", response);
    },
  },
};
</script>
<style lang="scss">
tr {
  &.date-header {
    background-color: #cdcdcd;
    cursor: pointer;
  }
}
#financials {
  .el-input {
    input {
      margin: 0;
    }
  }
}
</style>
