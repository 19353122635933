var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper mx-auto max-w-screen-2xl",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"user":_vm.user},scopedSlots:_vm._u([{key:"organizer",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.currentEvent ? _vm.currentEvent.name : 'Events',
          icon: 'assets/imgs/events-icon.svg',
          iconActive: 'assets/imgs/events-icon-active.svg',
          path: `/pages/events`,
        }}}),(_vm.user.type === 'org-administrator')?_c('sidebar-item',{attrs:{"link":{
          name: 'Accounts',
          icon: 'assets/imgs/accounts-icon.svg',
          iconActive: 'assets/imgs/accounts-icon-active.svg',
          path: `/pages/accounts`,
        }}}):_vm._e(),_c('user-menu')]}}])},[_c('template',{slot:"event"},[_c(_vm.userMenu,{tag:"component"})],1)],2),_c('div',{staticClass:"lg:pl-72"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"ctn-wr p-4 sm:px-6 sm:pb-10 sm:pt-10 lg:px-10 lg:pt-7"},[_c('top-navbar'),_c('div',{class:{
            content: !_vm.$route.meta.hideContent,
            'mt-0': _vm.$route.meta.noHeader,
          },on:{"click":_vm.toggleSidebar}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }