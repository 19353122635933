<template>
  <div
    class="wrapper mx-auto max-w-screen-2xl"
    :class="{ 'nav-open': $sidebar.showSidebar }"
  >
    <notifications></notifications>
    <side-bar :user="user">
      <template slot="event">
        <component :is="userMenu" />
      </template>

      <template slot-scope="props" slot="organizer">
        <sidebar-item
          :link="{
            name: currentEvent ? currentEvent.name : 'Events',
            icon: 'assets/imgs/events-icon.svg',
            iconActive: 'assets/imgs/events-icon-active.svg',
            path: `/pages/events`,
          }"
        >
          <!--         
        <sidebar-item
            
            :link="{
              name: 'Change Event',
              icon: 'el-icon-coffee',
              path: `/pages/events`,
              isIcon: true
            }"
          ></sidebar-item> -->
        </sidebar-item>

        <sidebar-item
          v-if="user.type === 'org-administrator'"
          :link="{
            name: 'Accounts',
            icon: 'assets/imgs/accounts-icon.svg',
            iconActive: 'assets/imgs/accounts-icon-active.svg',
            path: `/pages/accounts`,
          }"
        >
        </sidebar-item>

        <user-menu></user-menu>
      </template>
    </side-bar>
    <div class="lg:pl-72">
      <div class="relative">
        <div class="ctn-wr p-4 sm:px-6 sm:pb-10 sm:pt-10 lg:px-10 lg:pt-7">
          <top-navbar></top-navbar>

          <div
            :class="{
              content: !$route.meta.hideContent,
              'mt-0': $route.meta.noHeader,
            }"
            @click="toggleSidebar"
          >
            <router-view></router-view>
          </div>

          <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import { CollapseTransition } from "vue2-transitions";
import UserMenu from "src/pages/Dashboard/Layout/Extra/UserMenu";

import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";
import User from "src/mixins/User";
import Event from "src/mixins/Event";
import OrgAdministratorAdminMenu from "src/pages/Dashboard/Menus/OrgAdministratorMenu";
import VendorAdminMenu from "src/pages/Dashboard/Menus/VendorAdminMenu";
import CashAdminMenu from "src/pages/Dashboard/Menus/CashAdminMenu";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SlideYDownTransition,
    ZoomCenterTransition,
    OrgAdministratorAdminMenu,
    VendorAdminMenu,
    CashAdminMenu,
    CollapseTransition,
    UserMenu,
  },
  computed: {
    userMenu() {
      console.log(this.$store.state.user);
      if (this.$store.state.user) {
        return `${this.$store.state.user.type}-admin-menu`;
      }
      return null;
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
    this.$store.dispatch("getEvents");
  },
  mixins: [User, Event],
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
