<template>
  <card
    v-on:click="$emit('clicked', user)"
    class="card-chart event-card"
    no-footer-line
  >
    <div v-on:click="$emit('clicked', user)">
      <div class="d-flex">
        <div class="img-sec">
          <img v-if="user.image" :src="user.image" />
          <div class="circle-placeholder" v-else>
            {{ avatar_word }}
          </div>
        </div>
        <div class="des-sec">
          <p class="card-category">
            <span>{{ user.first_name }} {{ user.last_name }}</span>
          </p>
          <drop-down v-if="showLinks" :hide-arrow="true" position="right">
            <n-button
              slot="title"
              class="dropdown-toggle no-caret"
              round
              simple
              icon
            >
              <img
                class="dropdown-dot"
                src="assets/imgs/dot-dropdown.svg"
                alt=""
              />
            </n-button>
            <a class="dropdown-item" href="javascript:void(0)" @click="onEdit">Edit</a>
          </drop-down>
          <div slot="footer" class="stats small-stats pb-2">
            {{ title }}
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import { Card } from "src/components";
export default {
  components: {
    Card,
  },
  props: ["user", "showLinks", "title", "avatar_word"],
  methods: {
    onEdit() {
      this.$emit('onEdit', this.user)
    }
  }
};
</script>

<style scoped lang="scss">
.stats {
  color: #8e8e93;
}
.card-chart .dropdown {
  top: 20px;
  right: 10px;
}
.img-sec {
  margin-right: 15px;
  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
}

.circle-placeholder {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #c8ddff;
  color: #fff;
}

.des-sec {
  width: calc(100% - 120px);
}
</style>
