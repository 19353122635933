<template>
  <div class="preview-upload">
    <input type="file" v-on:change="read" />
    <div class="image-preview"><img :src="preview" /></div>
  </div>
</template>
<script>
export default {
  name: "PreviewUpload",
  data() {
    return { preview: null };
  },
  components: {},
  methods: {
    read(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.$emit("input", path);
        const reader = new FileReader();

        reader.onload = e => {
          self.preview = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    }
  },
  mounted() {
    console.log("Mounted");
  }
};
</script>
<style></style>
