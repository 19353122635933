<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-event" v-if="formIndex == 3">
        <div class="left-navigation">
          <h4>User Reload</h4>
          <ul>
            <li><UserGroupIcon />Select User & Reload</li>
            <li><WalletIcon />Payment Information</li>
            <li class="active"><CicleCheckIcon />Reload Confirmation</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><KeyIcon />Reload Confirmation</h3>
              <h3><UserIcon width="15px" height="15px" />Reload User</h3>
              <div class="row">
                <div class="col-xs-12">
                  <div class="wrap-infor-flex items-3">
                    <div class="flex-item">
                      <span class="type-m">Name</span>
                      <span class="value-m"
                        >{{ rawUserReload.reload_user.first_name }}
                        {{ rawUserReload.reload_user.last_name }}</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Phone</span>
                      <span
                        class="value-m"
                        style="text-transform: capitalize"
                        >{{ rawUserReload.reload_user.phone_number }}</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Email</span>
                      <span class="value-m">{{
                        rawUserReload.reload_user.email
                      }}</span>
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Reload Amount</span>
                      <span class="value-m"
                        >{{ rawUserReload.amount }} Tokens</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Initial Balance</span>
                      <span class="value-m"
                        >{{ rawUserReload.reload_user.balance }} Tokens</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">New Balance</span>
                      <span class="value-m"
                        >{{
                          parseFloat(rawUserReload.amount) +
                          parseFloat(rawUserReload.reload_user.balance)
                        }}
                        Tokens</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-xl-8">
                  <template>
                    <h3 class="mt-5">
                      <svg
                        width="16.864px"
                        height="16.864px"
                        viewBox="0 0 16.864 16.864"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="🔃-Reload"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Reload-Tokens---Step-3---Reload-Confirmation-Successful"
                            transform="translate(-565, -381)"
                          >
                            <rect
                              fill="#F4F5F7"
                              x="0"
                              y="0"
                              width="1440"
                              height="780"
                            ></rect>
                            <g
                              id="Group-13"
                              transform="translate(250, 144)"
                              fill="#59AF63"
                              fill-rule="nonzero"
                            >
                              <g id="Group-9" transform="translate(315, 235)">
                                <g
                                  id="check-circle"
                                  transform="translate(0, 2)"
                                >
                                  <path
                                    d="M8.432,0 C3.775122,0 0,3.775122 0,8.432 C0,13.088878 3.775122,16.864 8.432,16.864 C13.088878,16.864 16.864,13.088878 16.864,8.432 C16.864,3.775122 13.088878,0 8.432,0 Z M8.432,1.632 C12.190088,1.632 15.232,4.673334 15.232,8.432 C15.232,12.190088 12.190666,15.232 8.432,15.232 C4.673912,15.232 1.632,12.190666 1.632,8.432 C1.632,4.673912 4.673334,1.632 8.432,1.632 M13.198936,6.061078 L12.432712,5.288666 C12.274034,5.128696 12.015702,5.127642 11.855732,5.286354 L7.049764,10.053698 L5.016836,8.00428 C4.858158,7.84431 4.599826,7.843256 4.439856,8.001934 L3.66741,8.768158 C3.50744,8.926836 3.506386,9.185168 3.665098,9.345172 L6.751652,12.456716 C6.91033,12.616686 7.168662,12.61774 7.328632,12.459028 L13.196658,6.638092 C13.356594,6.47938 13.357614,6.221048 13.198936,6.061078 L13.198936,6.061078 Z"
                                    id="Shape"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Transaction Details
                    </h3>

                    <div class="wrap-infor-flex flex-wrap">
                      <div
                        class="flex-item d-flex align-items-center justify-content-between flex-row w-50 pe-3"
                      >
                        <span class="type-m">Transaction #</span>
                        <span class="value-m mt-0">
                          <a
                            href="javascript:void(0)"
                            @click="onViewTransaction()"
                            >{{ responseReload?.id }}</a
                          >
                        </span>
                      </div>
                      <div
                        class="flex-item d-flex align-items-center justify-content-between flex-row w-50 ps-3"
                        v-if="rawUserReload.paymentType === 'card'"
                      >
                        <span class="type-m">Transaction Type</span>
                        <span class="value-m mt-0"> Card **** </span>
                      </div>

                      <div class="flex-item w-100 mt-3">
                        <span class="type-m">Transaction Details</span>
                        <span class="value-m mt-0">
                          Transaction successful.
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-xs-12 col-xl-4">
                  <h3 class="mt-5">Order Overview</h3>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="wrap-infor-flex flex-wrap mt-0">
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          style="
                            margin-bottom: 5px;
                            border-bottom: 1px solid #eaeaec;
                            padding-bottom: 5px;
                          "
                        >
                          <span class="type-m">Purchase</span>
                          <span class="value-m"
                            >{{ rawUserReload.amount }} Token</span
                          >
                        </div>
                        <!-- <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                        >
                          <span class="type-m">Subtotal</span>
                          <span class="value-m">$20.00</span>
                        </div>

                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType === 'Complimentary'"
                        >
                          <span class="type-m">Complimentary</span>
                          <span class="value-m">$20.00</span>
                        </div>

                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType !== 'Complimentary'"
                        >
                          <span class="type-m">Taxes</span>
                          <span class="value-m">$2.60</span>
                        </div>
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType !== 'Complimentary'"
                          style="
                            margin-bottom: 5px;
                            border-bottom: 1px solid #eaeaec;
                            padding-bottom: 5px;
                          "
                        >
                          <span class="type-m">Fees</span>
                          <span class="value-m">$1.00</span>
                        </div> -->
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                        >
                          <span class="type-m">Total Due</span>
                          <span class="value-m"
                            >${{
                              rawUserReload.amount * currentEvent.token_value
                            }}</span
                          >
                        </div>
                        <!--                         
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          style="
                            margin-bottom: 5px;
                            border-bottom: 1px solid #eaeaec;
                            padding-bottom: 5px;
                          "
                        >
                          <span class="type-m">Purchase</span>
                          <span class="value-m"
                            >{{ rawUserReload.amount }} Token</span
                          >
                        </div>
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                        >
                          <span class="type-m">Subtotal</span>
                          <span class="value-m">$0.00</span>
                        </div>

                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType === 'Complimentary'"
                        >
                          <span class="type-m">Complimentary</span>
                          <span class="value-m">$0.00</span>
                        </div>

                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType !== 'Complimentary'"
                        >
                          <span class="type-m">Taxes</span>
                          <span class="value-m">$0.00</span>
                        </div>
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType !== 'Complimentary'"
                          style="
                            margin-bottom: 5px;
                            border-bottom: 1px solid #eaeaec;
                            padding-bottom: 5px;
                          "
                        >
                          <span class="type-m">Fees</span>
                          <span class="value-m">$0.00</span>
                        </div>
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                        >
                          <span class="type-m">Total Due</span>
                          <span class="value-m">${{  }}</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row mt-3"> -->
                <!-- <div class="col-xs-12 col-lg-4">
                        <n-button
                          type="default ml-0 w-100"
                          @click.native="formIndex = 2"
                        >
                          Back
                        </n-button>
                      </div> -->
                <div class="col-xs-12 col-xl-8">
                  <n-button type="primary ml-0 w-100" @click.native="onClose()">
                    Done
                  </n-button>
                </div>
                <!-- <div class="col-xs-12 col-lg-4">
                        <n-button type="default ml-0 w-100">
                          Save PDF
                        </n-button>
                      </div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-event" v-if="formIndex == 2">
        <div class="left-navigation">
          <h4>Reload Token</h4>
          <ul>
            <li><UserGroupIcon />Select User & Reload</li>
            <li class="active"><WalletIcon />Payment Information</li>
            <li><CicleCheckIcon />Reload Confirmation</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><UserIcon width="15px" height="15px" />Reload User</h3>
              <div class="row">
                <div class="col-xs-12">
                  <div class="wrap-infor-flex items-3">
                    <div class="flex-item">
                      <span class="type-m">Name</span>
                      <span class="value-m"
                        >{{ rawUserReload.reload_user.first_name }}
                        {{ rawUserReload.reload_user.last_name }}</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Phone</span>
                      <span
                        class="value-m"
                        style="text-transform: capitalize"
                        >{{ rawUserReload.reload_user.phone_number }}</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Email</span>
                      <span class="value-m">{{
                        rawUserReload.reload_user.email
                      }}</span>
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Reload Amount</span>
                      <span class="value-m"
                        >{{ rawUserReload.amount }} Tokens</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">Initial Balance</span>
                      <span class="value-m"
                        >{{ rawUserReload.reload_user.balance }} Tokens</span
                      >
                    </div>
                    <div class="flex-item">
                      <span class="type-m">New Balance</span>
                      <span class="value-m">
                        {{
                          parseFloat(rawUserReload.amount) +
                          parseFloat(rawUserReload.reload_user.balance)
                        }}
                        Tokens</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <h3 class="mt-3"><WalletIcon />Payment Method</h3>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="wrap-list-input-gr">
                    <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Cash' ? 'active' : ''
                      }`"
                      @click.native="onClickPaymentType('Cash')"
                    >
                      Cash
                    </n-button>

                    <!-- <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Credit' ? 'active' : ''
                      }`"
                      @click.native="onClickPaymentType('Credit')"
                    >
                      Credit
                    </n-button>

                     <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Card' ? 'active' : ''
                      }`"
                      @click.native="onClickPaymentType('Card')"
                    >
                      Card
                    </n-button>
                    <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Complimentary'
                          ? 'active'
                          : ''
                      }`"
                      @click.native="onClickPaymentType('Complimentary')"
                    >
                      Complimentary
                    </n-button> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-xs-12 col-xl-6"
                  v-if="rawUserReload.paymentType !== 'Complimentary'"
                >
                  <template v-if="rawUserReload.paymentType === 'Cash'">
                    <h3 class="mt-5"><MoneyIcon />Cash Order</h3>

                    <!-- <div class="wrap-event-input">
                      <label>Cash Recieved</label>
                      <fg-input
                        v-model="rawUserReload.cashReceive"
                        name="email"
                        placeholder="Cash Received"
                        addon-left-icon="''"
                        addonLeftIconStyle="display: none"
                      >
                        <div
                          style="
                            padding: 5px 13px;
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            border: 1px solid #e3e3e3;
                            color: #8e8e93;
                          "
                          slot="addonLeftComp"
                          class="input-group-addon input-group-prepend"
                        >
                          <DollarIcon />
                        </div>
                      </fg-input>
                    </div> -->
                  </template>

                  <template v-if="rawUserReload.paymentType === 'Card'">
                    <h3 class="mt-5"><CardIcon />Card Order</h3>

                    <div class="row mb-3">
                      <div class="col-xs-12">
                        <n-button
                          :type="`default m-0 mt-2 w-100 ${
                            rawUserReload.isSelectedCard ? 'active' : ''
                          }`"
                          @click.native="
                            rawUserReload.isSelectedCard = true;
                            isShowNewCardForm = false;
                          "
                        >
                          Jason Edwards - VISA *2092
                        </n-button>
                      </div>
                      <div class="col-xs-12 mt-2">
                        <n-button
                          type="default ml-0 w-100"
                          @click.native="
                            isShowNewCardForm = true;
                            rawUserReload.isSelectedCard = false;
                          "
                        >
                          Other Card
                        </n-button>
                      </div>
                    </div>

                    <div class="wrap-border-pael" v-if="isShowNewCardForm">
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="wrap-event-input">
                            <label>Card</label>
                            <fg-input
                              v-model="cardForm.number"
                              name="number"
                              placeholder="1234 5678 9012 3456"
                            >
                            </fg-input>
                          </div>
                        </div>
                        <div class="col-xs-12 col-lg-4">
                          <div class="wrap-event-input">
                            <label>CV Code</label>
                            <fg-input
                              v-model="cardForm.cvc"
                              name="cvc"
                              placeholder="123"
                            >
                            </fg-input>
                          </div>
                        </div>
                        <div class="col-xs-12 col-lg-4">
                          <div class="wrap-event-input">
                            <label>Expiry Date</label>
                            <fg-input
                              v-model="cardForm.number"
                              name="expired_date"
                              placeholder="MM/YY"
                            >
                            </fg-input>
                          </div>
                        </div>
                        <div class="col-xs-12 col-lg-4">
                          <div class="wrap-event-input">
                            <label>Save Card</label>
                            <checkbox
                              v-model="cardForm.isSave"
                              class="mt-0"
                              name="savecard"
                              inlineStyle="margin-top: 0; top: -6px"
                            >
                            </checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-xs-12 col-xl-6">
                  <h3
                    class="mt-5"
                    v-if="rawUserReload.paymentType !== 'Complimentary'"
                  >
                    Order Overview
                  </h3>
                  <h3
                    class="mt-5"
                    v-if="rawUserReload.paymentType === 'Complimentary'"
                  >
                    <CaseIcon /> Complimentary
                  </h3>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="wrap-infor-flex flex-wrap">
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          style="
                            margin-bottom: 5px;
                            border-bottom: 1px solid #eaeaec;
                            padding-bottom: 5px;
                          "
                        >
                          <span class="type-m">Purchase</span>
                          <span class="value-m"
                            >{{ rawUserReload.amount }} Token</span
                          >
                        </div>
                        <!-- <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                        >
                          <span class="type-m">Subtotal</span>
                          <span class="value-m">$20.00</span>
                        </div>

                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType === 'Complimentary'"
                        >
                          <span class="type-m">Complimentary</span>
                          <span class="value-m">$20.00</span>
                        </div>

                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType !== 'Complimentary'"
                        >
                          <span class="type-m">Taxes</span>
                          <span class="value-m">$2.60</span>
                        </div>
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                          v-if="rawUserReload.paymentType !== 'Complimentary'"
                          style="
                            margin-bottom: 5px;
                            border-bottom: 1px solid #eaeaec;
                            padding-bottom: 5px;
                          "
                        >
                          <span class="type-m">Fees</span>
                          <span class="value-m">$1.00</span>
                        </div> -->
                        <div
                          class="flex-item d-flex align-items-center justify-content-between flex-row"
                        >
                          <span class="type-m">Total Due</span>
                          <span class="value-m"
                            >${{
                              rawUserReload.amount * currentEvent.token_value
                            }}</span
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xs-12 col-xl-6">
                          <n-button
                            type="default ml-0 w-100"
                            @click.native="formIndex = 1"
                          >
                            Back
                          </n-button>
                        </div>
                        <div class="col-xs-12 col-xl-6">
                          <n-button
                            type="primary ml-0 w-100"
                            :loading="processing"
                            @click.native="onAddToken()"
                          >
                            Complete Reload
                          </n-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-event" v-if="formIndex == 1">
        <div class="left-navigation">
          <h4>Reload</h4>
          <ul>
            <li class="active"><UserGroupIcon />Select User & Reload</li>
            <li><WalletIcon />Payment Information</li>
            <li><CicleCheckIcon />Reload Confirmation</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><UserIcon width="14px" />User to Reload</h3>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Reload User</strong>
                    </label>

                    <div class="d-flex">
                      <div class="form-group d-flex w-100">
                        <div
                          :style="'background: #f6f6f6;border-right: 0;padding: 7px 0 6px 15px;'"
                          class="input-group-addon input-group-prepend"
                        >
                          <i :class="'el-icon-search'"></i>
                        </div>
                        <el-autocomplete
                          class="form-control"
                          placeholder="Search by Name, Phone or Email"
                          style="border-radius: 0; border-left: 0"
                          v-model="rawUserReload.reload_user_modal"
                          :fetch-suggestions="querySearch"
                          @select="handleSelect"
                          value-key="displayName"
                          :popper-append-to-body="false"
                        >
                          <template slot-scope="{ item }">
                            <div
                              class="d-flex w-100 align-items-center justify-content-between"
                              style="padding: 10px; gap: 10px"
                            >
                              <div
                                class="value"
                                style="
                                  font-family: 'Inter';
                                  font-size: 14px;
                                  color: #021b43;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.first_name }} {{ item.last_name }}
                              </div>
                              <div
                                class="phone"
                                style="
                                  font-family: 'Inter';
                                  font-size: 12px;
                                  color: #8e8e93;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                "
                              >
                                {{ item.phone_number }}
                              </div>
                              <div
                                class="email"
                                style="
                                  font-family: 'Inter';
                                  font-size: 12px;
                                  color: #8e8e93;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.email }}
                              </div>
                            </div>
                          </template>
                        </el-autocomplete>
                      </div>
                      <n-button
                        style="
                          margin: 0;
                          margin-left: 15px;
                          white-space: nowrap;
                          height: 38.5px;
                        "
                        type="primary azul"
                        @click.native="isDisplayed = true"
                      >
                        Add User
                      </n-button>
                    </div>

                    <div
                      v-if="rawUserReload.reload_user"
                      style="padding: 10px; background: #f5f5f5"
                    >
                      <label>
                        <b>User Information</b>
                      </label>
                      <div class="d-flex">
                        <div class="d-flex flex-column w-100">
                          <label>
                            Name :
                            {{
                              (
                                rawUserReload.reload_user.first_name +
                                " " +
                                rawUserReload.reload_user.last_name
                              ).trim() !== ""
                                ? rawUserReload.reload_user.first_name +
                                  " " +
                                  rawUserReload.reload_user.last_name
                                : "N/A"
                            }}
                          </label>
                          <label>
                            Email : {{ rawUserReload.reload_user.email }}
                          </label>
                        </div>
                        <div class="d-flex flex-column w-100">
                          <label>
                            Phone : {{ rawUserReload.reload_user.phone_number }}
                          </label>
                          <label>
                            Balance: {{ rawUserReload.reload_user.balance }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h3 class="mt-3"><TokenIcon />Reload Amount</h3>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="wrap-list-input-gr">
                    <n-button
                      v-for="(item, i) in topup_amounts_array"
                      :key="i"
                      :type="`default m-0 mt-2 ${
                        rawUserReload.amount === item ? 'active' : ''
                      }`"
                      @click.native="onClickToken(item)"
                    >
                      {{ item }} Tokens
                    </n-button>

                    <n-button
                      :type="`default m-0 mt-2 ${isShowCustom ? 'active' : ''}`"
                      @click.native="onClickToken(0)"
                    >
                      Custom Amount
                    </n-button>

                    <div class="wrap-event-input mb-0" v-if="isShowCustom">
                      <label>Custom Amount</label>
                      <fg-input
                        v-model="rawUserReload.amount"
                        name="custom_amount"
                        placeholder="Custom Amount"
                      >
                      </fg-input>
                    </div>
                  </div>
                </div>
              </div>

              <h3 class="mt-5"><WalletIcon />Payment Method</h3>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="wrap-list-input-gr">
                    <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Cash' ? 'active' : ''
                      }`"
                      @click.native="onClickPaymentType('Cash')"
                    >
                      Cash
                    </n-button>

                   <!-- <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Credit' ? 'active' : ''
                      }`"
                      @click.native="onClickPaymentType('Credit')"
                    >
                      Credit
                    </n-button>

                     <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Card' ? 'active' : ''
                      }`"
                      @click.native="onClickPaymentType('Card')"
                    >
                      Card
                    </n-button>
                    <n-button
                      :type="`default m-0 mt-2 ${
                        rawUserReload.paymentType === 'Complimentary'
                          ? 'active'
                          : ''
                      }`"
                      @click.native="onClickPaymentType('Complimentary')"
                    >
                      Complimentary
                    </n-button> -->
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-xs-12">
                  <div class="form-navigation text-left">
                    <!-- <div>Step 1 of 3</div> -->
                    <div class="text-left">
                      <n-button
                        type="primary ml-0"
                        :disabled="
                          !rawUserReload.amount ||
                          rawUserReload.amount === 0 ||
                          !rawUserReload.reload_user ||
                          rawUserReload.reload_user === ''
                        "
                        @click.native="
                          rawUserReload.reload_user &&
                          rawUserReload.reload_user !== ''
                            ? (formIndex = 2)
                            : null
                        "
                      >
                        Continue
                      </n-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="isDisplayed"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <new-user
        v-if="isDisplayed"
        :onClose="onCloseUserModal"
        @afterAddedUser="afterAddedUser"
      ></new-user>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { Radio } from "src/components";
import PreviewUpload from "../Components/PreviewUpload";
import { DatePicker, TimeSelect } from "element-ui";
import { humanize } from "src/util/string";
import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";
import CurrentEventMixin from "src/mixins/Event.js";

import UserGroupIcon from "src/components/Icons/UserGroup";
import WalletIcon from "src/components/Icons/Wallet";
import MoneyIcon from "src/components/Icons/Money";
import CardIcon from "src/components/Icons/Card";

import CicleCheckIcon from "src/components/Icons/CircleCheck";
import UserIcon from "src/components/Icons/User";
import TokenIcon from "src/components/Icons/Token";
import DollarIcon from "src/components/Icons/Dollar";
import CaseIcon from "src/components/Icons/Case";

import { Checkbox } from "src/components/index";
import NewUser from "src/pages/Dashboard/NewUser.vue";

import _ from "lodash";
const debounced = _.debounce((vm, url, params, cb) => {
  axios.get(url, { params }).then((response) => {
    if (response.data.results) {
      vm.users = response.data.results
        .map((e) => {
          e.displayName = e.first_name + " " + e.last_name;
          return e;
        })
        .filter((e) => e.id !== vm.user.id);
    } else {
      vm.users = response.data;
    }
    cb(vm.users);
  });
}, 500);

export default {
  components: {
    PreviewUpload,
    UserGroupIcon,
    WalletIcon,
    MoneyIcon,
    CicleCheckIcon,
    UserIcon,
    CaseIcon,
    DollarIcon,
    CardIcon,
    TokenIcon,
    [Radio.name]: Radio,
    Checkbox,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    NewUser,
  },
  props: ["onCloseReload", "defaultReloadUser"],
  computed: {},
  methods: {
    afterAddedUser(user) {},
    onCloseUserModal() {
      this.isDisplayed = false;
    },
    onClose() {
      this.rawUserReload = {
        isSelectedCard: false,
        paymentType: "Cash",
        amount: 5,
      };
      this.responseReload = null;
      this.formIndex = 1;
      this.onCloseReload();
    },
    onAddToken() {
      this.processing = true;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/`;
      const transaction = {
        amount: this.rawUserReload.amount,
        event: this.currentEvent.id,
        user: this.user.id,
        order: null,
        to_user: this.rawUserReload.reload_user.id,
      };
      axios
        .post(url, transaction)
        .then((response) => {
          this.processing = false;
          this.formIndex = 3;
          this.responseReload = response.data;
        })
        .catch((e) => {
          this.processing = false;
          if (e.response.status !== 403 || e.response.status != 401) {
            this.$message(humanize(e.response.data).join(" "));
          } else {
            this.$message(humanize(e.response.data.detail).join(" "));
          }
        });
    },
    onClickPaymentType(type) {
      this.rawUserReload.paymentType = type;
      this.$forceUpdate();
    },
    onClickToken(amount) {
      this.isShowCustom = false;
      if (amount === 0) {
        this.isShowCustom = true;
      }
      this.rawUserReload.amount = amount;
      this.$forceUpdate();
    },
    handleSelect(item) {
      this.rawUserReload.reload_user = item;
    },
    async querySearch(queryString, cb) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.currentEvent.id}/users/`;
      if (this.url) {
        url = this.url;
      }

      let params = {
        limit: 50,
        search: queryString,
        expand: "balance",
        event: this.currentEvent.id,
        all_users: 'true'
      };

      debounced(this, url, params, cb);
      // call callback function to return suggestions
    },

    async onNext(step) {
      switch (step) {
        case 2:
          await this.$validator.validate("name");
          break;
        case 3:
          await this.$validator.validate("topup_amounts");
          await this.$validator.validate("currency");
          await this.$validator.validate("tokenValue");
          await this.$validator.validate("token_symbol");
          break;
        default:
          break;
      }
      if (this.$validator.errors.items.length === 0) {
        this.formIndex = step;
      }
    },

    complete: function () {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/`;
      this.successMsg = "";
      this.serverErrors = [];

      axios
        .post(url, this.rawUserReload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.successMsg = "Successfully created the event";
        })
        .catch((e) => {
          if (e.response.status !== 401 && e.response.status !== 403) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    },
    onViewTransaction() {
      this.$router.push(
        `/pages/events/${this.currentEvent.id}/transactions/${this.responseReload?.id}`
      );
    },
  },
  data() {
    let reloadUser = null;
    if (this.defaultReloadUser) {
      reloadUser = this.defaultReloadUser;
      reloadUser.displayName =
        this.defaultReloadUser.first_name +
        " " +
        this.defaultReloadUser.last_name;

      if (
        !reloadUser.displayName ||
        reloadUser.displayName === "" ||
        reloadUser.displayName === " "
      ) {
        reloadUser.displayName = "N/A";
      }
    }

    return {
      topup_amounts_array: [5, 10, 15, 20],
      isDisplayed: false,
      processing: false,
      responseReload: null,
      links: [],
      datePicker: null,
      formIndex: 1,
      rawUserReload: {
        isSelectedCard: false,
        paymentType: "Cash",
        amount: 5,
        reload_user_modal: reloadUser?.displayName,
        reload_user: reloadUser,
      },
      cardForm: {},
      serverErrors: [],
      successMsg: "",
      users: [],
      isShowNewCardForm: false,
      isShowCustom: false,
    };
  },
  mounted() {
    if (this.currentEvent?.topup_amounts_array) {
      this.topup_amounts_array = this.currentEvent?.topup_amounts_array;
    }

    console.log(this.currentEvent);
  },
  mixins: [GetError, User, CurrentEventMixin],
};
</script>
<style lang="scss">
.form-event {
  display: flex;
  background: white;
  margin: 0;
}

.right-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .location,
  .returns {
    margin-top: 60px;
  }
}

.form-navigation {
  text-align: right;
  button {
    margin: 0;
    margin-left: 15px;
  }
}

.event-branding,
.logo {
  .image-preview {
    margin-top: 10px;
    height: 162px;
    width: 285px;
    border: solid 1px;
  }
}

.token-value-summary {
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  padding: 5px 18px;
  margin-bottom: 10px;
}

.add-vendor {
  margin-top: 20px;
}

.hint-label {
  font-family: Inter, sans-serif;
  font-size: 11px;
  color: #021b43;
  letter-spacing: 0.39px;
  line-height: 14px;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

.hint-small {
  font-family: Inter, sans-serif;
  font-size: 12px;
  color: #021b43;
}

.wrap-list-input-gr {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}
</style>
