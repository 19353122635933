<template>
  <div :class="'row ' + (isEdit ? 'editing' : '')">
    <div class="col-md-12">
      <div class="form-event" v-if="formIndex == 4">
        <div class="left-navigation">
          <h4>{{ isEdit ? userDetail.name : "User Profile" }}</h4>
          <ul>
            <li @click="onChangeStep(1)"><SettingIcon /> Account Setting</li>
            <!-- <li @click="onChangeStep(2)"><BellIcon />Notification</li>
            <li @click="onChangeStep(3)"><WalletIcon />Membership Plan</li> -->
            <li @click="onChangeStep(4)" class="active">
              <LockIcon /> Password & Security
            </li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><LockIcon />Password & Security</h3>
              <div class="row">
                <div class="col-12"></div>
              </div>

              <div class="form-navigation text-left mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-12 col-md-12">
                        <div class="wrap-event-input">
                          <fg-input
                            label="Current Password"
                            required
                            type="password"
                            name="currentPassword"
                            v-validate="modelValidations.currentPassword"
                            :error="getError('currentPassword')"
                            v-model="model.currentPassword"
                          >
                          </fg-input>
                        </div>
                      </div>

                      <div class="col-12 col-md-12">
                        <div class="wrap-event-input">
                          <fg-input
                            label="Password"
                            required
                            type="password"
                            name="password"
                            v-validate="modelValidations.password"
                            :error="getError('password')"
                            v-model="model.password"
                            ref="password"
                          >
                        </fg-input>
                        </div>
                      </div>
                      <div class="col-12 col-md-12">
                        <div class="wrap-event-input">
                          <fg-input
                            label="Confirm Password"
                            required
                            type="password"
                            name="confirm"
                            v-validate="modelValidations.confirmPassword"
                            :error="getError('confirm')"
                            v-model="model.confirmPassword"
                          >
                          </fg-input>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-right" v-if="isEdit">
                        <n-button
                          type="primary"
                          @click.native="onUpdatePaswword()"
                        >
                          Save
                        </n-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-event" v-if="formIndex == 3">
        <div class="left-navigation">
          <h4>{{ isEdit ? userDetail.name : "User Profile" }}</h4>
          <ul>
            <li @click="onChangeStep(1)"><SettingIcon /> Account Setting</li>
            <!-- <li @click="onChangeStep(2)"><BellIcon />Notification</li>
            <li @click="onChangeStep(3)" class="active">
              <WalletIcon />Membership Plan
            </li> -->
            <li @click="onChangeStep(4)"><LockIcon /> Password & Security</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <div class="row">
            <div class="col-md-12">
              <h3><WalletIcon width="15px" height="15px" />Membership Plan</h3>

              <div class="form-navigation text-left mt-3">
                <!-- <div>Step 3 of 4</div> -->
                <div v-if="isEdit">
                  <n-button type="primary" @click.native="onUpdateUser()">
                    Save
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-event" v-if="formIndex == 2">
        <div class="left-navigation">
          <h4>{{ isEdit ? userDetail.name : "User Profile" }}</h4>
          <ul>
            <li @click="onChangeStep(1)"><SettingIcon /> Account Setting</li>
            <!-- <li @click="onChangeStep(2)" class="active">
              <BellIcon />Notification
            </li>
            <li @click="onChangeStep(3)"><WalletIcon />Membership Plan</li> -->
            <li @click="onChangeStep(4)"><LockIcon /> Password & Security</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <div class="row">
            <div class="col-md-12">
              <h3><BellIcon />Notification</h3>
              <div class="form-navigation text-left">
                <!-- <div>Step 2 of 4</div> -->
                <div v-if="isEdit">
                  <n-button type="default" @click.native="onClose()">
                    Cancel
                  </n-button>

                  <n-button type="primary" @click.native="onUpdateUser()">
                    Save
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-event" v-if="formIndex == 1">
        <div class="left-navigation">
          <h4>{{ isEdit ? userDetail.name : "User Profile" }}</h4>
          <ul>
            <li @click="onChangeStep(1)" class="active">
              <SettingIcon /> Account Setting
            </li>
            <!-- <li @click="onChangeStep(2)"><BellIcon />Notification</li> -->
            <!-- <li @click="onChangeStep(3)"><WalletIcon />Membership Plan</li> -->
            <li @click="onChangeStep(4)"><LockIcon /> Password & Security</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><SettingIcon />User Details</h3>
              <!-- <div class="row">
                <div class="col-12">
                  <div
                    class="d-flex"
                    style="
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                      margin-bottom: 30px;
                    "
                  >
                    <img
                      style="
                        width: 100px;
                        height: 100px;
                        margin-bottom: 10px;
                        border-radius: 100%;
                      "
                      :src="
                        previewLogo
                          ? previewLogo
                          : 'assets/imgs/accounts-icon.svg'
                      "
                    />
                    <div class="input-file">
                      <input
                        class="input-file__input"
                        ref="file"
                        type="file"
                        v-on:change="onUploadLogo"
                      />
                      <div
                        style="padding: 10px"
                        class="input-file__button"
                        @click="$refs.file.click()"
                      >
                        Upload
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>First Name</strong>
                    </label>
                    <fg-input
                      v-model="userDetail.first_name"
                      name="first_name"
                      :error="getError('first_name')"
                      placeholder="First Name..."
                      v-validate="'required'"
                    ></fg-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Last Name</strong>
                    </label>
                    <fg-input
                      v-model="userDetail.last_name"
                      name="last_name"
                      :error="getError('last_name')"
                      placeholder="Last Name..."
                      v-validate="'required'"
                    ></fg-input>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Email</strong>
                    </label>
                    <fg-input
                      v-model="userDetail.email"
                      name="email"
                      :error="getError('email')"
                      placeholder="Email..."
                      v-validate="'required|email'"
                    ></fg-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>DOB</strong>
                    </label>
                    <el-date-picker
                      :editable="false"
                      v-model="userDetail.dob"
                      placeholder="DoB..."
                      type="datetime"
                      :format="'MMM DD, yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Address</strong>
                    </label>
                    <fg-input
                      v-model="userDetail.address"
                      name="first_name"
                      :error="getError('first_name')"
                      placeholder="First Name..."
                      v-validate="'required'"
                    ></fg-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Phone</strong>
                    </label>
                    <fg-input
                      placeholder="Phone..."
                      name="phone"
                      v-model="userDetail.phone"
                      :error="getError('phone')"
                      v-validate="{
                        required: true,
                        regex: /^\+?[0-9]{3}-?[0-9]{7,11}$/,
                      }"
                    ></fg-input>
                  </div>
                </div>
              </div>

              <div class="form-navigation text-right">
                <!-- <div>Step 1 of 4</div> -->

                <div v-if="isEdit">
                  <n-button type="primary" @click.native="onUpdateUser()">
                    Save
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Radio } from "src/components";
import { DatePicker, TimeSelect } from "element-ui";
import { humanize } from "src/util/string";
import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";

import LockIcon from "src/components/Icons/Lock";
import SettingIcon from "src/components/Icons/Setting";
import BellIcon from "src/components/Icons/Bell";
import WalletIcon from "src/components/Icons/Wallet";
import TokenIcon from "src/components/Icons/Token";
import InformationIcon from "src/components/Icons/Information";
import MapIcon from "src/components/Icons/Map";

import moment from "moment/moment";
const compact = (list) => list.filter((x) => !!x);

import _ from "lodash";
const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    if (response.data.results) {
      vm.users = response.data.results;
      vm.total = response.data.count;
    } else {
      vm.users = response.data;
      vm.total = vm.users.length;
    }
  });
}, 500);

export default {
  components: {
    LockIcon,
    SettingIcon,
    BellIcon,
    WalletIcon,
    TokenIcon,
    InformationIcon,
    MapIcon,
    [Radio.name]: Radio,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  props: ["onClose", "userDetailEdit", "isEdit"],
  computed: {},
  methods: {
    onUpdatePaswword() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/set-password/`;

          axios.post(url, {
            current_password: this.model.currentPassword,
            password: this.model.password
          }).then(rs => {
            this.$message("Your password has been updated");
          }).catch(e => {
            this.$message(humanize(e.response.data).join(" "));
          });
        }
      });
    },
    changed: function (key) {
      if (!this.dirties.includes(key)) {
        this.dirties.push(key);
      }
    },
    userAsFormData: function () {
      const e = {
        ...this.userDetail,
      };
      console.log(e);
      const form = new FormData();
      const ignores = [
        "id",
        "auto_topup_amount",
        "auto_topup_card",
        "date_joined",
        "default_card",
        "is_staff",
        "is_superuser",
        "last_location",
        "share_location",
        "twilio_chat_user",
        "type",
        "user_type",
      ].filter((x) => !this.dirties.includes(x));

      Object.keys(e)
        .filter((x) => !ignores.includes(x))
        .map((k) => {
          if (k === "logo" || k === "image") {
            console.log(e[k], this.origEvent[k]);
            if (e[k] != this.origEvent[k]) {
              form.append(k, e[k] || "");
            }
          } else if (k == "point") {
            form.append(k, `POINT(${e[k].lng} ${e[k].lat})`);
          } else {
            form.append(k, e[k] || "");
          }
        });

      return form;
    },
    onUpdateUser: function () {
      const self = this;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.userDetail.id}/`;
      self.successMsg = "";
      this.serverErrors = "";
      const payload = this.userAsFormData();
      if (payload.phone[0] !== "+") {
        payload.phone = "+" + payload.phone;
      }

      axios
        .patch(url, payload)
        .then((response) => {
          self.$message("Successfully update user detail");

          localStorage.setItem(
            "user",
            JSON.stringify({ ...this.userDetail, ...response.data })
          );
          this.userDetail = { ...this.userDetail, ...response.data };
          this.$store.commit("setUser", {
            ...this.userDetail,
            ...response.data,
          });
        })
        .catch((e) => {
          if (e.response.status !== 403 || e.response.status != 401) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
          self.$message(humanize(e.response.data).join(" "));
        });
    },
    onChangeStep(step) {
      this.onNext(step);
    },
    async onNext(step) {
      if (this.isEdit) {
        this.formIndex = step;
        return;
      }
      switch (step) {
        case 2:
          await this.$validator.validate("name");
          await this.$validator.validate("phone");
          await this.$validator.validate("email");
          await this.$validator.validate("address");
          await this.$validator.validate("website");
          await this.$validator.validate("city");
          break;
        case 3:
          await this.$validator.validate("topup_amounts");
          await this.$validator.validate("currency");
          await this.$validator.validate("tokenValue");
          await this.$validator.validate("token_symbol");
          break;
        default:
          break;
      }
      if (this.$validator.errors.items.length === 0) {
        this.formIndex = step;
      }
    },
    onUploadLogo(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.userDetail.logo = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewLogo = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    complete: function () {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/`;
      this.successMsg = "";
      this.serverErrors = [];

      axios
        .post(url, this.event, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.successMsg = "Successfully created the event";
        })
        .catch((e) => {
          if (e.response.status !== 401 && e.response.status !== 403) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    },
  },
  data() {
    let rwEvent = {};
    if (this.isEdit && this.userDetailEdit) {
      rwEvent = this.userDetailEdit;
    }

    return {
      model: {
        currentPassword: "",
        password: "",
        confirmPassword: "",
      },
      modelValidations: {
        currentPassword: {
          required: true,
        },
        password: {
          required: true,
          min: 5,
        },
        confirmPassword: {
          required: true,
          confirmed: "password",
        },
      },
      dirties: [],
      datePicker: null,
      formIndex: 1,
      userDetail: rwEvent,
      serverErrors: [],
      successMsg: "",
      previewBg: null,
      previewLogo: null,
      value: "",
      options: [
        {
          value: "Organizer",
          label: "Organizer",
        },
        {
          value: "Admin",
          label: "Admin",
        },
        {
          value: "Support",
          label: "Support",
        },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      users: [],
      titles: [
        {
          prop: "first_name",
          label: "Name",
          formatter: (row) => {
            return row.first_name + " " + row.last_name;
          },
        },
        {
          prop: "phone_number",
          label: "Phone",
        },
      ],
      filters: [
        {
          value: "",
        },
      ],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("user"));
  },
  mixins: [GetError, User],
};
</script>
<style lang="scss" scoped>
.form-event {
  display: flex;
  background: white;
  margin: 0;
}

.right-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .location,
  .returns {
    margin-top: 60px;
  }
}

.form-navigation {
  text-align: left;
  button {
    margin: 0;
    margin-left: 15px;
  }
}

.event-branding,
.logo {
  .image-preview {
    margin-top: 10px;
    height: 162px;
    width: 285px;
    border: solid 1px;
  }
}

.token-value-summary {
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  padding: 5px 18px;
  margin-bottom: 10px;
}

.add-vendor {
  margin-top: 20px;
}

.hint-label {
  font-family: Inter, sans-serif;
  font-size: 11px;
  color: #021b43;
  letter-spacing: 0.39px;
  line-height: 14px;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

.hint-small {
  font-family: Inter, sans-serif;
  font-size: 12px;
  color: #021b43;
}

.editing {
  .left-navigation {
    ul li {
      cursor: pointer;
    }
  }
}
</style>
