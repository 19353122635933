<template>
  <div v-if="$store.state.user.type == 'org-administrator'" class="row">
    <div class="col-md-12">
      <div class="card bg-transparent shadow-none card-new">
        <div class="card-header">
          <h4 slot="header" class="card-title">Top Locations</h4>
          <!-- <img class="dropdown-dot" src="assets/imgs/dot-dropdown.svg" alt="" /> -->
        </div>
        <div class="card-body">
          <div v-if="items && items.length === 0">
            <div
              class="w-100 d-flex h-100 align-items-center justify-content-center p-5"
            >
              No Data Available Yet
            </div>
          </div>
          <div class="table-responsive" v-if="items && items.length > 0">
            <n-table class="table-shopping" :data="items">
              <template slot="columns">
                <th>Name</th>
                <th class="text-right">Tokens</th>
                <th class="text-right">Revenue ($)</th>
              </template>
              <template slot-scope="{ row }">
                <td class="td-name">
                  <a href="javascript:void(0)" @click="onViewVendor(row)">
                    {{ row.vendor_event__vendor__name }} -
                    {{ row.vendor_event__booth }}
                  </a>
                </td>
                <td class="td-number">
                  {{ row.tokens }}
                </td>
                <td class="td-number">${{ row.amount }}</td>
              </template>
              <template slot="summary-row"> </template>
            </n-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Table as NTable } from "src/components";
import axios from "axios";

export default {
  props: ["items"],
  components: {
    Card,
    NTable,
  },
  methods: {
    onViewVendor(evt) {


      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/`;
      const params = {
        event: evt.vendor_event__event_id,
        limit: 1,
        search: evt.vendor_event__vendor__name,
      };

      axios.get(url, { params }).then((rs) => {
        if (rs.data && rs.data.results && rs.data.results[0]) {
          this.$router.push(
            `/pages/events/${evt.vendor_event__event_id}/vendors/${rs.data.results[0].id}/${evt.vendor_event_id}`
          );
        }
      });
      // path:
    },
  },
};
</script>
