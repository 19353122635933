<template>
  <div :class="'row ' + (isEdit ? 'editing' : '')">
    <div class="col-md-12">
      <div class="form-event" v-if="formIndex == 1">
        <div class="left-navigation">
          <h4>{{ isEdit ? newUser.name : "New User" }}</h4>

          <ul>
            <li @click="onChangeStep(1)" class="active">
              <InforCircleIcon />User Details
            </li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><InforCircleIcon />User Details</h3>
              <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>First Name</strong>
                    </label>
                    <fg-input
                      v-model="newUser.first_name"
                      name="first_name"
                      :error="getError('first_name')"
                      placeholder="First Name..."
                      v-validate="'required'"
                    ></fg-input>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Last Name</strong>
                    </label>
                    <fg-input
                      v-model="newUser.last_name"
                      v-validate="'required'"
                      name="last_name"
                      :error="getError('last_name')"
                      placeholder="Last Name..."
                    ></fg-input>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="wrap-event-input">
                    <div class="row">
                      <div :class="`col-xs-12 col-md-12 col-lg-6 `">
                        <label>
                          <strong>User Type</strong>
                        </label>
                        <div class="form-group custom-dropdown">
                          <el-select
                            :disabled="isAddNewVendor"
                            v-model="newUser.type"
                            name="type"
                            v-validate="'required'"
                          >
                            <el-option
                              v-if="!isCash && !isVendor"
                              :label="'org-administrator'"
                              :value="'org-administrator'"
                              @click="setUserType('org-administrator')"
                            >
                            </el-option>
                            <el-option
                              v-if="!isCash"
                              @click="setUserType('vendor')"
                              :label="'Vendor'"
                              :value="'vendor'"
                            >
                            </el-option>

                            <el-option
                              v-if="!isCash"
                              @click="setUserType('cash')"
                              :label="'Cash'"
                              :value="'cash'"
                            >
                            </el-option>
                            <el-option
                              @click="setUserType('user')"
                              :label="'User'"
                              :value="'user'"
                            >
                            </el-option>
                          </el-select>
                          <div
                            class="text-danger"
                            style="font-size: 10px; margin-top: 0.25rem"
                          >
                            {{ getError("type") }}
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-12 col-lg-6">
                        <label>
                          <strong
                            v-if="
                              newUser.type == 'org-administrator' &&
                              loggedUser().is_staff
                            "
                            >Organization</strong
                          >
                          <strong v-if="newUser.type == 'vendor'"
                            >Permission</strong
                          >
                          <strong v-if="!newUser.type || newUser.type === ''"
                            >Organization</strong
                          >
                        </label>
                        <div class="form-group custom-dropdown">
                          <multiselect
                            v-if="!newUser.type || newUser.type === ''"
                            disabled
                            id="ajax-organizations"
                            label="name"
                            track-by="id"
                            placeholder="Search..."
                            open-direction="bottom"
                            :options="[]"
                            :multiple="true"
                            :searchable="true"
                            :internal-search="false"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="false"
                            @search-change="searchOrgs"
                          />
                          <multiselect
                            v-if="
                              newUser.type == 'org-administrator' &&
                              loggedUser().is_staff
                            "
                            v-model="selectedOrganizations"
                            id="ajax-organizations"
                            label="name"
                            track-by="id"
                            placeholder="Search for organization..."
                            open-direction="bottom"
                            :options="organizations"
                            :multiple="true"
                            :searchable="true"
                            :loading="searchOrganizationsLoading"
                            :internal-search="false"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="false"
                            @search-change="searchOrgs"
                          />

                          <el-select
                            v-if="newUser.type == 'vendor'"
                            v-model="newUser.vendorType"
                            @change="onSelectUserVendorType($event)"
                            placeholder="Select"
                            class="mb-2"
                          >
                            <el-option
                              v-if="newUser.type == 'vendor'"
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>

                          <multiselect
                            v-if="newUser.type == 'vendor' && !isAddNewVendor"
                            v-model="selectedVendors"
                            id="ajax-vendors"
                            label="name"
                            track-by="id"
                            placeholder="Managed vendors"
                            open-direction="bottom"
                            :options="vendors"
                            :multiple="true"
                            :searchable="true"
                            :loading="searchVendorsLoading"
                            :internal-search="false"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="false"
                            @search-change="searchVendors"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-xs-12 col-md-12 col-lg-6"
                  v-if="!isAddNewVendor && newUser.type !== 'vendor' && newUser.type !== 'cash'"
                >
                  <div class="wrap-event-input">
                    <label>
                      <strong>Phone</strong>
                    </label>
                    <fg-input
                      v-model="newUser.phone_number"
                      v-validate="{
                        required: true,
                        regex: /^\+?[0-9]{3}-?[0-9]{7,11}$/,
                      }"
                      name="phone_number"
                      :error="getError('phone_number')"
                      placeholder="Phone..."
                    ></fg-input>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Email</strong>
                    </label>
                    <fg-input
                      v-validate="'required|email'"
                      v-model="newUser.email"
                      name="email"
                      :error="getError('email')"
                      placeholder="Email..."
                    ></fg-input>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- <div class="col-xs-12 col-md-12 col-lg-6 ">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Birthday</strong>
                    </label>
                    <fg-input
                      v-model="newUser.dob"
                      name="dob"
                      isDatePicker
                      placeholder="Birthday..."
                    ></fg-input>
                  </div>
                </div> -->
                <!-- <div class="col-xs-12 col-md-12 col-lg-6 ">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Gender</strong>
                    </label>

                    <div class="form-group custom-dropdown">
                      <el-select v-model="newUser.gender">
                        <el-option :label="'Male'" :value="'male'"> </el-option>
                        <el-option :label="'Female'" :value="'female'">
                        </el-option>
                        <el-option :label="'Other'" :value="'other'">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div> -->
              </div>

              <template v-if="isAdmin">
                <h3 class="mt-3"><InforCircleIcon />User Password</h3>

                <div class="row">
                  <div class="col-12">
                    <div class="wrap-list-input-gr">
                      <n-button
                        :type="`default m-0 mt-2 ${
                          passwordType === 'password' ? 'active' : ''
                        }`"
                        @click.native="passwordType = 'password'"
                      >
                        Set Password
                      </n-button>
                      <n-button
                        :type="`default m-0 mt-2 ${
                          passwordType === 'email' ? 'active' : ''
                        }`"
                        @click.native="passwordType = 'email'"
                      >
                        Email Temporary Password
                      </n-button>
                    </div>
                  </div>
                </div>

                <div class="row mt-3" v-if="passwordType === 'password'">
                  <div class="col-xs-12 col-md-12 col-lg-6">
                    <div class="wrap-event-input">
                      <label>
                        <strong>Password</strong>
                      </label>
                      <fg-input
                        ref="password"
                        v-model="newUser.password"
                        v-validate="'required'"
                        type="password"
                        name="password"
                        :error="getError('password')"
                        placeholder="Password..."
                      ></fg-input>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-6">
                    <div class="wrap-event-input">
                      <label>
                        <strong>Confirm Password</strong>
                      </label>
                      <fg-input
                        type="password"
                        data-vv-as="password"
                        v-model="newUser.confirm_password"
                        v-validate="'required|confirmed:password'"
                        name="confirm_password"
                        :error="getError('confirm_password')"
                        placeholder="Confirm Password..."
                      ></fg-input>
                    </div>
                  </div>
                </div>
              </template>
              <div class="row mt-4">
                <div class="col--12">
                  <loading-component
                    class="submit-container"
                    v-bind:loading="addUserLoading"
                  >
                    <n-button type="primary" @click.native="addUser"
                      >Save</n-button
                    >
                  </loading-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
import LoadingComponent from "src/components/LoadingComponent";
import User from "src/mixins/User";
import Multiselect from "vue-multiselect";
import lodash from "lodash";
import { Modal } from "src/components";

import InforCircleIcon from "src/components/Icons/InforCircle";
import GetError from "src/mixins/GetError.js";
import moment from "moment";

export default {
  components: {
    Modal,
    Spinner,
    LoadingComponent,
    Multiselect,
    InforCircleIcon,
  },
  props: {
    isAddNewVendor: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  computed: {
    isCash() {
      const user = this.$store.state.user;
      return user.type === "cash";
    },
    isVendor() {
      const user = this.$store.state.user;
      return user.type === "vendor";
    },
    isAdmin() {
      const user = this.$store.state.user;
      return user.type === "org-administrator";
    },
  },
  mixins: [GetError],
  data: () => {
    return {
      options: [
        {
          value: "owner",
          label: "Owner",
        },
        {
          value: "employee",
          label: "Employee",
        },
      ],
      searchOrganizationsLoading: false,
      errorsServer: {},
      admins: [],
      organizations: [],
      selectedOrganizations: [],
      vendors: [],
      selectedVendors: [],
      newUser: {
        email: "",
        first_name: "",
        last_name: "",
        type: "",
        phone_number: "",
        gender: "male",
        dob: "",
        organization_ids: [],
        vendor_ids: [],
        vendorType: "owner",
      },
      passwordType: "email",
      successMsg: "",
      loading: true,
      addUserLoading: false,
      searchOrgsLoading: false,
      searchVendorsLoading: false,
      userTypes: ["org-administrator", "cash"],
      formIndex: 1,
    };
  },
  methods: {
    onSelectUserVendorType(val) {
      this.newUser.vendorType = val;
    },
    closeModal() {},
    loggedUser() {
      return this.$store.state.user;
    },
    // getError(fieldName) {
    //   return lodash.get(this.errors, `${fieldName}[0]`);
    // },

    getProperty: function (target, property) {
      return lodash.get(target, property);
    },

    setUserType: function (value) {
      this.$set(this.newUser, "type", value);
    },

    addUser: async function () {
      await this.$validator.validate();

      if (this.$validator.errors.items.length > 0) {
        return;
      }
      this.errorsServer = {};
      this.addUserLoading = true;
      if (this.loggedUser().type == "cash") {
        this.newUser.type = "user";
      }
      this.newUser.organization_ids = this.selectedOrganizations.map(
        ({ id }) => id
      );

      this.newUser.vendor_ids = this.selectedVendors.map(({ id }) => id);
      const payload = { ...this.newUser };
      payload.dob = moment().format("YYYY-MM-DD");
      if (!payload.password || payload.password === "") {
        payload.password = Math.random().toString(36).slice(-8);
      }

      if (payload.phone_number[0] !== "+" && payload.phone_number[1]) {
        payload.phone_number = "+" + payload.phone_number;
      }

      if (payload.phone_number === "") {
        delete payload["phone_number"];
      }

      payload.event = this.$route.params.id;
      payload.vendor_event__event = this.$route.params.id;

      if (payload.type === "vendor" || this.passwordType === "email") {
        delete payload["password"];
        delete payload["confirm_password"];
      }

      if (this.isAddNewVendor || payload.type === "vendor" || payload.type === "cash") {
        delete payload["phone_number"];
      }

      if (payload.type !== "vendor") {
        delete payload["vendorType"];
      }

      try {
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/`;
        const response = await axios.post(url, payload);

        this.$emit("afterAddedUser", {
          ...response.data,
          ...{ vendorType: this.newUser.vendorType },
        });

        if (
          !this.isAddNewVendor &&
          this.newUser.vendor_ids.length > 0 &&
          this.newUser.type === "vendor"
        ) {
          this.newUser.vendor_ids.forEach((vendorId) => {
            this.afterAddedUser(
              { ...response.data, ...{ vendorType: this.newUser.vendorType } },
              vendorId
            );
          });
        }

        this.newUser = {
          email: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          gender: "male",
          dob: "",
          organization_ids: [],
          vendor_ids: [],
          password: "",
          confirm_password: "",
          vendorType: "owner",
        };
        this.successMsg = "Successfully add new user";
        this.addUserLoading = false;
        this.userQuery = "";
        this.onClose();
      } catch (error) {
        const errors = error.response.data;
        this.errorsServer = errors;

        let msg = "";
        Object.keys(errors).forEach((item) => {
          msg += `${item}: ${errors[item]}\n`;
        });

        this.addUserLoading = false;
        this.$message({
          message: msg,
          type: "error",
        });

        // throw error;
      }
    },
    afterAddedUser(user, vendorId) {
      console.log(user);
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${vendorId}/attach/${user.id}/${user.vendorType}/`;
      axios.get(url).then((rs) => {
        this.$message("Update successfully!");

        this.searchVendors(``);
        this.searchOrgs(``);
      });
    },
    searchVendors: function (query) {
      this.searchVendorsLoading = true;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/?search=${query}&event=${this.$route.params.id}`;
      axios.get(url).then((response) => {
        this.vendors = response.data.results;
        console.log("vendors", this.vendors, this.selectedVendors);

        this.searchVendorsLoading = false;
      });
    },

    searchOrgs: function (query) {
      this.searchOrgsLoading = true;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/organization/?search=${query}`;
      axios.get(url).then((response) => {
        this.organizations = response.data.results;
        console.log("orgs", this.organizations, this.selectedOrganizations);
        this.searchOrgsLoading = false;
      });
    },
  },
  mounted() {
    if (!this.isEdit && !this.isAddNewVendor) {
      this.newUser = {
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        gender: "male",
        dob: "",
        organization_ids: [],
        vendor_ids: [],
        password: "",
        confirm_password: "",
        vendorType: "owner",
        type: "user",
      };
    }

    if (this.isAddNewVendor) {
      this.newUser.type = "vendor";
      this.newUser.vendorType = "owner";
    }
    this.searchVendors(``);
    this.searchOrgs(``);
  },
  beforeMount() {},
};
</script>
<style lang="scss"></style>
