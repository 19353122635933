<template>
  <div :class="'row ' + (isEdit ? 'editing' : '')">
    <div class="col-md-12">
      <div class="form-event" v-if="formIndex == 2">
        <div class="left-navigation">
          <h4>{{ isEdit ? rawVendor.name : "New Vendor" }}</h4>
          <ul>
            <li @click="onChangeStep(1)"><InforCircleIcon />Vendor Details</li>
            <li @click="onChangeStep(2)" class="active">
              <LocationIcon />Locations
            </li>
            <li @click="onChangeStep(3)"><KeyIcon />Users</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><LocationIcon />Location</h3>
              <div class="row">
                <div class="col-12">
                  <div class="search-table">
                    <div class="wrap-event-input">
                      <label>
                        <strong class="me-2">Location Name</strong>
                      </label>

                      <div class="d-flex">
                        <el-input
                          placeholder="Search by Name, Phone, Email or Type"
                          v-model="filters[0].value"
                          @input="onChangeSearch"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon el-icon-search"
                          ></i>
                        </el-input>
                        <n-button
                          style="
                            margin: 0;
                            margin-left: 15px;
                            white-space: nowrap;
                          "
                          type="default"
                          :disabled="
                            !filters[0].value || filters[0].value == ''
                          "
                          @click.native="createLocation"
                        >
                          Create
                        </n-button>
                      </div>
                    </div>
                  </div>
                  <data-tables-server
                    class="event-table create-event-table"
                    :data="locations"
                  >
                    <el-table-column
                      v-for="(title, index) in location_titles"
                      :label="title.label"
                      :key="index"
                      :prop="title.prop"
                      sortable="custom"
                      :formatter="title.formatter"
                      v-if="
                        title.prop !== 'status_location' &&
                        title.prop !== 'isActive'
                      "
                    >
                    </el-table-column>

                    <el-table-column
                      v-for="(title, index) in location_titles"
                      :label="title.label"
                      :key="index"
                      :prop="title.prop"
                      sortable="custom"
                      :formatter="title.formatter"
                      v-if="title.prop === 'status_location'"
                    >
                      <template slot-scope="scope">
                        <div
                          :class="
                            scope.row.status === 'Ready'
                              ? 'tag azul'
                              : 'tag orange'
                          "
                          disable-transitions
                        >
                          {{ scope.row.status }}
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column
                      v-for="(title, index) in location_titles"
                      :label="title.label"
                      :key="index"
                      :prop="title.prop"
                      sortable="custom"
                      :formatter="title.formatter"
                      v-if="title.prop === 'isActive'"
                    >
                      <template slot-scope="scope">
                        <checkbox
                          v-model="scope.row.isActive"
                          class="pull-left"
                          name="subscribe"
                          @input="onChangeCheckApprove($event, scope.row)"
                        >
                          Approved
                        </checkbox>
                      </template>
                    </el-table-column>

                    <!-- <el-table-column :label="''" width="70">
                      <template slot-scope="scope">
                        <div :class="'action-create-event'" disable-transitions>
                          <el-dropdown>
                            <span class="el-dropdown-link">
                              <img
                                class="dropdown-dot"
                                src="assets/imgs/dot-dropdown.svg"
                                alt=""
                              />
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                command="a"
                                @click.native="onClickTransationView(scope.row)"
                                ><i class="el-icon-view"></i>
                                View</el-dropdown-item
                              >
                              <el-dropdown-item command="b"
                                ><i class="el-icon-edit-outline"></i>
                                Edit</el-dropdown-item
                              >
                              <el-dropdown-item command="c"
                                ><i class="el-icon-refresh-left"></i>
                                Reload</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </template>
                    </el-table-column> -->
                  </data-tables-server>
                </div>
              </div>

              <div class="form-navigation text-right mt-3">
                <!-- <div>Step 3 of 4</div> -->
                <div v-if="!isEdit">
                  <n-button type="default" @click.native="formIndex = 2">
                    Back
                  </n-button>
                  <!-- <n-button type="primary" @click.native="complete">
                    Save
                  </n-button> -->
                </div>
                <div v-if="isEdit">
                  <!-- <n-button type="primary" @click.native="onUpdateVendor()">
                    Save
                  </n-button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-event" v-if="formIndex == 3">
        <div class="left-navigation">
          <h4>{{ isEdit ? rawVendor.name : "New Vendor" }}</h4>
          <ul>
            <li @click="onChangeStep(1)"><InforCircleIcon />Vendor Details</li>
            <li @click="onChangeStep(2)"><LocationIcon />Locations</li>
            <li @click="onChangeStep(3)" class="active"><KeyIcon />Users</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><KeyIcon />Users</h3>
              <div class="row">
                <div class="col-12">
                  <div class="search-table">
                    <div class="wrap-event-input">
                      <label>
                        <strong class="me-2">Search User</strong>
                      </label>

                      <div class="d-flex">
                        <el-select
                          v-model="filters[0].value"
                          filterable
                          class="w-100"
                          remote
                          reserve-keyword
                          v-if="isShowSearch"
                          placeholder="Search by Name, Phone or Email"
                          :remote-method="loadData"
                          :loading="loading"
                          @change="handleSelect"
                        >
                          <div
                            slot="empty"
                            style="padding: 15px; text-align: center"
                          >
                            There is no data(s).
                            <n-button
                              style="
                                margin: 0;
                                margin-left: 15px;
                                white-space: nowrap;
                              "
                              type="default"
                              @click.native="onShowNewUser()"
                            >
                              Add Vendor Staff ?
                            </n-button>
                          </div>
                          <el-option
                            v-if="users"
                            v-for="item in users"
                            :key="item.value"
                            :label="item.displayName"
                            :value="item.id"
                          >
                            <div
                              class="d-flex w-100 align-items-center justify-content-between w-100"
                              style="padding: 10px; gap: 10px"
                            >
                              <div
                                class="value"
                                style="
                                  font-family: 'Inter';
                                  font-size: 14px;
                                  color: #021b43;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.displayName }}
                              </div>
                              <div
                                class="phone"
                                style="
                                  font-family: 'Inter';
                                  font-size: 12px;
                                  color: #8e8e93;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                "
                              >
                                {{ item.phone_number }}
                              </div>
                              <div
                                class="email"
                                style="
                                  font-family: 'Inter';
                                  font-size: 12px;
                                  color: #8e8e93;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.email }}
                              </div>
                            </div>
                          </el-option>
                        </el-select>

                        <!-- <el-autocomplete
                          class="form-control"
                          placeholder="Search by Name, Phone or Email"
                          style="border-radius: 0; border: 0; padding: 0"
                          v-model="filters[0].value"
                          :fetch-suggestions="loadData"
                          @select="handleSelect"
                          value-key="displayName"
                          :popper-append-to-body="false"
                        >
                          <template slot-scope="{ item }">
                            <div
                              class="d-flex w-100 align-items-center justify-content-between"
                              style="padding: 10px; gap: 10px"
                            >
                              <div
                                class="value"
                                style="
                                  font-family: 'Inter';
                                  font-size: 14px;
                                  color: #021b43;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.displayName }}
                              </div>
                              <div
                                class="phone"
                                style="
                                  font-family: 'Inter';
                                  font-size: 12px;
                                  color: #8e8e93;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                "
                              >
                                {{ item.phone_number }}
                              </div>
                              <div
                                class="email"
                                style="
                                  font-family: 'Inter';
                                  font-size: 12px;
                                  color: #8e8e93;
                                  letter-spacing: 0.43px;
                                  text-align: left;
                                  line-height: 14px;
                                  min-width: calc(100% / 3);
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.email }}
                              </div>
                            </div>
                          </template>
                        </el-autocomplete> -->

                        <!-- <el-input
                          placeholder="Search by Name, Phone, Email or Type"
                          v-model="filters[0].value"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon el-icon-search"
                          ></i>
                        </el-input> -->
                      </div>
                    </div>
                  </div>

                  <data-tables-server
                    class="event-table create-event-table"
                    :data="administrators"
                  >
                    <el-table-column :label="'Name'">
                      <template slot-scope="scope">
                        <a
                          href="javascript:void(0)"
                          @click="handlViewUser(scope.row)"
                        >
                          <template
                            v-if="
                              scope.row.user.first_name &&
                              scope.row.user.last_name
                            "
                          >
                            {{
                              scope.row.user.first_name +
                              " " +
                              scope.row.user.last_name
                            }}
                          </template>
                          <template v-else> N/A </template>
                        </a>
                      </template>
                    </el-table-column>

                    <el-table-column :label="'Email'">
                      <template slot-scope="scope">
                        {{ scope.row.user.email }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="''">
                      <template slot-scope="scope">
                        <div :class="'action-create-event'" disable-transitions>
                          <el-select
                            v-model="scope.row.type"
                            @change="onChangeUserAttach($event, scope.row)"
                            placeholder="Select"
                          >
                            <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                  </data-tables-server>
                </div>
              </div>

              <!-- <div class="form-navigation text-right mt-2">
               
                <div>
                  <n-button type="primary" @click.native="formIndex = 1">
                    Back
                  </n-button>
                  <n-button type="primary" @click.native="onNext(3)">
                    Next
                  </n-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="form-event" v-if="formIndex == 1">
        <div class="left-navigation">
          <h4>{{ isEdit ? rawVendor.name : "New Vendor" }}</h4>
          <ul>
            <li @click="onChangeStep(1)" class="active">
              <InforCircleIcon />Vendor Details
            </li>
            <li @click="onChangeStep(2)"><LocationIcon />Locations</li>
            <li @click="onChangeStep(3)"><KeyIcon />Users</li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button type="button" class="close-button-md" @click="onClose()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><InforCircleIcon />Vendor Details</h3>
              <div class="wrap-event-input">
                <label>
                  <strong>Vendor Name</strong>
                </label>
                <fg-input
                  v-model="rawVendor.name"
                  name="name"
                  :error="getError('name')"
                  placeholder="Vendor Name"
                  v-validate="'required'"
                ></fg-input>
              </div>

              <div class="wrap-event-input">
                <label>
                  <strong>Description</strong>
                </label>
                <fg-input
                  v-model="rawVendor.description"
                  placeholder="Vendor Description"
                  v-validate="'required'"
                  :textArea="true"
                  :error="getError('description')"
                  name="description"
                ></fg-input>
              </div>

              <div class="row">
                <div class="col-xs-12 col-md-12 col-xl-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Phone</strong>
                    </label>
                    <fg-input
                      placeholder="Phone..."
                      name="phone"
                      v-model="rawVendor.phone"
                      :error="getError('phone')"
                      v-validate="{
                        required: true,
                        regex: /^\+?[0-9]{3}-?[0-9]{7,11}$/,
                      }"
                    ></fg-input>
                  </div>
                </div>
                <!-- <div class="col-xs-12 col-md-12 col-xl-4">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Email</strong>
                    </label>
                    <fg-input
                      v-model="rawVendor.email"
                      v-validate="'required|email'"
                      placeholder="admin@vendor.com"
                      name="email"
                      :error="getError('email')"
                    ></fg-input>
                  </div>
                </div> -->
                <div class="col-xs-12 col-md-12 col-xl-6">
                  <div class="wrap-event-input">
                    <label>
                      <strong>Website URL</strong>
                    </label>
                    <fg-input
                      placeholder="URL"
                      v-validate="'url'"
                      name="website"
                      :error="getError('website')"
                      v-model="rawVendor.website"
                    ></fg-input>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-md-12 mt-4">
                  <h3><BrandingIcon />Vendor Branding</h3>
                </div>
                <div class="col-xs-12 col-lg-12 col-xl-4">
                  <div class="wrap-empty-image">
                    <img v-if="previewLogo" :src="previewLogo" alt="" />
                    <img
                      class="preview-bg"
                      v-if="previewBg"
                      :src="previewBg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-lg-12 col-xl-4">
                  <div class="wrap-event-input">
                    <label>
                      <strong class="me-2">Upload Logo </strong>
                      <InformationIcon />
                    </label>
                    <div class="input-file">
                      <input
                        class="input-file__input"
                        ref="file2"
                        type="file"
                        v-on:change="onUploadLogo"
                      />
                      <div
                        class="input-file__button"
                        @click="$refs.file2.click()"
                      >
                        Upload
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-lg-12 col-xl-4">
                  <div class="wrap-event-input">
                    <label>
                      <strong class="me-2">Upload Background </strong>
                      <InformationIcon />
                    </label>
                    <div class="input-file">
                      <input
                        class="input-file__input"
                        ref="file1"
                        type="file"
                        v-on:change="onUploadBackground"
                      />
                      <div
                        class="input-file__button"
                        @click="$refs.file1.click()"
                      >
                        Upload
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-navigation text-right">
                <!-- <div>Step 1 of 4</div> -->
                <div v-if="!isEdit">
                  <n-button
                    type="primary"
                    :disabled="isUpdating"
                    @click.native="onAddVendor()"
                  >
                    Next
                  </n-button>
                </div>
                <div v-if="isEdit">
                  <n-button
                    type="primary"
                    :disabled="isUpdating"
                    @click.native="onUpdateVendor()"
                  >
                    Save
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="isDisplayed"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <new-user
        v-if="isDisplayed"
        :onClose="onCloseUserModal"
        :isAddNewVendor="true"
        @afterAddedUser="afterAddedUser"
      ></new-user>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { Radio } from "src/components";
import PreviewUpload from "../Components/PreviewUpload";
import { DatePicker, TimeSelect } from "element-ui";
import { humanize } from "src/util/string";
import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";

import InforCircleIcon from "src/components/Icons/InforCircle";
import SettingIcon from "src/components/Icons/Setting";
import KeyIcon from "src/components/Icons/Key";
import BrandingIcon from "src/components/Icons/Branding";
import TokenIcon from "src/components/Icons/Token";
import InformationIcon from "src/components/Icons/Information";
import MapIcon from "src/components/Icons/Map";
import LocationIcon from "src/components/Icons/Location";
import moment from "moment/moment";
import { Checkbox } from "src/components/index";
import NewUser from "src/pages/Dashboard/NewUser.vue";

const compact = (list) => list.filter((x) => !!x);

import _ from "lodash";
const debounced = _.debounce((vm, url, params, cb) => {
  vm.loading = true;
  axios.get(url, { params }).then((response) => {
    if (response.data.results) {
      vm.users = response.data.results
        .map((e) => {
          e.displayName = e.first_name + " " + e.last_name;
          if (e.displayName.trim() === "") {
            e.displayName = "N/A";
          }
          return e;
        })
        .filter((e) => e.id !== vm.user.id);
    } else {
      vm.users = response.data;
    }
    vm.loading = false;
    if (cb) {
      cb(vm.users);
    }
  });
}, 500);

const debouncedLocation = _.debounce((vm, url, params) => {
  if (vm.isAddingLocation) {
    return;
  }
  axios.get(url, { params }).then((response) => {
    if (response.data.results) {
      vm.locations = response.data.results.map((e) => {
        return {
          ...e,
          location: e.booth,
          // status: "Finish Setup",
          isActive: e.is_approved,
        };
      });
    } else {
      vm.locations = [];
    }
  });
}, 500);

export default {
  components: {
    PreviewUpload,
    InforCircleIcon,
    SettingIcon,
    LocationIcon,
    KeyIcon,
    BrandingIcon,
    TokenIcon,
    InformationIcon,
    MapIcon,
    Checkbox,
    [Radio.name]: Radio,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    NewUser,
  },
  props: ["onClose", "rawVendorEdit", "isEdit", "origEvent"],
  computed: {
    administrators() {
      return this.rawVendor.administrators;
    },
    vendor: function () {
      const e = {
        name: this.rawVendor.name,
        description: this.rawVendor.description,
        website: this.rawVendor.website,
        logo:
          typeof this.rawVendor.logo === "string"
            ? undefined
            : this.rawVendor.logo,
        background:
          typeof this.rawVendor.background === "string"
            ? undefined
            : this.rawVendor.background,
        // email: this.rawVendor.email,
        phone: this.rawVendor.phone,
        event: this.$route.params.id,
      };
      const form = new FormData();

      Object.keys(e).map((k) => {
        if (!!e[k]) {
          form.append(k, e[k]);
        }
      });

      return form;
    },
  },
  methods: {
    onShowNewUser() {
      this.users = null;
      this.isDisplayed = true;
      this.isShowSearch = false;

      this.$forceUpdate();
      setTimeout(() => {
        this.isShowSearch = true;
      }, 200);
    },
    afterAddedUser(user) {
      console.log(user);
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.rawVendor.id}/attach/${user.id}/${user.vendorType}/`;
      axios.get(url).then((rs) => {
        this.$message("Update successfully!");
        this.loadVendor();
      });
    },
    handleSelect(item) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.rawVendor.id}/attach/${item}/support/`;
      axios.get(url).then((rs) => {
        this.$message("Attach successfully!");
        this.loadVendor();
        this.filters[0].value = "";
      });
    },
    onChangeUserAttach(value, user) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.rawVendor.id}/attach/${user.user.id}/${value}/`;
      axios.get(url).then((rs) => {
        this.$message("Update successfully!");
        this.loadVendor();
      });
    },
    isAttachedUser(user) {
      return this.administrators.find((e) => e.user === user);
    },
    onCloseUserModal() {
      this.isDisplayed = false;
    },
    handlViewUser(user) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/users/?userId=${user.id}`,
      });
    },
    onActiveUserToVendor(user) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/send-code/email/`;
      axios
        .post(url, {
          email: user.email,
          template: "activate",
        })
        .then((rs) => {
          this.$message(
            "We've sent you an email with activation link. Please check your email"
          );
        });
    },
    onAttachUserToVendor(user) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.rawVendor.id}/attach/${user.id}`;
      axios.get(url).then((rs) => {
        this.$message("Attach successfully!");
      });
    },
    onChangeCheckApprove(checked, row) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/${this.rawVendor.id}/`;
      axios.patch(url, {
        is_approved: checked,
      });
    },
    createLocation() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/`;
      this.isAddingLocation = true;
      axios
        .post(url, {
          vendor_event: this.rawVendor.id || this.$route.params.eventVendorId,
          vendor: this.rawVendor.id || this.$route.params.eventVendorId,
          event: this.$route.params.id,
          booth: this.filters[0].value,
        })
        .then((response) => {
           this.filters[0].value = ''
          this.successMsg = "Successfully add the vendor location";
          setTimeout(() => {
            this.isAddingLocation = false;
          }, 300);
          this.loadLocations();
        })
        .catch((e) => {
          let ms = "";
          if (e.response.status !== 401) {
            ms = humanize(e.response.data);
          } else {
            ms = [e.response.data.detail];
          }
          this.$message(ms.join("."));
        });
    },
    onChangeSearch(vl) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?vendor=${this.rawVendor.id}`;
      debouncedLocation(this, url, {
        search: this.filters[0].value,
      });
    },
    changed: function (key) {
      if (!this.dirties.includes(key)) {
        this.dirties.push(key);
      }
    },
    onUploadBackground(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.rawVendor.image = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewBg = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    onUploadLogo(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.rawVendor.logo = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewLogo = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    onUpdateVendor: function () {
      const self = this;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.rawVendor.id}/`;
      self.successMsg = "";
      this.serverErrors = [];
      const payload = this.vendor;
      if (payload.phone && payload.phone[0] !== "+") {
        payload.phone = "+" + payload.phone;
      }
      this.isUpdating = true;
      axios
        .patch(url, payload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.successMsg = "Successfully update the vendor";
          this.onClose();
          this.isUpdating = false;
          this.rawVendor = {};
        })
        .catch((e) => {
          let ms = "";
          this.isUpdating = false;
          if (e.response.status !== 401) {
            ms = humanize(e.response.data);
          } else {
            ms = [e.response.data.detail];
          }
          this.$message(ms.join("."));
        });
    },
    onAddVendor: function () {
      const self = this;
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/`;
      self.successMsg = "";
      this.serverErrors = [];
      let phone = this.rawVendor.phone;
      if (this.rawVendor.phone) {
        phone =
          (this.rawVendor.phone[0] === "+" ? "" : "+") + this.rawVendor.phone;
      }
      const pl = {
        name: this.rawVendor.name,
        description: this.rawVendor.description,
        website: this.rawVendor.website,
        logo: this.rawVendor.logo,
        background: this.rawVendor.background,
        // email: this.rawVendor.email,
        phone: phone,
        event: this.$route.params.id,
        password: Math.random().toString(36).slice(-8),
      };
      const form = new FormData();

      Object.keys(pl).map((k) => {
        if (!!pl[k]) {
          form.append(k, pl[k]);
        }
      });

      axios
        .post(url, form, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.successMsg = "Successfully add the vendor";
          this.rawVendor = response.data;
          this.loadVendor();
          this.loadLocations();
          this.$emit("onCreateNew", this.rawVendor);

          this.onNext(2);
          //this.rawVendor = {};
        })
        .catch((e) => {
          let ms = "";
          if (e.response.status !== 401) {
            ms = humanize(e.response.data);
          } else {
            ms = [e.response.data.detail];
          }
          this.$message(ms.join("."));
        });
    },
    onChangeStep(step) {
      if (!this.rawVendor?.id) {
      } else {
        if (this.isEdit) {
          this.filters[0].value = "";
          this.formIndex = step;
        } else {
          this.filters[0].value = "";
          this.onNext(step);
        }
      }
    },
    async onNext(step) {
      switch (step) {
        case 2:
          await this.$validator.validate("name");
          // await this.$validator.validate("email");
          break;
        default:
          break;
      }
      if (this.$validator.errors.items.length === 0) {
        this.formIndex = step;
      }
    },
    onUploadBackground(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.rawVendor.background = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewBg = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    onUploadLogo(uploadEvent) {
      const paths = uploadEvent.target.files;
      const self = this;

      if (paths && paths[0]) {
        const path = paths[0];
        self.rawVendor.logo = path;
        const reader = new FileReader();

        reader.onload = (e) => {
          self.previewLogo = e.target.result;
        };

        reader.readAsDataURL(path);
      }
    },
    async loadData(queryInfo, cb) {
      this.loading = true;

      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/`;
      if (this.url) {
        url = this.url;
      }

      let params = {
        search: queryInfo,
        // expand: "balance",
        type: "vendor",
        vendor_event__event: this.rawVendorEdit?.id,
      };

      if (this.params) {
        const passed = this.params;
        params = {
          ...params,
          ...passed,
        };
      }

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          queryInfo.sort.order == "descending" ? "-" : "" + queryInfo.sort.prop;
      }

      if (this.type) {
        params["type"] = this.type;
      }

      debounced(this, url, params, cb);
    },
    complete: function () {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/`;
      this.successMsg = "";
      this.serverErrors = [];

      axios
        .post(url, this.vendor, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.successMsg = "Successfully created the vendor";
          this.rawVendor = {};
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    },
    loadLocations() {
      if (this.rawVendor.id) {
        let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?vendor=${this.rawVendor.id}&search=${this.filters[0].value}`;
        axios.get(url, {}).then((response) => {
          this.locations = response.data.results.map((e) => {
            return {
              ...e,
              location: e.booth,
              // status: "Finish Setup",
              isActive: e.is_approved,
            };
          });
        });
      } else {
        this.locations = [];
      }
    },
    loadVendor() {
      if (this.rawVendor?.id) {
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.rawVendor?.id}/?expand=administrators.user`;
        axios.get(url, {}).then((response) => {
          this.rawVendor = response.data;
        });
      }
    },
  },
  data() {
    let rwEvent = {};
    let previewLogo = null;
    let previewBg = null;
    if (this.isEdit && this.rawVendorEdit) {
      rwEvent = this.rawVendorEdit;
      if (rwEvent.logo) {
        previewLogo = rwEvent.logo;
      }
      if (rwEvent.background) {
        previewBg = rwEvent.background;
      }
    }

    return {
      isAddingLocation: false,
      isUpdating: false,
      isShowSearch: true,
      loading: true,
      isDisplayed: false,
      dirties: [],
      datePicker: null,
      formIndex: 1,
      rawVendor: rwEvent,
      serverErrors: [],
      successMsg: "",
      previewBg,
      previewLogo,
      value: "",
      locations: [
        // {
        //   location: "Grand Electric North",
        //   status: "Finish Setup",
        //   isActive: false,
        // },
        // {
        //   location: "Grand Electric North",
        //   status: "Ready",
        //   isActive: true,
        // },
      ],
      options: [
        {
          value: "owner",
          label: "Owner",
        },
        {
          value: "employee",
          label: "Employee",
        },
      ],
      currentPage: 1,
      pageSize: 30,
      total: 0,
      users: [],
      titles: [
        // {
        //   prop: "first_name",
        //   label: "Name",
        //   formatter: (row) => {
        //     return row.first_name + " " + row.last_name;
        //   },
        // },
        {
          prop: "phone_number",
          label: "Phone",
        },
      ],
      location_titles: [
        {
          prop: "location",
          label: "Location",
        },
        // {
        //   prop: "status_location",
        //   label: "Status",
        // },
        {
          prop: "isActive",
          label: "Status",
        },
      ],
      filters: [
        {
          value: "",
        },
      ],
    };
  },
  mounted() {
    this.loadData({});
    this.loadVendor();
    this.loadLocations();
  },
  mixins: [GetError, User],
};
</script>
<style lang="scss">
.form-event {
  display: flex;
  background: white;
  margin: 0;
}

.right-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .location,
  .returns {
    margin-top: 60px;
  }
}

.form-navigation {
  text-align: right;
  button {
    margin: 0;
    margin-left: 15px;
  }
}

.event-branding,
.logo {
  .image-preview {
    margin-top: 10px;
    height: 162px;
    width: 285px;
    border: solid 1px;
  }
}

.token-value-summary {
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  padding: 5px 18px;
  margin-bottom: 10px;
}

.add-vendor {
  margin-top: 20px;
}

.hint-label {
  font-family: Inter, sans-serif;
  font-size: 11px;
  color: #021b43;
  letter-spacing: 0.39px;
  line-height: 14px;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

.hint-small {
  font-family: Inter, sans-serif;
  font-size: 12px;
  color: #021b43;
}

.editing {
  .left-navigation {
    ul li {
      cursor: pointer;
    }
  }
}
</style>
