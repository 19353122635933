<template>
  <div class="row">
    <div class="col-md-12">
      <h3 class="title-page">
        Organization Admins
        <n-button type="primary azul" @click.native="isDisplayed = !isDisplayed"
          >Add New Admin</n-button
        >
      </h3>
      <spinner v-if="loading" />
      <data-tables-server
        :class="'event-table ' + (loading ? 'hidden' : '')"
        :data="admins"
        :filters="filters"
        @query-change="loadData"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="paramsGrid ? paramsGrid.pageSize : 50"
        :pagination-props="{
          'prev-text': 'Prev',
          'next-text': 'Next',
          pageSizes: [25, 50, 100, 200],
          layout: 'slot, ->, prev, pager, next',
        }"
      >
        <span slot="pagination">
          <span>Showing</span>
          <el-select
            v-if="paramsGrid"
            v-model="paramsGrid.pageSize"
            @change="filterData"
          >
            <el-option :label="'25'" :value="25"> </el-option>
            <el-option :label="'50'" :value="50"> </el-option>
            <el-option :label="'100'" :value="100"> </el-option>
            <el-option :label="'200'" :value="200"> </el-option>
          </el-select>
          <span>of {{ total }}</span>
        </span>
        <el-table-column
          v-for="(title, index) in titles"
          :label="title.label"
          :key="index"
          :prop="title.prop"
          :sortable="false"
          :formatter="title.formatter"
          :minWidth="title.width"
        >
        </el-table-column>

        <el-table-column :label="''" :width="50">
          <template slot-scope="scope">
            <div :class="'action-cl'" disable-transitions>
              <el-dropdown type="dropdown-modal">
                <span class="el-dropdown-link">
                  <img
                    class="dropdown-dot"
                    src="assets/imgs/dot-dropdown.svg"
                    alt=""
                  />
                </span>
                <el-dropdown-menu class="dropdown-table" slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    @click.native="onView(scope.row)"
                  >
                    <svg
                      width="16px"
                      height="11px"
                      viewBox="0 0 16 11"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="📦-Orders"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Orders"
                          transform="translate(-1267, -359)"
                          fill="currentColor"
                          fill-rule="nonzero"
                        >
                          <g
                            id="dropdown-copy-2"
                            transform="translate(1255, 345)"
                          >
                            <g id="Group-16" transform="translate(12, 11)">
                              <path
                                d="M8,5.29165197 C7.70559036,5.29641209 7.41335972,5.34464549 7.13222057,5.43489583 C7.26226545,5.67074173 7.33150682,5.93703121 7.33333207,6.20833333 C7.33333207,7.09429012 6.6368848,7.8125 5.77777356,7.8125 C5.51469222,7.81061772 5.25647162,7.73921269 5.02777213,7.60510417 C4.65773102,8.92856003 5.15040745,10.3477198 6.2508765,11.1282695 C7.35134554,11.9088192 8.81268162,11.8756173 9.87874747,11.0458436 C10.9448133,10.2160698 11.3764028,8.77591051 10.9501991,7.47054548 C10.5239955,6.16518045 9.33563116,5.28751025 8,5.29165197 L8,5.29165197 Z M15.9033483,8.08177083 C14.3969566,5.05075521 11.4147287,3 8,3 C4.58527129,3 1.60221007,5.0521875 0.0966516601,8.08205729 C-0.03221722,8.34494005 -0.03221722,8.65534641 0.0966516601,8.91822917 C1.60304341,11.9492448 4.58527129,14 8,14 C11.4147287,14 14.3977899,11.9478125 15.9033483,8.91794271 C16.0322172,8.65505995 16.0322172,8.34465359 15.9033483,8.08177083 Z M8,12.625 C5.25971702,12.625 2.74749003,11.0494792 1.39082078,8.5 C2.74749003,5.95052083 5.25943924,4.375 8,4.375 C10.7405608,4.375 13.25251,5.95052083 14.6091792,8.5 C13.2527878,11.0494792 10.7405608,12.625 8,12.625 Z"
                                id="Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span style="font-size: 12px; padding-left: 5px">View</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </data-tables-server>
    </div>

    <el-dialog
      :visible.sync="isDisplayed"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>

      <div class="form-event">
        <div class="left-navigation" style="width: 350px">
          <h4>{{ this.newAdmin.user.id ? "Edit Admin" : "New Admin" }}</h4>
          <ul>
            <li class="active">
              <InforCircleIcon />Admin Organization Details
            </li>
          </ul>
        </div>
        <div class="right-panel-event">
          <button
            type="button"
            class="close-button-md"
            @click="isDisplayed = false"
          >
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <h3><InforCircleIcon />Organization Details</h3>

              <div class="alert alert-success" v-if="successMsg">
                {{ successMsg }}
              </div>
              <div class="row add-admin-container">
                <div class="col-xs-12">
                  <fg-input
                    v-model="newAdmin.user.first_name"
                    v-validate="'required'"
                    name="first_name"
                    :error="getError('user.first_name')"
                    class="no-border form-control-lg"
                    placeholder="First Name..."
                    addon-left-icon="now-ui-icons users_circle-08"
                  ></fg-input>

                  <fg-input
                    v-model="newAdmin.user.last_name"
                    v-validate="'required'"
                    name="last_name"
                    :error="getError('user.last_name')"
                    class="no-border form-control-lg"
                    placeholder="Last Name..."
                    addon-left-icon="now-ui-icons users_circle-08"
                  ></fg-input>

                  <fg-input
                    v-model="newAdmin.user.email"
                    v-validate="'required|email'"
                    name="email"
                    :error="getError('user.email')"
                    class="no-border form-control-lg"
                    placeholder="Email..."
                    addon-left-icon="now-ui-icons ui-1_email-85"
                  ></fg-input>
                  <fg-input
                    v-model="newAdmin.user.phone_number"
                    v-validate="'required'"
                    class="no-border form-control-lg"
                    name="phone"
                    :error="getError('user.phone_number')"
                    placeholder="Phone..."
                    addon-left-icon="now-ui-icons tech_mobile"
                  ></fg-input>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col--12">
                  <loading-component
                    class="submit-container"
                    v-bind:loading="addOrgAdminLoading"
                  >
                    <n-button type="primary" @click.native="addOrgAdmin"
                      >Submit</n-button
                    >
                  </loading-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "src/components/Spinner";
import LoadingComponent from "src/components/LoadingComponent";
import User from "src/mixins/User";
import lodash from "lodash";
import InforCircleIcon from "src/components/Icons/InforCircle";

const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    vm.loading = false;
    if (response.data.results) {
      vm.admins = response.data.results.map((v) => {
        v.status = "saved";
        return v;
      });
      vm.total = response.data.count;
    } else {
      vm.organizations = response.data;
      vm.total = vm.users.length;
    }
  });
}, 500);

export default {
  components: {
    Spinner,
    LoadingComponent,
    InforCircleIcon,
  },
  computed: {},
  methods: {
    onView(data) {
      this.newAdmin = {
        user: data.user,
      };

      this.isDisplayed = true;
    },
    getError(fieldName) {
      return lodash.get(this.errors, `${fieldName}[0]`);
    },

    getProperty: function (target, property) {
      return lodash.get(target, property);
    },

    addOrgAdmin: async function () {
      this.errors = {};
      this.newAdmin["organization_id"] = this.$route.params.id;
      this.addOrgAdminLoading = true;
      if (this.newAdmin.user.id) {
        try {
          const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/${this.newAdmin.user.id}/`;
          const payload = {
            email: this.newAdmin.user.email,
            first_name: this.newAdmin.user.first_name,
            last_name: this.newAdmin.user.last_name,
            phone_number: this.newAdmin.user.phone_number,
          };
          const response = await axios.patch(url, payload);
          this.fetchOrgAdmins();
          this.successMsg = "Successfully updated.";
          this.addOrgAdminLoading = false;
        } catch (error) {
          const errors = error.response.data;
          this.errors = errors;

          let msg = "";
          Object.keys(errors).forEach((item) => {
            msg += `${item}: ${errors[item]}\n`;
          });

          this.addOrgAdminLoading = false;
          throw error;
        }
      } else {
        try {
          const url = `${process.env.VUE_APP_BASE_HOST}/en/api/organization-administrator/`;
          const response = await axios.post(url, this.newAdmin);
          this.fetchOrgAdmins();
          this.successMsg = "Successfully add new admin";
          this.addOrgAdminLoading = false;
          this.userQuery = "";
          this.newAdmin = {
            user: {
              email: "",
              first_name: "",
              last_name: "",
              phone_number: "+",
              gender: "male",
              dob: "2000-01-01",
            },
          };
        } catch (error) {
          const errors = error.response.data;
          this.errors = errors;

          let msg = "";
          Object.keys(errors).forEach((item) => {
            msg += `${item}: ${errors[item]}\n`;
          });

          this.addOrgAdminLoading = false;
          throw error;
        }
      }
    },

    async loadData(queryInfo, isRefresh = false) {
      if (!isRefresh && !this.organizations) {
        this.loading = true;
      }
      if (!this.paramsGrid) {
        this.paramsGrid = {
          ...queryInfo,
        };
      }

      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/organization-administrator/?organization=${this.$route.params.id}&expand=user&status=saved`;
      if (this.url) {
        url = this.url;
      }

      let params = {
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        search: this.filters[0].value,
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          (queryInfo.sort.order == "descending" ? "-" : "") +
          queryInfo.sort.prop;
      }

      debounced(this, url, params);
    },

    fetchOrgAdmins() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/organization-administrator/?organization=${this.$route.params.id}&expand=user&status=saved`;
      axios.get(url).then((response) => {
        this.admins = response.data.results.map((v) => {
          v.status = "saved";
          return v;
        });
        this.loading = false;
      });
    },
  },
  data: () => ({
    errors: {},
    admins: [],
    newAdmin: {
      user: {
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "+",
        gender: "male",
        dob: "2000-01-01",
      },
    },
    isDisplayed: false,
    successMsg: "",
    loading: true,
    addOrgAdminLoading: false,
    currentPage: 1,
    pageSize: 50,
    total: 0,
    paramsGrid: null,
    filters: [
      {
        value: "",
      },
    ],
    titles: [
      {
        prop: "user",
        label: "Name",
        width: 180,
        formatter: (row) => {
          return row.user.first_name + " " + row.user.last_name;
        },
      },
      {
        prop: "user.email",
        label: "Email",
        width: 140,
      },
      {
        prop: "user.phone_number",
        label: "Phone",
        width: 150,
      },
    ],
  }),
  mounted() {
    // this.fetchOrgAdmins();
  },
  mixins: [User],
};
</script>
<style lang="scss">
.add-admin-container {
  .btn {
    margin: 0;
  }
  input {
    height: 38px;
  }
}
</style>
