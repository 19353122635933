<template>
  <div class="drag-to-upload">
    <div
      class="holder"
      :class="isHover ? 'hover' : ''"
      @dragover.prevent="isHover = true"
      @dragend="isHover = false"
      @drop="handleDrop"
    >
      <img :src="previewImage()" />
      <div class="clear-btn" v-if="previewImage()" v-on:click="clearImage($event)">✖️</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {},
  methods: {
    previewImage: function() {
      return this.preview || this.src;
    },

    handleDrop: function(e) {
      e.preventDefault();
      const self = this;
      this.isHover = false;

      const paths = e.dataTransfer.files,
        reader = new FileReader();

      if (paths && paths[0]) {
        const path = paths[0];
        self.$emit("input", path);

        reader.onload = e => {
          self.preview = e.target.result;
        };

        reader.readAsDataURL(path);
      }
      return false;
    },

    clearImage: function(event) {
      this.$emit("input", null);
      this.preview = this.src = null;
    }
  },
  props: {
    src: {
      type: String,
      default: null
    }
  },
  data: () => ({
    preview: null,
    supported: false,
    isHover: false
  }),
  mounted() {
    if (typeof window.FileReader !== "undefined") {
      this.supported = true;
    }
  }
};
</script>
<style lang="scss">
.drag-to-upload .holder {
  border: 1px dashed #ccc;
  width: 200px;
  max-height: 200px;
  min-height: 150px;
  overflow: hidden;
  position: relative;
}
.drag-to-upload .holder .hover {
  border: 1px dashed #333;
}

.drag-to-upload .clear-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
}
</style>
