import axios from "axios";
import moment from "moment";
import 'moment-timezone';

export default {
  computed: {
    events() {
      if (this.$store.state.events) {
        return this.$store.getters.allEvents;
      }
      return false;
    },
    currentEvent() {
      console.log("current event", this.$route.params.id);
      const event = this.events.find(
        (event) => event.id == this.$route.params.id
      );
      if (event && event.timezone) {
        moment.tz.setDefault(event.timezone);
      }
      return event;
    },
  },
  methods: {
    setEvent(e) {
      console.log(e);
      this.$router.push(`/pages/events/${e.target.value}/dashboard`);
    },
  },
  async created() {},
};
