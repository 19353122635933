<template>
  <card
    v-on:click="$emit('clicked', event)"
    class="card-chart event-card"
    no-footer-line
  >
    <div v-on:click="$emit('clicked', event)">
      <div class="d-flex">
        <div class="img-sec">
          <router-link
            v-if="showLinks"
            :to="{
              name: $store.state.user.type === 'cash' ? 'Users' : 'Dashboard',
              params: { id: event.id },
            }"
          >
            <img :src="event.image ? event.image : 'img/placeholder.jpg'" />
          </router-link>
          <img
            v-else
            :src="event.image ? event.image : 'img/placeholder.jpg'"
          />
        </div>
        <div class="des-sec">
          <p class="card-category">
            <router-link
              v-if="showLinks"
              :to="{
                name: $store.state.user.type === 'cash' ? 'Users' : 'Dashboard',
                params: { id: event.id },
              }"
              >{{ event.name }}
            </router-link>
            <span v-else>{{ event.name }}</span>
          </p>
          <drop-down
            v-if="showLinks && $store.state.user.type !== 'cash'"
            :hide-arrow="true"
            position="right"
          >
            <n-button
              slot="title"
              class="dropdown-toggle no-caret"
              round
              simple
              icon
            >
              <img :src="'img/icons/arrow-right.svg'" class="btn-arrow-right m-auto" />
              <!-- <i class="now-ui-icons arrows-1_minimal-right"></i> -->
            </n-button>
            <router-link
              class="dropdown-item"
              :to="{ path: `/pages/events/${event.id}/dashboard` }"
              >Event Dashboard</router-link
            >
            <router-link
              v-if="$store.state.user.type === 'org-administrator'"
              class="dropdown-item"
              :to="{ path: `/pages/events/${event.id}/vendors` }"
              >Vendors</router-link
            >

            <router-link
              v-if="$store.state.user.type !== 'cash'"
              class="dropdown-item"
              :to="{ path: `/pages/events/${event.id}/orders` }"
              >Orders</router-link
            >
            <router-link
              v-if="$store.state.user.type === 'org-administrator'"
              class="dropdown-item"
              :to="{ path: `/pages/events/${event.id}/users` }"
              >Users</router-link
            >
          </drop-down>
          <div slot="footer" class="stats small-stats pb-2">
            {{ event.start_date_time | moment("dddd, MMMM Do YYYY") }} -
            {{ event.end_date_time | moment("dddd, MMMM Do YYYY") }}
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import { Card } from "src/components";
export default {
  components: {
    Card,
  },
  props: ["event", "showLinks"],
};
</script>

<style scoped lang="scss">
.img-sec {
  margin-right: 15px;
  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
  .btn-arrow-right {
    margin: auto;
    width: 7px;
  }
}
.des-sec {
  width: calc(100% - 120px);
}
</style>
