<template>
  <div class="no-tab-content-inside">
    <h3 class="title-page">
      Menu
      <n-button type="primary azul" @click.native="newItem()"
        >New Item</n-button
      >
      <!-- <n-button type="primary azul">Bulk Import</n-button> -->
    </h3>

    <tabs type="primary" v-model="tab" v-on:change="onTabChange">
      <!-- <tab-pane label="Alcohol" :badge="tabsCount.cancelled || 0"> </tab-pane> -->
      <!-- <tab-pane label="Drink" :badge="tabsCount.delivered || 0"> </tab-pane>
      <tab-pane label="Appetizer" :badge="tabsCount.accepted || 0"> </tab-pane>
      <tab-pane label="Main" :badge="tabsCount.completed || 0"> </tab-pane> -->
      <!-- <tab-pane label="All Items" :badge="allData.count"> </tab-pane> -->
    </tabs>
    <div class="tab-content-outside" v-if="tab === 'All Items'">
      <div class="search-table">
        <el-input
          placeholder="Search by Item, Type, Vendor or Dietary Restriction... "
          v-model="filters[0].value"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <!-- :action-col="withOutActions == true ? {} : actionCol" -->
      <data-tables-server
        v-show="!loading"
        class="event-table"
        :data="allData.data"
        :filters="filters"
        :total="allData.count"
        @query-change="loadData"
        :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->

        <el-table-column label="Item Name" :minWidth="200">
          <template slot-scope="scope">
            <a href="javascript:void(0)" @click="handleEdit(scope.row)">
              {{ scope.row.name_en }}
            </a>
          </template>
        </el-table-column>

        <el-table-column
          v-for="title in titles"
          :prop="title.prop"
          :label="title.label"
          :key="title.prop"
          sortable="custom"
          :minWidth="
            title.prop === 'price'
              ? 100
              : title.prop === 'description_en'
              ? 250
              : 140
          "
          :formatter="title.formatter"
          v-if="title.prop !== 'category' && title.prop !== 'name'"
        >
        </el-table-column>

        <el-table-column
          v-for="title in titles"
          :prop="title.prop"
          :label="title.label"
          :key="title.prop"
          sortable="custom"
          :minWidth="150"
          :formatter="title.formatter"
          v-if="title.prop === 'category'"
        >
          <template slot-scope="scope">
            <div
              :class="
                'inline-flex items-center capitalize gap-x-1 whitespace-nowrap rounded px-1.5 py-0.5 text-xs font-semibold ring-1 bg-gray-50 text-gray-700 ring-gray-600/30 ' +
                (scope.row.is_alcoholic
                  ? 'bg-red-50 text-red-700 ring-red-600/30'
                  : scope.row.featured
                  ? 'bg-yellow-50 text-yellow-800 ring-yellow-600/30'
                  : scope.row.is_beverage
                  ? 'bg-purple-50 text-purple-800 ring-purple-600/30'
                  : 'bg-blue-50 text-blue-800 ring-blue-600/30')
              "
              disable-transitions
            >
              {{
                scope.row.is_alcoholic
                  ? "Alcohol"
                  : scope.row.featured
                  ? "Product"
                  : scope.row.is_beverage
                  ? "Appetizer"
                  : "Main"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="''" :width="50">
          <template slot-scope="scope">
            <div :class="'action-cl'" disable-transitions>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <img
                    class="dropdown-dot"
                    src="assets/imgs/dot-dropdown.svg"
                    alt=""
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    @click.native="handleEdit(scope.row)"
                    ><i class="el-icon-view"></i> View</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </data-tables-server>
      <spinner v-if="loading" />
    </div>

    <el-dialog
      :visible.sync="dialogTableVisible"
      :custom-class="'event-dialog menu-form'"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-event" v-if="selectedTabModal === 'location'">
            <div class="left-navigation">
              <h4>{{ isEdit ? rawEvent.name_en : "New Item" }}</h4>
              <ul>
                <li @click="onChangeStep('detail')">
                  <InforCircleIcon />Item Details
                </li>
                <!-- <li @click="onChangeStep('location')" class="active">
                  <LocationIcon />Locations & Availability
                </li> -->
              </ul>
            </div>
            <div class="right-panel-event">
              <button type="button" class="close-button-md" @click="onClose()">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <div
                class="wrap-content-md"
                v-if="selectedTabModal === 'location'"
                style="min-height: 545px"
              >
                <div class="infor-modal">
                  <h3><LocationIcon />Vendor</h3>
                </div>

                <form action="" class="event-form-2 mt-3">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group form-event-wrap">
                        <label for="" class="event-label">Vendor</label>
                        <el-select v-model="selectedItem.vendor_event">
                          <template v-for="(vendor, index) in vendorEvents">
                            <el-option :label="vendor.booth" :value="vendor.id">
                            </el-option>
                          </template>
                        </el-select>
                      </div>
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <div class="col-12 col-md-4">
                        <div class="form-group" style="margin-top: 34px">
                          <el-checkbox v-model="checkedVendor"
                            >Item Available</el-checkbox
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <div class="form-group" style="margin-top: 34px">
                        <el-checkbox v-model="checkedFeatured"
                          >Featured</el-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="form-event" v-if="selectedTabModal === 'detail'">
            <div class="left-navigation">
              <h4>{{ isEdit ? rawEvent.name_en : "New Item" }}</h4>
              <ul>
                <li @click="onChangeStep('detail')" class="active">
                  <InforCircleIcon />Item Details
                </li>
                <!-- <li @click="onChangeStep('location')">
                  <LocationIcon />Locations & Availability
                </li> -->
              </ul>
            </div>
            <div class="right-panel-event">
              <button type="button" class="close-button-md" @click="onClose()">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <div class="wrap-content-md" v-if="selectedTabModal === 'detail'">
                <div class="infor-modal">
                  <h3><InforCircleIcon />Item Details</h3>
                </div>
                <form action="" class="event-form-2 mt-3">
                  <div
                    class="row"
                    v-if="user.type !== 'vendor' && user.type !== 'cash'"
                  >
                    <div class="col-12 col-md-12">
                      <div class="form-group form-event-wrap">
                        <label for="" class="event-label">Vendor</label>
                        <el-select v-model="selectedItem.vendor_event">
                          <template v-for="(vendor, index) in vendorEvents">
                            <el-option :label="vendor.booth" :value="vendor.id">
                            </el-option>
                          </template>
                        </el-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <label for="">Item Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Item Name"
                          v-model="selectedItem.name_en"
                        />
                        <div class="wrap-absolute">
                          <el-checkbox v-model="selectedItem.is_available"
                            >Available</el-checkbox
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-12 col-md-5">
                      <div class="form-group">
                        <label for="">Type</label>
                        <select class="form-control">
                          <option value="0">select type</option>
                        </select>
                      </div>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="">Description</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control"
                          v-model="selectedItem.description_en"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="">Token Price</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text h-100">
                              <img src="assets/imgs/token-gray.svg" alt="" />
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Token Price"
                            v-model="selectedItem.price"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="">Discount Price</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <img src="assets/imgs/token-gray.svg" alt="" />
                            </div>
                          </div>

                          <el-input
                            class="form-control"
                            placeholder="Discount Price"
                            suffix-icon="el-icon-lock"
                            :disabled="!selectedItem.discounted"
                            v-model="selectedItem.discount_price"
                          >
                          </el-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group" style="margin-top: 34px">
                        <el-checkbox v-model="selectedItem.discounted"
                          >Discount Price</el-checkbox
                        >
                      </div>
                    </div>
                  </div>

                  <div class="infor-modal mt-3">
                    <span> Dietary Options</span>
                  </div>
                  <div class="row mt-3 form-group">
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="selectedItem.is_vegetarian"
                        >Vegetarian</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="selectedItem.is_vegan"
                        >Vegan</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="selectedItem.is_dairyfree"
                        >Dairy Free</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="selectedItem.is_glutenfree"
                        >Gluten Free</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="selectedItem.is_beverage"
                        >Is Beverage</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="selectedItem.is_alcoholic"
                        >Is Alcoholic</el-checkbox
                      >
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <div style="width: 170px">
                        <button
                          type="button"
                          class="btn btn-primary azul m-0 w-100"
                          @click="save"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import User from "src/mixins/User";
import moment from "moment";
import Spinner from "src/components/Spinner";
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import { formatRevenue } from "@/util/string.js";
import EventMixin from "@/mixins/Event.js";
import LocationIcon from "src/components/Icons/Location";
import InforCircleIcon from "src/components/Icons/InforCircle";
import { humanize } from "src/util/string";

const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    vm.allData.count = response.data.count;
    vm.loading = false;
    vm.allData.data = response.data.results;
  });
}, 500);

export default {
  props: ["event"],
  mixins: [User, EventMixin],
  components: {
    TabPane,
    Tabs,
    Spinner,
    LocationIcon,
    InforCircleIcon,
  },
  computed: {},
  data() {
    return {
      checkedVendor: false,
      checkedFeatured: false,
      selectedTabModal: "detail",
      tab: "All Items",
      tabsCount: {},
      total: 0,
      allData: {},
      loading: false,
      showNewItemDetail: false,
      dialogTableVisible: false,
      selectedItem: {},
      isEdit: false,
      timer: null,
      currentPriceType: "token",
      isTurnOnPageRefresh: false,
      titles: [
        {
          prop: "name",
          label: "Item Name",
        },

        {
          prop: "vendor_event.vendor.name",
          label: "Vendor",
        },
        {
          prop: "price",
          label: "Price",
          width: 120,
          formatter: (row, col, cell, index) => {
            let value = formatRevenue(row.price, this.currentEvent, 3);
            value = value.split(" ");
            // if (row.price < 0) {
            //   if (this.currentPriceType === "token") {
            //     return (
            //       <div style="color: #f27474">
            //         <span>{value[0].replace("-", "")}</span>{" "}
            //       </div>
            //     );
            //   }
            //   return (
            //     <div style="color: #f27474">
            //       <span>{value[1].replace("-", "")}</span>
            //     </div>
            //   );
            // }

            // if (row.price > 0) {
            //   if (this.currentPriceType === "token") {
            //     return (
            //       <div style="color: #2d61d3">
            //         <span>{value[0].replace("-", "")}</span>{" "}
            //       </div>
            //     );
            //   }
            //   return (
            //     <div style="color: #2d61d3">
            //       <span>{value[1].replace("-", "")}</span>
            //     </div>
            //   );
            // }

            if (this.currentPriceType === "token") {
              return (
                <div>
                  <span>{value[0].replace("-", "")}</span>
                </div>
              );
            }
            return (
              <div>
                <span>{value[1].replace("-", "")}</span>
              </div>
            );
          },
        },

        {
          prop: "description_en",
          label: "Description",
        },
        // {
        //   prop: "category",
        //   label: "Type",
        // },
      ],
      filters: [
        {
          prop: "name",
          value: "",
        },
      ],
      params: {
        offset: 0,
        limit: 50,
        status: "",
      },
      vendorEvents: [],
    };
  },
  methods: {
    onClose() {
      this.dialogTableVisible = false;
      this.selectedItem = {
        vendor_event: null,
      };
      this.isEdit = false;
    },
    onChangeStep(step) {
      this.selectedTabModal = step;
    },
    onTabChange(tab) {
      switch (tab) {
        case "Cancelled":
          this.params.status = "cancelled";
          break;
        case "Ready":
          this.params.status = "completed";
          break;
        case "In Progress":
          this.params.status = "accepted";
          break;
        case "Delivered":
          this.params.status = "delivered";
          break;

        default:
          this.params.status = "";
          break;
      }
      this.loadData(this.params);
    },
    newItem() {
      this.selectedItem = {
        vendor_event: null,
      };
      this.dialogTableVisible = true;
      this.isEdit = false;
    },
    handleEdit(row) {
      this.selectedItem = JSON.parse(JSON.stringify(row));
      this.selectedItem.vendor_event = this.selectedItem.vendor_event.id;
      this.rawEvent = row;
      this.isEdit = true;
      this.dialogTableVisible = true;
    },
    async loadData(queryInfo, isRefresh = false) {
      if (!isRefresh) {
        this.orders = [];
        this.loading = true;
      }

      console.log(queryInfo);

      const url = `${process.env.VUE_APP_BASE_HOST}/api/vendor-event-item/`;
      const params = {
        ...queryInfo,
        vendor_event__event: this.$route.params.id,
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        expand: "vendor_event,vendor_event.vendor,user",
        search: this.filters[0].value,
      };
      if (this.user && this.user.type === "vendor") {
        params.vendor_event__vendor = this.user.user_type.vendor.id;
      }

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          queryInfo.sort.order == "descending" ? "-" : "" + queryInfo.sort.prop;
      }

      debounced(this, url, params);
    },
    async save() {
      const urlAppend = this.selectedItem.id ? `${this.selectedItem.id}/` : "";
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/${urlAppend}`;

      const func = urlAppend ? axios.put : axios.post;
      const data = {
        ...this.selectedItem,
        name: this.selectedItem.name_en,
        price: this.selectedItem.price ? Number(this.selectedItem.price) : 0,
        // vendor_event__event: Number(this.$route.params.id),
      };

      if (!data.discounted) {
        data.discount_price = 0;
      }

      if (!data.vendor_event) {
        data.vendor_event = this.selectedItem?.vendor_event;
      } else {
        data.vendor_event = data.vendor_event.id
          ? data.vendor_event.id
          : data.vendor_event;
      }
      if (!data.vendor_event && this.user?.user_type?.vendor?.id) {
        const vendorData = this.vendorEvents.find(
          (e) => e.vendor.id === this.user?.user_type?.vendor?.id
        );
        if (vendorData) {
          data.vendor_event = vendorData.id;
        }
      }
      try {
        const response = await func(url, data);
        this.$message("Saved Successfully");
        this.loadData(this.params);
        this.onClose();
      } catch (e) {
        console.log(e);
        this.$message(humanize(e.response.data).join(" "));
      }
    },
    fetchVendorEvents() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?event=${this.$route.params.id}&expand=vendor`;

      if (this.$store.state.user.type == "vendor") {
        url += `&vendor=${this.$store.state.user.user_type.vendor.id}`;
      }
      axios.get(url).then((response) => {
        this.vendorEvents = response.data.results;
      });
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  mounted() {
    this.fetchVendorEvents();
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.loadData(this.params, true);
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.loadData(this.params, true);
      }, 10000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
  },

  beforeMount() {
    clearInterval(this.timer);
  },
  watch: {
    selectedItem(newValue, oldValue) {
      // if(!newValue.discounted) {
      //   this.sel
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 1em;
}

.form-event {
  display: flex;
  background: white;
  margin: 0;
}

.right-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .location,
  .returns {
    margin-top: 60px;
  }
}

.form-navigation {
  text-align: right;
  button {
    margin: 0;
    margin-left: 15px;
  }
}

.event-branding,
.logo {
  .image-preview {
    margin-top: 10px;
    height: 162px;
    width: 285px;
    border: solid 1px;
  }
}

.token-value-summary {
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  padding: 5px 18px;
  margin-bottom: 10px;
}

.add-vendor {
  margin-top: 20px;
}

.hint-label {
  font-family: Inter, sans-serif;
  font-size: 11px;
  color: #021b43;
  letter-spacing: 0.39px;
  line-height: 14px;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

.hint-small {
  font-family: Inter, sans-serif;
  font-size: 12px;
  color: #021b43;
}

.editing {
  .left-navigation {
    ul li {
      cursor: pointer;
    }
  }
}

.left-navigation {
  width: 350px !important;
}

.right-panel-event {
  width: calc(100% - 350px) !important;
}

.menu-form .event-form-2 {
  .form-group {
    margin-bottom: 20px;
    label {
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: 0.39px;
      color: #021b43;
      margin-bottom: 8px;
    }
    .input-group-text {
      padding: 10px;
      background: #fff;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
