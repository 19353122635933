<template>
  <div>
    <div v-if="currentEvent">
      <div class="title-row">
        <h3 class="title-page d-flex">
          {{ vendor.name }}
          <div
            class="wrap-datepicker"
            style="
              width: 300px;
              display: flex;
              margin-left: auto;
              justify-content: flex-end;
            "
            v-if="rawDateRange"
          >
            <DatePickerDropdown
              :rawDateRange="rawDateRange"
              v-if="rawDateRange"
              ref="datepicker"
              :hideDateType="true"
              @onChangeDate="onUpdateDate"
            />

            <n-button
              style="
                margin: 0px 0px 0px 20px;
                padding: 10px;
                font-size: 10px;
                background-color: #396ad5;
                font-weight: 600;
                color: white;
                white-space: nowrap;
              "
              slot="title"
              @click.native="onReset"
            >
              Reset To Default
            </n-button>
          </div>
        </h3>
        <order-summary
          v-if="rawDateRange"
          :summary="summary"
          :hideTokens="true"
          :currentPriceType="currentPriceType"
        />
        <DashboardHeader :groupType="'hour'"/>

        <h4 class="title-page">
          {{
            items && items.length > 1 ? "Vendor Locations" : "Vendor Location"
          }}

          <n-button type="primary azul" @click.native="onShowCreate()"
            >New Location</n-button
          >
        </h4>
        <data-tables-server
          class="event-table footer-pg"
          v-show="!loading"
          :data="items"
          :total="count"
          :filters="filters"
          @query-change="loadData"
          :page-size="params ? params.pageSize : 50"
          :pagination-props="{
            'prev-text': 'Prev',
            'next-text': 'Next',
            pageSizes: [25, 50, 100, 200],
            layout: 'slot, ->, prev, pager, next',
          }"
        >
          <span slot="pagination">
            <span>Showing</span>
            <el-select
              v-if="params && params.pageSize"
              v-model="params.pageSize"
              @change="filterData"
            >
              <el-option :label="'25'" :value="25"> </el-option>
              <el-option :label="'50'" :value="50"> </el-option>
              <el-option :label="'100'" :value="100"> </el-option>
              <el-option :label="'200'" :value="200"> </el-option>
            </el-select>
            <span>of {{ count }}</span>
          </span>

          <el-table-column
            v-for="title in titles"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            :sortable="title.isSortable"
            :minWidth="150"
            v-if="title.prop === 'booth'"
            ><template slot-scope="scope">
              <div>
                <router-link
                  :to="{
                    name: 'EventVendorDetail',
                    params: {
                      id: $route.params.id,
                      vendorId: scope.row.vendor,
                      eventVendorId: scope.row.id,
                    },
                  }"
                  >{{ scope.row.booth }}</router-link
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="title in titles"
            :prop="title.prop"
            :label="title.label"
            :key="title.prop"
            :formatter="title.formatter"
            :sortable="title.isSortable"
            v-if="title.prop !== 'booth'"
            :minWidth="180"
          >
          </el-table-column>

          <el-table-column :label="''" width="150">
            <template slot-scope="scope">
              <checkbox
                v-model="scope.row.is_approved"
                class="pull-left"
                name="subscribe"
                @input="onChangeCheckApprove($event, scope.row)"
              >
                Approved
              </checkbox>
            </template>
          </el-table-column>
          <el-table-column :label="''">
            <template slot-scope="scope">
              <div :class="'action-cl'" disable-transitions>
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <img
                      class="dropdown-dot"
                      src="assets/imgs/dot-dropdown.svg"
                      alt=""
                    />
                  </span>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      command="c"
                      @click.native="handlerView(scope.row)"
                      ><ViewIcon /> View
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </data-tables-server>

        <h4 class="title-page mt-3">
          {{
            administrators && administrators.length > 1
              ? "Vendor Staff"
              : "Vendor Staff"
          }}
        </h4>

        <div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex wrap-select-input">
                <el-select
                  v-model="filtersUsers[0].value"
                  filterable
                  class="w-100"
                  remote
                  reserve-keyword
                  v-if="isShowSearch"
                  placeholder="Search by Name, Phone or Email"
                  :remote-method="loadDataUser"
                  :loading="loadingUser"
                  @change="handleSelect"
                >
                  <div slot="empty" style="padding: 15px; text-align: center">
                    <template v-if="loadingUser">
                      <spinner></spinner>
                    </template>
                    <template v-else>
                      There is no data(s).
                      <n-button
                        style="
                          margin: 0;
                          margin-left: 15px;
                          white-space: nowrap;
                        "
                        type="default"
                        @click.native="onShowNewUser()"
                      >
                        Add Vendor Staff ?
                      </n-button>
                    </template>
                  </div>
                  <el-option
                    v-if="users"
                    v-for="item in users"
                    :key="item.value"
                    :label="item.displayName"
                    :value="item.id"
                  >
                    <div
                      class="d-flex w-100 align-items-center justify-content-between w-100"
                      style="padding: 10px; gap: 10px"
                    >
                      <div
                        class="value"
                        style="
                          font-family: 'Inter';
                          font-size: 14px;
                          color: #021b43;
                          letter-spacing: 0.43px;
                          text-align: left;
                          line-height: 14px;
                          min-width: calc(100% / 3);
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.displayName }}
                      </div>
                      <div
                        class="phone"
                        style="
                          font-family: 'Inter';
                          font-size: 12px;
                          color: #8e8e93;
                          letter-spacing: 0.43px;
                          text-align: left;
                          line-height: 14px;
                          min-width: calc(100% / 3);
                        "
                      >
                        {{ item.phone_number }}
                      </div>
                      <div
                        class="email"
                        style="
                          font-family: 'Inter';
                          font-size: 12px;
                          color: #8e8e93;
                          letter-spacing: 0.43px;
                          text-align: left;
                          line-height: 14px;
                          min-width: calc(100% / 3);
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.email }}
                      </div>
                    </div>
                  </el-option>
                </el-select>
                <n-button
                  type="primary azul"
                  @click.native="isDisplayed = true"
                  style="white-space: nowrap"
                  >Add Vendor Staff</n-button
                >
              </div>

              <data-tables-server
                class="event-table create-event-table"
                :data="administrators"
              >
                <el-table-column :label="'Name'">
                  <template slot-scope="scope">
                    <a
                      href="javascript:void(0)"
                      @click="handlViewUser(scope.row)"
                    >
                      <template
                        v-if="
                          scope.row.user.first_name && scope.row.user.last_name
                        "
                      >
                        {{
                          scope.row.user.first_name +
                          " " +
                          scope.row.user.last_name
                        }}
                      </template>
                      <template v-else> N/A </template>
                    </a>
                  </template>
                </el-table-column>

                <el-table-column :label="'Email'">
                  <template slot-scope="scope">
                    {{ scope.row.user.email }}
                  </template>
                </el-table-column>

                <el-table-column :label="''">
                  <template slot-scope="scope">
                    <div :class="'action-create-event'" disable-transitions>
                      <el-select
                        v-model="scope.row.type"
                        @change="onChangeUserAttach($event, scope.row)"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
              </data-tables-server>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="isDisplayed"
      custom-class="event-dialog"
      append-to-body
      :before-close="handleClose"
    >
      <template #title>
        <div></div>
      </template>
      <new-user
        v-if="isDisplayed"
        :onClose="onCloseUserModal"
        :isAddNewVendor="true"
        @afterAddedUser="afterAddedUser"
      ></new-user>
    </el-dialog>

    <el-dialog
      :visible.sync="showCreate"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <create-location
        v-if="showCreate"
        @onClose="onCloseCreateLocation"
        :currentEvent="currentEvent"
        :rawLocationEdit="currentLocation"
        @onComplete="onCompleteAdd"
        :before-close="handleClose"
      ></create-location>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import throttle from "src/util/throttle";
import OrderSummary from "./EventDetail/Summary.vue";
import GetSummaryData from "@/mixins/GetSummaryData.js";
import DashboardHeader from "../Dashboard/Dashboard/DashboardHeader";
import EventMixin from "@/mixins/Event.js";
import CreateLocation from "./Location/CreateLocationMap.vue";
import { Checkbox, DatePickerDropdown } from "src/components/index";
import NewUser from "src/pages/Dashboard/NewUser.vue";
import moment from "moment";
import Spinner from "src/components/Spinner";

import {
  booleanFormatter,
  formatNumber,
  numberFormatter,
  formatRevenueToken,
  formatRevenueValue,
  formatRevenue,
} from "@/util/string.js";
import ViewIcon from "src/components/Icons/View.vue";

import _ from "lodash";
const debounced = _.debounce((vm, url, params, cb) => {
  axios.get(url, { params }).then((response) => {
    if (response.data.results) {
      vm.users = response.data.results.map((e) => {
        e.displayName = e.first_name + " " + e.last_name;
        if (e.displayName.trim() === "") {
          e.displayName = "N/A";
        }
        return e;
      });

      vm.totalUsers = response.data.count;
    } else {
      vm.users = response.data;
      vm.totalUsers = vm.users.length;
    }
    if (cb) {
      cb(vm.users);
    }
    vm.loadingUser = false;
  });
}, 500);

export default {
  components: {
    OrderSummary,
    DashboardHeader,
    CreateLocation,
    ViewIcon,
    Checkbox,
    NewUser,
    DatePickerDropdown,
    Spinner,
  },
  mixins: [GetSummaryData, EventMixin],
  data() {
    const self = this;
    return {
      rawDateRange: null,
      rangeTime: null,
      isDisplayed: false,
      currentPageUsers: 1,
      pageSizeUser: 30,
      totalUsers: 0,
      users: [],
      options: [
        {
          value: "owner",
          label: "Owner",
        },
        {
          value: "employee",
          label: "Employee",
        },
      ],
      titlesUser: [
        {
          prop: "phone_number",
          label: "Phone",
        },
      ],
      filtersUsers: [
        {
          value: "",
        },
      ],
      currentLocation: null,
      showCreate: false,
      vendor: false,
      loadingUser: false,
      items: [],
      count: 0,
      loading: false,
      summary: {},
      filters: [
        {
          value: "",
        },
      ],
      titles: [
        {
          prop: "booth",
          label: "Location",
          isSortable: true,
        },
        {
          prop: "estimate_wait_time",
          label: "Estimated Wait time",
          isSortable: true,
        },
        {
          prop: "num_open_orders",
          label: "Open Orders",
          isSortable: true,
        },
        {
          prop: "completed_orders",
          label: "Completed Orders",
          isSortable: true,
          formatter: numberFormatter,
        },
        {
          prop: "refunded_orders",
          label: "Refunded Orders",
          isSortable: true,
          formatter: numberFormatter,
        },
        {
          prop: "avg_order",
          label: "Avg Order",
          formatter: (item) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(
                item.completed_orders === 0
                  ? 0
                  : item.total / item.completed_orders,
                this.currentEvent,
                3
              );
            }
            return formatRevenueValue(
              item.completed_orders === 0
                ? 0
                : item.total / item.completed_orders,
              this.currentEvent,
              3
            );
          },
        },
        {
          prop: "total",
          label: "Revenue",
          isSortable: true,
          formatter: (row, col, cell, index) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(cell, this.currentEvent, 3);
            }
            return formatRevenueValue(cell, this.currentEvent, 3);
          },
        },
      ],
      timer: null,
      currentPriceType: "token",
      isTurnOnPageRefresh: false,
      isShowSearch: true,
      params: null,
    };
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  async mounted() {
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }

    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.loadData({});
          this.fetchVendor();
          let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__vendor=${this.$route.params.vendorId}&vendor_event__event=${this.$route.params.id}`;
          if (this.rangeTime && this.rangeTime.length > 0) {
            countUrl += `&created_at__gte=${moment(this.rangeTime[0])
              .startOf("day")
              .format()}${
              this.rangeTime.length > 1
                ? "&created_at__lte=" +
                  moment(this.rangeTime[1]).endOf("day").format()
                : ""
            }`;
          }
          this.getSummaryData(countUrl);
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.loadData(this.params);
        this.fetchVendor();
        let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__vendor=${this.$route.params.vendorId}&vendor_event__event=${this.$route.params.id}`;
        if (this.rangeTime && this.rangeTime.length > 0) {
          countUrl += `&created_at__gte=${moment(this.rangeTime[0])
            .startOf("day")
            .format()}${
            this.rangeTime.length > 1
              ? "&created_at__lte=" +
                moment(this.rangeTime[1]).endOf("day").format()
              : ""
          }`;
        }
        this.getSummaryData(countUrl);
      }, 10000);
    } else {
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }

    this.loadData({});
    this.fetchVendor();
    let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__vendor=${this.$route.params.vendorId}&vendor_event__event=${this.$route.params.id}`;
    if (this.rangeTime && this.rangeTime.length > 0) {
      countUrl += `&created_at__gte=${moment(this.rangeTime[0])
        .startOf("day")
        .format()}${
        this.rangeTime.length > 1
          ? "&created_at__lte=" +
            moment(this.rangeTime[1]).endOf("day").format()
          : ""
      }`;
    }
    this.getSummaryData(countUrl);
  },
  computed: {
    administrators() {
      return this.vendor.administrators;
    },
  },
  methods: {
    filterData(e) {
      this.params.limit = e;
      this.params.pageSize = e;
      this.loadData(this.params);
    },
    onShowNewUser() {
      this.isDisplayed = true;
      this.isShowSearch = false;

      this.$forceUpdate();
      setTimeout(() => {
        this.isShowSearch = true;
      }, 200);
    },
    handleClose() {
      this.isDisplayed = false;
      this.showCreate = false;
      this.currentLocation = null;
    },
    onReset() {
      this.$refs.datepicker.onReset();
      this.rangeTime = [];

      setTimeout(() => {
        this.loadData(this.params);
        this.fetchVendor();
        let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__vendor=${this.$route.params.vendorId}&vendor_event__event=${this.$route.params.id}`;
        if (this.rangeTime && this.rangeTime.length > 0) {
          countUrl += `&created_at__gte=${moment(this.rangeTime[0])
            .startOf("day")
            .format()}${
            this.rangeTime.length > 1
              ? "&created_at__lte=" +
                moment(this.rangeTime[1]).endOf("day").format()
              : ""
          }`;
        }
        this.getSummaryData(countUrl);
      }, 0);
    },
    onUpdateDate(daterange) {
      this.rangeTime = daterange;
      this.loadData(this.params);
      this.fetchVendor();
      let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event__vendor=${this.$route.params.vendorId}&vendor_event__event=${this.$route.params.id}`;
      if (this.rangeTime && this.rangeTime.length > 0) {
        countUrl += `&created_at__gte=${moment(this.rangeTime[0])
          .startOf("day")
          .format()}${
          this.rangeTime.length > 1
            ? "&created_at__lte=" +
              moment(this.rangeTime[1]).endOf("day").format()
            : ""
        }`;
      }
      this.getSummaryData(countUrl);
    },
    afterAddedUser(user) {
      console.log(user);
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.$route.params.vendorId}/attach/${user.id}/${user.vendorType}/`;
      axios.get(url).then((rs) => {
        this.$message("Update successfully!");
        this.fetchVendor();
      });
    },
    handleSelect(item) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.$route.params.vendorId}/attach/${item}/support/`;
      axios.get(url).then((rs) => {
        this.$message("Attach successfully!");
        this.fetchVendor();
        this.filtersUsers[0].value = "";
      });
    },
    onChangeUserAttach(value, user) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.$route.params.vendorId}/attach/${user.user.id}/${value}/`;
      axios.get(url).then((rs) => {
        this.$message("Update successfully!");
        this.fetchVendor();
      });
    },
    onCloseUserModal() {
      this.isDisplayed = false;
    },
    isAttachedUser(user) {
      return this.administrators.find((e) => e.user === user);
    },
    handlViewUser(user) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/users/?userId=${user.user.id}`,
      });
    },
    onActiveUserToVendor(user) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/send-code/email/`;
      axios
        .post(url, {
          email: user.email,
          template: "activate",
        })
        .then((rs) => {
          this.$message(
            "We've sent you an email with activation link. Please check your email"
          );
        });
    },
    onAttachUserToVendor(user) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor/${this.$route.params.vendorId}/attach/${user.id}`;
      axios.get(url).then((rs) => {
        this.$message("Attach successfully!");
      });
    },
    onChangeCheckApprove(checked, row) {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/${this.$route.params.vendorId}/`;
      axios.patch(url, {
        is_approved: checked,
      });
    },
    handlerView(data) {
      this.currentLocation = data;
      this.showCreate = true;
    },
    onShowCreate() {
      this.currentLocation = null;
      this.showCreate = true;
    },
    onCompleteAdd(isEdit) {
      if (!isEdit) {
        this.showCreate = false;
      }
      this.loadData(this.params);
      this.fetchVendor();
    },
    onCloseCreateLocation() {
      this.showCreate = false;
    },
    async loadData(queryInfo) {
      this.loading = true;

      console.log(queryInfo);
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/`;

      const params = {
        event: this.$route.params.id,
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        expand: "",
        vendor: this.$route.params.vendorId,
        search: this.filters[0].value,
        aggregates: "1",
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          (queryInfo.sort.order == "descending" ? "-" : "") +
          queryInfo.sort.prop;
      }

      this.filters.forEach((element) => {
        if (element.value.length > 3) {
          params[element.prop] = element.value;
        }
      });
      this.params = params;
      this.params.pageSize = params.limit;
      throttle.debounced(this, url, params);
    },
    async fetchVendor() {
      this.vendor = await this.$store.dispatch(
        "fetchVendor",
        this.$route.params.vendorId
      );
    },
    async loadDataUser(queryInfo, cb) {
      this.loadingUser = true;

      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/`;
      if (this.url) {
        url = this.url;
      }

      let params = {
        search: queryInfo,
        type: "vendor",
        vendor_event__event: this.$route.params.vendorId,
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          queryInfo.sort.order == "descending" ? "-" : "" + queryInfo.sort.prop;
      }

      debounced(this, url, params, cb);
    },
  },
};
</script>
<style lang="scss">
.preview-upload {
  display: flex;
  align-items: center;
}
.image-preview {
  height: 150px;
  width: 150px;
}
</style>
