<template>
  <div>
    <div
      class="navbar tablet-ct relative m-0"
      style="box-shadow: none; display: none; padding: 10px"
    >
      <navbar-toggle-button @click.native="toggleSidebar">
      </navbar-toggle-button>
    </div>
    <nav
      class="main-sidebar lg:fixed lg:inset-y-0 z-[99999] lg:flex lg:w-72 lg:flex-col relative d-flex border-b border align-items-center justify-content-between lg:!border-0"
    >
      <div
        class="flex grow flex-col gap-y-6 overflow-y-auto border-r border-gray-200 bg-white !p-4"
        :data-color="backgroundColor"
      >
        <button
          class="flex w-full items-center gap-x-2.5 rounded-md border border-gray-300 bg-white p-2 text-sm shadow-sm transition-all hover:bg-gray-50 focus:ring-2 focus:ring-indigo-200"
          type="button"
          id="radix-:Rlja:"
          aria-haspopup="menu"
          aria-expanded="false"
          data-state="closed"
        >
          <span
            class="flex aspect-square size-8 items-center justify-center rounded bg-indigo-600 p-2 text-xs font-medium text-white"
            aria-hidden="true"
            >{{
              $store.state?.user?.user_type?.organization?.name
                ? $store.state?.user?.user_type?.organization?.name[0]
                : "N/A"
            }}</span
          >
          <div
            class="flex w-full items-center justify-between gap-x-4 truncate text-left"
          >
            <div class="truncate">
              <p
                class="truncate whitespace-nowrap text-sm font-medium text-gray-900"
              >
                <span
                  class="user-title"
                  v-if="$store.state.user.type !== 'cash'"
                >
                  {{ $store.state.user.first_name }}
                  {{ $store.state.user.last_name }}
                </span>
                <span v-else class="user-title">
                  {{ $store.state.user.email }}
                </span>
              </p>
              <p class="whitespace-nowrap text-left text-xs text-gray-700">
                {{ $store.state.user.type }}
              </p>
            </div>

            <el-dropdown @command="onSelectItemDropdown">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                aria-hidden="true"
                class="remixicon size-5 shrink-0 text-gray-500"
              >
                <path
                  d="M18.2072 9.0428 12.0001 2.83569 5.79297 9.0428 7.20718 10.457 12.0001 5.66412 16.793 10.457 18.2072 9.0428ZM5.79282 14.9572 11.9999 21.1643 18.207 14.9572 16.7928 13.543 11.9999 18.3359 7.20703 13.543 5.79282 14.9572Z"
                ></path>
              </svg>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>
                  Logged in as
                  <span class="text purple">
                    {{ $store.state.user.type }}</span
                  ></el-dropdown-item
                >
                <el-dropdown-item command="profile">Profile</el-dropdown-item>

                <el-dropdown-item command="logout">Logout</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </button>
        <div
          v-click-outside="closeSideBar"
          class="sidebar-wrapper"
          :class="{
            activeM: showSidebarMobile,
          }"
          ref="sidebarScrollArea"
        >
          <div class="is-tablet hidden">
            <button
              class="flex w-full items-center gap-x-2.5 rounded-md border border-gray-300 bg-white p-2 text-sm shadow-sm transition-all hover:bg-gray-50 focus:ring-2 focus:ring-indigo-200"
              type="button"
              id="radix-:Rlja:"
              aria-haspopup="menu"
              aria-expanded="false"
              data-state="closed"
            >
              <span
                class="flex aspect-square size-8 items-center justify-center rounded bg-indigo-600 p-2 text-xs font-medium text-white"
                aria-hidden="true"
                >{{
                  $store.state?.user?.user_type?.organization?.name
                    ? $store.state?.user?.user_type?.organization?.name[0]
                    : "N/A"
                }}</span
              >
              <div
                class="flex w-full items-center justify-between gap-x-4 truncate text-left"
              >
                <div class="truncate">
                  <p
                    class="truncate whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <span
                      class="user-title"
                      v-if="$store.state.user.type !== 'cash'"
                    >
                      {{ $store.state.user.first_name }}
                      {{ $store.state.user.last_name }}
                    </span>
                    <span v-else class="user-title">
                      {{ $store.state.user.email }}
                    </span>
                  </p>
                  <p class="whitespace-nowrap text-left text-xs text-gray-700">
                    {{ $store.state.user.type }}
                  </p>
                </div>

                <el-dropdown @command="onSelectItemDropdown">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    aria-hidden="true"
                    class="remixicon size-5 shrink-0 text-gray-500"
                  >
                    <path
                      d="M18.2072 9.0428 12.0001 2.83569 5.79297 9.0428 7.20718 10.457 12.0001 5.66412 16.793 10.457 18.2072 9.0428ZM5.79282 14.9572 11.9999 21.1643 18.207 14.9572 16.7928 13.543 11.9999 18.3359 7.20703 13.543 5.79282 14.9572Z"
                    ></path>
                  </svg>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>
                      Logged in as
                      <span class="text purple">
                        {{ $store.state.user.type }}</span
                      ></el-dropdown-item
                    >
                    <el-dropdown-item command="profile"
                      >Profile</el-dropdown-item
                    >

                    <el-dropdown-item command="logout">Logout</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </button>
          </div>
          <ul class="nav space-y-0.5 flex flex-column">
            <slot name="event">
              <sidebar-item
                v-for="(link, index) in sidebarLinks"
                :key="link.name + index"
                :link="link"
              >
                <sidebar-item
                  v-for="(subLink, index) in link.children"
                  :key="subLink.name + index"
                  :link="subLink"
                  :onClick="(this.showSidebarMobile = false)"
                >
                </sidebar-item>
              </sidebar-item>
            </slot>
          </ul>
          <div class="title-links mt-4">Current Event</div>
          <ul class="nav space-y-0.5 flex flex-column">
            <slot name="organizer">
              <div class="flex flex-column">
                <sidebar-item
                  v-for="(link, index) in sidebarLinks"
                  :key="link.name + index"
                  :link="link"
                >
                  <sidebar-item
                    v-for="(subLink, index) in link.children"
                    :key="subLink.name + index"
                    :link="subLink"
                  >
                  </sidebar-item>
                </sidebar-item>
              </div>
            </slot>
          </ul>
        </div>
        <!-- <div class="logo">
        <a href="/" class="simple-text logo-mini">
          <div class="logo-image">
            <img :src="logo" />
          </div>
        </a> -->

        <!-- <a href="/" class="simple-text logo-normal">
        {{title}}
      </a> -->
        <!-- <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-simple btn-icon btn-black btn-round" @click="minimizeSidebar">
          <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
          <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
        </button>
      </div> -->
        <!-- </div> -->

        <!-- <div class="flex items-center nav-item mt-auto gap-2">
        <img src="assets/imgs/accounts-icon.svg" />
        <div class="user-info">
          <span
            class="comp-title"
            v-if="
              $store.state.user.user_type &&
              $store.state.user.user_type.organization
            "
            >{{ $store.state.user.user_type.organization.name }}</span
          >
          <span class="user-title" v-if="$store.state.user.type !== 'cash'">
            {{ $store.state.user.first_name }}
            {{ $store.state.user.last_name }}
          </span>
          <span v-else class="user-title">
            {{ $store.state.user.email }}
          </span>
        </div>
        <el-dropdown @command="onSelectItemDropdown">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item disabled>
              Logged in as
              <span class="text purple">
                {{ $store.state.user.type }}</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="profile">Profile</el-dropdown-item>

            <el-dropdown-item command="logout">Logout</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
      </div>
      <div
        class="navbar relative m-0"
        style="box-shadow: none; padding: 0 10px"
      >
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <el-dialog
        :visible.sync="showUserProfile"
        custom-class="event-dialog"
        append-to-body
      >
        <template #title>
          <div></div>
        </template>
        <user-profile-form
          :onClose="onCloseUserProfile"
          :isEdit="true"
        ></user-profile-form>
      </el-dialog>
    </nav>
  </div>
</template>
<script>
import UserProfileForm from "../../pages/Dashboard/UserProfileForm.vue";
import { RouteBreadCrumb, Navbar, NavbarToggleButton } from "src/components";

export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "EventPay",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: "assets/imgs/logo-blue.svg",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: {},
    },
  },
  components: {
    UserProfileForm,
    NavbarToggleButton,
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      showUserProfile: false,
      showSidebarMobile: false,
    };
  },
  watch: {
    $route(to, from) {
      this.showSidebarMobile = false;
    },
  },
  methods: {
    closeSideBar() {
      setTimeout(() => {
        if (this.showSidebarMobile) {
          this.showSidebarMobile = false;
        }
      }, 0);
    },
    toggleSidebar(evt) {
      evt.stopPropagation();
      this.showSidebarMobile = true;
    },
    onCloseUserProfile() {
      this.showUserProfile = false;
    },
    onSelectItemDropdown(evt) {
      switch (evt) {
        case "logout":
          this.logout();
          break;
        case "profile":
          this.showUserProfile = true;
        default:
          break;
      }
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      sessionStorage.removeItem("isRefresh");

      this.$router.push({ name: "Login" });
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
