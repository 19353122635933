<template>
  <div>
    <div
      v-for="(event, x) in events"
      v-if="event.id == $route.params.id"
      :key="x"
      :link="{
        name: 'Event',
        icon: 'now-ui-icons design_image',
      }"
    >
      <!-- <sidebar-item
        :link="{
          name: 'Reload',
          icon: 'assets/imgs/reload-icon.svg',
          iconActive: 'assets/imgs/reload-icon-active.svg',
          path: `/pages/events/${event.id}/reloads`,
        }"
      > -->
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Users',
          icon: 'assets/imgs/users-icon.svg',
          iconActive: 'assets/imgs/users-icon-active.svg',
          path: `/pages/events/${event.id}/users`,
        }"
      ></sidebar-item>
    </div>
  </div>
</template>
<script>
import UserMenu from "src/pages/Dashboard/Layout/Extra/UserMenu";
import User from "src/mixins/User";
import Event from "src/mixins/Event";
export default {
  components: {
    UserMenu,
  },
  mixins: [User, Event],
  methods: {
    setEvent(e) {
      console.log(e, `/mini/${e.target.value}`);
      this.$router.push(`/mini/${e.target.value}`);
    },
  },
};
</script>
