<template>
  <div class="a-account-image-wrapper">
    <img
      src="assets/imgs/header-bg-min.jpg"
      loading="lazy"
      alt=""
      class="a-account-image"
    />
    <div class="a-section-regular" style="height: 100vh;">
      <div
        class="a-container-regular"
        style="
          width: 50%;
          max-width: 100%;
          margin-left: 50%;
          margin-right: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-align: left;
        "
      >
        <div class="a-account-form-wrapper" style="width: 100%; margin: 0">
          <div class="centered">
            <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>404</h1>
            <p>The page you requested could not be found.</p>
            <a href="/">Back to home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss">
.not-found-page {
  .full-page > .content,
  .centered {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
