<template>
  <div>
    <el-dialog
      :title="`${currentRow.first_name} ${currentRow.last_name} - ${currentRow.email}`"
      v-if="currentRow"
      :visible.sync="modalVisible"
      :custom-class="'md-dialog hidden-header'"
    >
      <div class="row" style="background: #fff">
        <div class="col-xs-12 col-lg-3 left-panel">
          <h3 class="modal-title-n">User</h3>
          <span class="small text-order-id"
            >{{ currentRow.first_name }} {{ currentRow.last_name }}
            <br />
            <span class="small">
              {{ currentRow.email }}
              <br />
              {{ currentRow.phone_number }}
            </span>
          </span>
          <div class=""></div>
          <div class="infor-modal active">
            <i class="now-ui-icons travel_info"></i>
            <span> User Details</span>
          </div>
        </div>
        <div class="col-12 col-lg-9 right-panel">
          <button
            type="button"
            class="close-button-md"
            @click="modalVisible = false"
          >
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <user-modal
            @qrClicked="qrClicked"
            :current-user="currentRow"
            :event="event"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="isDisplayed"
      custom-class="event-dialog"
      append-to-body
    >
      <template #title>
        <div></div>
      </template>
      <new-user v-if="isDisplayed" :onClose="onCloseUserModal"></new-user>
    </el-dialog>

    <div class="users-main">
      <div class="d-flex justify-content-between mb-4">
        <input
          type="text"
          class="search-box"
          placeholder="Search"
          v-model="search"
          v-on:input="onSearch"
        />
        <button
          v-if="user.type == 'org-administrator'"
          class="create-event"
          @click="isDisplayed = !isDisplayed"
        >
          Create Account
        </button>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <h3 class="title-page mb-0">Accounts</h3>
            <h5 class="subtitle">
              Create accounts that can access your EventPay organization
            </h5>

            <div class="row">
              <div class="col-12">
                <h5 class="title-account mt-5">
                  <span class="circle purple"></span> Event Organizer
                </h5>
              </div>
              <spinner v-if="usersLoading" />
              <div
                class="col-12 col-lg-6"
                v-for="(user, x) in superUsers"
                :key="x"
              >
                <user-detail
                  :user="user.user"
                  :show-links="true"
                  :title="'Admin'"
                  :avatar_word="'EO'"
                  @onEdit="onEdit"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h5 class="title-account mt-5">
                  <span class="circle purple"></span> Admin
                </h5>
              </div>
              <spinner v-if="usersLoading" />
              <div
                class="col-12 col-lg-6"
                v-for="(user, x) in staffUsers"
                :key="x"
              >
                <user-detail
                  :user="user.user"
                  :show-links="true"
                  :title="'Event Organizer'"
                  :avatar_word="'EO'"
                  @onEdit="onEdit"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h5 class="title-account mt-5">
                  <span class="circle purple"></span> Support
                </h5>
              </div>
              <spinner v-if="usersLoading" />
              <div
                class="col-12 col-lg-6"
                v-for="(user, x) in standardUsers"
                :key="x"
              >
                <user-detail
                  :user="user.user"
                  :show-links="true"
                  :title="'Support'"
                  :avatar_word="'S'"
                  @onEdit="onEdit"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          v-if="user.type == 'org-administrator'"
          class="col-md-12 add-vendor"
        >
          <el-dialog title="Create Event" :visible.sync="showCreate">
            <create-event-form></create-event-form>
          </el-dialog>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PreviewUpload from "./Components/PreviewUpload";
import CreateEventForm from "./Events/CreateEventForm.vue";
import moment from "moment/moment";
import Spinner from "src/components/Spinner";

import GetError from "src/mixins/GetError.js";
import User from "src/mixins/User.js";
import UserDetail from "src/components/User.vue";
import NewUser from "src/pages/Dashboard/NewUser.vue";
import UserModal from "src/components/Modals/User.vue";
import _ from "lodash";

const compact = (list) => list.filter((x) => !!x);

// TODO:
// - Map the remaining fields to the models
//   - Returns: min_return_ammount, refund_claim_days, return_allowed
//   - min_num_items, max_num_items, add_on?
// - Who can send this API?
// - Split the forms out
export default {
  components: {
    PreviewUpload,
    CreateEventForm,
    Spinner,
    UserDetail,
    NewUser,
    UserModal,
  },
  mixins: [GetError, User],
  computed: {
    superUsers() {
      return this.usersOrg.filter((e) => e.user.is_superuser);
    },
    staffUsers() {
      return this.usersOrg.filter(
        (e) => e.user.is_staff && !e.user.is_superuser
      );
    },
    standardUsers() {
      return this.usersOrg.filter(
        (e) => !e.user.is_staff && !e.user.is_superuser
      );
    },
  },
  data() {
    return {
      usersLoading: true,
      pastEventsLoading: true,
      usersOrg: [],
      isDisplayed: false,
      currentRow: null,
      modalVisible: false,
      search: '',
    };
  },
  methods: {
    onSearch: _.debounce(function (e) {
      this.usersLoading = true;
      this.getAllUserOrg();
      this.usersLoading = false;
    }, 500),
    qrClicked(item) {
      this.qrCode = item;
    },
    onEdit(row) {
      this.currentRow = row;
      this.modalVisible = true;
    },
    onCloseUserModal() {
      this.isDisplayed = false;
    },
    getAllUserOrg() {
      this.usersLoading = true;
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/organization-administrator/`;
      axios
        .get(url, { params: { search: this.search } })
        .then((response) => {
          this.usersOrg = response.data.results;
          this.usersLoading = false;
        });
    },
  },
  mounted() {
    this.getAllUserOrg();
  },
};
</script>
<style scoped lang="scss"></style>
