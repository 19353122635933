<template>
  <card>
    <h5>Vendor Details</h5>
    <spinner v-if="loading"/>
    <div v-else>
      <el-dialog title="Add Vendor" :visible.sync="modalVisible">
          <div class="alert alert-success" v-if="successMsg">{{ successMsg }}</div>
          <div class="alert alert-warning" v-if="serverErrors.length > 0">
            <ul>
              <li v-bind:key="error" v-for="error in serverErrors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div v-show="user.type === 'org-administrator'" class="col-md-4">
              <el-autocomplete
                class="inline-input"
                v-model="vendorName"
                :fetch-suggestions="autocompleteVendors"
                placeholder="Search for vendor"
                @select="handleSelect"
              ></el-autocomplete>
            </div>
            <div class="col-md-4">
              <el-input v-model="vendorBooth" placeholder="Booth Name"></el-input>
            </div>
            <div class="col-md-3">
              <n-checkbox v-model="walkUp">Walk up</n-checkbox>
              <n-checkbox v-model="orderAhead">Order ahead</n-checkbox>
            </div>


            <div class="col-md-3">
              <n-button type="primary" @click.native="addVendor">
                <span v-if="user.type === 'org-administrator'">Add</span>
                <span v-if="user.type === 'vendor'">Add Booth</span>
              </n-button>
            </div>

          </div>
      </el-dialog>
      <table class="table table-hover table-border">
        <thead>
          <tr>
            <th>Vendor</th>
            <th>Booth</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vendorEvent in vendorEvents" :key="vendorEvent.id">
            <td><router-link :to="{name: 'EventVendorDetail', params: {id: vendorEvent.event, vendorId: vendorEvent.id}}">{{ vendorEvent.vendor.name }}</router-link></td>
            <td>{{ vendorEvent.booth }}</td>

            <td>

              <n-button
                class="detail-button"
                type="primary"
                round
                icon
                @click.native="goToVendorEventPage(vendorEvent)"
              >
                <i class="el-icon-document"></i>
              </n-button>
              <router-link :to="{name: 'Org Vendor Financials', params: {id: vendorEvent.event, vendorId: vendorEvent.vendor.id}}">
                <n-button
                v-if="isOrgAdmin"
                class="detail-button"
                type="primary"
                round
                icon
                
              ><i class='now-ui-icons business_money-coins'></i>

              </n-button></router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <button @click="modalVisible = true" class='primary' style='margin-left:0px;'>Add a Vendor</button> -->

    </div>
  </card>
</template>
<script>
import axios from "axios";
import { Autocomplete } from "element-ui";
import { Checkbox } from "src/components";
import Spinner from "src/components/Spinner";
import User from "src/mixins/User.js";
import { humanize } from "src/util/string";
import LoadingComponent from "src/components/LoadingComponent";

export default {
  components: {
    [Autocomplete.name]: Autocomplete,
    [Checkbox.name]: Checkbox,
    Spinner,
    LoadingComponent
  },
  computed: {
    vendor: function() {
      return {
        event: this.$route.params.id,
        vendor: this.vendorName && this.vendorId ? this.vendorId : null,
        booth: this.vendorBooth,
        walk_up: this.walkUp,
        order_ahead: this.orderAhead
      };
    }
  },
  methods: {
    deleteVendor: async function(vendorEventId) {
      if (!confirm("Are you sure you want to delete this booth?")) {
        return
      }
      this.deleteVendorLoading[vendorEventId] = true;
      try {
        const url = `${
          process.env.VUE_APP_BASE_HOST
        }/en/api/vendor-event/${vendorEventId}`;
        this.successMsg = "";
        this.serverErrors = [];

        const response = await axios.delete(url, { vendor: vendorEventId });
        this.successMsg = "Vendor removed";
        this.fetchVendorEvents();
        this.deleteVendorLoading[vendorEventId] = false;
      } catch (error) {
        this.deleteVendorLoading[vendorEventId] = false;
        if (error.response.status === 500) {
          this.serverErrors = [error.response.statusText];
        } else if (error.response.status !== 403) {
          this.serverErrors = humanize(error.response.data);
        } else {
          this.serverErrors = [error.response.data.detail];
        }
      }
    },
    addVendor: function() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/`;
      this.successMsg = "";
      this.serverErrors = [];

      axios
        .post(url, { ...this.vendor })
        .then(response => {
          this.successMsg = "Vendor added";
          this.fetchVendorEvents();
        })
        .catch(e => {
          if (e.response.status === 500) {
            this.serverErrors = [e.response.statusText];
          } else if (e.response.status !== 403) {
            this.serverErrors = humanize(e.response.data);
          } else {
            this.serverErrors = [e.response.data.detail];
          }
        });
    },
    approveVendor: async function(vendorEventId, isApproved) {
      if (this.$store.state.user.type == 'vendor') {
        return
      }
      this.approveLoading[vendorEventId] = true;
      try {
        const url = `${
          process.env.VUE_APP_BASE_HOST
        }/en/api/vendor-event/${vendorEventId}/`;
        this.successMsg = "";

        const response = await axios.patch(url, { is_approved: !isApproved });
        this.successMsg = `Vendor ${isApproved ? "unapproved" : "approved"}`;
        this.fetchVendorEvents();
        this.approveLoading[vendorEventId] = false;
      } catch (error) {
        console.log(error);
        this.approveLoading[vendorEventId] = false;
      }
    },
    autocompleteVendors: function(queryString, cb) {
      if (queryString) {
        const url = `${
          process.env.VUE_APP_BASE_HOST
        }/en/api/vendor/?name__icontains=${queryString}`;

        axios.get(url).then(response => {
          cb(response.data["results"].map(x => ({ value: x.name, id: x.id })));
        });
      }
    },
    goToVendorEventPage: function(vendorEvent) {
      this.$router.push({name: 'EventVendorDetail', params: {id: vendorEvent.event, vendorId: vendorEvent.id}});
    },
    handleSelect(item) {
      this.vendorId = item.id;
    },
    fetchVendorEvents() {
      let url = `${
        process.env.VUE_APP_BASE_HOST
      }/en/api/vendor-event/?event=${this.$route.params.id}&expand=vendor`;

      if (this.$store.state.user.type == 'vendor') {
        url += `&vendor=${this.$store.state.user.user_type.vendor.id}`
      }
      axios.get(url).then(response => {
        this.vendorEvents = response.data.results;
        this.loading = false;
      });
    }
  },
  data: () => ({
    vendorEvents: [],
    serverErrors: [],
    walkUp: true,
    orderAhead: true,
    vendorName: "",
    vendorId: "",
    vendorBooth: "",
    successMsg: "",
    loading: true,
    approveLoading: {},
    deleteVendorLoading: {},
    modalVisible: false,
  }),
  mounted() {
    this.fetchVendorEvents();
  },
  mixins: [User]
};
</script>
<style lang="scss">
.action-approve {
  .el-icon-success {
    color: green;
  }
  .el-icon-error {
    color: red;
  }
}
.table {
  margin-top: 30px;
}
.btn {
  margin: 10px;
}
</style>
