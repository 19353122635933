<template>
  <div class="wrap-content-md">
    <div class="infor-modal">
      <i class="el-icon-wallet"></i>
      <span> Payment Method</span>
    </div>
    <div class="wrap-shadow-box p-3 position-relative">
      <div class="row">
        <div class="col-12 col-md-4 flex-column">
          <img src="assets/wallet.png" alt="" class="img-fluid" />
        </div>
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-12 col-md-6 flex-column mb-3">
              <span class="title-navy mb-2"> Wallet ID </span>
              <div><span class="value-navy"> HD34dS83 </span></div>
            </div>
            <div class="col-12 col-md-6 flex-column mb-3">
              <span class="title-navy mb-2"> Event </span>
              <div>
                <span class="value-navy"> Taco Fest Montreal 2021 </span>
              </div>
            </div>

            <div class="col-12 col-md-4 flex-column">
              <span class="title-navy mb-2"> Balance </span>
              <div><span class="value-navy"> 24 Tokens </span></div>
            </div>
            <div class="col-12 col-md-4 flex-column">
              <span class="title-navy mb-2"> Generated By </span>
              <div><span class="value-navy"> EventPay </span></div>
            </div>
            <div class="col-12 col-md-4 flex-column">
              <span class="title-navy mb-2"> Date Generated </span>
              <div><span class="value-navy"> Aug 1, 2021 9:41 AM </span></div>
            </div>
          </div>
        </div>
      </div>
      <button class="more-button" type="button">
        <img class="dropdown-dot" src="assets/imgs/dot-dropdown.svg" alt="" />
      </button>
    </div>

    <div class="row mt-3">
      <div class="col-12 text-center">
        <button type="button" class="btn btn-primary grey">Add Wallet ID</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AddToken from "src/components/AddToken.vue";
import TransferToken from "src/components/TransferToken.vue";
import Transactions from "src/components/Transactions.vue";
import Orders from "src/components/Orders.vue";
import Reloads from "src/components/Reloads.vue";
import GetBalance from "src/mixins/GetBalance.js";

export default {
  props: ["currentUser", "event"],
  components: {},
  computed: {},
  data() {
    return {
      orders: [],
      selectedTabModal: "detail",
      eventBalance: 0,
      component: false,
      modalVisible: true,
      localEvent: "",
      filters: [
        {
          prop: "user__email__icontains",
          value: "",
        },
      ],
      titleCols: [
        {
          prop: "order_display_id",
          label: "ID",
        },
        {
          prop: "name",
          label: "Name",
        },
        {
          prop: "total",
          label: "Tokens",
        },
        {
          prop: "vendor_event.vendor.name",
          label: "Customer",
        },
        {
          prop: "status",
          label: "Status",
        },
        {
          prop: "created_at",
          label: "Date & Time",
          formatter: (row, col, cell, index) => moment(cell).format("LLL"),
        },
      ],
    };
  },
  methods: {
    qrClicked() {
      this.$emit("qrClicked", this.currentUser);
    },
  },
  mounted() {
    if (this.eventId) {
      this.getBalance(this.currentUser.id, this.eventId);
    }
  },
  watch: {
    currentUser(newVal, oldVal) {
      console.log(oldVal, newVal);
      this.eventBalance = 0;
      const eventId = this.eventId;
      if (eventId) {
        this.getBalance(newVal.id, eventId);
      }
    },
  },
  mixins: [GetBalance],
};
</script>

<style lang="scss" scoped>
.more-button {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>