<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icon/branding" fill="currentColor" fill-rule="nonzero">
        <g id="paint-roller">
          <path
            d="M19.59375,3.09375 L17.875,3.09375 L17.875,2.0625 C17.875,0.923398437 16.9516016,0 15.8125,0 L2.0625,0 C0.923398437,0 0,0.923398437 0,2.0625 L0,6.1875 C0,7.32660156 0.923398437,8.25 2.0625,8.25 L15.8125,8.25 C16.9516016,8.25 17.875,7.32660156 17.875,6.1875 L17.875,5.15625 L19.59375,5.15625 C19.7832422,5.15625 19.9375,5.31050781 19.9375,5.5 L19.9375,9.625 C19.9375,9.81449219 19.7832422,9.96875 19.59375,9.96875 L11,9.96875 C9.673125,9.96875 8.59375,11.048125 8.59375,12.375 L8.59375,13.75 L8.25,13.75 C7.49074219,13.75 6.875,14.3657422 6.875,15.125 L6.875,20.625 C6.875,21.3842578 7.49074219,22 8.25,22 L11,22 C11.7592578,22 12.375,21.3842578 12.375,20.625 L12.375,15.125 C12.375,14.3657422 11.7592578,13.75 11,13.75 L10.65625,13.75 L10.65625,12.375 C10.65625,12.1855078 10.8105078,12.03125 11,12.03125 L19.59375,12.03125 C20.920625,12.03125 22,10.951875 22,9.625 L22,5.5 C22,4.173125 20.920625,3.09375 19.59375,3.09375 Z M15.8125,6.1875 L2.0625,6.1875 L2.0625,2.0625 L15.8125,2.0625 L15.8125,6.1875 Z M10.3125,19.9375 L8.9375,19.9375 L8.9375,15.8125 L10.3125,15.8125 L10.3125,19.9375 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '17px',
    },
    height: {
      default: '17px',
    },
  },
};
</script>
