<template>
  <div id="financials">
    <div class="no-tab-content-inside">
      <h3 class="title-page">Demographics</h3>

      <div class="row mt-3 mb-3">
        <div class="col-12 col-md-6 ">
          <div class="textbox-coming-soon">
            <span class="text-coming-soon">Coming Soon</span>
          </div>
          <div class="card-event card-coming-soon">

            <h3 class="title-page medium-size d-flex">Venue Heatmap</h3>
            <div class="map-content">
              <div id="zoom-button" class="btn-group-vertical">
                <n-button type="primary default m-0" disabled
                  ><i class="el-icon-plus"></i
                ></n-button>
                <n-button type="primary default m-0" disabled
                  ><i class="el-icon-minus"></i
                ></n-button>
              </div>
              <img
                style="filter: grayscale(100%)"
                src="assets/Desert-Trip-Map-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div
            class="card card-stats card-raised bg-transparent shadow-none p-0"
          >
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-primary blue-bg">
                        <img src="assets/imgs/orders-icon-active.svg" alt="" />
                      </div>
                      <div class="statistics-infor">
                        <h3 class="info-title">
                          <animated-number
                            :value="1024"
                          ></animated-number>
                        </h3>
                        <h6 class="stats-title">Attended</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="statistics">
                    <div class="info">
                      <div class="icon icon-success blue-bg">
                        <img src="assets/imgs/booth-icon-blue.svg" alt="" />
                      </div>
                      <div class="statistics-infor">
                        <h3 class="info-title">
                          <small>$</small>
                          <animated-number :value="55.74"></animated-number>
                        </h3>
                        <h6 class="stats-title">Avg. Spend</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 mt-4">
                  <div class="textbox-coming-soon">
                    <span class="text-coming-soon">Coming Soon</span>
                  </div>
                  <div class="card-event card-coming-soon" style="opacity: 0.15;">
                    <h3 class="title-page medium-size d-flex">Age & Gender</h3>
                    <div class="flex-list">
                      <div class="col-f">
                        <div></div>
                        <span>Male</span>
                        <span>Female</span>
                        <span>Other</span>
                      </div>
                      <div class="col-f">
                        <div>0-10</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>11-20</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>21-30</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>31-40</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>41-50</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>51-60</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>61-70</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                      <div class="col-f">
                        <div>&lt; 70</div>
                        <span class="full-active"></span>
                        <span class="active"></span>
                        <span class="empty"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EventMixin from "src/mixins/Event";
import Spinner from "src/components/Spinner";
import { TabPane, Tabs, AnimatedNumber } from "src/components";
import _ from "lodash";

const debounced = _.debounce((vm, url, params) => {
  vm.loading = true;
  axios.get(url, { params }).then((response) => {
    vm.total = response.data.reloads.length;
    vm.data = response.data.reloads;
    vm.chart_data = response.data.time_data;
    let chartColor = "rgba(45, 97, 211, 0.25)";

    const ctx = document.getElementById("chart").getContext("2d");
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);

    const gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    const whitefill = ctx.createLinearGradient(0, 200, 0, 50);
    whitefill.addColorStop(0, "rgba(255, 255, 255, 0.5)");
    gradientFill.addColorStop(1, "rgba(89, 154, 235, 0.8)");
    gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");
    vm.loading = false;
  });
}, 500);

export default {
  props: ["currentUser", "event", 'transactions'],
  components: {
    Spinner,
    TabPane,
    Tabs,
    AnimatedNumber,
  },
  computed: {},
  data() {
    const self = this;
    return {
      data: {},
      loading: false,
    };
  },
  async mounted() {},
  methods: {},
  mixins: [EventMixin],
};
</script>
<style lang='scss'>
.map-content {
  position: relative;
  padding: 5px;
  #zoom-button {
    position: absolute;
    left: 0;
    z-index: 9;
  }

  #edit-button {
    position: absolute;
    right: 0;
    z-index: 9;
    button {
      color: #2d61d3;
      border-radius: 8px;
      box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
      border: solid 1px rgba(238, 238, 239, 0.7);
      background: #fff;
    }
  }
}

.flex-list {
  display: flex;
  .col-f {
    width: calc(100% / 9);
    display: flex;
    flex-direction: column;
    & > * {
      width: 49px;
      height: 49px;
      font-size: 12px;
      color: #021b43;
      text-align: center;
      line-height: 49px;
      margin-bottom: 10px;
    }
    .empty {
      opacity: 0.05;
      border-radius: 4px;
      background: #2d61d3;
      cursor: pointer;
    }
    .active {
      opacity: 0.25;
      border-radius: 4px;
      background: #2d61d3;
      cursor: pointer;
    }
    .full-active {
      border-radius: 4px;
      background: #2d61d3;
      cursor: pointer;
    }
  }
}
</style>
