<template>
  <orders :event="currentEvent"/>
</template>
<script>
import Orders from "./EventDetail/Orders.vue";
import EventMixin from "@/mixins/Event";
export default {
  mixins: [EventMixin],
  components: {
    Orders
  }
}
</script>