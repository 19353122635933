var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.events),function(event,x){return (event.id == _vm.$route.params.id)?_c('div',{key:x,attrs:{"link":{
      name: event.name,
      icon: 'now-ui-icons design_image',
    }}},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'assets/imgs/dashboard-icon.svg',
        iconActive: 'assets/imgs/dashboard-icon-active.svg',
        path: `/pages/events/${event.id}/dashboard`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Sales',
        icon: 'assets/imgs/sales-icon.svg',
        iconActive: 'assets/imgs/sales-icon-active.svg',
        path: `/pages/events/${event.id}/vendor-financials`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Orders',
        icon: 'assets/imgs/orders-icon.svg',
        iconActive: 'assets/imgs/orders-icon-active.svg',
        path: `/pages/events/${event.id}/orders`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Menu',
        icon: 'assets/imgs/menu-icon.svg',
        iconActive: 'assets/imgs/menu-icon-active.svg',
        path: `/pages/events/${_vm.currentEvent ? _vm.currentEvent.id : 'id'}/menu`,
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Locations',
        icon: 'assets/imgs/booth-icon.svg',
        iconActive: 'assets/imgs/booth-icon-active.svg',
        path: `/pages/events/${
          _vm.currentEvent ? _vm.currentEvent.id : 'id'
        }/vendors/${_vm.user.user_type.vendor.id}`,
      }}})],1):_vm._e()}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }