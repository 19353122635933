<template>
  <div class="row">
    <div v-if="qrCode">
      <h4>User: {{qrCode.id}}</h4>
      <h5>{{qrCode.first_name}} {{qrCode.last_name}} </h5>
      <h6>{{qrCode.phone_number}} {{qrCode.email}} </h6>
      <qrcode :value="qrCode.one_time_payment_code" :options="{ width: 600 }"></qrcode>
      <div><button @click="qrCode=false">Close</button></div>
    </div>
    <card v-if="!qrCode && event">
      <p class="pt-2">Reload Tokens and View Transactions: {{currentEvent.name}}</p>
      <div class="alert alert-success" v-if="msg">
        {{ msg }}
      </div>
      <div class="alert alert-warning" v-if="errs.length > 0">
        <ul>
          <li v-bind:key="error" v-for="error in errs">
            {{ error }}
          </li>
        </ul>
      </div>
      <div>
        <modal :show.sync="confirmation" headerclasses="justify-content-center">
          <h4 slot="header" class="title title-up">Confirm your transfer</h4>
          <p>
            You will transfer {{ numberOfTokens }} tokens to user
            {{ userInfo().phone_number }}, ok?
          </p>
          <template slot="footer">
            <n-button @click.native="submit">Confirm</n-button>
            <n-button type="danger" @click.native="confirmation = false"
              >Close</n-button
            >
          </template>
        </modal>
      </div>
      <div class="row">

        <div class="col-md-4 user-search">
          <fg-input
            v-model="userQuery"
            @input.native="fetchUsers"
            placeholder="Search by name or email..."
          ></fg-input>
        </div>
      </div>
      <table class="table table-hover table-border">
        <thead>
          <th></th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Actions</th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              <n-checkbox
                :checked="selectedUser === user.id"
                @input="selectedUser = user.id"
                :label="user.id"
              ></n-checkbox>
            </td>
            <td>{{ user.first_name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.email }}</td>
            <td><button class="el-button el-button--primary" @click="getUser(user)">View User</button></td>
          </tr>
        </tbody>
      </table>

      <el-dialog v-if="currentUser"  :title="`${currentUser.first_name} ${currentUser.last_name} - ${currentUser.email} (${currentEvent.name})`"  :visible.sync="modalVisible">
        <user-modal @qrClicked="qrClicked"  :current-user="currentUser" :event="currentEvent" />
      </el-dialog>

    </card>

  </div>
</template>
<script>
import axios from "axios";
import {
  Radio,
  Checkbox,
  Modal,
  FormGroupInput as FgInput
} from "src/components";
import { humanize } from "src/util/string";
import { debounce, find } from "lodash";
import Event from 'src/components/Event.vue';
import EventMixin from 'src/mixins/Event.js';
import Spinner from "src/components/Spinner";
import UserModal from 'src/components/Modals/User.vue';
import { Select, Option } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    Modal,
    FgInput,
    Spinner,
    Event,
    UserModal
  },
  data() {
    return {
      confirmation: false,
      msg: "",
      errs: [],
      cash: 0,
      selectedUser: "",
      userQuery: "",
      eventDetail: {},
      eventsList: [],
      users: [],
      loading: true,
      eventDetailLoading: false,
      modalVisible: false,
      currentUser: false,
      eventBalance: false,
      qrCode: false,
    };
  },
  computed: {
    numberOfTokens() {
      return this.cash / (this.eventDetail.token_value || 1);
    },

    event() {
      return this.events.find(item => item.id == this.$route.params.id)
    }
  },
  methods: {
    qrClicked (item) {
      this.qrCode = item;
    },
    getUser(user) {
      this.modalVisible = true;
      this.currentUser = user
    },

    userInfo() {
      const selectedUser = this.selectedUser;
      const u = this.users.filter(user => user.id == selectedUser)[0];

      return (
        u || {
          phone_number: ""
        }
      );
    },
    fetchEvents() {
      const url = `${
        process.env.VUE_APP_BASE_HOST
      }/en/api/event/`;
      axios.get(url).then(response => {
        this.loading = false;
        this.eventsList = response.data.results
      });
    },

    fetchUsers: debounce(function(query) {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/user/?search=${
        this.userQuery
      }`;
      axios.get(url).then(response => {
        this.users = response.data.results;
      });
    }, 300),


  },
  mounted() {
    this.fetchEvents();
    this.fetchUsers()
  },
mixins: [EventMixin]
};
</script>
<style lang="scss">
.event-select {
  margin-left: 15px;
}
.user-search {
  margin-top: 15px;
}
</style>
