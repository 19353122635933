<template>
  <div
    :class="{
      mobile: isMobile,
      tablet: isTablet,
    }"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      isTablet: false,
    };
  },
  beforeMount() {
    let check = false;
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    check = toMatch.some((toMatchItem) => {
      return (
        navigator.userAgent.match(toMatchItem) ||
        window.innerWidth <= 1024 ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    });

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    if (isTablet) {
      check = true;
      if (window.innerWidth > 1000) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    } else {
      this.isTablet = false;
    }

    if (check) {
      if (window.innerWidth > 1000) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    }

    this.isMobile = check;
  },
};
</script>
