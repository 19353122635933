<template>
  <div>
    <div class="row" v-if="vendorEvent && vendorEvent.vendor">
      <div class="col-12" style="position: relative">
        <h3 class="title-page d-flex">
          <div class="d-flex align-items-center">
            <div class="me-2">
              {{ vendorEvent.vendor.name_en }}: {{ vendorEvent.booth }}
            </div>
          </div>
          <div
            class="wrap-datepicker"
            style="
              width: 300px;
              display: flex;
              margin-left: auto;
              justify-content: flex-end;
            "
            v-if="rawDateRange"
          >
            <DatePickerDropdown
              :rawDateRange="rawDateRange"
              :hideDateType="true"
              ref="datepicker"
              @onChangeDate="onUpdateDate"
            />
            <n-button
              style="
                margin: 0px 0px 0px 20px;
                padding: 10px;
                font-size: 10px;
                background-color: #396ad5;
                font-weight: 600;
                color: white;
                white-space: nowrap;
              "
              slot="title"
              @click.native="onReset"
            >
              Reset To Default
            </n-button>
            <!-- <el-date-picker
              :editable="false"
              @change="loadData({})"
              v-model="rangeTime"
              type="daterange"
              prefix-icon="el-icon-arrow-down"
              :align="'right'"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :format="'MMM dd'"
              :clearable="false"
            >
            </el-date-picker> -->
          </div>
        </h3>
      </div>
    </div>
    <order-summary
      :summary="summary"
      :hideTokens="user.type == 'vendor'"
      :currentPriceType="currentPriceType"
    />
    <DashboardHeader :groupType="'hour'"/>

    <!-- <card> -->
    <!-- <div class="title-row">
        <div>
          <button
            v-if="user.type == 'org-administrator'"
            @click="showBulkUpload = !showBulkUpload"
            class="btn pull-right"
          >
            Bulk Upload
          </button>
          <h3>Items</h3>
        </div>
      </div> -->
    <!-- <div class="alert alert-success" v-if="successMsg">{{ successMsg }}</div>
    <div class="alert alert-error" v-if="successMsg">{{ csvError }}</div> -->
    <spinner v-if="loading" />
    <div class="row" v-else>
      <div class="col-md-12">
        <!-- <csv-upload
          v-if="showBulkUpload"
          v-on:received="handleReceiveCsv"
        ></csv-upload> -->

        <div class="card bg-transparent shadow-none card-new">
          <div class="card-body">
            <el-table
              max-height="250"
              v-show="vendorEventItems"
              class="event-table"
              :data="vendorEventItems"
            >
              <el-table-column :prop="'name'" :label="'Name'" :minWidth="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.name_en }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :prop="'description_en'"
                :label="'Description'"
                :minWidth="250"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.description_en }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :prop="'category'"
                :label="'Category'"
                :minWidth="110"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.category }}</span>
                </template>
              </el-table-column>
              <el-table-column :prop="'price'" :label="'Price'" :minWidth="70">
                <template slot-scope="scope">
                  <span v-if="currentPriceType === 'token'">{{
                    scope.row.price
                  }}</span>
                  <span v-else
                    >${{ scope.row.price * currentEvent?.token_value }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column :label="''" :minWidth="80">
                <template slot-scope="scope">
                  <n-button
                    type="primary m-0"
                    size="sm"
                    @click.native="onShowEdit(scope.row)"
                    >Edit
                  </n-button>
                </template>
              </el-table-column>
            </el-table>

            <div class="mt-3">
              <n-button type="primary m-0" @click.native="onShowAdd()"
                >Add an Item</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </card> -->
    <div class="row">
      <div class="col-12">
        <top-5-items
          :items="top5Items"
          :event="currentEvent"
          title="Menu Items Report"
          :currentPriceType="currentPriceType"
        />
      </div>
    </div>
    <el-dialog
      width="75%"
      title=""
      :visible.sync="dialogTableVisible"
      :custom-class="'event-dialog menu-form'"
      :before-close="handleClose"
    >
      <div class="row" v-if="currentRow">
        <div class="col-md-12">
          <div class="form-event" v-if="selectedTabModal === 'location'">
            <div class="left-navigation">
              <h4>{{ currentRow.id ? rawEvent.name_en : "New Item" }}</h4>
              <ul>
                <li @click="onChangeStep('detail')">
                  <InforCircleIcon />Item Details
                </li>
                <!-- <li @click="onChangeStep('location')" class="active">
                  <LocationIcon />Locations & Availability
                </li> -->
              </ul>
            </div>
            <div class="right-panel-event">
              <button type="button" class="close-button-md" @click="onClose()">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <div
                class="wrap-content-md"
                v-if="selectedTabModal === 'location'"
                style="min-height: 545px"
              >
                <div class="infor-modal">
                  <h3><LocationIcon />Vendor</h3>
                </div>

                <form action="" class="form-event mt-3">
                  <div class="row">
                    <div class="col-xs-12 col-lg-3">
                      <div class="col-12 col-md-4">
                        <div class="form-group" style="margin-top: 34px">
                          <el-checkbox v-model="checkedVendor"
                            >Item Available</el-checkbox
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <div class="form-group" style="margin-top: 34px">
                        <el-checkbox v-model="checkedFeatured"
                          >Featured</el-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="form-event" v-if="selectedTabModal === 'detail'">
            <div class="left-navigation">
              <h4>{{ isEdit ? rawEvent.name_en : "New Item" }}</h4>
              <ul>
                <li @click="onChangeStep('detail')" class="active">
                  <InforCircleIcon />Item Details
                </li>
                <!-- <li @click="onChangeStep('location')">
                  <LocationIcon />Locations & Availability
                </li> -->
              </ul>
            </div>
            <div class="right-panel-event">
              <button type="button" class="close-button-md" @click="onClose()">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </button>
              <div class="wrap-content-md" v-if="selectedTabModal === 'detail'">
                <div class="infor-modal">
                  <h3><InforCircleIcon />Item Details</h3>
                </div>
                <form action="" class="event-form-2 mt-3">
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <label for="">Item Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Item Name"
                          v-model="currentRow.name_en"
                        />
                        <div class="wrap-absolute" style="top: 30px">
                          <el-checkbox v-model="currentRow.is_available"
                            >Available</el-checkbox
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-12 col-md-5">
                      <div class="form-group">
                        <label for="">Type</label>
                        <select class="form-control">
                          <option value="0">select type</option>
                        </select>
                      </div>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="">Description</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control"
                          v-model="currentRow.description_en"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="">Token Price</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text h-100">
                              <img src="assets/imgs/token-gray.svg" alt="" />
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Token Price"
                            v-model="currentRow.price"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="">Discount Price</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text h-100">
                              <img src="assets/imgs/token-gray.svg" alt="" />
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Discount Price"
                            :disabled="!currentRow.discounted"
                            v-model="currentRow.discount_price"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-4">
                      <div class="form-group" style="margin-top: 34px">
                        <el-checkbox v-model="currentRow.discounted"
                          >Discount Price</el-checkbox
                        >
                      </div>
                    </div>
                  </div>

                  <div class="infor-modal mt-3">
                    <span> Dietary Options</span>
                  </div>
                  <div class="row mt-3 form-group">
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="currentRow.is_vegetarian"
                        >Vegetarian</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="currentRow.is_vegan"
                        >Vegan</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="currentRow.is_dairyfree"
                        >Dairy Free</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="currentRow.is_glutenfree"
                        >Gluten Free</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="currentRow.is_beverage"
                        >Is Beverage</el-checkbox
                      >
                    </div>
                    <div class="col-xs-12 col-lg-3">
                      <el-checkbox v-model="currentRow.is_alcoholic"
                        >Is Alcoholic</el-checkbox
                      >
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <div style="width: 170px">
                        <button
                          type="button"
                          class="btn btn-primary azul m-0 w-100"
                          @click="createOrUpdateVendorEventItem(currentRow)"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { Autocomplete } from "element-ui";
import { Checkbox, Modal, DatePickerDropdown } from "src/components";
import { humanize } from "src/util/string";
import PreviewUpload from "./Components/PreviewUpload";
import DragToUpload from "./Components/DragToUpload.vue";
import CsvUpload from "./VendorEventDetail/CsvUpload.vue";
import Spinner from "src/components/Spinner";
import LoadingComponent from "src/components/LoadingComponent";
import User from "src/mixins/User";
import Event from "src/mixins/Event";
import pp from "papaparse";
import Top5Items from "./EventDetail/Top5Items.vue";
import OrderSummary from "./EventDetail/Summary.vue";
import GetSummaryData from "@/mixins/GetSummaryData.js";
import DashboardHeader from "../Dashboard/Dashboard/DashboardHeader";
import moment from "moment";
import { Card, Table as NTable } from "src/components";

export default {
  components: {
    [Autocomplete.name_en]: Autocomplete,
    [Checkbox.name_en]: Checkbox,
    Modal,
    PreviewUpload,
    DragToUpload,
    CsvUpload,
    Spinner,
    LoadingComponent,
    Top5Items,
    OrderSummary,
    DashboardHeader,
    NTable,
    DatePickerDropdown,
  },
  computed: {},
  methods: {
    onClose() {
      this.dialogTableVisible = false;
    },
    fetchVendorEvents() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?event=${this.$route.params.id}&expand=vendor`;

      if (this.$store.state.user.type == "vendor") {
        url += `&vendor=${this.$store.state.user.user_type.vendor.id}`;
      }
      axios.get(url).then((response) => {
        this.vendorEvents = response.data.results;
      });
    },
    onReset() {
      this.$refs.datepicker.onReset();
      this.rangeTime = [];
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    onUpdateDate(daterange) {
      this.rangeTime = daterange;
      this.loadData({});
    },
    handleClose() {
      this.currentRow = {};
      this.dialogTableVisible = false;
    },
    onShowEdit(evnt) {
      this.currentRow = evnt;
      this.dialogTableVisible = true;
    },
    onShowAdd() {
      this.currentRow = {
        vendor_event: this.$route.params.eventVendorId,
        discount_price: 0,
      };
      this.dialogTableVisible = true;
    },
    onChangeStep(step) {
      this.selectedTabModal = step;
    },
    addRow: function (item = {}) {
      const ret = {
        vendor_event: this.$route.params.eventVendorId,
        saved: false,
        ...item,
      };
      this.vendorEventItems.push(ret);
      return ret;
    },
    pictureChanged: function (item) {
      if (!this.pictureChangeds.includes(item.id)) {
        this.pictureChangeds.push(item.id);
      }
    },
    handleDragStart: function (e, index) {
      e.dataTransfer.effectAllowed = "move";
      this.currentDraggable = index;
    },
    handleDragEnd: function () {
      this.currentDraggable = -1;
    },
    handleDrop: function (e, index) {
      const currentDraggable = this.vendorEventItems[this.currentDraggable];
      const currentDrop = this.vendorEventItems[index];

      //this.$set(this.vendorEventItems, index, currentDraggable);
      //this.$set(this.vendorEventItems, this.currentDraggable, currentDrop);
      this.vendorEventItems.splice(this.currentDraggable, 1);
      this.vendorEventItems.splice(index, 0, currentDraggable);

      this.currentDraggable = -1;
      this.updateOrders();
    },
    handleCsvError: function (err) {
      this.successMsg = "";
      this.csvError = err;
    },
    handleReceiveCsv: function (items) {
      let i = 0;
      this.successMsg = "";
      const bulk = [];
      items.map((item) => {
        const [
          name,
          description_en,
          category,
          price,
          isAvailable,
          isFeatured,
          isVegetarian,
          isVegan,
          isGlutenFree,
          discountPrice,
        ] = item;
        if ((item, item.length == 10)) {
          let row = {
            name,
            description_en,
            category,
            price,
            is_available: isAvailable == "Y",
            is_featured: isFeatured == "Y",
            is_vegetarian: isVegetarian == "Y",
            is_vegan: isVegetarian == "Y",
            is_glutenfree: isGlutenFree == "Y",
            discount_price: discountPrice,
            status: "import",
          };
          row = this.addRow(row);
          i++;
          bulk.push(row);
        }
      });
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/`;
      axios
        .post(url, bulk)
        .then((response) => {
          this.$message(`${i} row(s) are imported`);
        })
        .catch((e) => {
          this.$message("Invalid rows, please check again and reload the page");
        });
    },
    deleteRow: async function (item) {
      if (!confirm("Are you sure you want to delete this")) {
        return false;
      }
      this.deleteRowLoading = true;
      try {
        const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/${item.id}/`;
        const response = await axios.delete(url);
        this.fetchVendorEventItem();
        this.deleteRowLoading = false;
      } catch (error) {
        console.log(error);
        this.deleteRowLoading = false;
      }
    },
    formDataFor: function (item) {
      const self = this;
      const e = {
        ...item,
        name: item.name_en
      };

      if (!e.discounted) {
        e.discount_price = 0;
      }

      if (!e.vendor_event) {
        e.vendor_event = item?.vendor_event;
      } else {
        e.vendor_event = e.vendor_event.id ? e.vendor_event.id : e.vendor_event;
      }
      if (!e.vendor_event && this.$store.state.user?.user_type?.vendor?.id) {
        const vendorData = this.vendorEvents.find(
          (e) => e.vendor.id === this.$store.state.user?.user_type?.vendor?.id
        );
        if (vendorData) {
          data.vendor_event = vendorData.id;
        }
      }

      const form = new FormData();

      Object.keys(e)
        .filter((x) => {
          return !self.pictureChangeds.includes(item.id)
            ? x !== "picture"
            : true;
        })
        .map((k) => {
          let value = e[k];
          if (k === "picture") {
            value = value || "";
          }
          form.append(k, value);
        });
      return form;
    },
    createOrUpdateVendorEventItem: async function (item) {
      this.createOrUpdateVendorEventItemLoading = true;
      try {
        if (item.id) {
          const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/${item.id}/`;
          const data = this.formDataFor(item);
          const response = await axios.patch(url, data, {
            headers: {
              "content-type": "multipart/form-data",
            },
          });
          this.dialogTableVisible = false;
          this.currentRow = false;
          this.pictureChangeds.splice(this.pictureChangeds.indexOf(item.id), 1);
          this.fetchVendorEventItem();
          this.createOrUpdateVendorEventItemLoading = false;
          this.$message("Menu Item saved successfully");
        } else {
          const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/`;
          const response = await axios.post(url, this.formDataFor(item), {
            headers: {
              "content-type": "multipart/form-data",
            },
          });
          this.dialogTableVisible = false;
          this.currentRow = false;
          this.fetchVendorEventItem();
          this.createOrUpdateVendorEventItemLoading = false;
          this.$message("Menu Item saved successfully");
        }
      } catch (error) {
        console.log(error.response.data);
        const errors = error.response.data;
        console.log(errors);
        let msg = "";
        Object.keys(errors).forEach((item) => {
          msg += `${item}: ${errors[item].join ? errors[item].join(", ") : errors[item]}\n`;
        });
        this.$message(msg);
        this.createOrUpdateVendorEventItemLoading = false;
      }
    },
    orders: function () {
      return this.vendorEventItems
        .filter((x) => x)
        .map((x, idx) => ({
          pk: x.id,
          order: idx,
        }));
    },
    updateOrders: function () {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/${this.$route.params.eventVendorId}/orders/`;
      axios.patch(url, this.orders()).then((response) => {});
    },
    fetchVendorEventItem() {
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event-item/?vendor_event=${this.$route.params.eventVendorId}&expand=items`;
      axios.get(url).then((response) => {
        this.vendorEventItems = response.data.results
          .map((v) => {
            v.status = "saved";
            return v;
          })
          .sort((x, y) => x.order - y.order);
        this.loading = false;
      });
    },
    async loadData() {
      this.fetchVendorEventItem();
      let countUrl = `${process.env.VUE_APP_BASE_HOST}/en/api/order/count/?vendor_event=${this.$route.params.eventVendorId}&top_count=1000`;

      if (this.rangeTime.length && this.rangeTime[0]) {
        countUrl += `&created_at__gte=${moment(this.rangeTime[0]).startOf('day').format()}&created_at__lte=${moment(this.rangeTime[1]).endOf('day').format()}`;
      }
      this.getSummaryData(countUrl);
      this.vendorEvent = await this.$store.dispatch(
        "fetchVendorEvent",
        this.$route.params.eventVendorId
      );
    },
  },
  data: () => ({
    vendorEvents: [],
    checkedVendor: false,
    selectedTabModal: "detail",
    vendorEventItems: [],
    serverErrors: [],
    successMsg: "",
    currentDraggable: -1,
    csvError: null,
    pictureChangeds: [],
    loading: true,
    createOrUpdateVendorEventItemLoading: false,
    deleteRowLoading: false,
    top5Items: [],
    dialogTableVisible: false,
    currentRow: false,
    total_orders: 0,
    revenue: 0,
    avgOrderRevenue: 0,
    showBulkUpload: false,
    vendorEvent: false,
    rawDateRange: null,
    rangeTime: null,
    currentPriceType: "token",
    isTurnOnPageRefresh: false,
  }),
  beforeUnmount() {
    clearInterval(this.timer);
  },
  async mounted() {
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }

    this.fetchVendorEvents();
    this.loadData();
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.loadData({});
        }, 10000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.loadData({});
      }, 10000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
  },
  mixins: [User, GetSummaryData, Event],
};
</script>
<style lang="scss">
.preview-upload {
  display: flex;
  align-items: center;
}
.image-preview {
  height: 150px;
  width: 150px;
}
.form-group {
  input {
    // height: 3.5em;
  }
}
</style>
