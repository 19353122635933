<template>
  <div id="reporting">
    <div class="no-tab-content-inside">
      <div class="row">
        <div
          class="col-12 create-event-table d-flex"
          style="position: relative"
        >
          <h3 class="title-page d-flex w-100">
            Reporting
            <div
              class="wrap-datepicker action-create-event"
              style="
                width: auto;
                margin-left: auto;
                margin-right: 0;
                justify-content: flex-end;
              "
              v-if="rawDateRange"
            >
              <DatePickerDropdown
                :rawDateRange="rawDateRange"
                ref="datepicker"
                @onChangeDateType="onChangeDateType"
                @onChangeDate="onChangeDate"
              />

              <n-button
                style="
                  margin: 0px 0px 0px 20px;
                padding: 10px;
                font-size: 10px;
                background-color: #396ad5;
                font-weight: 600;
                color: white;
                white-space: nowrap;
                "
                slot="title"
                @click.native="onReset"
              >
                Reset To Default
              </n-button>

              <!-- <el-date-picker
              :editable="false"
              style="width: 190px !important"
              v-model="rangeTime"
              type="daterange"
              prefix-icon="el-icon-arrow-down"
              :align="'right'"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :format="'MMM dd'"
              :clearable="false"
              @change="loadData({})"
            >
            </el-date-picker> -->
            </div>
          </h3>
        </div>
      </div>

      <canvas id="chart" style="width: 0; height: 0"></canvas>
      <spinner v-if="loading" />

      <template v-if="data && !loading">
        <div class="row mt-3 mb-3">
          <div class="col-12 col-md-12">
            <div class="card card-stats card-raised bg-transparent shadow-none">
              <div class="card-body pl-0 pr-0">
                <div class="row">
                  <div
                    class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-4"
                  >
                    <div class="statistics">
                      <div class="info">
                        <div class="icon icon-primary">
                          <img src="assets/imgs/red-box.svg" alt="" />
                        </div>
                        <div
                          class="statistics-infor"
                          v-if="data && data.aggregates"
                        >
                          <h3 class="info-title">
                            <animated-number
                              :value="data.aggregates.orders_received"
                            ></animated-number>
                          </h3>
                          <h6 class="stats-title">Orders</h6>
                        </div>
                        <spinner v-if="!data.aggregates" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-4"
                  >
                    <div class="statistics">
                      <div class="info">
                        <div class="icon icon-primary">
                          <img src="assets/imgs/yellow-transfer.svg" alt="" />
                        </div>

                        <div
                          class="statistics-infor"
                          v-if="data && data.aggregates"
                        >
                          <h3 class="info-title">
                            <animated-number
                              :value="data.aggregates.items_sold"
                            ></animated-number>
                          </h3>
                          <h6 class="stats-title">Items Sold</h6>
                        </div>
                        <spinner v-if="!data.aggregates" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-4"
                  >
                    <div class="statistics">
                      <div class="info">
                        <div class="icon icon-primary">
                          <img src="assets/imgs/customer.svg" alt="" />
                        </div>

                        <div
                          class="statistics-infor"
                          v-if="data && data.aggregates"
                        >
                          <h3 class="info-title">
                            <animated-number
                              :value="data.aggregates.customers"
                            ></animated-number>
                          </h3>
                          <h6 class="stats-title">Customers</h6>
                        </div>
                        <spinner v-if="!data.aggregates" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-4"
                  >
                    <div class="statistics">
                      <div class="info">
                        <div class="icon icon-primary">
                          <img src="assets/imgs/token-gray-box.svg" alt="" />
                        </div>

                        <div
                          class="statistics-infor"
                          v-if="data && data.aggregates"
                        >
                          <h3 class="info-title">
                            <animated-number
                              v-if="currentPriceType === 'token'"
                              :value="data.aggregates.avg"
                            ></animated-number>
                            <span v-if="currentPriceType === 'dollar'">
                              $<animated-number
                                :value="data.aggregates.avg * event.token_value"
                              ></animated-number>
                            </span>
                          </h3>
                          <h6 class="stats-title">Avg. Token Spend</h6>
                        </div>
                        <spinner v-if="!data.aggregates" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-4"
                  >
                    <div class="statistics">
                      <div class="info">
                        <div class="icon icon-primary blue-bg">
                          <img src="assets/imgs/token-blue.svg" alt="" />
                        </div>

                        <div
                          class="statistics-infor"
                          v-if="data && data.aggregates"
                        >
                          <h3 class="info-title">
                            <animated-number
                              v-if="currentPriceType === 'token'"
                              :value="
                                data.aggregates.tokens /
                                data.aggregates.orders_received
                              "
                            ></animated-number>
                            <span v-if="currentPriceType === 'dollar'">
                              $<animated-number
                                :value="
                                  (data.aggregates.tokens /
                                    data.aggregates.orders_received) *
                                  event.token_value
                                "
                              ></animated-number>
                            </span>
                          </h3>
                          <h6 class="stats-title">Avg. Order</h6>
                        </div>
                        <spinner v-if="!data.aggregates" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 mb-4"
                  >
                    <div class="statistics">
                      <div class="info">
                        <div class="icon icon-primary">
                          <img src="assets/imgs/revenue.svg" alt="" />
                        </div>

                        <div
                          class="statistics-infor"
                          v-if="data && data.aggregates"
                        >
                          <h3 class="info-title">
                            <animated-number
                              v-if="currentPriceType === 'token'"
                              :value="data.aggregates.tokens"
                            ></animated-number>
                            <span v-if="currentPriceType === 'dollar'">
                              $<animated-number
                                :value="
                                  data.aggregates.tokens * event.token_value
                                "
                              ></animated-number>
                            </span>
                          </h3>
                          <h6 class="stats-title">Revenue</h6>
                        </div>
                        <spinner v-if="!data.aggregates" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-12">
            <h3 class="title-page medium-size">Sales Report</h3>
            <div class="panel-header panel-header-lg">
              <LineChartGenerator
                v-if="chartData && !loading"
                :data="chartData"
                :options="options"
                :height="600"
                :width="'100%'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <tabs
              type="primary"
              v-on:change="onTabChange"
              v-model="currentTab"
              :hidden-dot="true"
            >
              <tab-pane label="By Date"> </tab-pane>
              <tab-pane label="By Item"> </tab-pane>
              <tab-pane label="By Location"> </tab-pane>
              <tab-pane label="By Vendor"> </tab-pane>
            </tabs>

            <div class="tab-content-outside" v-if="currentTab === 'By Vendor'">
              <div class="search-table"></div>

              <data-tables-server
                v-show="!loading && data"
                class="event-table"
                :data="data.vendors"
                :total="data.vendors ? data.vendors.length : 0"
                :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
              >
                <el-table-column
                  :label="'Vendor'"
                  :prop="'name'"
                  sortable="custom"
                  :minWidth="140"
                >
                  <template slot-scope="scope">
                    <a href="javascript:void(0)" @click="handleView(scope.row)">
                      <template v-if="scope.row.name">
                        {{ scope.row.name }}
                      </template>
                      <template v-else> N/A </template>
                    </a>
                  </template>
                </el-table-column>

                <el-table-column
                  v-for="title in titlesVendor"
                  :prop="title.prop"
                  :label="title.label"
                  :key="title.prop"
                  :minWidth="140"
                  :formatter="title.formatter"
                  :sortable="title.isSortable"
                >
                </el-table-column>
                <el-table-column :label="''">
                  <template slot-scope="scope">
                    <div :class="'action-cl'" disable-transitions>
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          <img
                            class="dropdown-dot"
                            src="assets/imgs/dot-dropdown.svg"
                            alt=""
                          />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            command="a"
                            @click.native="handleView(scope.row)"
                            ><i class="el-icon-view"></i> View</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </data-tables-server>

              <spinner v-if="loading" />
            </div>
            <div
              class="tab-content-outside"
              v-if="currentTab === 'By Location'"
            >
              <div class="search-table"></div>

              <data-tables-server
                v-show="!loading && data"
                class="event-table"
                :data="data.locations"
                :total="data.locations ? data.locations.length : 0"
                :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
              >
                <el-table-column
                  :label="'Vendor'"
                  :prop="'name'"
                  sortable="custom"
                  :minWidth="140"
                >
                  <template slot-scope="scope">
                    <a
                      href="javascript:void(0)"
                      @click="handleViewByLocation(scope.row)"
                    >
                      <template v-if="scope.row.vendor__name">
                        {{ scope.row.vendor__name }}
                      </template>
                      <template v-else> N/A </template>
                    </a>
                  </template>
                </el-table-column>

                <el-table-column
                  v-for="title in titlesLocation"
                  :prop="title.prop"
                  :label="title.label"
                  :key="title.prop"
                  :formatter="title.formatter"
                  :sortable="title.isSortable"
                  :minWidth="140"
                >
                </el-table-column>
                <el-table-column :label="''">
                  <template slot-scope="scope">
                    <div :class="'action-cl'" disable-transitions>
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          <img
                            class="dropdown-dot"
                            src="assets/imgs/dot-dropdown.svg"
                            alt=""
                          />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            command="a"
                            @click.native="handleViewByLocation(scope.row)"
                            ><i class="el-icon-view"></i> View</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </data-tables-server>

              <spinner v-if="loading" />
            </div>
            <div class="tab-content-outside" v-if="currentTab === 'By Item'">
              <div class="search-table"></div>

              <data-tables-server
                v-show="!loading && data"
                class="event-table"
                :data="data.items"
                :total="data.items ? data.items.length : 0"
                :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
              >
                <el-table-column
                  v-for="title in titlesItem"
                  :prop="title.prop"
                  :label="title.label"
                  :key="title.prop"
                  :formatter="title.formatter"
                  :sortable="title.isSortable"
                  :minWidth="140"
                >
                </el-table-column>
                <!-- <el-table-column :label="''">
                  <template slot-scope="scope">
                    <div :class="'action-cl'" disable-transitions>
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          <img
                            class="dropdown-dot"
                            src="assets/imgs/dot-dropdown.svg"
                            alt=""
                          />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            command="a"
                            @click.native="handleView(scope.row)"
                            ><i class="el-icon-view"></i> View</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column> -->
              </data-tables-server>

              <spinner v-if="loading" />
            </div>
            <div class="tab-content-outside" v-if="currentTab === 'By Date'">
              <div class="search-table">
                <el-input
                  placeholder="Search by Account"
                  v-model="filters[0].value"
                >
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>

              <data-tables-server
                v-show="!loading && data"
                class="event-table"
                :data="data.date"
                :total="data.date ? data.date.length : 0"
                :pagination-props="{ pageSizes: [50, 100, 500, 1000] }"
              >
                <el-table-column
                  v-for="title in titlesDate"
                  :prop="title.prop"
                  :label="title.label"
                  :key="title.prop"
                  :formatter="title.formatter"
                  :minWidth="150"
                  :sortable="title.isSortable"
                >
                </el-table-column>
                <!-- <el-table-column :label="''">
                  <template slot-scope="scope">
                    <div :class="'action-cl'" disable-transitions>
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          <img
                            class="dropdown-dot"
                            src="assets/imgs/dot-dropdown.svg"
                            alt=""
                          />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            command="a"
                            @click.native="handleView(scope.row)"
                            ><i class="el-icon-view"></i> View</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column> -->
              </data-tables-server>

              <spinner v-if="loading" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EventMixin from "src/mixins/Event";
import Spinner from "src/components/Spinner";
import {
  TabPane,
  Tabs,
  AnimatedNumber,
  DatePickerDropdown,
} from "src/components";
import _ from "lodash";
import moment from "moment";
import "chart.js/auto";

import { Line as LineChartGenerator } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import "chartjs-adapter-moment";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

ChartJS.defaults.font.weight = 600;

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "8px";
    tooltipEl.style.color = "#021B43";
    tooltipEl.style.padding = "15px";
    tooltipEl.style.boxShadow = "0 0 12px 2px rgba(231,234,243,0.30)";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.minWidth = "180px";
    tooltipEl.classList.add("arrow-panel");

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tooltip._tooltipItems.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;
      tr.style.borderWidth = 0;
      tr.style.fontFamily = '"Inter", sans-serif';
      tr.style.fontSize = "12px";
      tr.style.marginTop = "8px";

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(
        body.formattedValue + " Tokens via " + body.dataset.label
      );

      // td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left =
    tooltip.x <= 100
      ? 100 + "px"
      : positionX +
        (tooltip.x + tooltip.width + 30 < chart.canvas.offsetWidth ? 0 : -90) +
        tooltip.caretX +
        "px";

  tooltipEl.style.top = -positionY + tooltip.y - 44 + "px";
  //  -
  // (tooltip.y + tooltip.height + 120 < chart.canvas.offsetHeight ? 0 : -90) +
  // tooltip.caretY +

  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";

  tooltipEl.classList.remove("right");
  if (tooltip.x + tooltip.width + 100 >= chart.canvas.offsetWidth) {
    tooltipEl.classList.add("right");
  }
  tooltipEl.classList.remove("left");
  if (tooltip.x <= 100) {
    tooltipEl.classList.add("left");
  }

  if (tooltip.y + tooltip.height + 30 >= chart.canvas.offsetHeight) {
    tooltipEl.classList.add("bottom");
  }
};

const debounced = _.debounce((vm, url, params) => {
  axios.get(url, { params }).then((response) => {
    vm.data = response.data;
    let chartColor = "#2d61d3"; //"rgba(45, 97, 211, 0.25)";
    const ctx = document.getElementById("chart").getContext("2d");
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);

    const gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    const whitefill = ctx.createLinearGradient(0, 200, 0, 50);
    whitefill.addColorStop(0, "rgba(255, 255, 255, 0.5)");
    gradientFill.addColorStop(1, "rgba(89, 154, 235, 0.8)");
    gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");

    let responseChart = response.data.date;

    if (vm.groupType === "date") {
      responseChart = [
        ...responseChart
          .reduce((map, item) => {
            const { created_at__date, tokens, orders_received } = item;
            let key = moment(created_at__date).format("DD MMM YYYY");
            const prev = map.get(key);

            if (prev) {
              prev.tokens += tokens;
              prev.orders_received += orders_received;
              prev.created_at__date = key;
            } else {
              map.set(
                key,
                Object.assign(
                  {},
                  {
                    tokens,
                    orders_received,
                    created_at__date: key,
                  }
                )
              );
            }

            return map;
          }, new Map())
          .values(),
      ];
    } else {
      responseChart = [...responseChart];
    }
  
    vm.chartData = {
      labels: responseChart.map((item) => item.created_at__date),
      datasets: [
        {
          label: "Tokens Issued",
          // backgroundColor: gradientFill,
          pointBorderColor: chartColor,
          pointBackgroundColor: "#2d61d3",
          pointHoverBackgroundColor: "#2d61d3",
          borderColor: "#2d61d3",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 2,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 3,
          pointRadius: 4,
          fill: true,
          borderWidth: 2,
          lineTension: 0,
          data: responseChart.map((item) => item.tokens),
          type: "line",
        },
        {
          type: "line",
          label: "Orders",
          backgroundColor: gradientFill,
          pointBorderColor: chartColor,
          pointBackgroundColor: "#2d61d3",
          pointHoverBackgroundColor: "#2d61d3",
          borderColor: "#2d61d3",
          pointBorderWidth: 2,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 3,
          pointRadius: 4,
          lineTension: 0,
          // fill: true,
          borderWidth: 2,
          data: responseChart.map((item) => item.orders_received),
        },
      ],
    };

    vm.options = {
          interaction: {
            intersect: false,
            mode: "index",
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 40,
              bottom: 0,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: undefined,
              gridLines: {
                zeroLineColor: "transparent",
                display: false,
                color: '#fff'
              },
              grid: {
                display: false
              },
              distribution: "series",
              ticks: {
                padding: 10,
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: 10,
                color: "#6B7280",
                font: {
                  weight: 400,
                  size: 14,
                  family: '"Inter", sans-serif;',
                },
                callback: function (label, index, labels) {
                  const lb = this.getLabelForValue(label);
                  if (self.groupType === "hour") {
                    return lb;
                  }
                  return moment(lb).format("ddd DD");
                },
              },
            },
            y: {
              ticks: {
                fontColor: "#8e8e93",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 10,
                color: "#6B7280",
                font: {
                  weight: 400,
                  size: 14,
                  family: '"Inter", sans-serif;',
                },
              },
              gridLines: {
                drawTicks: true,
                drawBorder: false,
                display: true,
                borderDash: [4, 2],
                color: "#bdbdbd",
                zeroLineColor: "transparent",
              },
            },
          },
          plugins: {
            decimation: {
              algorithm: "lttb",
              enabled: true,
              samples: 10,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: (event) => externalTooltipHandler(event, this),
            },
          },
        };

    vm.loading = false;
  });
}, 500);
import {
  formatRevenue,
  formatRevenueToken,
  formatRevenueValue,
} from "@/util/string.js";
export default {
  components: {
    Spinner,
    TabPane,
    Tabs,
    AnimatedNumber,
    LineChartGenerator,
    DatePickerDropdown,
  },
  // extends: Bar,
  computed: {
    event() {
      console.log(this.$store.state.allEvents);
      return this.$store.getters.allEvents
        ? this.$store.getters.allEvents.find(
            (item) => item.id == parseInt(this.$route.params.id)
          )
        : null;
    },
    fees() {
      console.log(this.data.total_card_amount, this.event.fee);
      return (this.data.total_card_amount * this.event.fee).toFixed(2);
    },
    tax() {
      return (this.data.total_card_amount * this.event.tax).toFixed(2);
    },
  },
  data() {
    return {
      selectedReportingType: null,
      optionsReportingType: [
        {
          value: "all",
          label: "All Vendors",
        },
      ],
      options: null,
      chartData: null,
      data: null,
      date: {},
      vendorEvent: "",
      vendorEvents: [],
      aggregationBy: "day",
      currentRow: false,
      loading: false,
      groupType: "hour",
      rawDateRange: null,
      rangeTime: null,
      total: 0,
      titlesVendor: [
        {
          prop: "name",
          label: "Vendor",
          isSortable: true,
        },
        {
          prop: "orders_received",
          label: "Orders",
          isSortable: true,
        },
        {
          prop: "items_sold",
          label: "Items Sold",
          isSortable: true,
        },
        {
          prop: "customers",
          label: "Customers",
          isSortable: true,
        },
        {
          prop: "revenue",
          label: "Tokens Collected",
          isSortable: true,
          formatter: (row, col, cell, index) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(cell, this.currentEvent, 3);
            }
            return formatRevenueValue(cell, this.currentEvent, 3);
          },
        },
      ],
      titlesLocation: [
        // {
        //   prop: "vendor__name",
        //   label: "Vendor",
        //   isSortable: true,
        // },
        {
          prop: "booth",
          label: "Booth",
          isSortable: true,
        },
        {
          prop: "items_sold",
          label: "Items Sold",
          isSortable: true,
        },
        {
          prop: "customers",
          label: "Customers",
          isSortable: true,
        },
        {
          prop: "revenue",
          label: "Tokens Collected",
          isSortable: true,
          formatter: (row, col, cell, index) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(cell, this.currentEvent, 3);
            }
            return formatRevenueValue(cell, this.currentEvent, 3);
          },
        },
      ],
      titlesItem: [
        {
          prop: "vendor_event_item__vendor_event__vendor__name",
          label: "Vendor",
          isSortable: true,
        },
        {
          prop: "vendor_event_item__name",
          label: "Item",
          isSortable: true,
        },
        {
          prop: "items_sold",
          label: "Items Sold",
          isSortable: true,
        },
        {
          prop: "customers",
          label: "Customers",
          isSortable: true,
        },
        {
          prop: "revenue",
          label: "Tokens Collected",
          isSortable: true,
          formatter: (row, col, cell, index) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(cell, this.currentEvent, 3);
            }
            return formatRevenueValue(cell, this.currentEvent, 3);
          },
        },
      ],
      titlesDate: [
        {
          prop: "created_at__date",
          label: "Date",
          isSortable: true,
          formatter: (row, col, cell, index) => moment(cell).format("LLL"),
        },
        {
          prop: "orders_received",
          label: "Orders",
          isSortable: true,
        },
        {
          prop: "items_sold",
          label: "Items Sold",
          isSortable: true,
        },
        {
          prop: "customers",
          label: "Customers",
          isSortable: true,
        },
        {
          prop: "revenue",
          label: "Tokens Collected",
          isSortable: true,
          formatter: (row, col, cell, index) => {
            if (this.currentPriceType === "token") {
              return formatRevenueToken(cell, this.currentEvent, 3);
            }
            return formatRevenueValue(cell, this.currentEvent, 3);
          },
        },
      ],
      filters: [
        {
          prop: "created_at__date",
          value: "",
        },
        {
          prop: "items__vendor_event_item__name",
          value: "",
        },
        {
          prop: "vendor_event__booth",
          value: "",
        },
        {
          prop: "vendor_event__vendor__name",
          value: "",
        },
      ],
      queryInfo: {},
      currentTab: "By Vendor",
      timer: null,
      currentPriceType: "token",
      vendorEvents: [],
      filterByVendor: null,
    };
  },
  async mounted() {
    this.loading = true;
    const url = `${process.env.VUE_APP_BASE_HOST}/en/api/transaction/?event=${this.$route.params.id}`;
    const params = {
      limit: 1,
      ordering: "-timestamp",
    };
    const rs = await axios.get(url, { params });

    const params2 = {
      limit: 1,
      ordering: "timestamp",
    };
    const rs2 = await axios.get(url, { params2 });

    if (
      rs?.data?.results &&
      rs.data.results[0] &&
      rs.data.results[0].timestamp &&
      rs2?.data?.results &&
      rs2.data.results[0] &&
      rs2.data.results[0].timestamp
    ) {
      this.rawDateRange = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
      this.rangeTime = [
        moment(rs2.data.results[0].timestamp).toDate(),
        moment(rs.data.results[0].timestamp).toDate(),
      ];
    } else {
      this.rawDateRange = [moment().toDate(), moment().toDate()];
      this.rangeTime = [moment().toDate(), moment().toDate()];
    }

    this.loadData({});
    this.fetchVendorEvents();
    window.eventBus.$on("onChangeRefreshPage", (refresh) => {
      if (refresh && !this.timer) {
        this.timer = setInterval(() => {
          this.loadData(this.queryInfo || {}, true);
        }, 5000);
      } else {
        clearInterval(this.timer);
      }
    });
    const isTurnOnPageRefresh = sessionStorage.getItem("isRefresh") === "true";
    if (isTurnOnPageRefresh) {
      this.timer = setInterval(() => {
        this.loadData(this.queryInfo || {}, true);
      }, 5000);
    }

    window.eventBus.$on("onChangePriceType", (type) => {
      this.currentPriceType = type;
    });
    const currentPriceType = sessionStorage.getItem("priceType");
    if (currentPriceType === "token" || currentPriceType === "dollar") {
      this.currentPriceType = currentPriceType;
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },

  methods: {
    onReset() {
      this.$refs.datepicker.onReset();
      this.rangeTime = [];
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    onChangeDateType(type) {
      this.groupType = type;
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    onChangeDate(daterange) {
      this.rangeTime = daterange;
      setTimeout(() => {
        this.loadData({});
      }, 0);
    },
    handleView(row) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/vendors/${row.id}`,
      });
    },
    handleViewByLocation(row) {
      this.$router.push({
        path: `/pages/events/${this.$route.params.id}/vendors/${row.vendor_id}`,
      });
    },
    fetchVendorEvents() {
      let url = `${process.env.VUE_APP_BASE_HOST}/en/api/vendor-event/?event=${this.$route.params.id}&expand=vendor`;

      if (this.$store.state.user.type == "vendor") {
        url += `&vendor=${this.$store.state.user.user_type.vendor.id}`;
      }
      axios.get(url).then((response) => {
        this.vendorEvents = response.data.results;
        this.loading = false;
      });
    },
    onChangeReportingType(vl) {
      this.filterByVendor = vl === "all" ? null : vl;
      this.loadData(this.queryInfo || {});
    },
    onTabChange(tab) {
      this.currentTab = tab;
      // this.loadData(this.queryInfo)
    },

    async loadData(queryInfo, isRefresh = false) {
      if (!isRefresh) {
        this.loading = true;
      }
      // this.chartData = null;

      console.log(queryInfo);
      const url = `${process.env.VUE_APP_BASE_HOST}/en/api/event/${this.$route.params.id}/reporting`;
      const params = {
        vendor_event__vendor: this.filterByVendor,
        vendor_event__event: this.$route.params.id,
        offset: (queryInfo.page - 1) * queryInfo.pageSize,
        limit: queryInfo.pageSize,
        expand: "vendor_event,vendor_event.vendor,user",
        search: this.filters[0].value,
        status__in: this.status,
      };

      if (queryInfo.sort && queryInfo.sort.prop) {
        params["ordering"] =
          queryInfo.sort.order == "descending" ? "-" : "" + queryInfo.sort.prop;
      }

      if (this.date) {
        params["start"] = this.date[0];
        params["end"] = this.date[1];
      }

      if (this.aggregationBy) {
        params["aggregation_by"] = this.aggregationBy;
      }

      if (this.rangeTime) {
        params["created_at__gte"] = moment(this.rangeTime[0])
          .startOf("day")
          .format();
        params["created_at__lte"] = moment(this.rangeTime[1])
          .endOf("day")
          .format();
      }

      this.queryInfo = queryInfo;
      debounced(this, url, params);
    },
  },
  mixins: [EventMixin],
};
</script>
<style lang="scss">
tr {
  &.date-header {
    background-color: #cdcdcd;
    cursor: pointer;
  }
}
#financials {
  .el-input {
    input {
      margin: 0;
    }
  }
}

.wrap-datepicker {
  // position: absolute;
  // top: 0;
  // right: 0;
  .el-date-editor {
    background: #ffffff;
    border: 1px solid rgba(238, 238, 239, 0.7);
    box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    color: #021b43;
    font-size: 12px;
    font-weight: 500;
    input {
      font-family: "Inter", sans-serif;
      color: #021b43;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .el-icon-arrow-down {
    position: absolute;
    right: 5px;
    color: #021b43;
    font-weight: bold;
  }
}
</style>
