var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      { 'col-md-4': _vm.vertical && !_vm.tabNavWrapperClasses },
      { 'col-12': _vm.centered && !_vm.tabNavWrapperClasses },
      _vm.tabNavWrapperClasses,
    ]},[_c('ul',{staticClass:"nav-tab",class:[
        `nav-pills-${_vm.type}`,
        { 'nav-pills-icons': _vm.square },
        { 'flex-column': _vm.vertical },
        { 'justify-content-center': _vm.centered },
        _vm.tabNavClasses,
      ],attrs:{"role":"tablist"}},[(!_vm.hiddenDot)?_c('li',{staticStyle:{"margin-left":"auto"}}):_vm._e(),_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"nav-tab-item",attrs:{"data-toggle":"tab","role":"tablist","aria-expanded":"true"}},[_c('a',{staticClass:"nv-lnk flex w-full items-center gap-x-1.5 whitespace-nowrap rounded-md border border-gray-300 px-2 py-1.5 font-medium text-gray-600 hover:bg-gray-50 sm:w-fit sm:text-xs !border-dashed outline outline-offset-2 outline-0 focus-visible:outline-2 outline-indigo-500",class:{ active: tab.active },attrs:{"data-toggle":"tab","role":"tablist","href":`#${tab.id}`,"aria-expanded":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('div',[_vm._v(" "+_vm._s(tab.$slots.label || tab.label)+" "),(tab.$slots.badgge || tab.badge)?_c('div',{staticClass:"badge-tab"},[_vm._v(" "+_vm._s(tab.$slots.badgge || tab.badge)+" ")]):_vm._e()])])])})],2)]),_c('div',{staticClass:"tab-content",class:[
      { 'tab-space': !_vm.vertical },
      { 'col-md-8': _vm.vertical && !_vm.tabContentClasses },
      _vm.tabContentClasses,
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }