<template>
  <div>
    <div
      :class="[
        { 'col-md-4': vertical && !tabNavWrapperClasses },
        { 'col-12': centered && !tabNavWrapperClasses },
        tabNavWrapperClasses,
      ]"
    >
      <ul
        class="nav-tab"
        role="tablist"
        :class="[
          `nav-pills-${type}`,
          { 'nav-pills-icons': square },
          { 'flex-column': vertical },
          { 'justify-content-center': centered },
          tabNavClasses,
        ]"
      >
        <li style="margin-left: auto" v-if="!hiddenDot">
          <!-- <img class="dropdown-dot" src="assets/imgs/dot-dropdown.svg" alt="" /> -->
        </li>
        <li
          v-for="tab in tabs"
          class="nav-tab-item"
          data-toggle="tab"
          role="tablist"
          aria-expanded="true"
          :key="tab.id"
        >
          <a
            data-toggle="tab"
            role="tablist"
            :href="`#${tab.id}`"
            @click.prevent="activateTab(tab)"
            :aria-expanded="tab.active"
            class="nv-lnk flex w-full items-center gap-x-1.5 whitespace-nowrap rounded-md border border-gray-300 px-2 py-1.5 font-medium text-gray-600 hover:bg-gray-50 sm:w-fit sm:text-xs !border-dashed outline outline-offset-2 outline-0 focus-visible:outline-2 outline-indigo-500"
            :class="{ active: tab.active }"
          >
            <!-- <tab-item-content :tab="tab"> </tab-item-content> -->
            <div>
              {{ tab.$slots.label || tab.label }}
              <div class="badge-tab" v-if="tab.$slots.badgge || tab.badge">
                {{ tab.$slots.badgge || tab.badge }}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="tab-content"
      :class="[
        { 'tab-space': !vertical },
        { 'col-md-8': vertical && !tabContentClasses },
        tabContentClasses,
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs",
  components: {
    // TabItemContent: {
    //   props: ["tab"],
    //   render(h) {
    //     return h("div", [this.tab.$slots.label || this.tab.label]);
    //   },
    // },
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab,
    };
  },
  props: {
    hiddenDot: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "primary",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    activeTab: {
      type: String,
      default: "",
    },
    tabNavWrapperClasses: {
      type: [String, Object],
      default: "",
    },
    tabNavClasses: {
      type: [String, Object],
      default: "",
    },
    tabContentClasses: {
      type: [String, Object],
      default: "",
    },
    vertical: Boolean,
    square: Boolean,
    centered: Boolean,
    value: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      tabs: [],
    };
  },
  methods: {
    findAndActivateTab(label, isInit) {
      let tabToActivate = this.tabs.find((t) => t.label === label);
      if (tabToActivate) {
        this.activateTab(tabToActivate, isInit);
      }
    },
    activateTab(tab, isInit) {
      if (this.handleClick) {
        this.handleClick(tab);
      }
      this.deactivateTabs();
      tab.active = true;
      if (!isInit) {
        this.$emit("change", tab.label);
      }
    },
    deactivateTabs() {
      this.tabs.forEach((tab) => {
        tab.active = false;
      });
    },
    addTab(tab) {
      const index = this.$slots.default.indexOf(tab.$vnode);
      if (!this.activeTab && index === 0) {
        tab.active = true;
      }
      if (this.activeTab === tab.name) {
        tab.active = true;
      }
      this.tabs.splice(index, 0, tab);
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.findAndActivateTab(this.value, true);
      }
    });
  },
  watch: {
    value(newVal) {
      this.findAndActivateTab(newVal);
    },
  },
};
</script>

<style scoped lang="scss">
ul.nav__items {
  display: flex;
  flex-direction: column;
}

ul.nav__items > li {
  margin-bottom: 15px;
}
ul.nav__items .nav-link {
  margin-right: 20px;
}
@media (min-width: 800px) {
  ul.nav__items {
    flex-direction: row;
  }
  ul.nav__items > li {
    margin-bottom: 0;
  }
  ul.nav__items .nav-link {
    margin-right: 20px;
  }
}
.nav-tab {
  flex-wrap: wrap;
  display: flex;
  padding: 0;
  list-style: none;
  position: relative;
  z-index: 10;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 0;
  top: 0;
  background: transparent;
  gap: 8px;
  margin-bottom: 10px;
  .nav-tab-item > .nav-tab-link,
  .nav-tab-item > .nv-lnk {
    // grid-column-gap: 8px;
    // grid-row-gap: 8px;
    // border: 1px solid rgba(18, 42, 105, 0.08);
    // background-color: #fff;
    // color: #36364a;
    // border-radius: 8px;

    // padding: 6px 16px 6px 12px;
    // height: 100%;
    // font-weight: 500;
    // text-decoration: none;
    // transition: color 0.3s, background-color 0.3s;
    min-height: 32px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .badge-tab {
      display: inline-block;
      background: rgba(189, 189, 189, 0.5);
      margin: 1px 0 1px 17px;
      padding: 0 7px;
      border-radius: 11.5px;
      font-size: 12px;
    }
    &.active {
      border-style: solid !important;;
    }
    &.active,
    &:hover {
      background-color: #2d61d3;
      color: #fff;
      border-color: #2d61d3;
      .badge-tab {
        display: inline-block;
        background: rgba(45, 97, 211, 0.25);
        margin: 1px 0 1px 17px;
        padding: 0 7px;
        border-radius: 11.5px;
        font-size: 12px;
      }
    }
  }
}

// .tab-content {
//   background: #fff;
//   margin-bottom: 20px;
//   padding: 0 0 20px;
//   border-radius: 8px;
//   box-shadow: 0 0 12px 2px rgba(231, 234, 243, 0.3);
//   border: solid 1px rgba(238, 238, 239, 0.7);
//   border-top-left-radius: 0;
//   // padding-top: 10px;
//   position: relative;
//   z-index: 1;
//   top: 0px;
// }

.right-align-tabs {
  .nav-tab {
    justify-content: flex-start;
  }
}
</style>
