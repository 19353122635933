<template>
  <article>
    <p id="status">
      Drop a csv file here, you can find the template
      <a href="/template.csv">here</a>
    </p>
    <div
      id="holder"
      :class="isHover ? 'hover' : ''"
      @dragover.prevent="isHover = true"
      @dragend="isHover = false"
      @drop="handleDrop"
    >
    
      <div class="text">
        Drag or select a csv file for bulk upload
        <input type='file' @change="handleDrop" />
      </div>
    </div>
  </article>
</template>
<script>
import pp from "papaparse";

export default {
  components: {},
  computed: {},
  methods: {
    handleDrop: function(e) {
      const that = this;
      this.isHover = false;
      e.preventDefault();
      const reader = new FileReader();
      let file;
      if (e.dataTransfer) {
        file = e.dataTransfer.files[0];
      } else {
        file = e.target.files[0];
      }

      reader.onload = function(event) {
        try {
          const data = event.target.result;
          const items = pp.parse(data).data;
          that.$emit("received", items.slice(1, items.length));
        } catch (err) {
          that.$emit("errors", err);
        }
      };

      reader.readAsText(file);

      return false;
    }
  },
  data: () => ({
    supported: false,
    isHover: false
  }),
  mounted() {
    if (typeof window.FileReader !== "undefined") {
      this.supported = true;
    }
  }
};
</script>
<style lang="scss">
#holder {
  border: 10px dashed #ccc;
  width: 300px;
  height: 300px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  .text {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
}

#holder.hover {
  border: 10px dashed #333;
}
</style>
