<template>
  <div class="row" v-if="summary">
    <div class="col-md-12">
      <div class="card card-stats card-raised bg-transparent shadow-none">
        <div class="card-body pl-0 pr-0">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3">
              <div class="statistics">
                <div class="info">
                  <div class="icon icon-primary">
                    <img src="assets/imgs/users-icon.svg" alt="" />
                  </div>
                  <div class="statistics-infor">
                    <h3 class="info-title">
                      <animated-number
                        :value="summary.customers"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Customers</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3">
              <div class="statistics">
                <div class="info">
                  <div class="icon icon-primary">
                    <img src="assets/imgs/order-receive.svg" alt="" />
                  </div>
                  <div class="statistics-infor">
                    <h3 class="info-title">
                      <animated-number
                        :value="summary.total_orders"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Orders</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3">
              <div class="statistics">
                <div class="info">
                  <div class="icon icon-success">
                    <img src="assets/imgs/items-sold.svg" alt="" />
                  </div>
                  <div class="statistics-infor">
                    <h3 class="info-title">
                      <small></small>
                      <animated-number :value="summary.items"></animated-number>
                    </h3>
                    <h6 class="stats-title">Items</h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
              v-if="!hideTokens"
            >
              <div class="statistics">
                <div class="info">
                  <div class="icon icon-info">
                    <img src="assets/imgs/token.svg" alt="" />
                  </div>
                  <div class="statistics-infor">
                    <h3 class="info-title">
                      <animated-number
                        v-if="currentPriceType === 'token'"
                        :value="summary.tokens"
                      ></animated-number>
                      <animated-number
                        v-if="currentPriceType === 'dollar'"
                        :value="currentEvent.token_value * summary.tokens"
                        type="currency"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Tokens Issued</h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
              v-if="hideTokens"
            >
              <div class="statistics">
                <div class="info">
                  <div class="icon icon-info">
                    <img src="assets/imgs/token.svg" alt="" />
                  </div>
                  <div class="statistics-infor">
                    <h3 class="info-title">
                      <animated-number
                        v-if="
                          summary.tokens !== undefined &&
                          currentPriceType === 'token'
                        "
                        :value="
                          summary.total_orders === 0
                            ? 0
                            : summary.tokens / summary.total_orders
                        "
                      ></animated-number>
                      <animated-number
                        v-if="
                          summary.tokens !== undefined &&
                          currentPriceType === 'dollar'
                        "
                        type="currency"
                        :value="
                          summary.total_orders === 0
                            ? 0
                            : (summary.tokens / summary.total_orders) *
                              currentEvent.token_value
                        "
                      ></animated-number>
                      <animated-number
                        v-if="summary.tokens === undefined"
                        type="currency"
                        :value="0"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Avg Order</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
              <div class="statistics">
                <div class="info">
                  <div class="icon icon-danger">
                    <img src="assets/imgs/revenue.svg" alt="" />
                  </div>
                  <div class="statistics-infor">
                    <h3 class="info-title">
                      <animated-number
                        v-if="
                          summary.tokens_spent !== undefined &&
                          currentPriceType === 'token'
                        "
                        :value="summary.tokens_spent"
                      ></animated-number>
                      <animated-number
                        v-if="
                          summary.tokens_spent !== undefined &&
                          currentPriceType === 'dollar'
                        "
                        type="currency"
                        :value="summary.tokens_spent * currentEvent.token_value"
                      ></animated-number>
                      <animated-number
                        v-if="summary.tokens_spent === undefined"
                        type="currency"
                        :value="0"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Revenue</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, AnimatedNumber } from "src/components";
import EventMixin from "src/mixins/Event";

export default {
  props: ["summary", "hideTokens", "currentPriceType"],
  mixins: [EventMixin],
  components: {
    AnimatedNumber,
  },
};
</script>
